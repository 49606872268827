import withAuthorizedGuard from '@/features/auth/hocs/withAuthorizedGuard';
import withActiveCompanyGuard from '@/features/company/hocs/withActiveCompanyGuard';
import withInvitedUserGuard from '@/features/company/hocs/withInvitedUserGuard';
import withMerchantWalletOwnershipCheck from '@/features/merchant-wallets/hocs/withMerchantWalletOwnershipCheck';
import withOnboardedGuard from '@/pages/onboard/hocs/withOnboardedGuard';
import { AppPageRoutes, appRoute } from '@/pages/routes';

import type React from 'react';

const withBasicPageGuard = <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  withAuthorizedGuard(appRoute(AppPageRoutes.AUTH))(
    withOnboardedGuard(withMerchantWalletOwnershipCheck(withInvitedUserGuard(withActiveCompanyGuard(Component)))),
  );

export default withBasicPageGuard;
