import type {
  BlockchainTypeAPIModel,
  MerchantWalletAPIModel,
  MerchantWalletSignatureAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import type { LoadingFullDataState } from '@/infrastructure/model/full/types';
import type { SingleState } from '@/infrastructure/model/single/types';

import type { Address } from 'viem';

export const NAMESPACE = 'merchant-wallets';

export interface MerchantWalletId {
  bt: BlockchainTypeAPIModel;
  address: Address;
}

export type MerchantWalletDeploymentState = {
  bt: BlockchainTypeAPIModel;
} & ({ address: Address; deployed: true } | { deployed: false });

export type MerchantWalletSignature = MerchantWalletSignatureAPIModel;
export type MerchantWallet = MerchantWalletAPIModel;

export interface MerchantWalletsState {
  ownership: LoadingStateWithDirty<MerchantWalletSignature>;
  deployments: SingleState<MerchantWalletDeploymentState>;
  wallets: LoadingFullDataState<MerchantWallet>;
}
