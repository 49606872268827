import { createReducer } from '@reduxjs/toolkit';

import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { extractReportScheduleId } from '@/features/report-schedules/utils';
import { defaultPage } from '@/infrastructure/model/api';
import { createNormalizedListReducers } from '@/infrastructure/model/list/reducers';
import { storedDirtyListDataTyped } from '@/infrastructure/model/list/utils';

import {
  markMultipleReportScheduleDirty,
  markReportScheduleDirty,
  markReportScheduleListDirty,
  storeMultipleReportSchedule,
  storeRemoveReportSchedule,
  storeReportSchedule,
  storeReportScheduleListData,
  storeReportScheduleListParameters,
} from './actions';
import { defaultReportScheduleSortBy } from './types';

import type { ReportSchedulesState } from './types';
import type { Draft } from 'immer';

const initialState: ReportSchedulesState = {
  entities: {},
  list: {
    data: storedDirtyListDataTyped(),
    page: defaultPage,
    filter: {},
    sortBy: defaultReportScheduleSortBy,
    columnState: {},
  },
};

const {
  markReportScheduleListDirtyReducer,
  storeReportScheduleListDataReducer,
  storeReportScheduleListParametersReducer,
  markReportScheduleDirtyReducer,
  markMultipleReportScheduleDirtyReducer,
  storeReportScheduleReducer,
  storeRemoveReportScheduleReducer,
  storeMultipleReportScheduleReducer,
} = createNormalizedListReducers(
  'ReportSchedule' as const,
  (state: Draft<ReportSchedulesState>) => state.list,
  (state, list) => ({ ...state, list }),
  initialState.list,
  (state) => state.entities,
  (state, entities) => ({ ...state, entities }),
  extractReportScheduleId,
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markReportScheduleListDirty, markReportScheduleListDirtyReducer)
    .addCase(storeReportScheduleListData, storeReportScheduleListDataReducer)
    .addCase(storeReportScheduleListParameters, storeReportScheduleListParametersReducer)
    .addCase(markReportScheduleDirty, markReportScheduleDirtyReducer)
    .addCase(markMultipleReportScheduleDirty, markMultipleReportScheduleDirtyReducer)
    .addCase(storeReportSchedule, storeReportScheduleReducer)
    .addCase(storeRemoveReportSchedule, storeRemoveReportScheduleReducer)
    .addCase(storeMultipleReportSchedule, storeMultipleReportScheduleReducer)

    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address ? initialState : state,
    );
});

export default reducer;
