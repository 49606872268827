import type { PushAddressLinkAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { stringComparator } from '@/infrastructure/model/comparators';

import type { CollectableEntityTransaction, CollectTask, CollectTaskSummary } from './types';

export const extractCollectTaskId = ({ id }: Pick<CollectTask, 'id'> | Pick<CollectTaskSummary, 'id'>) => id;
export const extractEntityTransactionId = ({ id }: Pick<CollectableEntityTransaction, 'id'>) => id;

const addressLinkComparator = stringComparator<PushAddressLinkAPIModel>(({ address, asset }) => `${asset}${address}`);
export const collectableTaskLinksToId = (addresses: PushAddressLinkAPIModel[]) =>
  [...addresses]
    .sort(addressLinkComparator('DESC'))
    .map(({ address, asset }) => `${asset}${address}`)
    .join('_');
