/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SignatureComponentsAPIModel } from './SignatureComponentsAPIModel';
import {
    SignatureComponentsAPIModelFromJSON,
    SignatureComponentsAPIModelFromJSONTyped,
    SignatureComponentsAPIModelToJSON,
} from './SignatureComponentsAPIModel';

/**
 * 
 * @export
 * @interface CompanySeedWeb3CredentialsAPIModel
 */
export interface CompanySeedWeb3CredentialsAPIModel {
    /**
     * 
     * @type {SignatureComponentsAPIModel}
     * @memberof CompanySeedWeb3CredentialsAPIModel
     */
    signature: SignatureComponentsAPIModel;
    /**
     * 
     * @type {string}
     * @memberof CompanySeedWeb3CredentialsAPIModel
     */
    message?: string;
}

/**
 * Check if a given object implements the CompanySeedWeb3CredentialsAPIModel interface.
 */
export function instanceOfCompanySeedWeb3CredentialsAPIModel(value: object): boolean {
    if (!('signature' in value)) return false;
    return true;
}

export function CompanySeedWeb3CredentialsAPIModelFromJSON(json: any): CompanySeedWeb3CredentialsAPIModel {
    return CompanySeedWeb3CredentialsAPIModelFromJSONTyped(json, false);
}

export function CompanySeedWeb3CredentialsAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySeedWeb3CredentialsAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'signature': SignatureComponentsAPIModelFromJSON(json['signature']),
        'message': json['message'] == null ? undefined : json['message'],
    };
}

export function CompanySeedWeb3CredentialsAPIModelToJSON(value?: CompanySeedWeb3CredentialsAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'signature': SignatureComponentsAPIModelToJSON(value['signature']),
        'message': value['message'],
    };
}

