import type { Subscription } from '@/features/subscriptions/types';
import {
  type BlockchainTypeAPIModel,
  SubscriptionApi,
  type SubscriptionPredicatesAPIModel,
  type SubscriptionSortByAPIModel,
  type SubscriptionSummaryAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { coreConfigurationFactory } from '@/infrastructure/api';
import {
  amountFromAPI,
  type DataSlice,
  pageToAPI,
  type SliceDataRequestWithTotal,
  sliceFromAPI,
} from '@/infrastructure/model/api';

const subscriptionApi = new SubscriptionApi(coreConfigurationFactory('auth'));

export const parseSubscription = ({
  pendingStatuses,
  allowance,
  ...subscription
}: SubscriptionSummaryAPIModel): Subscription => ({
  ...subscription,
  id: subscription.contractAddress,
  pendingStatuses: pendingStatuses ?? [],
  allowance: amountFromAPI(allowance),
});

export const querySubscriptions = async (
  {
    page,
    filter,
    orderBy,
    withTotal,
  }: SliceDataRequestWithTotal<SubscriptionPredicatesAPIModel, SubscriptionSortByAPIModel>,
  initOverrides?: RequestInit,
): Promise<DataSlice<Subscription, SubscriptionSortByAPIModel>> =>
  sliceFromAPI(
    await subscriptionApi.searchSubscriptions(
      { page: pageToAPI(page, orderBy), predicates: filter ?? {} },
      withTotal,
      initOverrides,
    ),
    parseSubscription,
  );

export const querySubscription = async (subscriptionId: string, initOverrides?: RequestInit): Promise<Subscription> =>
  parseSubscription(await subscriptionApi.getSubscription(subscriptionId, initOverrides));

export const requestCancelSubscription = async (subscriptionId: string, initOverrides?: RequestInit) =>
  parseSubscription(await subscriptionApi.cancelSubscription(subscriptionId, initOverrides));

export const requestHintSubscriptionStatusChanged = async (
  blockchain: BlockchainTypeAPIModel,
  hash: string,
  initOverrides?: RequestInit,
) => parseSubscription(await subscriptionApi.hintUpdateSubscriptionState({ blockchain, hash }, initOverrides));
