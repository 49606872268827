import type { OnboardRequestAPIModel } from '@/generated/api/ncps-api';
import type {
  CompanyUserAPIModel,
  MerchantPermissionsAPIModel,
  UserCompanyInfoAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import type { LoadingFullDataState } from '@/infrastructure/model/full/types';

import type { Address } from 'viem';

export const NAMESPACE = 'company';

export type NewCompany = Omit<OnboardRequestAPIModel, 'emailTokenProvider'>;

export interface UpdateCompanyName {
  name: string;
}

export type Company = UserCompanyInfoAPIModel;

export type MerchantPermission = keyof MerchantPermissionsAPIModel;
export type MerchantPermissions = MerchantPermissionsAPIModel;

export interface CompanyWithOwner extends Company {
  isOwner: boolean;
}

export interface CompanyWithSelection extends CompanyWithOwner {
  isSelected: boolean;
}

export interface CompanyUser extends Omit<CompanyUserAPIModel, 'address'> {
  address: Address;
}

export interface CompanyState {
  companies: LoadingFullDataState<Company>;
  users: LoadingFullDataState<CompanyUser>;
  permissions: LoadingStateWithDirty<MerchantPermissions>;
}
