/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PageSubscriptionPlanSortByAPIModel } from './PageSubscriptionPlanSortByAPIModel';
import {
    PageSubscriptionPlanSortByAPIModelFromJSON,
    PageSubscriptionPlanSortByAPIModelFromJSONTyped,
    PageSubscriptionPlanSortByAPIModelToJSON,
} from './PageSubscriptionPlanSortByAPIModel';
import type { SubscriptionPlanPredicatesAPIModel } from './SubscriptionPlanPredicatesAPIModel';
import {
    SubscriptionPlanPredicatesAPIModelFromJSON,
    SubscriptionPlanPredicatesAPIModelFromJSONTyped,
    SubscriptionPlanPredicatesAPIModelToJSON,
} from './SubscriptionPlanPredicatesAPIModel';

/**
 * 
 * @export
 * @interface SubscriptionPlanFilterAPIModel
 */
export interface SubscriptionPlanFilterAPIModel {
    /**
     * 
     * @type {SubscriptionPlanPredicatesAPIModel}
     * @memberof SubscriptionPlanFilterAPIModel
     */
    predicates: SubscriptionPlanPredicatesAPIModel;
    /**
     * 
     * @type {PageSubscriptionPlanSortByAPIModel}
     * @memberof SubscriptionPlanFilterAPIModel
     */
    page: PageSubscriptionPlanSortByAPIModel;
}

/**
 * Check if a given object implements the SubscriptionPlanFilterAPIModel interface.
 */
export function instanceOfSubscriptionPlanFilterAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    if (!('page' in value)) return false;
    return true;
}

export function SubscriptionPlanFilterAPIModelFromJSON(json: any): SubscriptionPlanFilterAPIModel {
    return SubscriptionPlanFilterAPIModelFromJSONTyped(json, false);
}

export function SubscriptionPlanFilterAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionPlanFilterAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': SubscriptionPlanPredicatesAPIModelFromJSON(json['predicates']),
        'page': PageSubscriptionPlanSortByAPIModelFromJSON(json['page']),
    };
}

export function SubscriptionPlanFilterAPIModelToJSON(value?: SubscriptionPlanFilterAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': SubscriptionPlanPredicatesAPIModelToJSON(value['predicates']),
        'page': PageSubscriptionPlanSortByAPIModelToJSON(value['page']),
    };
}

