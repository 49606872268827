import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { storedDirtyData } from '@/infrastructure/model';
import type { OrderBy, Page } from '@/infrastructure/model/api';
import { defaultPage } from '@/infrastructure/model/api';

import type { ColumnsState } from '@ant-design/pro-table';

export interface ListData<Value> {
  data: Value[];
  total: number;
}

export interface LoadingListDataState<Value, Exc = string> extends LoadingStateWithDirty<ListData<Value>, Exc> {
  isTotalDirty: boolean;
}

export type ListSortBy<T extends string> = OrderBy<T>;

export type ListColumnState = Record<string, ColumnsState>;

export interface ListParametersState<Filter, SortBy extends string> {
  page: Page;
  filter: Filter;
  columnState: ListColumnState;
  sortBy: ListSortBy<SortBy>;
}

export interface ListStateNoColumns<Value, Filter, SortBy extends string>
  extends Omit<ListParametersState<Filter, SortBy>, 'columnState'> {
  data: LoadingListDataState<Value>;
}

export interface ListState<Value, Filter, SortBy extends string> extends ListParametersState<Filter, SortBy> {
  data: LoadingListDataState<Value>;
}

export interface UpdateListParametersPayload<Filter, SortBy extends string> {
  page?: Page | null;
  filter?: Filter | null;
  columnState?: ListColumnState | null;
  sortBy?: ListSortBy<SortBy> | null;
}

export interface FetchListPayload {
  force: boolean;
}

export const defaultListState = <Value, Filter, SortBy extends string>(
  filter: Filter,
  sortBy: ListSortBy<SortBy>,
): ListState<Value, Filter, SortBy> => ({
  data: {
    ...storedDirtyData,
    isTotalDirty: true,
  },
  filter,
  sortBy,
  columnState: {},
  page: defaultPage,
});
