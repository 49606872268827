/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RenewalConfirmationResponseAPIModel
 */
export interface RenewalConfirmationResponseAPIModel {
    /**
     * 
     * @type {string}
     * @memberof RenewalConfirmationResponseAPIModel
     */
    oldEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof RenewalConfirmationResponseAPIModel
     */
    newEmail: string;
}

/**
 * Check if a given object implements the RenewalConfirmationResponseAPIModel interface.
 */
export function instanceOfRenewalConfirmationResponseAPIModel(value: object): boolean {
    if (!('newEmail' in value)) return false;
    return true;
}

export function RenewalConfirmationResponseAPIModelFromJSON(json: any): RenewalConfirmationResponseAPIModel {
    return RenewalConfirmationResponseAPIModelFromJSONTyped(json, false);
}

export function RenewalConfirmationResponseAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenewalConfirmationResponseAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'oldEmail': json['oldEmail'] == null ? undefined : json['oldEmail'],
        'newEmail': json['newEmail'],
    };
}

export function RenewalConfirmationResponseAPIModelToJSON(value?: RenewalConfirmationResponseAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'oldEmail': value['oldEmail'],
        'newEmail': value['newEmail'],
    };
}

