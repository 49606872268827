import Icon from '@ant-design/icons';
import { List, Space, Typography } from 'antd';
import React, { useCallback } from 'react';

import LogoSmallLight from '@/assets/icons/smartypay/logo-small-light';
import { FormattedMessage } from '@/components';
import { OperationDeleteAPIKey, OperationUpdateAPIKeyStatus } from '@/features/company-settings/components';
import type { APIKey } from '@/features/company-settings/types';
import { I18nPageSettings } from '@/generated/i18n/i18n';

import { withAPIKeysDataLoading } from './hocs';

import type { APIKeysCardProps } from './types';

const { Text, Paragraph } = Typography;

const APIKeysCard: React.FC<APIKeysCardProps> = ({ data, 'data-test': dataTest }) => (
  <>
    <Paragraph style={{ paddingBottom: '20px' }} data-test={dataTest && `${dataTest}-description`}>
      <FormattedMessage id={I18nPageSettings.INTEGRATION_COMPONENTS_API_KEYS_CARD_DESCRIPTION} />
    </Paragraph>
    <List<APIKey>
      itemLayout="horizontal"
      dataSource={data}
      renderItem={useCallback(
        (item: APIKey) => (
          <List.Item key={item.key}>
            <List.Item.Meta
              style={{ alignItems: 'center' }}
              avatar={<Icon style={{ fontSize: 32 }} component={LogoSmallLight} />}
              title={
                <Text
                  data-test={dataTest && `${dataTest}-title`}
                  ellipsis
                  copyable
                  style={{ width: '100%' }}
                  className="ym-hide-content"
                >
                  {item.key}
                </Text>
              }
            />
            <Space>
              <OperationUpdateAPIKeyStatus id={item} data-test={dataTest && `${dataTest}-edit`} />
              <OperationDeleteAPIKey id={item} data-test={dataTest && `${dataTest}-delete`} confirmation />
            </Space>
          </List.Item>
        ),
        [dataTest],
      )}
    />
  </>
);

const APIKeysCardLoaded = withAPIKeysDataLoading(APIKeysCard);

const APIKeysCardMemo = React.memo(APIKeysCardLoaded) as typeof APIKeysCardLoaded;

export default APIKeysCardMemo;
