import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { storeMultipleMerchantWalletTransfer } from '@/features/merchant-wallet-transfers/actions';
import { queryMerchantWalletTransfers } from '@/features/merchant-wallet-transfers/api';
import type {
  MerchantWalletTransfer,
  MerchantWalletTransferFilterPredicate,
} from '@/features/merchant-wallet-transfers/types';
import { extractMerchantWalletTransferId } from '@/features/merchant-wallet-transfers/utils';
import type { MerchantWalletTransferSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { MerchantWalletTransferDirectionAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { combine, mapLoadingState } from '@/infrastructure/model';
import { defaultPageFn, withAPICall, withFetchAllDataOrThrow } from '@/infrastructure/model/api';
import { createNormalizedFullActions } from '@/infrastructure/model/full/actions';
import { loadingSliceStateToFullData } from '@/infrastructure/model/full/utils';
import { sliceToMultipleEntities } from '@/infrastructure/model/list/utils';
import { createSingleActions } from '@/infrastructure/model/single/actions';
import { toMultiplePayload } from '@/infrastructure/model/single/utils';
import { identity } from '@/infrastructure/utils/functions';

import { queryReconciliations, querySettlementReconciliation, requestRecalculateSettlementReconciliation } from './api';
import {
  makeSelectDirtySettlementReconciliationIds,
  makeSelectIncomingForReconciliationFullData,
  makeSelectMultipleSettlementReconciliation,
  makeSelectOutgoingForReconciliationFullData,
  makeSelectSettlementReconciliation,
} from './selectors';
import { NAMESPACE, type SettlementReconciliation } from './types';

export const {
  storeSettlementReconciliation,
  storeMultipleSettlementReconciliation,
  markSettlementReconciliationDirty,
} = createSingleActions<SettlementReconciliation, 'SettlementReconciliation'>(NAMESPACE, 'SettlementReconciliation');

const reconciliationFetchLimit = pLimit(1);
export const fetchSettlementReconciliation = createAppAsyncThunk(
  `${NAMESPACE}/fetchSettlementReconciliation`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    reconciliationFetchLimit(async () => {
      const saved = makeSelectSettlementReconciliation(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(querySettlementReconciliation, 'unable to fetch reconciliation')(id, {
        signal,
      });
      dispatch(storeSettlementReconciliation({ id, data }));

      return makeSelectSettlementReconciliation(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

const settlementsFetchLimit = pLimit(1);
export const fetchMultipleSettlementReconciliation = createAppAsyncThunk(
  `${NAMESPACE}/fetchMultipleSettlementReconciliation`,
  async ({ force, ids }: { force?: boolean; ids: string[] }, { dispatch, getState, signal }) =>
    settlementsFetchLimit(async () => {
      const absent = makeSelectDirtySettlementReconciliationIds(ids)(getState());
      if (!force && !absent.length) {
        return makeSelectMultipleSettlementReconciliation(ids)(getState());
      }

      const data = await withAPICall(queryReconciliations, 'unable to fetch company')(
        { filter: { idIn: ids }, page: defaultPageFn({ perPage: ids.length }) },
        { signal },
      );
      dispatch(
        storeMultipleSettlementReconciliation(
          toMultiplePayload(
            mapLoadingState(data, ({ list }) => list),
            ids,
            ({ id }) => id,
            identity,
          ),
        ),
      );

      return makeSelectMultipleSettlementReconciliation(ids)(getState());
    }),
);

const recalculateLimit = pLimit(1);
export const recalculateSettlementReconciliation = createAppAsyncThunk(
  `${NAMESPACE}/recalculateSettlementReconciliation`,
  async ({ id }: { id: string }, { dispatch, getState, signal }) =>
    recalculateLimit(async () => {
      const data = await withAPICall(requestRecalculateSettlementReconciliation, 'unable to recalculate')(id, {
        signal,
      });

      if (data.data) {
        dispatch(storeSettlementReconciliation({ id, data }));
      }

      return makeSelectSettlementReconciliation(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

export const {
  storeIncomingForReconciliationFullData,
  storeIncomingForReconciliationFullDataParameters,
  markIncomingForReconciliationFullDirty,
} = createNormalizedFullActions<
  MerchantWalletTransfer,
  'IncomingForReconciliation',
  MerchantWalletTransferFilterPredicate,
  MerchantWalletTransferSortByAPIModel
>(NAMESPACE, 'IncomingForReconciliation');

export const {
  storeOutgoingForReconciliationFullData,
  storeOutgoingForReconciliationFullDataParameters,
  markOutgoingForReconciliationFullDirty,
} = createNormalizedFullActions<
  MerchantWalletTransfer,
  'OutgoingForReconciliation',
  MerchantWalletTransferFilterPredicate,
  MerchantWalletTransferSortByAPIModel
>(NAMESPACE, 'OutgoingForReconciliation');

const transfersPerReconciliationFetchLimit = pLimit(1);
export const fetchTransfersForReconciliation = createAppAsyncThunk(
  `${NAMESPACE}/fetchTransfersForReconciliation`,
  async ({ force, settlementId }: { force?: boolean; settlementId: string }, { dispatch, getState, signal }) =>
    transfersPerReconciliationFetchLimit(async () => {
      const incoming = makeSelectIncomingForReconciliationFullData(settlementId)(getState());
      const outgoing = makeSelectOutgoingForReconciliationFullData(settlementId)(getState());
      if (!force && !incoming.isDirty && !outgoing.isDirty) {
        return combine(incoming, outgoing, (data1, data2) => [...data1, ...data2]);
      }

      const data = await withAPICall(
        withFetchAllDataOrThrow(
          (page) => () => queryMerchantWalletTransfers({ filter: { withdrawalIn: [settlementId] }, page }, { signal }),
        ),
        'unable to fetch reconciliation transfers',
      )();
      dispatch(
        storeOutgoingForReconciliationFullData({
          parentId: settlementId,
          data: mapLoadingState(loadingSliceStateToFullData(data), (transfers) =>
            transfers.filter(({ direction }) => direction === MerchantWalletTransferDirectionAPIModel.Outgoing),
          ),
        }),
      );
      dispatch(
        storeIncomingForReconciliationFullData({
          parentId: settlementId,
          data: mapLoadingState(loadingSliceStateToFullData(data), (transfers) =>
            transfers.filter(({ direction }) => direction === MerchantWalletTransferDirectionAPIModel.Incoming),
          ),
        }),
      );
      if (data.data) {
        dispatch(
          storeMultipleMerchantWalletTransfer(sliceToMultipleEntities(data.data, extractMerchantWalletTransferId)),
        );
      }

      return combine(
        makeSelectIncomingForReconciliationFullData(settlementId)(getState()),
        makeSelectOutgoingForReconciliationFullData(settlementId)(getState()),
        (data1, data2) => [...data1, ...data2],
      );
    }),
  { idGenerator: ({ settlementId }) => settlementId },
);
