import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { FormattedMessage } from '@/components';
import { useRechargeTransaction } from '@/features/recharges/hooks';
import { MerchantWalletTransferKindAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { uuidToBase58 } from '@/infrastructure/utils/uuid';
import { rechargeTransactionViewLink } from '@/pages/recharges/routes';

import EntityNotFoundAlert from '../EntityNotFoundAlert';

import type { RechargeTransactionEntityLinkProps } from './types';

const RechargeTransactionEntityLink: React.FC<RechargeTransactionEntityLinkProps> = ({
  'data-test': dataTest,
  value,
}) => {
  const rechargeId = useMemo(
    () => (value.entityId?.includes('-') ? uuidToBase58(value.entityId) : value.entityId),
    [value.entityId],
  );
  const data = useRechargeTransaction(rechargeId);

  if (data.data.isDirty || data.loading) {
    return <FormattedMessage id={I18nPageAudit.TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_LOADING} />;
  }

  if (data.data.data?.id) {
    return <Navigate to={rechargeTransactionViewLink(data.data.data.id)} replace />;
  }

  return (
    <EntityNotFoundAlert
      data-test={dataTest}
      value={MerchantWalletTransferKindAPIModel.RechargeTransaction}
      onRetry={data.forceRefresh}
    />
  );
};

const RechargeTransactionEntityLinkMemo = React.memo(RechargeTransactionEntityLink);

export default RechargeTransactionEntityLinkMemo;
