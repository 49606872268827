import type { ReCaptchaParams } from '@/features/recaptcha/types';
import { parseUser } from '@/features/user/api';
import type { OpenIdProviderTypeAPIModel, TokenWithUserAPIModel } from '@/generated/api/ncps-api';
import { AuthWeb3XRecaptchaTypeEnum, MerchantAuthApi, MerchantUserApi } from '@/generated/api/ncps-api';
import { apiConfigurationFactory } from '@/infrastructure/api';

import type { JWTTokenWithUser, Web3Credentials } from './types';

const userAPINoAuth = new MerchantUserApi(apiConfigurationFactory('public'));
const authAPINoAuth = new MerchantAuthApi(apiConfigurationFactory('public'));
const authAPIWithAuth = new MerchantAuthApi(apiConfigurationFactory('no-relogin'));

const jwtTokenWithUser = ({ user, token }: TokenWithUserAPIModel): JWTTokenWithUser => ({
  user: parseUser(user),
  token,
});

export const queryAuthParameters = async (address: string, initOverrides?: RequestInit) =>
  await authAPIWithAuth.getWeb3AddressAuthParams(address, initOverrides);

export const requestAuthWeb3 = async (
  credentials: Web3Credentials,
  { recaptchaToken, recaptchaType }: ReCaptchaParams,
  initOverrides?: RequestInit,
) =>
  jwtTokenWithUser(
    await authAPINoAuth.authWeb3(recaptchaToken, credentials, AuthWeb3XRecaptchaTypeEnum[recaptchaType], initOverrides),
  );

export const requestAuthEmail = async (
  credentials: Web3Credentials,
  emailToken: string,
  emailProvider: OpenIdProviderTypeAPIModel,
  initOverrides?: RequestInit,
): Promise<JWTTokenWithUser> =>
  jwtTokenWithUser(
    await authAPINoAuth.authEmail({ credentials, emailToken, emailTokenProvider: emailProvider }, initOverrides),
  );

/** Authenticates user for new company */
export const requestRelogin = async (companyId: number, initOverrides?: RequestInit): Promise<string> =>
  (await authAPIWithAuth.authCompany(companyId, initOverrides)).token;

// the token is in headers means no logout is required
// @ts-expect-error the token is in headers means no logout is required
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const requestLogout = async (initOverrides?: RequestInit): Promise<void> => Promise.resolve();

export const requestEmailResetAllowance = async (email: string, initOverrides?: RequestInit): Promise<boolean> =>
  (await userAPINoAuth.checkEmailResetAllowance(email, initOverrides)).isAllowed;
