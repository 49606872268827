import Icon from '@ant-design/icons';
import React, { forwardRef } from 'react';

import BTCLogo from '@/assets/icons/bitcoin.svg?react';
import BUSDLogo from '@/assets/icons/busd.svg?react';
import DAILogo from '@/assets/icons/dai.svg?react';
import LogoSmallLight from '@/assets/icons/smartypay/logo-small-light';
import TetherLogo from '@/assets/icons/tether.svg?react';
import USDCLogo from '@/assets/icons/usdc.svg?react';

import type { AssetIconProps } from './types';

const mapping: Partial<Record<string, typeof DAILogo>> = {
  USDT: TetherLogo,
  USDC: USDCLogo,
  sUSDT: TetherLogo,
  sUSDC: USDCLogo,
  bUSDT: TetherLogo,
  btUSDTv2: TetherLogo,
  bUSDC: USDCLogo,
  tsUSDt: TetherLogo,
  pUSDC: USDCLogo,
  pUSDT: TetherLogo,
  paUSDC: USDCLogo,
  paUSDT: TetherLogo,
  aUSDC: USDCLogo,
  aUSDT: TetherLogo,
  asUSDC: USDCLogo,
  asUSDT: TetherLogo,
  BTC: BTCLogo,
  tBTC: BTCLogo,

  // usupported
  DAI: DAILogo,
  EURx: LogoSmallLight,
  USDx: LogoSmallLight,
  gUSDT: TetherLogo,
  gUSDC: USDCLogo,
  bBUSD: BUSDLogo,
  btUSDT: TetherLogo,
  btBUSD: BUSDLogo,
  btMNXe: LogoSmallLight,
  tsMNXe: LogoSmallLight,
  atUSDC: USDCLogo,
  atUSDT: TetherLogo,
  pmUSDC: USDCLogo,
  pmUSDT: TetherLogo,
};

const defaultIcon = LogoSmallLight;

const AssetIcon: React.FC<AssetIconProps> = forwardRef<HTMLSpanElement, AssetIconProps>(function AssetIcon(
  { 'data-test': dataTest, type, className, style },
  ref,
) {
  return (
    <Icon component={mapping[type] ?? defaultIcon} className={className} style={style} data-test={dataTest} ref={ref} />
  );
});

const AssetIconMemo = React.memo(AssetIcon);

export default AssetIconMemo;
