import React, { useCallback, useMemo } from 'react';

import {
  FormattedMessage,
  NCPSTable,
  ReadonlyComponent,
  ReadonlyDateTime,
  StopPropagationContainer,
  TitleWithDetails,
} from '@/components';
import ReportView from '@/features/reports/components/ReportView';
import type { Report, ReportFilterPredicate } from '@/features/reports/types';
import { extractReportId } from '@/features/reports/utils';
import { ReportSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nComponents, I18nFeatureReports } from '@/generated/i18n/i18n';
import { sortOrderToTable } from '@/infrastructure/model/view';
import { enumByKey, nameof } from '@/infrastructure/utils/ts';

import OperationDeleteReport from '../OperationDeleteReport';
import OperationDownloadReport from '../OperationDownloadReport';
import ReportStatusView from '../ReportStatusView';
import ReportTemplateTitle from '../ReportTemplateTitle';
import ReportTypeView from '../ReportTypeView';

import type { ReportsTableProps } from './types';
import type { ProColumns } from '@ant-design/pro-table';

const sortColumnToModel = (s: string) => enumByKey(ReportSortByAPIModel, s);

const ReportsTableRaw = <FilterType extends ReportFilterPredicate = ReportFilterPredicate>({
  'data-test': dataTest,
  data,
  loading,
  page,
  filter,
  columnState,
  sortBy,
  forceRefresh,
  updateParameters,
  FilterForm,
  ReportLink,
}: ReportsTableProps<FilterType>) => {
  const columns: ProColumns<Report>[] = useMemo(
    () => [
      {
        title: <FormattedMessage id={I18nFeatureReports.LABELS_ROW_TITLE_CREATED_AT} />,
        dataIndex: nameof<Report>('createdAt'),
        valueType: 'dateTime',
        sortOrder: sortOrderToTable(sortBy.createdAt),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        render: (_, { id, createdAt }) => (
          <ReadonlyDateTime
            style={{ whiteSpace: 'nowrap' }}
            data-test={dataTest && `${dataTest}-${id}-createdAt`}
            value={createdAt}
          />
        ),
      },
      {
        title: <FormattedMessage id={I18nFeatureReports.LABELS_ROW_TITLE_TEMPLATE} />,
        dataIndex: nameof<Report>('template'),
        render: (_, { id, template }) => (
          <ReportTemplateTitle value={template} data-test={dataTest && `${dataTest}-${id}-template`} />
        ),
      },
      {
        title: <FormattedMessage id={I18nFeatureReports.LABELS_ROW_TITLE_ID} />,
        dataIndex: nameof<Report>('id'),
        render: (_, { id }) => (
          <ReadonlyComponent
            value={id}
            style={{ whiteSpace: 'nowrap' }}
            data-test={dataTest && `${dataTest}-${id}-id`}
          />
        ),
      },
      {
        title: <FormattedMessage id={I18nFeatureReports.LABELS_ROW_TITLE_STATUS} />,
        dataIndex: nameof<Report>('status'),
        width: 80,
        fixed: 'right',
        render: (_, { id, status }) => (
          <ReportStatusView value={status} data-test={dataTest && `${dataTest}-${id}-status`} mode="icon" />
        ),
        align: 'center',
      },
      {
        title: <FormattedMessage id={I18nFeatureReports.LABELS_ROW_TITLE_TYPE} />,
        dataIndex: nameof<Report>('status'),
        width: 80,
        fixed: 'right',
        render: (_, { id, type }) => (
          <ReportTypeView value={type} data-test={dataTest && `${dataTest}-${id}-type`} mode="icon" />
        ),
        align: 'center',
      },
      {
        key: 'options',
        title: <FormattedMessage id={I18nComponents.TABLE_ROW_OPTIONS} />,
        dataIndex: nameof<Report>('id'),
        width: 1,
        fixed: 'right',
        render: (_, { id }) => (
          <StopPropagationContainer>
            {ReportLink && <ReportLink value={id} data-test={dataTest && `${dataTest}-${id}-linkTo`} />}
            <OperationDownloadReport reportId={id} data-test={dataTest && `${dataTest}-${id}-opDownload`} />
            <OperationDeleteReport reportId={id} data-test={dataTest && `${dataTest}-${id}-opDelete`} />
          </StopPropagationContainer>
        ),
      },
    ],
    [ReportLink, dataTest, sortBy.createdAt],
  );

  const preview = useCallback(
    (value: Report) => ({
      title: ReportLink ? (
        <TitleWithDetails
          title={
            <FormattedMessage id={I18nFeatureReports.COMPONENTS_REPORTS_TABLE_DIALOG_TITLE} values={{ id: value.id }} />
          }
          link={
            <ReportLink
              value={value.id}
              mode="text"
              title={<FormattedMessage id={I18nComponents.TABLE_LINK_DETAILS} values={{ id: value.id }} />}
            />
          }
          data-test={dataTest && `${dataTest}-reportDialogTitle`}
        />
      ) : (
        <FormattedMessage id={I18nFeatureReports.COMPONENTS_REPORTS_TABLE_DIALOG_TITLE} values={{ id: value.id }} />
      ),
      content: (
        <ReportView
          data-test={dataTest && `${dataTest}-dialog-view`}
          data={value}
          style={{ paddingTop: '10px' }}
          columns={1}
        />
      ),
      maskClosable: true,
      width: 650,
    }),
    [ReportLink, dataTest],
  );

  const filterForm = useMemo(
    () => (FilterForm ? { Form: FilterForm, empty: {} as FilterType } : undefined),
    [FilterForm],
  );

  return (
    <NCPSTable<Report, FilterType, ReportSortByAPIModel>
      data-test={dataTest}
      filter={filterForm}
      preview={preview}
      sortColumnToModel={sortColumnToModel}
      columns={columns}
      data={data}
      loading={loading}
      page={page}
      filterData={filter}
      columnsState={columnState}
      reload={forceRefresh}
      updateParameters={updateParameters}
      extractKey={extractReportId}
    />
  );
};

const ReportsTable = React.memo(ReportsTableRaw);

export default ReportsTable;
