import { Space } from 'antd';
import React, { useMemo } from 'react';

import type { LinkMode, OperationMode } from '@/components';
import { FormattedMessage, PageCard } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { RechargeAddressesTable, RechargesDocumentationLink } from '@/features/recharges/components';
import { useRechargeAddressListView } from '@/features/recharges/hooks';
import { I18nPageRecharges } from '@/generated/i18n/i18n';
import { useDimensions } from '@/hooks';
import RechargeAddressAddLink from '@/pages/recharges/address-add/components/RechargeAddressAddLink';

import type { RechargeAddressesCardProps } from './types';
import type { FC } from 'react';

const RechargeAddressesCard: FC<RechargeAddressesCardProps> = (props) => {
  const parameters = useRechargeAddressListView();
  const [ref, dimension] = useDimensions();
  const documentationLinkMode: LinkMode = useMemo(
    () => ((dimension?.width ?? 0) < 530 ? 'icon' : 'text_icon'),
    [dimension?.width],
  );
  const createMode: OperationMode = useMemo(
    () => ((dimension?.width ?? 0) < 430 ? 'inline' : 'button'),
    [dimension?.width],
  );

  const { 'data-test': dataTest } = props;
  return (
    <PageCard
      ref={ref}
      title={
        <Space>
          <FormattedMessage id={I18nPageRecharges.ADDRESSES_COMPONENTS_ADDRESSES_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-selectNetwork`} />
        </Space>
      }
      cardSize="big"
      extra={
        <Space>
          <RechargesDocumentationLink
            data-test={dataTest && `${dataTest}-documentationLink`}
            mode={documentationLinkMode}
          />
          <RechargeAddressAddLink data-test={dataTest && `${dataTest}-create`} mode={createMode} />
        </Space>
      }
    >
      <RechargeAddressesTable {...parameters} {...props} />
    </PageCard>
  );
};

const RechargeAddressesCardMemo = React.memo(RechargeAddressesCard);

export default RechargeAddressesCardMemo;
