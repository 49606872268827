import React, { useCallback, useMemo } from 'react';

import {
  FormattedMessage,
  NCPSTable,
  OperationDateTimeRelativeQuickFilter,
  ReadonlyComponent,
  ReadonlyDateTime,
  StopPropagationContainer,
  TitleWithDetails,
} from '@/components';
import { CollectableContractDeployStatusView } from '@/features/collectable/components';
import { AddressLink, AssetAmount } from '@/features/dictionary/blockchain/components';
import type { RechargeAddress } from '@/features/recharges/types';
import { extractRechargeAddressId } from '@/features/recharges/utils';
import { PushAddressSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nComponents, I18nFeatureRecharges } from '@/generated/i18n/i18n';
import { useDefaultNotification, useTableButtonsMode } from '@/hooks';
import { sortOrderToTable } from '@/infrastructure/model/view';
import { nameof } from '@/infrastructure/utils/ts';

import RechargeAddressView from '../RechargeAddressView';

import type { BaseRechargeAddressFilter, RechargeAddressesTableProps } from './types';
import type { ProColumns } from '@ant-design/pro-table';

const sortMapping = {
  [nameof<RechargeAddress>('createdAt')]: PushAddressSortByAPIModel.CreatedAt,
};
const extractKey = extractRechargeAddressId;
const sortColumnToModel = (s: string) => sortMapping[s];

const RechargeAddressesTable = <FilterType extends BaseRechargeAddressFilter = BaseRechargeAddressFilter>({
  'data-test': dataTest,
  data,
  columnState,
  filter,
  updateParameters,
  loading,
  forceRefresh,
  page,
  sortBy,
  ExportOperation,
  FilterForm,
  RechargeAddressLink,
  Operations,
}: RechargeAddressesTableProps<FilterType>) => {
  const { withDefaultError } = useDefaultNotification();

  const reload = useMemo(() => withDefaultError(forceRefresh), [forceRefresh, withDefaultError]);

  const columns: ProColumns<RechargeAddress>[] = useMemo(
    () => [
      {
        key: nameof<RechargeAddress>('id'),
        title: <FormattedMessage id={I18nFeatureRecharges.LABELS_ADDRESS_ROW_TITLE_ID} />,
        dataIndex: nameof<RechargeAddress>('id'),
        render: (_, { id }) => <ReadonlyComponent value={id} data-test={dataTest && `${dataTest}-${id}-id`} copyable />,
      },
      {
        key: nameof<RechargeAddress>('cid'),
        title: <FormattedMessage id={I18nFeatureRecharges.LABELS_ADDRESS_ROW_TITLE_CID} />,
        dataIndex: nameof<RechargeAddress>('cid'),
        render: (_, { id, cid }) => <ReadonlyComponent value={cid} data-test={dataTest && `${dataTest}-${id}-cid`} />,
      },
      {
        key: nameof<RechargeAddress>('balance'),
        title: <FormattedMessage id={I18nFeatureRecharges.LABELS_ADDRESS_ROW_TITLE_BALANCE} />,
        dataIndex: nameof<RechargeAddress>('balance'),
        render: (_, { id, balance }) => (
          <ReadonlyComponent
            value={
              <AssetAmount
                assetId={balance.asset}
                value={balance.value}
                withCurrency
                withBlockchainMark
                data-test={dataTest && `${dataTest}-${id}-balance`}
              />
            }
            copyable={false}
          />
        ),
        width: 120,
      },
      {
        key: nameof<RechargeAddress>('address'),
        title: <FormattedMessage id={I18nFeatureRecharges.LABELS_ADDRESS_ROW_TITLE_ADDRESS} />,
        dataIndex: nameof<RechargeAddress>('address'),
        render: (_, { id, blockchain, address }) => (
          <AddressLink data-test={dataTest && `${dataTest}-${id}-address`} bt={blockchain} address={address} />
        ),
      },
      {
        key: nameof<RechargeAddress>('createdAt'),
        title: <FormattedMessage id={I18nFeatureRecharges.LABELS_ADDRESS_ROW_TITLE_CREATED_AT} />,
        dataIndex: nameof<RechargeAddress>('createdAt'),
        valueType: 'dateTime',
        sortOrder: sortOrderToTable(sortBy.CreatedAt),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        render: (_, { id, createdAt }) => (
          <ReadonlyDateTime value={createdAt} data-test={dataTest && `${dataTest}-${id}-createdAt`} />
        ),
        width: 160,
      },
      {
        key: nameof<RechargeAddress>('deployStatus'),
        title: <FormattedMessage id={I18nFeatureRecharges.LABELS_ADDRESS_ROW_TITLE_DEPLOY_STATUS} />,
        dataIndex: nameof<RechargeAddress>('deployStatus'),
        hideInSetting: true,
        align: 'center',
        fixed: 'right',
        render: (_, { id, deployStatus }) => (
          <CollectableContractDeployStatusView
            value={deployStatus}
            data-test={dataTest && `${dataTest}-${id}-deployStatus`}
            mode="icon"
          />
        ),
        width: 60,
      },
      {
        key: 'options',
        title: <FormattedMessage id={I18nComponents.TABLE_ROW_OPTIONS} />,
        width: 1,
        fixed: 'right',
        render: (_, value) => (
          <StopPropagationContainer>
            {RechargeAddressLink && (
              <RechargeAddressLink value={value.id} data-test={dataTest && `${dataTest}-${value.id}-link`} />
            )}
            {Operations && <Operations data-test={dataTest && `${dataTest}-${value.id}-op`} value={value} />}
          </StopPropagationContainer>
        ),
      },
    ],
    [Operations, RechargeAddressLink, dataTest, sortBy.CreatedAt],
  );

  const preview = useCallback(
    (value: RechargeAddress) => ({
      title: (
        <TitleWithDetails
          title={
            <FormattedMessage
              id={I18nFeatureRecharges.COMPONENTS_RECHARGE_ADDRESSES_TABLE_RECHARGE_ADDRESS_DIALOG_TITLE}
              values={{ address: value.address }}
            />
          }
          link={
            RechargeAddressLink && (
              <RechargeAddressLink
                value={value.id}
                mode="text"
                title={<FormattedMessage id={I18nComponents.TABLE_LINK_DETAILS} values={{ id: value.id }} />}
              />
            )
          }
          data-test={dataTest && `${dataTest}-dialogTitle`}
        />
      ),
      content: (
        <RechargeAddressView
          data={value}
          data-test={dataTest && `${dataTest}-view`}
          style={{ paddingTop: '10px' }}
          columns={1}
        />
      ),
    }),
    [RechargeAddressLink, dataTest],
  );

  const { ref, quickFilterMode, exportMode } = useTableButtonsMode();
  const toolBar = useMemo(
    () =>
      FilterForm
        ? (filterComponent?: React.ReactNode) => [
            ...(ExportOperation
              ? [
                  <ExportOperation
                    key="export"
                    data-test={dataTest && `${dataTest}-export`}
                    mode={exportMode}
                    rows={data.data?.total}
                  />,
                ]
              : []),
            <OperationDateTimeRelativeQuickFilter<'createdAtRange', 'createdAtRangeRelative', FilterType>
              key="quickFilter"
              absoluteKey="createdAtRange"
              relativeKey="createdAtRangeRelative"
              onChange={(newValue) => updateParameters({ filter: newValue })}
              mode={quickFilterMode}
              value={filter}
              disabled={loading}
            />,
            filterComponent,
          ]
        : undefined,
    [
      FilterForm,
      ExportOperation,
      dataTest,
      exportMode,
      data.data?.total,
      quickFilterMode,
      filter,
      loading,
      updateParameters,
    ],
  );

  const filterForm = useMemo(
    () => (FilterForm ? { Form: FilterForm, empty: {} as FilterType } : undefined),
    [FilterForm],
  );

  return (
    <div ref={ref}>
      <NCPSTable<RechargeAddress, FilterType, PushAddressSortByAPIModel>
        data-test={dataTest}
        filter={filterForm}
        preview={preview}
        toolBarRender={toolBar}
        sortColumnToModel={sortColumnToModel}
        columns={columns}
        data={data}
        loading={loading}
        page={page}
        filterData={filter}
        columnsState={columnState}
        reload={reload}
        updateParameters={updateParameters}
        extractKey={extractKey}
      />
    </div>
  );
};

const RechargeAddressesTableMemo = React.memo(RechargeAddressesTable);

export default RechargeAddressesTableMemo;
