import { DefaultFooter } from '@ant-design/pro-components';
import { css, cx } from '@emotion/css';
import React from 'react';

import { layoutTheme, layoutToken } from '@/layouts/theme';

import type { LayoutFooterProps } from './types';

const year = new Date().getFullYear();
const copyright = `${year} Smarty Pay.`;

const LayoutFooter: React.FC<LayoutFooterProps> = ({ 'data-test': dataTest, className, style }) => (
  <DefaultFooter
    links={[]}
    copyright={copyright}
    data-test={dataTest}
    className={cx(
      css`
        background-color: ${layoutToken.header?.colorBgHeader ?? layoutTheme.colorBgElevated};

        > div > div {
          color: ${layoutTheme.colorText};
        }
      `,
      className,
    )}
    style={style}
  />
);

const LayoutFooterMemo = React.memo(LayoutFooter);

export default LayoutFooterMemo;
