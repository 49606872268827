import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import type { NavigateFunction } from 'react-router';

type OpenFormOptions =
  | { route: string; submitRoute?: undefined; cancelRoute?: string }
  | { route?: undefined; submitRoute: string; cancelRoute?: string }
  | { route?: undefined; submitRoute?: undefined; cancelRoute: string }
  | { route?: undefined; submitRoute: string; cancelRoute: string }
  | { route?: undefined; submitRoute?: undefined; cancelRoute?: undefined };

interface UsePageFormActionType {
  redirectOnSubmit: <T>(
    defaultRoute: string,
    extractId?: (created: T) => string,
  ) => (created: T) => ReturnType<NavigateFunction>;
  redirectOnCancel: (defaultRoute: string) => ReturnType<NavigateFunction>;
  openForm: (path: string, options?: OpenFormOptions) => ReturnType<NavigateFunction>;
}

const returnSubmitPathQueryParam = 'submit';
const returnCancelPathQueryParam = 'cancel';

export default function useFormPageAction(): UsePageFormActionType {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const redirectOnSubmit = useCallback(
    <T>(defaultRoute: string, extractId?: (created: T) => string) =>
      (created: T) => {
        const path = new URLSearchParams(search).get(returnSubmitPathQueryParam) || defaultRoute;
        const redirectTo = extractId ? path.replace(/(:[^/]+)/g, extractId(created)) : path;
        return navigate(redirectTo, { replace: true });
      },
    [navigate, search],
  );
  const redirectOnCancel = useCallback(
    (defaultRoute: string) => {
      const path = new URLSearchParams(search).get(returnCancelPathQueryParam) || defaultRoute;
      return navigate(path, { replace: true });
    },
    [navigate, search],
  );
  const openForm = useCallback(
    (path: string, options: OpenFormOptions = {}) =>
      navigate(
        `${path}${path.includes('?') ? '&' : '?'}${returnSubmitPathQueryParam}=${options.submitRoute || options.route || pathname}&${returnCancelPathQueryParam}=${
          options.cancelRoute || options.route || pathname
        }`,
      ),
    [navigate, pathname],
  );
  return { redirectOnSubmit, redirectOnCancel, openForm };
}
