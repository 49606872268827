/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  SliceSubscriptionChargeSubscriptionChargeSortByAPIModel,
  SubscriptionChargeAPIModel,
  SubscriptionChargeFilterAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    SliceSubscriptionChargeSubscriptionChargeSortByAPIModelFromJSON,
    SliceSubscriptionChargeSubscriptionChargeSortByAPIModelToJSON,
    SubscriptionChargeAPIModelFromJSON,
    SubscriptionChargeAPIModelToJSON,
    SubscriptionChargeFilterAPIModelFromJSON,
    SubscriptionChargeFilterAPIModelToJSON,
} from '../models/index';

export interface GetSubscriptionChargeRequest {
    id: string;
}

export interface SearchSubscriptionChargesRequest {
    subscriptionChargeFilterAPIModel: SubscriptionChargeFilterAPIModel;
    withTotal?: boolean;
}

/**
 * SubscriptionChargeApi - interface
 * 
 * @export
 * @interface SubscriptionChargeApiInterface
 */
export interface SubscriptionChargeApiInterface {
    /**
     * Returns subscription charge by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionChargeApiInterface
     */
    getSubscriptionChargeRaw(requestParameters: GetSubscriptionChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionChargeAPIModel>>;

    /**
     * Returns subscription charge by id
     */
    getSubscriptionCharge(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionChargeAPIModel>;

    /**
     * Returns subscription charges by a filter
     * @param {SubscriptionChargeFilterAPIModel} subscriptionChargeFilterAPIModel 
     * @param {boolean} [withTotal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionChargeApiInterface
     */
    searchSubscriptionChargesRaw(requestParameters: SearchSubscriptionChargesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceSubscriptionChargeSubscriptionChargeSortByAPIModel>>;

    /**
     * Returns subscription charges by a filter
     */
    searchSubscriptionCharges(subscriptionChargeFilterAPIModel: SubscriptionChargeFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceSubscriptionChargeSubscriptionChargeSortByAPIModel>;

}

/**
 * 
 */
export class SubscriptionChargeApi extends runtime.BaseAPI implements SubscriptionChargeApiInterface {

    /**
     * Returns subscription charge by id
     */
    async getSubscriptionChargeRaw(requestParameters: GetSubscriptionChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionChargeAPIModel>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getSubscriptionCharge().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription-charges/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionChargeAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns subscription charge by id
     */
    async getSubscriptionCharge(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionChargeAPIModel> {
        const response = await this.getSubscriptionChargeRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Returns subscription charges by a filter
     */
    async searchSubscriptionChargesRaw(requestParameters: SearchSubscriptionChargesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceSubscriptionChargeSubscriptionChargeSortByAPIModel>> {
        if (requestParameters['subscriptionChargeFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'subscriptionChargeFilterAPIModel',
                'Required parameter "subscriptionChargeFilterAPIModel" was null or undefined when calling searchSubscriptionCharges().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['withTotal'] != null) {
            queryParameters['withTotal'] = requestParameters['withTotal'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription-charges/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionChargeFilterAPIModelToJSON(requestParameters['subscriptionChargeFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceSubscriptionChargeSubscriptionChargeSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns subscription charges by a filter
     */
    async searchSubscriptionCharges(subscriptionChargeFilterAPIModel: SubscriptionChargeFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceSubscriptionChargeSubscriptionChargeSortByAPIModel> {
        const response = await this.searchSubscriptionChargesRaw({ subscriptionChargeFilterAPIModel: subscriptionChargeFilterAPIModel, withTotal: withTotal }, initOverrides);
        return await response.value();
    }

}
