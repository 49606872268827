import { css } from '@emotion/css';
import { Form } from 'antd';

import type { BrandingData } from '@/features/branding/types';
import { nameof } from '@/infrastructure/utils/ts';

import { SupportedQRList } from './components';

import type { SupportedQRItemProps } from './types';
import type React from 'react';

const SupportedQRItem: React.FC<SupportedQRItemProps> = ({ 'data-test': dataTest }) => (
  <Form.Item<BrandingData> name={nameof<BrandingData>('qr')} noStyle>
    <SupportedQRList
      data-test={dataTest}
      className={css`
        width: 100%;
      `}
    />
  </Form.Item>
);

export default SupportedQRItem;
