/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum PushKindAPIModel {
    Recharge = 'Recharge',
    Donation = 'Donation'
}


export function PushKindAPIModelFromJSON(json: any): PushKindAPIModel {
    return PushKindAPIModelFromJSONTyped(json, false);
}

export function PushKindAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushKindAPIModel {
    return json as PushKindAPIModel;
}

export function PushKindAPIModelToJSON(value?: PushKindAPIModel | null): any {
    return value as any;
}

