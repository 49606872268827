import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { mapStoredState, withApiRequest } from '@/infrastructure/model';
import { defaultPageFn } from '@/infrastructure/model/api';
import { createSingleActions } from '@/infrastructure/model/single/actions';
import { toMultiplePayload } from '@/infrastructure/model/single/utils';

import { querySubscriptionCharges, querySubscriptionCharge } from './api';
import {
  makeSelectDirtySubscriptionChargeIds,
  makeSelectMultipleSubscriptionCharge,
  makeSelectSubscriptionCharge,
} from './selectors';
import { NAMESPACE, type SubscriptionCharge } from './types';
import { extractChargeId } from './utils';

export const { storeSubscriptionCharge, storeMultipleSubscriptionCharge, markSubscriptionChargeDirty } =
  createSingleActions<SubscriptionCharge, 'SubscriptionCharge'>(NAMESPACE, 'SubscriptionCharge');

const chargeFetchLimit = pLimit(1);
export const fetchSubscriptionCharge = createAppAsyncThunk(
  `${NAMESPACE}/fetchSubscriptionCharge`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    chargeFetchLimit(async () => {
      const saved = makeSelectSubscriptionCharge(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withApiRequest(querySubscriptionCharge, 'unable to fetch charge')(id, { signal });
      dispatch(storeSubscriptionCharge({ id, data }));

      return makeSelectSubscriptionCharge(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

const chargesFetchLimit = pLimit(1);
export const fetchMultipleSubscriptionCharge = createAppAsyncThunk(
  `${NAMESPACE}/fetchMultipleSubscriptionCharge`,
  async ({ force, ids }: { force?: boolean; ids: string[] }, { dispatch, getState, signal }) =>
    chargesFetchLimit(async () => {
      const absent = makeSelectDirtySubscriptionChargeIds(ids)(getState());
      if (!force && !absent.length) {
        return makeSelectMultipleSubscriptionCharge(ids)(getState());
      }

      const data = await withApiRequest(querySubscriptionCharges, 'unable to fetch charges')(
        { filter: { idIn: absent }, page: defaultPageFn({ perPage: absent.length }) },
        { signal },
      );
      dispatch(
        storeMultipleSubscriptionCharge(
          toMultiplePayload(
            mapStoredState(data, ({ list }) => list),
            ids,
            extractChargeId,
          ),
        ),
      );

      return makeSelectMultipleSubscriptionCharge(ids)(getState());
    }),
);
