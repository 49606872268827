/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  HintWalletDeployedStateAPIModel,
  MerchantWalletSignatureAPIModel,
  MerchantWalletSignatureCreateAPIModel,
  MerchantWalletsAPIModel,
  UserCompaniesAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    HintWalletDeployedStateAPIModelFromJSON,
    HintWalletDeployedStateAPIModelToJSON,
    MerchantWalletSignatureAPIModelFromJSON,
    MerchantWalletSignatureAPIModelToJSON,
    MerchantWalletSignatureCreateAPIModelFromJSON,
    MerchantWalletSignatureCreateAPIModelToJSON,
    MerchantWalletsAPIModelFromJSON,
    MerchantWalletsAPIModelToJSON,
    UserCompaniesAPIModelFromJSON,
    UserCompaniesAPIModelToJSON,
} from '../models/index';

export interface ConfirmOwnedCompanyWalletOwnershipRequest {
    merchantWalletSignatureCreateAPIModel: MerchantWalletSignatureCreateAPIModel;
}

export interface HintWalletDeployedRequest {
    hintWalletDeployedStateAPIModel: HintWalletDeployedStateAPIModel;
}

/**
 * UserApi - interface
 * 
 * @export
 * @interface UserApiInterface
 */
export interface UserApiInterface {
    /**
     * Saves merchant wallet ownership confirmation for owned company
     * @param {MerchantWalletSignatureCreateAPIModel} merchantWalletSignatureCreateAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    confirmOwnedCompanyWalletOwnershipRaw(requestParameters: ConfirmOwnedCompanyWalletOwnershipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantWalletSignatureAPIModel>>;

    /**
     * Saves merchant wallet ownership confirmation for owned company
     */
    confirmOwnedCompanyWalletOwnership(merchantWalletSignatureCreateAPIModel: MerchantWalletSignatureCreateAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantWalletSignatureAPIModel>;

    /**
     * Returns merchant wallet ownership confirmation for owned company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getOwnedCompanyWalletSignatureRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantWalletSignatureAPIModel>>;

    /**
     * Returns merchant wallet ownership confirmation for owned company
     */
    getOwnedCompanyWalletSignature(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantWalletSignatureAPIModel>;

    /**
     * Returns companies that this user has access to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserCompaniesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserCompaniesAPIModel>>;

    /**
     * Returns companies that this user has access to
     */
    getUserCompanies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserCompaniesAPIModel>;

    /**
     * Returns currently authenticated user merchant wallets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserWalletsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantWalletsAPIModel>>;

    /**
     * Returns currently authenticated user merchant wallets
     */
    getUserWallets(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantWalletsAPIModel>;

    /**
     * Hints the contract has been deployed
     * @param {HintWalletDeployedStateAPIModel} hintWalletDeployedStateAPIModel 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    hintWalletDeployedRaw(requestParameters: HintWalletDeployedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Hints the contract has been deployed
     * @deprecated
     */
    hintWalletDeployed(hintWalletDeployedStateAPIModel: HintWalletDeployedStateAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI implements UserApiInterface {

    /**
     * Saves merchant wallet ownership confirmation for owned company
     */
    async confirmOwnedCompanyWalletOwnershipRaw(requestParameters: ConfirmOwnedCompanyWalletOwnershipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantWalletSignatureAPIModel>> {
        if (requestParameters['merchantWalletSignatureCreateAPIModel'] == null) {
            throw new runtime.RequiredError(
                'merchantWalletSignatureCreateAPIModel',
                'Required parameter "merchantWalletSignatureCreateAPIModel" was null or undefined when calling confirmOwnedCompanyWalletOwnership().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/owned-company/signature`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MerchantWalletSignatureCreateAPIModelToJSON(requestParameters['merchantWalletSignatureCreateAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantWalletSignatureAPIModelFromJSON(jsonValue));
    }

    /**
     * Saves merchant wallet ownership confirmation for owned company
     */
    async confirmOwnedCompanyWalletOwnership(merchantWalletSignatureCreateAPIModel: MerchantWalletSignatureCreateAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantWalletSignatureAPIModel> {
        const response = await this.confirmOwnedCompanyWalletOwnershipRaw({ merchantWalletSignatureCreateAPIModel: merchantWalletSignatureCreateAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Returns merchant wallet ownership confirmation for owned company
     */
    async getOwnedCompanyWalletSignatureRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantWalletSignatureAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/owned-company/signature`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantWalletSignatureAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns merchant wallet ownership confirmation for owned company
     */
    async getOwnedCompanyWalletSignature(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantWalletSignatureAPIModel> {
        const response = await this.getOwnedCompanyWalletSignatureRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns companies that this user has access to
     */
    async getUserCompaniesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserCompaniesAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCompaniesAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns companies that this user has access to
     */
    async getUserCompanies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserCompaniesAPIModel> {
        const response = await this.getUserCompaniesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns currently authenticated user merchant wallets
     */
    async getUserWalletsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantWalletsAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/wallets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantWalletsAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns currently authenticated user merchant wallets
     */
    async getUserWallets(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantWalletsAPIModel> {
        const response = await this.getUserWalletsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Hints the contract has been deployed
     * @deprecated
     */
    async hintWalletDeployedRaw(requestParameters: HintWalletDeployedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['hintWalletDeployedStateAPIModel'] == null) {
            throw new runtime.RequiredError(
                'hintWalletDeployedStateAPIModel',
                'Required parameter "hintWalletDeployedStateAPIModel" was null or undefined when calling hintWalletDeployed().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/wallets/hint-deployed`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HintWalletDeployedStateAPIModelToJSON(requestParameters['hintWalletDeployedStateAPIModel']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Hints the contract has been deployed
     * @deprecated
     */
    async hintWalletDeployed(hintWalletDeployedStateAPIModel: HintWalletDeployedStateAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.hintWalletDeployedRaw({ hintWalletDeployedStateAPIModel: hintWalletDeployedStateAPIModel }, initOverrides);
    }

}
