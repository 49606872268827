/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PagePushAddressSortByAPIModel } from './PagePushAddressSortByAPIModel';
import {
    PagePushAddressSortByAPIModelFromJSON,
    PagePushAddressSortByAPIModelFromJSONTyped,
    PagePushAddressSortByAPIModelToJSON,
} from './PagePushAddressSortByAPIModel';
import type { RechargeAddressAPIModel } from './RechargeAddressAPIModel';
import {
    RechargeAddressAPIModelFromJSON,
    RechargeAddressAPIModelFromJSONTyped,
    RechargeAddressAPIModelToJSON,
} from './RechargeAddressAPIModel';

/**
 * 
 * @export
 * @interface SliceRechargeAddressPushAddressSortByAPIModel
 */
export interface SliceRechargeAddressPushAddressSortByAPIModel {
    /**
     * 
     * @type {Array<RechargeAddressAPIModel>}
     * @memberof SliceRechargeAddressPushAddressSortByAPIModel
     */
    list?: Array<RechargeAddressAPIModel>;
    /**
     * 
     * @type {PagePushAddressSortByAPIModel}
     * @memberof SliceRechargeAddressPushAddressSortByAPIModel
     */
    page: PagePushAddressSortByAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SliceRechargeAddressPushAddressSortByAPIModel
     */
    total?: number;
}

/**
 * Check if a given object implements the SliceRechargeAddressPushAddressSortByAPIModel interface.
 */
export function instanceOfSliceRechargeAddressPushAddressSortByAPIModel(value: object): boolean {
    if (!('page' in value)) return false;
    return true;
}

export function SliceRechargeAddressPushAddressSortByAPIModelFromJSON(json: any): SliceRechargeAddressPushAddressSortByAPIModel {
    return SliceRechargeAddressPushAddressSortByAPIModelFromJSONTyped(json, false);
}

export function SliceRechargeAddressPushAddressSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SliceRechargeAddressPushAddressSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(RechargeAddressAPIModelFromJSON)),
        'page': PagePushAddressSortByAPIModelFromJSON(json['page']),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function SliceRechargeAddressPushAddressSortByAPIModelToJSON(value?: SliceRechargeAddressPushAddressSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(RechargeAddressAPIModelToJSON)),
        'page': PagePushAddressSortByAPIModelToJSON(value['page']),
        'total': value['total'],
    };
}

