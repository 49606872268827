/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCompanyNameAPIModel
 */
export interface UpdateCompanyNameAPIModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyNameAPIModel
     */
    name: string;
}

/**
 * Check if a given object implements the UpdateCompanyNameAPIModel interface.
 */
export function instanceOfUpdateCompanyNameAPIModel(value: object): boolean {
    if (!('name' in value)) return false;
    return true;
}

export function UpdateCompanyNameAPIModelFromJSON(json: any): UpdateCompanyNameAPIModel {
    return UpdateCompanyNameAPIModelFromJSONTyped(json, false);
}

export function UpdateCompanyNameAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCompanyNameAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
    };
}

export function UpdateCompanyNameAPIModelToJSON(value?: UpdateCompanyNameAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
    };
}

