/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MerchantPermissionsAPIModel
 */
export interface MerchantPermissionsAPIModel {
    /**
     * 
     * @type {boolean}
     * @memberof MerchantPermissionsAPIModel
     */
    gasWalletsManagement: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantPermissionsAPIModel
     */
    integrationManagement: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantPermissionsAPIModel
     */
    brandingConfiguration: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantPermissionsAPIModel
     */
    termsOfServiceSet: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantPermissionsAPIModel
     */
    assetsManagement: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantPermissionsAPIModel
     */
    usersManagement: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantPermissionsAPIModel
     */
    collectManagement: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantPermissionsAPIModel
     */
    settleManagement: boolean;
}

/**
 * Check if a given object implements the MerchantPermissionsAPIModel interface.
 */
export function instanceOfMerchantPermissionsAPIModel(value: object): boolean {
    if (!('gasWalletsManagement' in value)) return false;
    if (!('integrationManagement' in value)) return false;
    if (!('brandingConfiguration' in value)) return false;
    if (!('termsOfServiceSet' in value)) return false;
    if (!('assetsManagement' in value)) return false;
    if (!('usersManagement' in value)) return false;
    if (!('collectManagement' in value)) return false;
    if (!('settleManagement' in value)) return false;
    return true;
}

export function MerchantPermissionsAPIModelFromJSON(json: any): MerchantPermissionsAPIModel {
    return MerchantPermissionsAPIModelFromJSONTyped(json, false);
}

export function MerchantPermissionsAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantPermissionsAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'gasWalletsManagement': json['gasWalletsManagement'],
        'integrationManagement': json['integrationManagement'],
        'brandingConfiguration': json['brandingConfiguration'],
        'termsOfServiceSet': json['termsOfServiceSet'],
        'assetsManagement': json['assetsManagement'],
        'usersManagement': json['usersManagement'],
        'collectManagement': json['collectManagement'],
        'settleManagement': json['settleManagement'],
    };
}

export function MerchantPermissionsAPIModelToJSON(value?: MerchantPermissionsAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'gasWalletsManagement': value['gasWalletsManagement'],
        'integrationManagement': value['integrationManagement'],
        'brandingConfiguration': value['brandingConfiguration'],
        'termsOfServiceSet': value['termsOfServiceSet'],
        'assetsManagement': value['assetsManagement'],
        'usersManagement': value['usersManagement'],
        'collectManagement': value['collectManagement'],
        'settleManagement': value['settleManagement'],
    };
}

