import { useCompanyPermissions } from '@/features/company/hooks';
import type { MerchantPermission } from '@/features/company/types';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import type React from 'react';

const emptyComponent = () => null;

const withCompanyPermissionsGuard =
  (
    permission: MerchantPermission,
    expected = true,
    EmptyComponent: React.ComponentType = emptyComponent,
    passLoading = false,
  ) =>
  <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
    namedHOC(
      Component,
      'WithCompanyPermissionsGuard',
    )((props) => {
      const {
        data: { data: permissions, isDirty },
      } = useCompanyPermissions();
      if (!permissions && isDirty) {
        return passLoading ? <Component {...props} /> : <EmptyComponent />;
      }
      return permissions?.[permission] === expected ? <Component {...props} /> : <EmptyComponent />;
    });

export default withCompanyPermissionsGuard;
