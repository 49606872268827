import { EditOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation, UpdateEmailForm } from '@/components';
import { useCompanySettings, useCompanySettingsActions } from '@/features/company-settings/hooks';
import type { CompanySettings, RenewalTokenExpiration } from '@/features/company-settings/types';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { useFormVisible, useNotification } from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';

import type { OperationUpdateEmailProps } from './types';

const notificationOptions = {
  error: () => <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_EMAIL_MESSAGE_ERROR} />,
  success: (result: CompanySettings | RenewalTokenExpiration) =>
    'expiresAt' in result
      ? {
          message: (
            <FormattedMessage
              id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_EMAIL_UPDATE_MESSAGE_SUCCESS_TITLE}
            />
          ),
          description: (
            <FormattedMessage
              id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_EMAIL_UPDATE_MESSAGE_SUCCESS_DESCRIPTION}
            />
          ),
        }
      : {
          message: (
            <FormattedMessage
              id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_EMAIL_RESET_MESSAGE_SUCCESS_TITLE}
            />
          ),
          description: (
            <FormattedMessage
              id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_EMAIL_RESET_MESSAGE_SUCCESS_DESCRIPTION}
            />
          ),
        },
};

const OperationUpdateEmail: React.FC<OperationUpdateEmailProps> = ({
  'data-test': dataTest,
  disabled,
  inProgress,
  onSuccess = noop,
  mode = 'inline',
}) => {
  const { updateEmail: updateEmailAction } = useCompanySettingsActions();
  const {
    data: { data, isDirty },
  } = useCompanySettings();

  const formControl = useFormVisible(false);
  const { withNotification } = useNotification();

  const updateEmail = useMemo(
    () =>
      formControl.withFormHide(withOnSuccess(withNotification(updateEmailAction.act, notificationOptions), onSuccess)),
    [formControl, withNotification, updateEmailAction.act, onSuccess],
  );

  const defaultValues = useMemo(() => ({ email: data?.email }), [data?.email]);

  return (
    <>
      {formControl.visible && (
        <Modal
          open
          maskClosable={false}
          footer={null}
          title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_EMAIL_FORM_TITLE} />}
          closable
          onCancel={formControl.hide}
        >
          <UpdateEmailForm
            data-test={dataTest && `${dataTest}-form`}
            values={defaultValues}
            onCancel={formControl.hide}
            onSubmit={updateEmail}
          />
        </Modal>
      )}
      <Operation
        title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_EMAIL_LABEL} />}
        icon={<EditOutlined />}
        onClick={formControl.show}
        disabled={disabled || !updateEmailAction.available || (!data && !isDirty)}
        inProgress={inProgress || formControl.visible || updateEmailAction.inAction}
        data-test={dataTest}
        mode={mode}
      />
    </>
  );
};

const OperationUpdateEmailMemo = React.memo(OperationUpdateEmail);

export default OperationUpdateEmailMemo;
