/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SortAttributeMerchantWalletRunningBalanceSortByAPIModel } from './SortAttributeMerchantWalletRunningBalanceSortByAPIModel';
import {
    SortAttributeMerchantWalletRunningBalanceSortByAPIModelFromJSON,
    SortAttributeMerchantWalletRunningBalanceSortByAPIModelFromJSONTyped,
    SortAttributeMerchantWalletRunningBalanceSortByAPIModelToJSON,
} from './SortAttributeMerchantWalletRunningBalanceSortByAPIModel';

/**
 * 
 * @export
 * @interface PageMerchantWalletRunningBalanceSortByAPIModel
 */
export interface PageMerchantWalletRunningBalanceSortByAPIModel {
    /**
     * 
     * @type {number}
     * @memberof PageMerchantWalletRunningBalanceSortByAPIModel
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PageMerchantWalletRunningBalanceSortByAPIModel
     */
    offset: number;
    /**
     * 
     * @type {Array<SortAttributeMerchantWalletRunningBalanceSortByAPIModel>}
     * @memberof PageMerchantWalletRunningBalanceSortByAPIModel
     */
    sortBy?: Array<SortAttributeMerchantWalletRunningBalanceSortByAPIModel>;
}

/**
 * Check if a given object implements the PageMerchantWalletRunningBalanceSortByAPIModel interface.
 */
export function instanceOfPageMerchantWalletRunningBalanceSortByAPIModel(value: object): boolean {
    if (!('limit' in value)) return false;
    if (!('offset' in value)) return false;
    return true;
}

export function PageMerchantWalletRunningBalanceSortByAPIModelFromJSON(json: any): PageMerchantWalletRunningBalanceSortByAPIModel {
    return PageMerchantWalletRunningBalanceSortByAPIModelFromJSONTyped(json, false);
}

export function PageMerchantWalletRunningBalanceSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageMerchantWalletRunningBalanceSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'limit': json['limit'],
        'offset': json['offset'],
        'sortBy': json['sortBy'] == null ? undefined : ((json['sortBy'] as Array<any>).map(SortAttributeMerchantWalletRunningBalanceSortByAPIModelFromJSON)),
    };
}

export function PageMerchantWalletRunningBalanceSortByAPIModelToJSON(value?: PageMerchantWalletRunningBalanceSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'limit': value['limit'],
        'offset': value['offset'],
        'sortBy': value['sortBy'] == null ? undefined : ((value['sortBy'] as Array<any>).map(SortAttributeMerchantWalletRunningBalanceSortByAPIModelToJSON)),
    };
}

