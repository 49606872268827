import { Form } from 'antd';
import React, { useCallback, useMemo } from 'react';
import isEqual from 'react-fast-compare';

import {
  defaultPageFormLayout,
  defaultPageFormTailLayout,
  ErrorFormMessage,
  FormattedMessage,
  FormCompletenessItem,
  FormFooter,
  PageLoading,
} from '@/components';
import CompanyActiveAssetItem from '@/features/company-settings/components/CompanyActiveAssetItem';
import type { NewRechargeAddress } from '@/features/recharges/types';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureRecharges } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { noop, noopAsync, withVoidOrThrow, wrap } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import { RechargeCIdItem } from './components';
import { useInitialValues } from './hooks';

import type { CreateRechargeAddressFormData, CreateRechargeAddressFormProps } from './types';
import type { NamePath } from 'rc-field-form/lib/interface';

const requiredFields: NamePath[] = [
  nameof<CreateRechargeAddressFormData>('asset'),
  nameof<CreateRechargeAddressFormData>('cid'),
];

const fieldsToValidate: NamePath[] = [];

const formMessages = {
  submit: (
    <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_CREATE_RECHARGE_ADDRESS_FORM_SUBMIT_BUTTON} tagName="span" />
  ),
};

const CreateRechargeAddressForm: React.FC<CreateRechargeAddressFormProps> = ({
  'data-test': dataTest,
  submitCallback,
  onSubmitting,
  onSubmit,
  onReset = noop,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const initialValues = useInitialValues();
  const { form, withResetForm } = useForm<CreateRechargeAddressFormData>();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { submitting, error, withSubmitting, withErrorHandling, reset: resetSubmit } = useErrorSafeSubmitting();
  const doReset = useMemo(() => withResetForm(resetSubmit), [resetSubmit, withResetForm]);
  const doCancel = useMemo(() => withErrorHandling(onReset), [onReset, withErrorHandling]);
  const doSubmit = useMemo(
    () =>
      // TODO: maybe check each field somehow instead of cast? the check complexity looks like overengineering so just rely on the FormCompletenessItem
      withSubmitting((values: CreateRechargeAddressFormData): unknown => {
        setTimeout(doReset, 0);
        return onSubmit(values as NewRechargeAddress);
      }),
    [doReset, onSubmit, withSubmitting],
  );
  const doSubmitForm = useMemo(() => (isFormComplete ? doSubmit : noopAsync), [doSubmit, isFormComplete]);

  const updateComplete = useCallback(
    (complete: boolean) => {
      const value = form.getFieldsValue();
      const hasBeenUpdated = !initialValues || !isEqual(initialValues, value);
      const isCompleteAndUpdated = complete && hasBeenUpdated;
      if (isFormComplete != isCompleteAndUpdated) {
        setFormComplete(isCompleteAndUpdated);
      }
      const submitFn = isCompleteAndUpdated
        ? withSubmitting(
            withVoidOrThrow(
              wrap(
                () => doSubmit(value),
                () => onSubmitting?.(true),
                () => onSubmitting?.(false),
              ),
            ),
          )
        : undefined;
      submitCallback?.(submitFn);
    },
    [form, initialValues, isFormComplete, setFormComplete, withSubmitting, submitCallback, doSubmit, onSubmitting],
  );

  const errorText =
    error === 'RechargeCustomerAssetDuplicate' ? (
      <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_CREATE_RECHARGE_ADDRESS_FORM_ERRORS_DUPLICATE_CID} />
    ) : (
      error
    );

  if (!initialValues) {
    return <PageLoading type="CreateRechargeAddressForm" />;
  }

  return (
    <Form<CreateRechargeAddressFormData>
      onValuesChange={resetSubmit}
      onResetCapture={doReset}
      {...layout}
      initialValues={initialValues}
      autoComplete="off"
      form={form}
      onFinish={doSubmitForm}
      onReset={doCancel}
    >
      {errorText && <ErrorFormMessage data-test={dataTest && `${dataTest}-error`} content={errorText} />}
      <CompanyActiveAssetItem<CreateRechargeAddressFormData>
        data-test={dataTest && `${dataTest}-asset`}
        name="asset"
        excludeAPIs={[BlockchainAPITypeAPIModel.Tron, BlockchainAPITypeAPIModel.BTC]}
      />
      <RechargeCIdItem<CreateRechargeAddressFormData> data-test={dataTest && `${dataTest}-cid`} name="cid" required />

      <FormCompletenessItem<CreateRechargeAddressFormData>
        requiredFields={requiredFields}
        fields={fieldsToValidate}
        onChange={updateComplete}
      />
      <FormFooter
        data-test={dataTest && `${dataTest}-footer`}
        noStyle={!!submitCallback}
        style={submitCallback ? { display: 'none' } : undefined}
        messages={formMessages}
        tailLayout={tailLayout}
        submitDisabled={!isFormComplete}
        submitting={submitting}
      />
    </Form>
  );
};

const CreateRechargeAddressFormMemo = React.memo(CreateRechargeAddressForm);

export default CreateRechargeAddressFormMemo;
