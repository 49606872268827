/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportScheduleDeactivateParamsAPIModel
 */
export interface ReportScheduleDeactivateParamsAPIModel {
    /**
     * 
     * @type {string}
     * @memberof ReportScheduleDeactivateParamsAPIModel
     */
    reason?: string;
}

/**
 * Check if a given object implements the ReportScheduleDeactivateParamsAPIModel interface.
 */
export function instanceOfReportScheduleDeactivateParamsAPIModel(value: object): boolean {
    return true;
}

export function ReportScheduleDeactivateParamsAPIModelFromJSON(json: any): ReportScheduleDeactivateParamsAPIModel {
    return ReportScheduleDeactivateParamsAPIModelFromJSONTyped(json, false);
}

export function ReportScheduleDeactivateParamsAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportScheduleDeactivateParamsAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'reason': json['reason'] == null ? undefined : json['reason'],
    };
}

export function ReportScheduleDeactivateParamsAPIModelToJSON(value?: ReportScheduleDeactivateParamsAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'reason': value['reason'],
    };
}

