import { PageContainer } from '@/components';
import type { TestableProps } from '@/infrastructure/utils/react';

import {
  MerchantWalletRunningBalanceBreadcrumb,
  MerchantWalletRunningBalanceTransfersCard,
  MerchantWalletRunningBalanceCard,
} from './components';
import { withAuditBalanceViewPageParams } from './hocs';

import type React from 'react';
import type { PropsWithChildren } from 'react';

type MerchantWalletRunningBalancePageProps = PropsWithChildren<TestableProps & { pageParams: { balanceId: string } }>;

const MerchantWalletRunningBalancePage: React.FC<MerchantWalletRunningBalancePageProps> = ({
  'data-test': dataTest = 'mwb',
  pageParams: { balanceId },
}) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <MerchantWalletRunningBalanceBreadcrumb
        data-test={dataTest && `${dataTest}-breadcrumb`}
        disabled
        balanceId={balanceId}
      />
    }
  >
    <MerchantWalletRunningBalanceCard data-test={dataTest && `${dataTest}-balance`} balanceId={balanceId} />
    <MerchantWalletRunningBalanceTransfersCard data-test={dataTest && `${dataTest}-transfers`} balanceId={balanceId} />
  </PageContainer>
);

const MerchantWalletRunningBalancePageWithParam = withAuditBalanceViewPageParams(MerchantWalletRunningBalancePage);

export default MerchantWalletRunningBalancePageWithParam;
