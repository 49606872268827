import { List } from 'antd';
import React, { useCallback, useMemo } from 'react';

import type { GasWalletDerived } from '@/features/gas-wallets/types';
import { stringComparator } from '@/infrastructure/model/comparators';

import { GasWalletDerivedItem } from './components';

import type { GasWalletDerivedListProps } from './types';

const rowKey = ({ address }: GasWalletDerived) => address;

const GasWalletDerivedList: React.FC<GasWalletDerivedListProps> = ({ 'data-test': dataTest, data }) => {
  const sorted = useMemo(() => data.sort(stringComparator<GasWalletDerived>(({ address }) => address)('ASC')), [data]);
  return (
    <List<GasWalletDerived>
      dataSource={sorted}
      rowKey={rowKey}
      renderItem={useCallback(
        (item: GasWalletDerived) => (
          <GasWalletDerivedItem wallet={item} data-test={dataTest && `${dataTest}-${item.bt}`} />
        ),
        [dataTest],
      )}
    />
  );
};

const GasWalletMemo = React.memo(GasWalletDerivedList);

export default GasWalletMemo;
