import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { type Account, type Chain, type Client, parseSignature, type Transport, walletActions } from 'viem';

import { useAppDispatch } from '@/app/hooks';
import { useOwnedCompany } from '@/features/company/hooks';
import { confirmMerchantWalletOwnership } from '@/features/merchant-wallets/actions';
import type { MerchantWalletSignature } from '@/features/merchant-wallets/types';
import { useUser } from '@/features/user/hooks';
import { I18nFeatureMerchantWallets } from '@/generated/i18n/i18n';
import { useSubmitting } from '@/hooks';
import { type HookAction } from '@/infrastructure/model';
import { someOrFail } from '@/infrastructure/utils/functions';
import { type Cancellable, rejected, rejection } from '@/infrastructure/utils/ui';

import useMerchantWalletOwnership from '../useMerchantWalletOwnership';

export type ConfirmUnavailabilityReason = 'loading' | 'already-confirmed' | 'no-data' | 'no-owned-company';

export interface UseMerchantWalletsActionsType {
  confirmOwnership: HookAction<
    [client: Client<Transport, Chain, Account>, { cancellationPromise?: Cancellable }],
    MerchantWalletSignature,
    ConfirmUnavailabilityReason
  >;
}

export default function useMerchantWalletsActions(): UseMerchantWalletsActionsType {
  const { withExtractDataDispatch } = useAppDispatch();
  const ownedCompany = useOwnedCompany();
  const ownershipState = useMerchantWalletOwnership();

  const { formatMessage } = useIntl();
  const userState = useUser();

  const confirmUnavailabilityReason: UseMerchantWalletsActionsType['confirmOwnership']['unavailabilityReason'] =
    useMemo(() => {
      if (
        userState.data.isDirty
        || (userState.loading && !userState.data.data)
        || ownedCompany.data.isDirty
        || (ownedCompany.loading && !ownedCompany.data.data)
        || ownershipState.data.isDirty
        || (ownershipState.loading && !ownershipState.data.data)
      ) {
        return 'loading';
      }
      if (!userState.data.data) {
        return 'no-data';
      }
      if (!ownedCompany.data.data) {
        return 'no-owned-company';
      }
      if (ownershipState.data.data) {
        return 'already-confirmed';
      }
      return undefined;
    }, [
      userState.data.isDirty,
      userState.data.data,
      userState.loading,
      ownedCompany.data.isDirty,
      ownedCompany.data.data,
      ownedCompany.loading,
      ownershipState.data.isDirty,
      ownershipState.data.data,
      ownershipState.loading,
    ]);

  const [confirming, withConfirming] = useSubmitting(false);
  const createAction: UseMerchantWalletsActionsType['confirmOwnership']['act'] = useMemo(
    () =>
      withConfirming(async (client, { cancellationPromise } = {}) => {
        const ownerAddress = someOrFail(
          userState.data.data?.address,
          () => new Error(confirmUnavailabilityReason ?? 'no-data'),
        );
        const partnerAddress = someOrFail(
          ownedCompany.data.data,
          () => new Error(confirmUnavailabilityReason ?? 'no-owned-company'),
        ).partnerAddress;
        const signer = client.extend(walletActions);
        const messageToSign = partnerAddress
          ? formatMessage(
              { id: I18nFeatureMerchantWallets.HOOKS_ACTIONS_CONFIRM_OWNERSHIP_MESSAGE_WITH_BROKER },
              { partner: partnerAddress.toLowerCase() },
            )
          : formatMessage(
              { id: I18nFeatureMerchantWallets.HOOKS_ACTIONS_CONFIRM_OWNERSHIP_MESSAGE_NO_BROKER },
              { address: ownerAddress.toLowerCase() },
            );
        const signature = await (async () => {
          try {
            const rawSignature = await (cancellationPromise
              ? Promise.race([
                  signer.signMessage({ message: messageToSign, account: ownerAddress }),
                  cancellationPromise,
                ])
              : await signer.signMessage({ message: messageToSign, account: ownerAddress }));
            if (rawSignature === rejection) {
              throw rejected();
            }
            return parseSignature(rawSignature);
          } catch {
            throw rejected();
          }
        })();
        return withExtractDataDispatch(confirmMerchantWalletOwnership)({
          signature: { ...signature, v: Number(signature.v) },
          bootstrapMessage: messageToSign,
          brokerAddress: partnerAddress || ownerAddress,
        });
      }),
    [
      confirmUnavailabilityReason,
      formatMessage,
      ownedCompany.data.data,
      userState.data.data?.address,
      withConfirming,
      withExtractDataDispatch,
    ],
  );
  const confirmOwnership: UseMerchantWalletsActionsType['confirmOwnership'] = {
    act: createAction,
    available: !confirmUnavailabilityReason,
    unavailabilityReason: confirmUnavailabilityReason,
    inAction: confirming,
  };

  return { confirmOwnership };
}
