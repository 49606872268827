import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import type {
  SubscriptionPlanSortByAPIModel,
  SubscriptionPlanStatusAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { mapLoadingState } from '@/infrastructure/model';
import { defaultPageFn, withAPICall } from '@/infrastructure/model/api';
import { createNormalizedListActions } from '@/infrastructure/model/list/actions';
import { listStateToSliceRequest, mapLoadingSliceStateToListData } from '@/infrastructure/model/list/utils';
import { createSingleActions } from '@/infrastructure/model/single/actions';
import { toMultiplePayload } from '@/infrastructure/model/single/utils';
import { identity } from '@/infrastructure/utils/functions';

import {
  querySubscriptionPlan,
  querySubscriptionPlans,
  requestCreateSubscriptionPlan,
  requestDeleteSubscriptionPlan,
  requestUpdateSubscriptionPlan,
  requestUpdateSubscriptionPlanStatus,
} from './api';
import {
  makeSelectDirtySubscriptionPlanIds,
  makeSelectMultipleSubscriptionPlan,
  makeSelectSubscriptionPlan,
  makeSelectSubscriptionPlanListData,
  makeSelectSubscriptionPlanListParameters,
} from './selectors';
import { NAMESPACE } from './types';
import { extractSubscriptionPlanId } from './utils';

import type {
  NewSubscriptionPlan,
  SubscriptionPlan,
  SubscriptionPlanFilterPredicate,
  UpdateSubscriptionPlan,
} from './types';

export const {
  storeSubscriptionPlan,
  markSubscriptionPlanDirty,
  storeMultipleSubscriptionPlan,
  markMultipleSubscriptionPlanDirty,
  storeRemoveSubscriptionPlan,
} = createSingleActions<SubscriptionPlan, 'SubscriptionPlan'>(NAMESPACE, 'SubscriptionPlan');

const subscriptionPlanFetchLimit = pLimit(1);
export const fetchSubscriptionPlan = createAppAsyncThunk(
  `${NAMESPACE}/fetchSubscriptionPlan`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    subscriptionPlanFetchLimit(async () => {
      const saved = makeSelectSubscriptionPlan(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(querySubscriptionPlan, 'unable to fetch subscriptionPlan')(id, {
        signal,
      });
      dispatch(storeSubscriptionPlan({ id, data }));

      return makeSelectSubscriptionPlan(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

const multipleSubscriptionPlansFetchLimit = pLimit(1);
export const fetchMultipleSubscriptionPlan = createAppAsyncThunk(
  `${NAMESPACE}/fetchMultipleSubscriptionPlan`,
  async ({ force, ids }: { force?: boolean; ids: string[] }, { dispatch, getState, signal }) =>
    multipleSubscriptionPlansFetchLimit(async () => {
      const absent = makeSelectDirtySubscriptionPlanIds(ids)(getState());
      if (!force && !absent.length) {
        return makeSelectMultipleSubscriptionPlan(ids)(getState());
      }

      const data = await withAPICall(querySubscriptionPlans, 'unable to fetch subscription plans')(
        { filter: { idIn: ids }, page: defaultPageFn({ perPage: ids.length }) },
        { signal },
      );
      dispatch(
        storeMultipleSubscriptionPlan(
          toMultiplePayload(
            mapLoadingState(data, ({ list }) => list),
            ids,
            extractSubscriptionPlanId,
            identity,
          ),
        ),
      );

      return makeSelectMultipleSubscriptionPlan(ids)(getState());
    }),
);

export const { storeSubscriptionPlansListData, storeSubscriptionPlansListParameters, markSubscriptionPlansListDirty } =
  createNormalizedListActions<
    SubscriptionPlan,
    'SubscriptionPlans',
    SubscriptionPlanFilterPredicate,
    SubscriptionPlanSortByAPIModel
  >(NAMESPACE, 'SubscriptionPlans');

const subscriptionPlansFetchLimit = pLimit(1);
export const fetchSubscriptionPlans = createAppAsyncThunk(
  `${NAMESPACE}/fetchSubscriptionPlans`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    subscriptionPlansFetchLimit(async () => {
      const saved = makeSelectSubscriptionPlanListData()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(querySubscriptionPlans, 'unable to fetch subscription plans')(
        listStateToSliceRequest({ data: saved, ...makeSelectSubscriptionPlanListParameters()(getState()) }),
        { signal },
      );
      dispatch(storeSubscriptionPlansListData(mapLoadingSliceStateToListData(saved.data?.total)(data)));

      return makeSelectSubscriptionPlanListData()(getState());
    }),
);

export const createSubscriptionPlan = createAppAsyncThunk(
  `${NAMESPACE}/createSubscriptionPlan`,
  async (data: NewSubscriptionPlan, { dispatch, signal }) => {
    const result = await withAPICall(requestCreateSubscriptionPlan, 'Unable to create subscription plan')(data, {
      signal,
    });

    if (result.data) {
      dispatch(storeSubscriptionPlan({ id: result.data.id, data: result }));
      dispatch(markSubscriptionPlansListDirty());
    }

    return result;
  },
);

export const updateSubscriptionPlan = createAppAsyncThunk(
  `${NAMESPACE}/updateSubscriptionPlan`,
  async ({ id, data }: { id: string; data: UpdateSubscriptionPlan }, { dispatch, signal }) => {
    const result = await withAPICall(requestUpdateSubscriptionPlan, 'Unable to update subscription plan')(id, data, {
      signal,
    });

    if (result.data) {
      dispatch(storeSubscriptionPlan({ id, data: result }));
      dispatch(markSubscriptionPlansListDirty());
    }

    return result;
  },
  { idGenerator: ({ id }) => id },
);

export const removeSubscriptionPlan = createAppAsyncThunk(
  `${NAMESPACE}/removeSubscriptionPlan`,
  async (id: string, { dispatch, signal }) => {
    const result = await withAPICall(requestDeleteSubscriptionPlan, 'Unable to delete subscription plan')(id, {
      signal,
    });

    if (!result.error) {
      dispatch(markSubscriptionPlansListDirty());
      dispatch(storeRemoveSubscriptionPlan(id));
    }

    return result;
  },
  { idGenerator: identity },
);

export const updateSubscriptionPlanStatus = createAppAsyncThunk(
  `${NAMESPACE}/updateSubscriptionPlanStatus`,
  async ({ id, newStatus }: { id: string; newStatus: SubscriptionPlanStatusAPIModel }, { dispatch, signal }) => {
    const data = await withAPICall(requestUpdateSubscriptionPlanStatus, 'Unable to update subscription plan status')(
      id,
      newStatus,
      { signal },
    );

    if (data.data) {
      dispatch(storeSubscriptionPlan({ id, data }));
      dispatch(markSubscriptionPlansListDirty());
    }

    return data;
  },
  { idGenerator: ({ id }) => id },
);
