import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage, InputItem } from '@/components';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';

import type { PayoutTitleItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const minLength = 4;
const maxLength = 512;

const PayoutTitleItem = <Values extends Store = Store>({
  placeholder,
  messages: baseMessages,
  rules: extraRules = [],
  ...props
}: PayoutTitleItemProps<Values>) => {
  const { formatMessage: i18n } = useIntl();
  const rules = useMemo(
    () => [
      ...extraRules,
      {
        transform: (v?: string) => v?.trim(),
        min: minLength,
        message: (
          <FormattedMessage
            id={I18nFeaturePayouts.COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_TITLE_ITEM_ERRORS_MIN_LENGTH}
            values={{ min: minLength }}
          />
        ),
      },
      {
        transform: (v?: string) => v?.trim(),
        max: maxLength,
        message: (
          <FormattedMessage
            id={I18nFeaturePayouts.COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_TITLE_ITEM_ERRORS_MAX_LENGTH}
            values={{ max: maxLength }}
          />
        ),
      },
    ],
    [extraRules],
  );
  const messages = useMemo(
    () => ({
      label: baseMessages?.label || (
        <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_TITLE_ITEM_LABEL} />
      ),
      required: baseMessages?.required ?? (
        <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_TITLE_ITEM_ERRORS_REQUIRED} />
      ),
    }),
    [baseMessages?.label, baseMessages?.required],
  );
  return (
    <InputItem<Values>
      {...props}
      messages={messages}
      placeholder={i18n({ id: I18nFeaturePayouts.COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_TITLE_ITEM_PLACEHOLDER })}
      rules={rules}
    />
  );
};

const PayoutTitleItemMemo = React.memo(PayoutTitleItem) as typeof PayoutTitleItem;

export default PayoutTitleItemMemo;
