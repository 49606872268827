/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SortAttributeReportSortByAPIModel } from './SortAttributeReportSortByAPIModel';
import {
    SortAttributeReportSortByAPIModelFromJSON,
    SortAttributeReportSortByAPIModelFromJSONTyped,
    SortAttributeReportSortByAPIModelToJSON,
} from './SortAttributeReportSortByAPIModel';

/**
 * 
 * @export
 * @interface PageReportSortByAPIModel
 */
export interface PageReportSortByAPIModel {
    /**
     * 
     * @type {number}
     * @memberof PageReportSortByAPIModel
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PageReportSortByAPIModel
     */
    offset: number;
    /**
     * 
     * @type {Array<SortAttributeReportSortByAPIModel>}
     * @memberof PageReportSortByAPIModel
     */
    sortBy?: Array<SortAttributeReportSortByAPIModel>;
}

/**
 * Check if a given object implements the PageReportSortByAPIModel interface.
 */
export function instanceOfPageReportSortByAPIModel(value: object): boolean {
    if (!('limit' in value)) return false;
    if (!('offset' in value)) return false;
    return true;
}

export function PageReportSortByAPIModelFromJSON(json: any): PageReportSortByAPIModel {
    return PageReportSortByAPIModelFromJSONTyped(json, false);
}

export function PageReportSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageReportSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'limit': json['limit'],
        'offset': json['offset'],
        'sortBy': json['sortBy'] == null ? undefined : ((json['sortBy'] as Array<any>).map(SortAttributeReportSortByAPIModelFromJSON)),
    };
}

export function PageReportSortByAPIModelToJSON(value?: PageReportSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'limit': value['limit'],
        'offset': value['offset'],
        'sortBy': value['sortBy'] == null ? undefined : ((value['sortBy'] as Array<any>).map(SortAttributeReportSortByAPIModelToJSON)),
    };
}

