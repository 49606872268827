/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { WithdrawalStatusAPIModel } from './WithdrawalStatusAPIModel';
import {
    WithdrawalStatusAPIModelFromJSON,
    WithdrawalStatusAPIModelFromJSONTyped,
    WithdrawalStatusAPIModelToJSON,
} from './WithdrawalStatusAPIModel';

/**
 * 
 * @export
 * @interface WithdrawalBatchStatsAPIModel
 */
export interface WithdrawalBatchStatsAPIModel {
    /**
     * 
     * @type {number}
     * @memberof WithdrawalBatchStatsAPIModel
     */
    batchNum: number;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalBatchStatsAPIModel
     */
    fullness: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalBatchStatsAPIModel
     */
    hash?: string;
    /**
     * 
     * @type {WithdrawalStatusAPIModel}
     * @memberof WithdrawalBatchStatsAPIModel
     */
    status: WithdrawalStatusAPIModel;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalBatchStatsAPIModel
     */
    batchTotal: string;
    /**
     * 
     * @type {boolean}
     * @memberof WithdrawalBatchStatsAPIModel
     */
    isPush: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WithdrawalBatchStatsAPIModel
     */
    isPull: boolean;
}

/**
 * Check if a given object implements the WithdrawalBatchStatsAPIModel interface.
 */
export function instanceOfWithdrawalBatchStatsAPIModel(value: object): boolean {
    if (!('batchNum' in value)) return false;
    if (!('fullness' in value)) return false;
    if (!('status' in value)) return false;
    if (!('batchTotal' in value)) return false;
    if (!('isPush' in value)) return false;
    if (!('isPull' in value)) return false;
    return true;
}

export function WithdrawalBatchStatsAPIModelFromJSON(json: any): WithdrawalBatchStatsAPIModel {
    return WithdrawalBatchStatsAPIModelFromJSONTyped(json, false);
}

export function WithdrawalBatchStatsAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalBatchStatsAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'batchNum': json['batchNum'],
        'fullness': json['fullness'],
        'hash': json['hash'] == null ? undefined : json['hash'],
        'status': WithdrawalStatusAPIModelFromJSON(json['status']),
        'batchTotal': json['batchTotal'],
        'isPush': json['isPush'],
        'isPull': json['isPull'],
    };
}

export function WithdrawalBatchStatsAPIModelToJSON(value?: WithdrawalBatchStatsAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'batchNum': value['batchNum'],
        'fullness': value['fullness'],
        'hash': value['hash'],
        'status': WithdrawalStatusAPIModelToJSON(value['status']),
        'batchTotal': value['batchTotal'],
        'isPush': value['isPush'],
        'isPull': value['isPull'],
    };
}

