/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BigIntRangeAPIModel
 */
export interface BigIntRangeAPIModel {
    /**
     * 
     * @type {string}
     * @memberof BigIntRangeAPIModel
     */
    gte?: string;
    /**
     * 
     * @type {string}
     * @memberof BigIntRangeAPIModel
     */
    lte?: string;
}

/**
 * Check if a given object implements the BigIntRangeAPIModel interface.
 */
export function instanceOfBigIntRangeAPIModel(value: object): boolean {
    return true;
}

export function BigIntRangeAPIModelFromJSON(json: any): BigIntRangeAPIModel {
    return BigIntRangeAPIModelFromJSONTyped(json, false);
}

export function BigIntRangeAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BigIntRangeAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'gte': json['gte'] == null ? undefined : json['gte'],
        'lte': json['lte'] == null ? undefined : json['lte'],
    };
}

export function BigIntRangeAPIModelToJSON(value?: BigIntRangeAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'gte': value['gte'],
        'lte': value['lte'],
    };
}

