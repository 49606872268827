import { useAppLoadableData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchPermissions } from '@/features/company/actions';
import { makeSelectCompanyPermissions } from '@/features/company/selectors';
import type { MerchantPermissions } from '@/features/company/types';
import { makeSelectPending } from '@/features/global/selectors';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

const fetch = (
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<MerchantPermissions>, Parameters<typeof fetchPermissions>[0]> =>
  fetchPermissions({ force });

const selectFetching = makeSelectPending(fetchPermissions);
const selectData = makeSelectCompanyPermissions();

export default function useCompanyPermissions() {
  return useAppLoadableData(fetch, selectData, selectFetching);
}
