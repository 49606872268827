import { Form } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { I18nComponents } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import EmailItem from '../EmailItem';
import ErrorFormMessage from '../ErrorFormMessage';
import FormattedMessage from '../FormattedMessage';
import FormCompletenessItem from '../FormCompletenessItem';
import FormFooter from '../FormFooter';
import { defaultPageFormTailLayout, noLabelFormLayout } from '../utils';

import type { UpdateEmailFormContent, UpdateEmailFormProps } from './types';
import type { FormInstance } from 'antd/es/form';

const requiredFields = [nameof<UpdateEmailFormContent>('email')];

const footerMessages = {
  submit: <FormattedMessage id={I18nComponents.UPDATE_EMAIL_FORM_SUBMIT} tagName="span" />,
};

const UpdateEmailForm: React.FC<UpdateEmailFormProps> = ({
  'data-test': dataTest,
  values,
  onSubmit,
  onCancel = noop,
  layout = noLabelFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const oldValue = values?.email;
  const { form, withResetForm } = useForm<UpdateEmailFormContent>();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { submitting, error, withSubmitting, withErrorHandling } = useErrorSafeSubmitting();
  const doSubmit = useMemo(
    () => withSubmitting((val: UpdateEmailFormContent): unknown => onSubmit(val.email)),
    [onSubmit, withSubmitting],
  );
  const doReset = useMemo(
    () => withErrorHandling(withResetForm(onCancel)),
    [onCancel, withErrorHandling, withResetForm],
  );
  const errorMessage = useMemo(() => {
    if (!error) {
      return undefined;
    }
    if (error === 'UserAlreadyExists') {
      return <FormattedMessage id={I18nComponents.UPDATE_EMAIL_FORM_ERROR_DUPLICATE_EMAIL} />;
    }
    return <FormattedMessage id={I18nComponents.UPDATE_EMAIL_FORM_ERROR_COMMON} />;
  }, [error]);
  const isNew = useCallback(
    ({ getFieldValue }: FormInstance<UpdateEmailFormContent>) =>
      getFieldValue(nameof<UpdateEmailFormContent>('email')) !== oldValue,
    [oldValue],
  );

  return (
    <Form<UpdateEmailFormContent>
      {...layout}
      autoComplete="off"
      form={form}
      initialValues={values}
      size="middle"
      onFinish={doSubmit}
      onReset={doReset}
    >
      {errorMessage && <ErrorFormMessage data-test={dataTest && `${dataTest}-error`} content={errorMessage} />}
      <EmailItem name={nameof<UpdateEmailFormContent>('email')} data-test={dataTest && `${dataTest}-email`} />
      <FormCompletenessItem requiredFields={requiredFields} onChange={setFormComplete} checkIsComplete={isNew} />
      <FormFooter
        data-test={dataTest && `${dataTest}-submit`}
        tailLayout={tailLayout}
        submitting={submitting}
        submitDisabled={!isFormComplete}
        messages={footerMessages}
      />
    </Form>
  );
};

const UpdateEmailFormMemo = React.memo(UpdateEmailForm);

export default UpdateEmailFormMemo;
