import { css } from '@emotion/css';
import { theme } from 'antd';
import React, { useCallback, useMemo } from 'react';

import {
  FormattedMessage,
  NCPSTable,
  ReadonlyComponent,
  ReadonlyDateTime,
  StopPropagationContainer,
  TitleWithDetails,
} from '@/components';
import { AddressLink } from '@/features/dictionary/blockchain/components';
import { GasWalletInvalidStateBanner } from '@/features/gas-wallets/components';
import SubscriptionChargeAtView from '@/features/subscription-charges/components/SubscriptionChargeAtView';
import OperationCancelSubscription from '@/features/subscriptions/components/OperationCancelSubscription';
import OperationUpdateSubscriptionStatus from '@/features/subscriptions/components/OperationUpdateSubscriptionStatus';
import type { SubscriptionExtended, SubscriptionFilterPredicate } from '@/features/subscriptions/types';
import { extractSubscriptionId } from '@/features/subscriptions/utils';
import { SubscriptionSortByAPIModel, SubscriptionStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nComponents, I18nFeatureSubscriptions } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { sortOrderToTable } from '@/infrastructure/model/view';
import type { EmptyObject } from '@/infrastructure/utils/ts';
import { nameof } from '@/infrastructure/utils/ts';

import SubscriptionAllowanceView from '../SubscriptionAllowanceView';
import SubscriptionStatusView from '../SubscriptionStatusView';
import SubscriptionView from '../SubscriptionView';

import type { SubscriptionsTableProps } from './types';
import type { ProColumns, ProTableProps } from '@ant-design/pro-table';

const sortMapping = {
  [nameof<SubscriptionExtended>('createdAt')]: SubscriptionSortByAPIModel.CreatedAt,
};

const sortColumnToModel = (s: string) => sortMapping[s];

const SubscriptionsTable = <FilterType extends EmptyObject = SubscriptionFilterPredicate>({
  'data-test': dataTest,
  data,
  loading,
  page,
  forceRefresh,
  columnState,
  sortBy,
  updateParameters,
  filter,
  FilterForm,
  SubscriptionLink,
  SubscriptionPlanLink,
}: SubscriptionsTableProps<FilterType>) => {
  const { withError } = useNotification();

  const reload = useMemo(
    () =>
      withError(forceRefresh, () => (
        <FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTIONS_TABLE_REFRESH_ERROR_MESSAGE} />
      )),
    [forceRefresh, withError],
  );

  const columns: ProColumns<SubscriptionExtended>[] = useMemo(
    () => [
      {
        fixed: 'left',
        width: 5,
        hideInSetting: true,
        render: (_, { id, blockchain, status }) => (
          <StopPropagationContainer>
            {status === SubscriptionStatusAPIModel.Pending && (
              <GasWalletInvalidStateBanner
                bt={blockchain}
                mode="icon"
                title={
                  <FormattedMessage
                    id={I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTIONS_TABLE_GAS_WALLET_ERROR_MESSAGE}
                  />
                }
                data-test={dataTest && `${dataTest}-${id}-alert`}
              />
            )}
          </StopPropagationContainer>
        ),
      },
      {
        title: <FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_CUSTOMER} />,
        dataIndex: nameof<SubscriptionExtended>('customerId'),
        fixed: 'left',
        render: (_, { id, customerId }) => (
          <ReadonlyComponent value={customerId} data-test={dataTest && `${dataTest}-${id}-customer`} />
        ),
      },
      {
        title: <FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_PAYER} />,
        dataIndex: nameof<SubscriptionExtended>('payer'),
        render: (_, { id, payer, blockchain }) => (
          <AddressLink address={payer} bt={blockchain} data-test={dataTest && `${dataTest}-${id}-payer`} />
        ),
        width: 160,
      },
      {
        title: <FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_ADDRESS} />,
        dataIndex: nameof<SubscriptionExtended>('contractAddress'),
        render: (_, { id, contractAddress, blockchain }) => (
          <AddressLink address={contractAddress} bt={blockchain} data-test={dataTest && `${dataTest}-${id}-address`} />
        ),
        width: 160,
      },
      {
        title: <FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_ALLOWANCE} />,
        dataIndex: nameof<SubscriptionExtended>('allowance'),
        render: (_, subscription) => (
          <SubscriptionAllowanceView
            data-test={dataTest && `${dataTest}-${subscription.id}-allowance`}
            subscription={subscription}
            mode="short"
          />
        ),
      },
      {
        title: <FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_CREATED_AT} />,
        dataIndex: nameof<SubscriptionExtended>('createdAt'),
        valueType: 'dateTime',
        sortOrder: sortOrderToTable(sortBy.CreatedAt),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        render: (_, { id, createdAt }) => (
          <ReadonlyDateTime value={createdAt} data-test={dataTest && `${dataTest}-${id}-createdAt`} />
        ),
      },
      {
        title: <FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_NEXT_CHARGE_AT} />,
        dataIndex: nameof<SubscriptionExtended>('nextChargeAt'),
        valueType: 'dateTime',
        render: (_, value) => (
          <SubscriptionChargeAtView value={value} data-test={dataTest && `${dataTest}-${value.id}-nextChargeAt`} />
        ),
      },
      {
        title: <FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_STATUS} />,
        dataIndex: nameof<SubscriptionExtended>('status'),
        width: 1,
        fixed: 'right',
        render: (_, { id, status }) => (
          <SubscriptionStatusView value={status} data-test={dataTest && `${dataTest}-${id}-status`} mode="icon" />
        ),
        align: 'center',
      },
      {
        key: 'options',
        title: <FormattedMessage id={I18nComponents.TABLE_ROW_OPTIONS} />,
        dataIndex: nameof<SubscriptionExtended>('id'),
        width: 1,
        fixed: 'right',
        render: (_, { id }) => (
          <StopPropagationContainer>
            {SubscriptionLink && <SubscriptionLink value={id} data-test={dataTest && `${dataTest}-linkTo-${id}`} />}
            <OperationUpdateSubscriptionStatus
              subscriptionId={id}
              data-test={dataTest && `${dataTest}-opUpdateStatus-${id}`}
            />
            <OperationCancelSubscription subscriptionId={id} data-test={dataTest && `${dataTest}-opCancel-${id}`} />
          </StopPropagationContainer>
        ),
      },
    ],
    [SubscriptionLink, dataTest, sortBy.CreatedAt],
  );

  const preview = useCallback(
    (value: SubscriptionExtended) => ({
      title: (
        <TitleWithDetails
          title={
            <FormattedMessage
              id={I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTIONS_TABLE_DIALOG_TITLE}
              values={{ id: value.id }}
            />
          }
          link={
            SubscriptionLink && (
              <SubscriptionLink
                value={value.id}
                mode="text"
                title={<FormattedMessage id={I18nComponents.TABLE_LINK_DETAILS} values={{ id: value.id }} />}
              />
            )
          }
          data-test={dataTest && `${dataTest}-dialogTitle`}
        />
      ),
      content: (
        <SubscriptionView
          data={value}
          data-test={dataTest && `${dataTest}-view`}
          style={{ paddingTop: '10px' }}
          hideOperations
          planLink="link"
          columns={1}
          SubscriptionPlanLink={SubscriptionPlanLink}
        />
      ),
    }),
    [SubscriptionLink, SubscriptionPlanLink, dataTest],
  );

  const filterForm = useMemo(
    () => (FilterForm ? { Form: FilterForm, empty: {} as FilterType } : undefined),
    [FilterForm],
  );

  const { token } = theme.useToken();
  const TableProps: ProTableProps<SubscriptionExtended, Record<string, unknown>> = useMemo(
    () => ({
      rowClassName: ({ status, isGasWalletStateInvalid }: SubscriptionExtended) =>
        (status === SubscriptionStatusAPIModel.Pending
          && isGasWalletStateInvalid
          && css`
            > td {
              background-color: ${token.orange2};
            }
          `)
        || '',
    }),
    [token.orange2],
  );

  return (
    <NCPSTable<SubscriptionExtended, FilterType, SubscriptionSortByAPIModel>
      data-test={dataTest}
      filter={filterForm}
      preview={preview}
      sortColumnToModel={sortColumnToModel}
      columns={columns}
      data={data}
      loading={loading}
      page={page}
      filterData={filter}
      columnsState={columnState}
      reload={reload}
      updateParameters={updateParameters}
      TableProps={TableProps}
      extractKey={extractSubscriptionId}
    />
  );
};

const SubscriptionsTableMemo = React.memo(SubscriptionsTable) as typeof SubscriptionsTable;

export default SubscriptionsTableMemo;
