import { namedHOC } from '@/infrastructure/utils/react';

import { OperationWithConfirmation } from './components';

import type { ConfirmationProps, OperationProps } from '../types';
import type React from 'react';

const withConfirmation = <T extends Omit<OperationProps, 'confirmation' | 'Component'>>(
  Component: React.ComponentType<T>,
) =>
  namedHOC<T, T & { confirmation?: ConfirmationProps }>(
    Component,
    'WithConfirmation',
  )((props) =>
    'confirmation' in props && props.confirmation ? (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      <OperationWithConfirmation {...props} Component={Component} />
    ) : (
      <Component {...props} />
    ),
  );

export default withConfirmation;
