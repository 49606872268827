import { base58 } from '@scure/base';
import { parse as uuidParse } from 'uuid';

export const uuidToBase58 = (uuid: string) => {
  try {
    const bytes = uuidParse(uuid);
    return base58.encode(bytes);
  } catch (e) {
    console.warn(`Unable to transform ${uuid} to base58`, e);
    return undefined;
  }
};
