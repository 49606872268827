/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum MerchantWalletTransferSortByAPIModel {
    companyId = 'companyId',
    createdAt = 'createdAt',
    broadcastAt = 'broadcastAt',
    blockNum = 'blockNum'
}


export function MerchantWalletTransferSortByAPIModelFromJSON(json: any): MerchantWalletTransferSortByAPIModel {
    return MerchantWalletTransferSortByAPIModelFromJSONTyped(json, false);
}

export function MerchantWalletTransferSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantWalletTransferSortByAPIModel {
    return json as MerchantWalletTransferSortByAPIModel;
}

export function MerchantWalletTransferSortByAPIModelToJSON(value?: MerchantWalletTransferSortByAPIModel | null): any {
    return value as any;
}

