import { css } from '@emotion/css';
import { List, Space } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { ReadonlyComponent } from '@/components';
import { AssetAmount, TransactionLink } from '@/features/dictionary/blockchain/components';
import type { PayoutBatch } from '@/features/payouts/types';
import { numberComparator } from '@/infrastructure/model/comparators';

import PayoutBatchStatusView from '../PayoutBatchStatusView';

import type { PayoutBatchesListProps } from './types';

const batchComparator = numberComparator<PayoutBatch>(({ num }) => num)('ASC');

const PayoutBatchesList: React.FC<PayoutBatchesListProps> = ({
  'data-test': dataTest,
  data,
  BatchLink,
  Operations,
}) => {
  const orderedData = useMemo(() => [...data].sort(batchComparator), [data]);
  return (
    <List
      data-test={dataTest}
      itemLayout="horizontal"
      loadMore={false}
      style={{ width: '100%' }}
      dataSource={orderedData}
      renderItem={useCallback(
        (batch: PayoutBatch) => (
          <List.Item
            key={batch.num}
            actions={[
              <Space key="actions">
                {BatchLink && <BatchLink data-test={dataTest && `${dataTest}-${batch.num}-link`} value={batch.num} />}
                {Operations && (
                  <Operations data-test={dataTest && `${dataTest}-${batch.num}-operations`} value={batch} />
                )}
              </Space>,
            ]}
          >
            <List.Item.Meta
              style={{ alignItems: 'center' }}
              avatar={
                <PayoutBatchStatusView
                  data-test={dataTest && `${dataTest}-${batch.num}-icon`}
                  value={batch.status}
                  className={css`
                    > div > div > span {
                      font-size: 32px;
                    }
                  `}
                  mode="icon"
                />
              }
              title={
                <ReadonlyComponent
                  value={
                    <AssetAmount
                      data-test={dataTest && `${dataTest}-${batch.num}-amount`}
                      value={batch.amount.value}
                      assetId={batch.amount.asset}
                      currencyMode="long"
                      withCurrency
                      withBlockchainMark
                    />
                  }
                  style={{
                    display: 'inline-flex',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                />
              }
              description={
                batch.hash ? (
                  <TransactionLink
                    data-test={dataTest && `${dataTest}-${batch.num}-hash`}
                    assetId={batch.amount.asset}
                    tx={batch.hash}
                  />
                ) : undefined
              }
            />
          </List.Item>
        ),
        [BatchLink, Operations, dataTest],
      )}
    />
  );
};

const PayoutBatchesListMemo = React.memo(PayoutBatchesList);

export default PayoutBatchesListMemo;
