import { LogoutOutlined, ReadOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import React, { useMemo } from 'react';

import { DocumentationLink, FormattedMessage } from '@/components';
import { useAuthActions } from '@/features/auth/hooks';
import { I18nCommon } from '@/generated/i18n/i18n';
import { suppressPromise } from '@/infrastructure/utils/functions';
import type { TestableProps } from '@/infrastructure/utils/react';
import type { ArrayElement } from '@/infrastructure/utils/ts';
import { asType } from '@/infrastructure/utils/ts';
import SettingsLink from '@/pages/settings/components/SettingsLink';
import ProfileLink from '@/pages/settings/profile/components/ProfileLink';

import type { AvatarDropdownProps } from './types';
import type { DropdownProps } from 'antd';

const AvatarDropdown: React.FC<AvatarDropdownProps> = ({ 'data-test': dataTest, title, simplified }) => {
  const { logout } = useAuthActions();
  const items = useMemo<(ArrayElement<NonNullable<NonNullable<DropdownProps['menu']>['items']>> & TestableProps)[]>(
    () => [
      ...asType<(ArrayElement<NonNullable<NonNullable<DropdownProps['menu']>['items']>> & TestableProps)[]>(
        !simplified
          ? [
              {
                'data-test': dataTest && `${dataTest}-item-profile`,
                key: 'profile',
                icon: <UserOutlined />,
                label: <ProfileLink data-test="menu-user-profile" mode="text" />,
              },
              {
                'data-test': dataTest && `${dataTest}-item-settings`,
                key: 'settings',
                icon: <SettingOutlined />,
                label: <SettingsLink data-test="menu-user-setting" mode="text" />,
              },
              { key: 'divider', type: 'divider' },
            ]
          : [],
      ),
      {
        'data-test': dataTest && `${dataTest}-item-documentation`,
        key: 'documentation',
        icon: <ReadOutlined />,
        label: <DocumentationLink data-test="menu-user-documentation" mode="text" />,
      },
      ...asType<(ArrayElement<NonNullable<NonNullable<DropdownProps['menu']>['items']>> & TestableProps)[]>(
        logout.available
          ? [
              { key: 'divider2', type: 'divider' },
              {
                'data-test': dataTest && `${dataTest}-item-logout`,
                key: 'logout',
                icon: <LogoutOutlined />,
                label: <FormattedMessage data-test="menu-user-logout" id={I18nCommon.LOGOUT} tagName="span" />,
                onClick: () => suppressPromise(logout.act()),
              },
            ]
          : [],
      ),
    ],
    [dataTest, logout, simplified],
  );

  return <Dropdown menu={useMemo(() => ({ items, selectedKeys: [] }), [items])}>{title}</Dropdown>;
};

const AvatarDropdownMemo = React.memo(AvatarDropdown);

export default AvatarDropdownMemo;
