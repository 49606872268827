/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { BottomProofsAPIModel } from './BottomProofsAPIModel';
import {
    BottomProofsAPIModelFromJSON,
    BottomProofsAPIModelFromJSONTyped,
    BottomProofsAPIModelToJSON,
} from './BottomProofsAPIModel';
import type { PayoutStatusAPIModel } from './PayoutStatusAPIModel';
import {
    PayoutStatusAPIModelFromJSON,
    PayoutStatusAPIModelFromJSONTyped,
    PayoutStatusAPIModelToJSON,
} from './PayoutStatusAPIModel';
import type { PayoutTypeAPIModel } from './PayoutTypeAPIModel';
import {
    PayoutTypeAPIModelFromJSON,
    PayoutTypeAPIModelFromJSONTyped,
    PayoutTypeAPIModelToJSON,
} from './PayoutTypeAPIModel';
import type { SignatureComponentsAPIModel } from './SignatureComponentsAPIModel';
import {
    SignatureComponentsAPIModelFromJSON,
    SignatureComponentsAPIModelFromJSONTyped,
    SignatureComponentsAPIModelToJSON,
} from './SignatureComponentsAPIModel';

/**
 * 
 * @export
 * @interface PayoutAPIModel
 */
export interface PayoutAPIModel {
    /**
     * 
     * @type {string}
     * @memberof PayoutAPIModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutAPIModel
     */
    companyId: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof PayoutAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof PayoutAPIModel
     */
    title: string;
    /**
     * 
     * @type {PayoutTypeAPIModel}
     * @memberof PayoutAPIModel
     */
    mode: PayoutTypeAPIModel;
    /**
     * 
     * @type {PayoutStatusAPIModel}
     * @memberof PayoutAPIModel
     */
    status: PayoutStatusAPIModel;
    /**
     * 
     * @type {string}
     * @memberof PayoutAPIModel
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutAPIModel
     */
    settledAmount: string;
    /**
     * 
     * @type {Date}
     * @memberof PayoutAPIModel
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof PayoutAPIModel
     */
    rootProof?: string;
    /**
     * 
     * @type {BottomProofsAPIModel}
     * @memberof PayoutAPIModel
     */
    bottomProofs?: BottomProofsAPIModel;
    /**
     * 
     * @type {SignatureComponentsAPIModel}
     * @memberof PayoutAPIModel
     */
    eip712signature?: SignatureComponentsAPIModel;
    /**
     * 
     * @type {Date}
     * @memberof PayoutAPIModel
     */
    expiresAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PayoutAPIModel
     */
    isStarted: boolean;
}

/**
 * Check if a given object implements the PayoutAPIModel interface.
 */
export function instanceOfPayoutAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('title' in value)) return false;
    if (!('mode' in value)) return false;
    if (!('status' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('settledAmount' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('isStarted' in value)) return false;
    return true;
}

export function PayoutAPIModelFromJSON(json: any): PayoutAPIModel {
    return PayoutAPIModelFromJSONTyped(json, false);
}

export function PayoutAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayoutAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'title': json['title'],
        'mode': PayoutTypeAPIModelFromJSON(json['mode']),
        'status': PayoutStatusAPIModelFromJSON(json['status']),
        'amount': json['amount'],
        'settledAmount': json['settledAmount'],
        'createdAt': (new Date(json['createdAt'])),
        'rootProof': json['rootProof'] == null ? undefined : json['rootProof'],
        'bottomProofs': json['bottomProofs'] == null ? undefined : BottomProofsAPIModelFromJSON(json['bottomProofs']),
        'eip712signature': json['eip712signature'] == null ? undefined : SignatureComponentsAPIModelFromJSON(json['eip712signature']),
        'expiresAt': json['expiresAt'] == null ? undefined : (new Date(json['expiresAt'])),
        'isStarted': json['isStarted'],
    };
}

export function PayoutAPIModelToJSON(value?: PayoutAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'title': value['title'],
        'mode': PayoutTypeAPIModelToJSON(value['mode']),
        'status': PayoutStatusAPIModelToJSON(value['status']),
        'amount': value['amount'],
        'settledAmount': value['settledAmount'],
        'createdAt': ((value['createdAt']).toISOString()),
        'rootProof': value['rootProof'],
        'bottomProofs': BottomProofsAPIModelToJSON(value['bottomProofs']),
        'eip712signature': SignatureComponentsAPIModelToJSON(value['eip712signature']),
        'expiresAt': value['expiresAt'] == null ? undefined : ((value['expiresAt']).toISOString()),
        'isStarted': value['isStarted'],
    };
}

