/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyUserAPIModel
 */
export interface CompanyUserAPIModel {
    /**
     * 
     * @type {string}
     * @memberof CompanyUserAPIModel
     */
    address: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyUserAPIModel
     */
    companyId: number;
}

/**
 * Check if a given object implements the CompanyUserAPIModel interface.
 */
export function instanceOfCompanyUserAPIModel(value: object): boolean {
    if (!('address' in value)) return false;
    if (!('companyId' in value)) return false;
    return true;
}

export function CompanyUserAPIModelFromJSON(json: any): CompanyUserAPIModel {
    return CompanyUserAPIModelFromJSONTyped(json, false);
}

export function CompanyUserAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyUserAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'],
        'companyId': json['companyId'],
    };
}

export function CompanyUserAPIModelToJSON(value?: CompanyUserAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'companyId': value['companyId'],
    };
}

