import React, { useMemo } from 'react';

import { useCompanyPermissions } from '@/features/company/hooks';
import type { MerchantPermissions } from '@/features/company/types';
import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import type { FeatureFlags } from '@/features/feature-toggle/types';
import GasHistoryReportParametersView from '@/features/gas-history/components/GasHistoryReportParametersView';
import GasHistoryReportScheduleParametersForm from '@/features/gas-history/components/GasHistoryReportScheduleParametersForm';
import GasHistoryReportScheduleParametersView from '@/features/gas-history/components/GasHistoryReportScheduleParametersView';
import GasHistoryReportTitle from '@/features/gas-history/components/GasHistoryReportTitle';
import { gasHistoryExportReport } from '@/features/gas-history/types';
import MerchantWalletRunningBalancesReportParametersView from '@/features/merchant-wallet-balance/components/MerchantWalletRunningBalancesReportParametersView';
import MerchantWalletRunningBalancesScheduleParametersForm from '@/features/merchant-wallet-balance/components/MerchantWalletRunningBalancesReportScheduleParametersForm';
import MerchantWalletRunningBalancesReportTitle from '@/features/merchant-wallet-balance/components/MerchantWalletRunningBalancesReportTitle';
import MerchantWalletRunningBalancesScheduleParametersView from '@/features/merchant-wallet-balance/components/MerchantWalletRunningBalancesScheduleParametersView';
import { merchantWalletRunningBalanceExportReport } from '@/features/merchant-wallet-balance/types';
import MerchantWalletTransfersReportParametersView from '@/features/merchant-wallet-transfers/components/MerchantWalletTransfersReportParametersView';
import MerchantWalletTransfersScheduleParametersForm from '@/features/merchant-wallet-transfers/components/MerchantWalletTransfersReportScheduleParametersForm';
import MerchantWalletTransfersReportTitle from '@/features/merchant-wallet-transfers/components/MerchantWalletTransfersReportTitle';
import MerchantWalletTransfersScheduleParametersView from '@/features/merchant-wallet-transfers/components/MerchantWalletTransfersScheduleParametersView';
import { merchantWalletTransfersExportReport } from '@/features/merchant-wallet-transfers/types';
import {
  PaymentsReportParametersTitle,
  PaymentsReportParametersView,
  PaymentsReportScheduleParametersForm,
  PaymentsReportScheduleParametersView,
} from '@/features/payments/components';
import { paymentExportReport } from '@/features/payments/types';
import SettlementReconciliationReportParametersView from '@/features/settlement-reconciliations/components/SettlementReconciliationReportParametersView';
import SettlementReconciliationScheduleParametersForm from '@/features/settlement-reconciliations/components/SettlementReconciliationScheduleParametersForm';
import SettlementReconciliationScheduleParametersView from '@/features/settlement-reconciliations/components/SettlementReconciliationScheduleParametersView';
import SettlementReconciliationsReportTitle from '@/features/settlement-reconciliations/components/SettlementReconciliationsReportTitle';
import SettlementReconciliationTransfersReportTitle from '@/features/settlement-reconciliations/components/SettlementReconciliationTransfersReportTitle';
import {
  settlementReconciliationsExportReport,
  settlementReconciliationTransfersExportReport,
} from '@/features/settlement-reconciliations/types';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import HandlersContext from './context';

import type { HandlersContextType } from './context';

const createContext = (
  featureFlags: FeatureFlags | undefined,
  permissions: MerchantPermissions | undefined,
): HandlersContextType => ({
  alerts: [],
  background: [
    // {
    //   type: refundingGasWalletsTaskType,
    //   Message: GasWalletRefundingTaskTitle,
    //   Icon: CloudDownloadOutlined,
    //   selector: makeSelectRefundingWalletsDetails(),
    // },
  ],
  reports: [
    // payments
    {
      template: paymentExportReport,
      Title: PaymentsReportParametersTitle,
      ParametersView: PaymentsReportParametersView,
    },
    // running balance
    {
      template: merchantWalletRunningBalanceExportReport,
      Title: MerchantWalletRunningBalancesReportTitle,
      ParametersView: MerchantWalletRunningBalancesReportParametersView,
    },
    // wallet transfers
    {
      template: merchantWalletTransfersExportReport,
      Title: MerchantWalletTransfersReportTitle,
      ParametersView: MerchantWalletTransfersReportParametersView,
    },
    // reconciliation
    {
      template: settlementReconciliationTransfersExportReport,
      Title: SettlementReconciliationTransfersReportTitle,
      ParametersView: SettlementReconciliationScheduleParametersView,
    },
    {
      template: settlementReconciliationsExportReport,
      Title: SettlementReconciliationsReportTitle,
      ParametersView: SettlementReconciliationReportParametersView,
    },
    // gas history
    ...(featureFlags?.enableGasHistory && permissions?.gasWalletsManagement
      ? [
          {
            template: gasHistoryExportReport,
            Title: GasHistoryReportTitle,
            ParametersView: GasHistoryReportParametersView,
          },
        ]
      : []),
  ],
  reportSchedules: [
    // payments
    {
      template: paymentExportReport,
      Title: PaymentsReportParametersTitle,
      ParametersView: PaymentsReportScheduleParametersView,
      ParametersForm: PaymentsReportScheduleParametersForm,
    },
    // running balance
    {
      template: merchantWalletRunningBalanceExportReport,
      Title: MerchantWalletRunningBalancesReportTitle,
      ParametersView: MerchantWalletRunningBalancesScheduleParametersView,
      ParametersForm: MerchantWalletRunningBalancesScheduleParametersForm,
    },
    // merchant wallets
    {
      template: merchantWalletTransfersExportReport,
      Title: MerchantWalletTransfersReportTitle,
      ParametersView: MerchantWalletTransfersScheduleParametersView,
      ParametersForm: MerchantWalletTransfersScheduleParametersForm,
    },
    // reconciliations
    ...(featureFlags?.enableReconciliation
      ? [
          {
            template: settlementReconciliationTransfersExportReport,
            Title: SettlementReconciliationTransfersReportTitle,
            ParametersView: SettlementReconciliationScheduleParametersView,
            ParametersForm: SettlementReconciliationScheduleParametersForm,
          },
          {
            template: settlementReconciliationsExportReport,
            Title: SettlementReconciliationsReportTitle,
            ParametersView: SettlementReconciliationScheduleParametersView,
            ParametersForm: SettlementReconciliationScheduleParametersForm,
          },
        ]
      : []),
    // gas history
    ...(featureFlags?.enableGasHistory && permissions?.gasWalletsManagement
      ? [
          {
            template: gasHistoryExportReport,
            Title: GasHistoryReportTitle,
            ParametersView: GasHistoryReportScheduleParametersView,
            ParametersForm: GasHistoryReportScheduleParametersForm,
          },
        ]
      : []),
  ],
});

const withHandlerRegistration = <P extends EmptyObject>(Component: React.ComponentType<P>) =>
  namedHOC(
    Component,
    'WithHandlerRegistration',
  )((props) => {
    const {
      data: { data: featureFlags },
    } = useFeatureToggle();
    const {
      data: { data: permissions },
    } = useCompanyPermissions();
    const context = useMemo(() => createContext(featureFlags, permissions), [featureFlags, permissions]);
    return (
      <HandlersContext.Provider value={context}>
        <Component {...props} />
      </HandlersContext.Provider>
    );
  });

export default withHandlerRegistration;
