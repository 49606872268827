/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PageWithdrawalSortByAPIModel } from './PageWithdrawalSortByAPIModel';
import {
    PageWithdrawalSortByAPIModelFromJSON,
    PageWithdrawalSortByAPIModelFromJSONTyped,
    PageWithdrawalSortByAPIModelToJSON,
} from './PageWithdrawalSortByAPIModel';
import type { WithdrawalPredicatesAPIModel } from './WithdrawalPredicatesAPIModel';
import {
    WithdrawalPredicatesAPIModelFromJSON,
    WithdrawalPredicatesAPIModelFromJSONTyped,
    WithdrawalPredicatesAPIModelToJSON,
} from './WithdrawalPredicatesAPIModel';

/**
 * 
 * @export
 * @interface WithdrawalFilterAPIModel
 */
export interface WithdrawalFilterAPIModel {
    /**
     * 
     * @type {WithdrawalPredicatesAPIModel}
     * @memberof WithdrawalFilterAPIModel
     */
    predicates: WithdrawalPredicatesAPIModel;
    /**
     * 
     * @type {PageWithdrawalSortByAPIModel}
     * @memberof WithdrawalFilterAPIModel
     */
    page: PageWithdrawalSortByAPIModel;
}

/**
 * Check if a given object implements the WithdrawalFilterAPIModel interface.
 */
export function instanceOfWithdrawalFilterAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    if (!('page' in value)) return false;
    return true;
}

export function WithdrawalFilterAPIModelFromJSON(json: any): WithdrawalFilterAPIModel {
    return WithdrawalFilterAPIModelFromJSONTyped(json, false);
}

export function WithdrawalFilterAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalFilterAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': WithdrawalPredicatesAPIModelFromJSON(json['predicates']),
        'page': PageWithdrawalSortByAPIModelFromJSON(json['page']),
    };
}

export function WithdrawalFilterAPIModelToJSON(value?: WithdrawalFilterAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': WithdrawalPredicatesAPIModelToJSON(value['predicates']),
        'page': PageWithdrawalSortByAPIModelToJSON(value['page']),
    };
}

