/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { NewPayoutBatchAPIModel } from './NewPayoutBatchAPIModel';
import {
    NewPayoutBatchAPIModelFromJSON,
    NewPayoutBatchAPIModelFromJSONTyped,
    NewPayoutBatchAPIModelToJSON,
} from './NewPayoutBatchAPIModel';
import type { SignatureComponentsAPIModel } from './SignatureComponentsAPIModel';
import {
    SignatureComponentsAPIModelFromJSON,
    SignatureComponentsAPIModelFromJSONTyped,
    SignatureComponentsAPIModelToJSON,
} from './SignatureComponentsAPIModel';

/**
 * 
 * @export
 * @interface MerklePayoutSignatureDataAPIModel
 */
export interface MerklePayoutSignatureDataAPIModel {
    /**
     * 
     * @type {string}
     * @memberof MerklePayoutSignatureDataAPIModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MerklePayoutSignatureDataAPIModel
     */
    rootProof: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MerklePayoutSignatureDataAPIModel
     */
    bottomProofs?: Array<string>;
    /**
     * 
     * @type {Array<NewPayoutBatchAPIModel>}
     * @memberof MerklePayoutSignatureDataAPIModel
     */
    batches?: Array<NewPayoutBatchAPIModel>;
    /**
     * 
     * @type {SignatureComponentsAPIModel}
     * @memberof MerklePayoutSignatureDataAPIModel
     */
    eip712signature: SignatureComponentsAPIModel;
    /**
     * 
     * @type {Date}
     * @memberof MerklePayoutSignatureDataAPIModel
     */
    expiresAt: Date;
}

/**
 * Check if a given object implements the MerklePayoutSignatureDataAPIModel interface.
 */
export function instanceOfMerklePayoutSignatureDataAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('rootProof' in value)) return false;
    if (!('eip712signature' in value)) return false;
    if (!('expiresAt' in value)) return false;
    return true;
}

export function MerklePayoutSignatureDataAPIModelFromJSON(json: any): MerklePayoutSignatureDataAPIModel {
    return MerklePayoutSignatureDataAPIModelFromJSONTyped(json, false);
}

export function MerklePayoutSignatureDataAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerklePayoutSignatureDataAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'rootProof': json['rootProof'],
        'bottomProofs': json['bottomProofs'] == null ? undefined : json['bottomProofs'],
        'batches': json['batches'] == null ? undefined : ((json['batches'] as Array<any>).map(NewPayoutBatchAPIModelFromJSON)),
        'eip712signature': SignatureComponentsAPIModelFromJSON(json['eip712signature']),
        'expiresAt': (new Date(json['expiresAt'])),
    };
}

export function MerklePayoutSignatureDataAPIModelToJSON(value?: MerklePayoutSignatureDataAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'rootProof': value['rootProof'],
        'bottomProofs': value['bottomProofs'],
        'batches': value['batches'] == null ? undefined : ((value['batches'] as Array<any>).map(NewPayoutBatchAPIModelToJSON)),
        'eip712signature': SignatureComponentsAPIModelToJSON(value['eip712signature']),
        'expiresAt': ((value['expiresAt']).toISOString()),
    };
}

