/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { MonthlyGrowthRateRecordAPIModel } from './MonthlyGrowthRateRecordAPIModel';
import {
    MonthlyGrowthRateRecordAPIModelFromJSON,
    MonthlyGrowthRateRecordAPIModelFromJSONTyped,
    MonthlyGrowthRateRecordAPIModelToJSON,
} from './MonthlyGrowthRateRecordAPIModel';

/**
 * 
 * @export
 * @interface GrowthRatePerPlanAPIModel
 */
export interface GrowthRatePerPlanAPIModel {
    /**
     * 
     * @type {string}
     * @memberof GrowthRatePerPlanAPIModel
     */
    planId: string;
    /**
     * 
     * @type {Array<MonthlyGrowthRateRecordAPIModel>}
     * @memberof GrowthRatePerPlanAPIModel
     */
    perMonth?: Array<MonthlyGrowthRateRecordAPIModel>;
}

/**
 * Check if a given object implements the GrowthRatePerPlanAPIModel interface.
 */
export function instanceOfGrowthRatePerPlanAPIModel(value: object): boolean {
    if (!('planId' in value)) return false;
    return true;
}

export function GrowthRatePerPlanAPIModelFromJSON(json: any): GrowthRatePerPlanAPIModel {
    return GrowthRatePerPlanAPIModelFromJSONTyped(json, false);
}

export function GrowthRatePerPlanAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GrowthRatePerPlanAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'planId': json['planId'],
        'perMonth': json['perMonth'] == null ? undefined : ((json['perMonth'] as Array<any>).map(MonthlyGrowthRateRecordAPIModelFromJSON)),
    };
}

export function GrowthRatePerPlanAPIModelToJSON(value?: GrowthRatePerPlanAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'planId': value['planId'],
        'perMonth': value['perMonth'] == null ? undefined : ((value['perMonth'] as Array<any>).map(MonthlyGrowthRateRecordAPIModelToJSON)),
    };
}

