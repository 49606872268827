import React, { useCallback, useMemo } from 'react';

import {
  FormattedMessage,
  NCPSTable,
  ReadonlyComponent,
  ReadonlyDateTime,
  StopPropagationContainer,
  TitleWithDetails,
} from '@/components';
import type { ReportSchedule, ReportScheduleFilterPredicate } from '@/features/report-schedules/types';
import { extractReportScheduleId } from '@/features/report-schedules/utils';
import { ReportScheduleSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nComponents, I18nFeatureReportSchedules } from '@/generated/i18n/i18n';
import { sortOrderToTable } from '@/infrastructure/model/view';
import { enumByKey, nameof } from '@/infrastructure/utils/ts';

import ReportScheduleCronView from '../ReportScheduleCronView';
import ReportScheduleStatusView from '../ReportScheduleStatusView';
import ReportScheduleTemplateTitle from '../ReportScheduleTemplateTitle';
import ReportScheduleView from '../ReportScheduleView';

import type { ReportSchedulesTableProps } from './types';
import type { ProColumns } from '@ant-design/pro-table';

const sortColumnToModel = (s: string) => enumByKey(ReportScheduleSortByAPIModel, s);

const ReportSchedulesTableRaw = <FilterType extends ReportScheduleFilterPredicate = ReportScheduleFilterPredicate>({
  'data-test': dataTest,
  data,
  loading,
  page,
  filter,
  columnState,
  sortBy,
  forceRefresh,
  updateParameters,
  title,
  FilterForm,
  ReportScheduleLink,
  Operations,
}: ReportSchedulesTableProps<FilterType>) => {
  const columns: ProColumns<ReportSchedule>[] = useMemo(
    () => [
      {
        title: <FormattedMessage id={I18nFeatureReportSchedules.LABELS_ROW_TITLE_TEMPLATE} />,
        dataIndex: nameof<ReportSchedule>('template'),
        render: (_, { id, template }) => (
          <ReportScheduleTemplateTitle value={template} data-test={dataTest && `${dataTest}-${id}-template`} />
        ),
      },
      {
        title: <FormattedMessage id={I18nFeatureReportSchedules.LABELS_ROW_TITLE_CRON} />,
        dataIndex: nameof<ReportSchedule>('cron'),
        width: 100,
        render: (_, { id, cron }) => (
          <ReportScheduleCronView value={cron} data-test={dataTest && `${dataTest}-${id}-cron`} />
        ),
        align: 'left',
      },
      {
        title: <FormattedMessage id={I18nFeatureReportSchedules.LABELS_ROW_TITLE_CREATED_AT} />,
        dataIndex: nameof<ReportSchedule>('createdAt'),
        valueType: 'dateTime',
        sortOrder: sortOrderToTable(sortBy.createdAt),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        render: (_, { id, createdAt }) => (
          <ReadonlyDateTime
            value={createdAt}
            style={{ whiteSpace: 'nowrap' }}
            data-test={dataTest && `${dataTest}-${id}-createdAt`}
          />
        ),
        width: 160,
      },
      {
        title: <FormattedMessage id={I18nFeatureReportSchedules.LABELS_ROW_TITLE_ID} />,
        dataIndex: nameof<ReportSchedule>('id'),
        render: (_, { id }) => (
          <ReadonlyComponent
            value={id}
            style={{ whiteSpace: 'nowrap' }}
            data-test={dataTest && `${dataTest}-${id}-id`}
          />
        ),
        width: 160,
      },
      {
        title: <FormattedMessage id={I18nFeatureReportSchedules.LABELS_ROW_TITLE_STATUS} />,
        dataIndex: nameof<ReportSchedule>('status'),
        width: 80,
        fixed: 'right',
        render: (_, { id, status }) => (
          <ReportScheduleStatusView value={status} data-test={dataTest && `${dataTest}-${id}-status`} mode="icon" />
        ),
        align: 'center',
      },
      {
        title: <FormattedMessage id={I18nFeatureReportSchedules.LABELS_ROW_TITLE_NEXT_AT} />,
        dataIndex: nameof<ReportSchedule>('nextGenerationAt'),
        valueType: 'dateTime',
        width: 160,
        fixed: 'right',
        render: (_, { id, nextGenerationAt }) => (
          <ReadonlyDateTime
            value={nextGenerationAt}
            style={{ whiteSpace: 'nowrap' }}
            data-test={dataTest && `${dataTest}-${id}-nextGenerationAt`}
          />
        ),
      },
      {
        key: 'options',
        title: <FormattedMessage id={I18nComponents.TABLE_ROW_OPTIONS} />,
        dataIndex: nameof<ReportSchedule>('id'),
        width: 1,
        fixed: 'right',
        render: (_, value) => (
          <StopPropagationContainer>
            {ReportScheduleLink && (
              <ReportScheduleLink data-test={dataTest && `${dataTest}-${value.id}-link`} value={value.id} />
            )}
            {Operations && <Operations data-test={dataTest && `${dataTest}-${value.id}-op`} value={value} />}
          </StopPropagationContainer>
        ),
      },
    ],
    [Operations, ReportScheduleLink, dataTest, sortBy.createdAt],
  );

  const preview = useCallback(
    (value: ReportSchedule) => ({
      title: ReportScheduleLink ? (
        <TitleWithDetails
          title={
            <FormattedMessage
              id={I18nFeatureReportSchedules.COMPONENTS_REPORTS_SCHEDULE_TABLE_DIALOG_TITLE}
              values={{ id: value.id }}
            />
          }
          link={
            <ReportScheduleLink
              value={value.id}
              mode="text"
              title={<FormattedMessage id={I18nComponents.TABLE_LINK_DETAILS} values={{ id: value.id }} />}
            />
          }
          data-test={dataTest && `${dataTest}-reportScheduleDialogTitle`}
        />
      ) : (
        <FormattedMessage
          id={I18nFeatureReportSchedules.COMPONENTS_REPORTS_SCHEDULE_TABLE_DIALOG_TITLE}
          values={{ id: value.id }}
        />
      ),
      content: (
        <ReportScheduleView
          data={value}
          data-test={dataTest && `${dataTest}-reportSchedule`}
          style={{ paddingTop: '10px' }}
          columns={1}
        />
      ),
      maskClosable: true,
      width: 650,
    }),
    [ReportScheduleLink, dataTest],
  );

  const TableProps = useMemo(() => (title ? { headerTitle: title } : undefined), [title]);
  const filterForm = useMemo(
    () => (FilterForm ? { Form: FilterForm, empty: {} as FilterType } : undefined),
    [FilterForm],
  );

  return (
    <NCPSTable<ReportSchedule, FilterType, ReportScheduleSortByAPIModel>
      data-test={dataTest}
      filter={filterForm}
      preview={preview}
      sortColumnToModel={sortColumnToModel}
      columns={columns}
      data={data}
      loading={loading}
      page={page}
      filterData={filter}
      columnsState={columnState}
      reload={forceRefresh}
      updateParameters={updateParameters}
      extractKey={extractReportScheduleId}
      TableProps={TableProps}
    />
  );
};

const ReportSchedulesTable = React.memo(ReportSchedulesTableRaw);

export default ReportSchedulesTable;
