import { useCallback, useMemo } from 'react';

import {
  FormattedMessage,
  NCPSTable,
  OperationDateTimeRelativeQuickFilter,
  ReadonlyComponent,
  ReadonlyDateTime,
  StopPropagationContainer,
  TitleWithDetails,
} from '@/components';
import { AssetAmount, AddressLink, TransactionLink } from '@/features/dictionary/blockchain/components';
import type {
  MerchantWalletTransfer,
  MerchantWalletTransferFilterPredicate,
} from '@/features/merchant-wallet-transfers/types';
import { MerchantWalletTransferSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nComponents, I18nFeatureMerchantWalletTransfers } from '@/generated/i18n/i18n';
import { useTableButtonsMode } from '@/hooks';
import { sortOrderToTable } from '@/infrastructure/model/view';
import { nameof } from '@/infrastructure/utils/ts';

import MerchantWalletTransferDirectionView from '../MerchantWalletTransferDirectionView';
import MerchantWalletTransferKindView from '../MerchantWalletTransferKindView';
import MerchantWalletTransferView from '../MerchantWalletTransferView';

import type { MerchantWalletTransfersTableProps } from './types';
import type { ProColumnType } from '@ant-design/pro-table';
import type React from 'react';

const sortMapping = {
  [nameof<MerchantWalletTransfer>('broadcastAt')]: MerchantWalletTransferSortByAPIModel.broadcastAt,
};
const sortColumnToModel = (s: string) => sortMapping[s];
const extractKey = ({ id }: MerchantWalletTransfer) => id;

const MerchantWalletTransfersTable = <
  FilterType extends MerchantWalletTransferFilterPredicate = MerchantWalletTransferFilterPredicate,
>({
  'data-test': dataTest,
  data,
  loading,
  page,
  filter,
  columnState,
  sortBy,
  forceRefresh,
  updateParameters,
  title,
  ExportOperation,
  FilterForm,
  MerchantWalletTransferLink,
  EntityLink,
  SettlementLink,
}: MerchantWalletTransfersTableProps<FilterType>) => {
  const columns: ProColumnType<MerchantWalletTransfer>[] = useMemo(
    () => [
      {
        title: <FormattedMessage id={I18nFeatureMerchantWalletTransfers.LABELS_ROW_TITLE_DIRECTION} />,
        dataIndex: nameof<MerchantWalletTransfer>('direction'),
        fixed: 'left',
        render: (_, { id, direction }) => (
          <MerchantWalletTransferDirectionView
            value={direction}
            data-test={dataTest && `${dataTest}-${id}-direction`}
            mode="icon"
          />
        ),
        align: 'center',
        width: 80,
      },
      {
        title: <FormattedMessage id={I18nFeatureMerchantWalletTransfers.LABELS_ROW_TITLE_KIND} />,
        dataIndex: nameof<MerchantWalletTransfer>('kind'),
        fixed: 'left',
        render: (_, { id, kind }) => (
          <MerchantWalletTransferKindView value={kind} data-test={dataTest && `${dataTest}-${id}-kind`} mode="icon" />
        ),
        align: 'center',
        width: 80,
      },
      {
        title: <FormattedMessage id={I18nFeatureMerchantWalletTransfers.LABELS_ROW_TITLE_WALLET} />,
        dataIndex: nameof<MerchantWalletTransfer>('wallet'),
        render: (_, { id, blockchain, wallet }) => (
          <AddressLink data-test={dataTest && `${dataTest}-${id}-wallet`} address={wallet} bt={blockchain} />
        ),
      },
      {
        key: nameof<MerchantWalletTransfer>('txHash'),
        title: <FormattedMessage id={I18nFeatureMerchantWalletTransfers.LABELS_ROW_TITLE_TX} />,
        dataIndex: nameof<MerchantWalletTransfer>('txHash'),
        render: (_, { txHash, blockchain }) => (
          <TransactionLink data-test={dataTest && `${dataTest}-tx`} bt={blockchain} tx={txHash} />
        ),
        width: 160,
      },
      {
        title: <FormattedMessage id={I18nFeatureMerchantWalletTransfers.LABELS_ROW_TITLE_BROADCAST_AT} />,
        dataIndex: nameof<MerchantWalletTransfer>('broadcastAt'),
        valueType: 'dateTime',
        sortOrder: sortOrderToTable(sortBy.broadcastAt),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        render: (_, { id, broadcastAt }) => (
          <ReadonlyDateTime value={broadcastAt} data-test={dataTest && `${dataTest}-${id}-broadcastAt`} />
        ),
        width: 160,
      },
      {
        title: <FormattedMessage id={I18nFeatureMerchantWalletTransfers.LABELS_ROW_TITLE_AMOUNT} />,
        dataIndex: nameof<MerchantWalletTransfer>('amount'),
        fixed: 'right',
        width: 120,
        render: (_, { amount: { value, asset } }) => (
          <ReadonlyComponent
            value={
              <AssetAmount
                data-test={dataTest && `${dataTest}-amount`}
                assetId={asset}
                value={value}
                withCurrency
                withBlockchainMark
              />
            }
            copyable={false}
          />
        ),
      },
      {
        key: 'options',
        title: <FormattedMessage id={I18nComponents.TABLE_ROW_OPTIONS} />,
        dataIndex: 'options',
        width: 1,
        fixed: 'right',
        render: (_, { id, withdrawalId, kind, entityId }) => (
          <StopPropagationContainer>
            {MerchantWalletTransferLink && (
              <MerchantWalletTransferLink value={id} data-test={dataTest && `${dataTest}-${id}-link`} />
            )}
            {EntityLink && (
              <EntityLink value={{ id, kind, entityId }} data-test={dataTest && `${dataTest}-${id}-entity`} />
            )}
            {withdrawalId && SettlementLink && (
              <SettlementLink value={withdrawalId} data-test={dataTest && `${dataTest}-${id}-settlement`} />
            )}
          </StopPropagationContainer>
        ),
      },
    ],
    [EntityLink, MerchantWalletTransferLink, SettlementLink, dataTest, sortBy.broadcastAt],
  );

  const preview = useCallback(
    (value: MerchantWalletTransfer) => ({
      title: MerchantWalletTransferLink ? (
        <TitleWithDetails
          title={
            <FormattedMessage
              id={I18nFeatureMerchantWalletTransfers.COMPONENTS_TRANSFERS_TABLE_TRANSFER_DIALOG_TITLE}
            />
          }
          link={
            <MerchantWalletTransferLink
              value={value.id}
              mode="text"
              title={<FormattedMessage id={I18nComponents.TABLE_LINK_DETAILS} />}
            />
          }
          data-test={dataTest && `${dataTest}-dialog-title`}
        />
      ) : (
        <FormattedMessage id={I18nFeatureMerchantWalletTransfers.COMPONENTS_TRANSFERS_TABLE_TRANSFER_DIALOG_TITLE} />
      ),
      content: (
        <MerchantWalletTransferView
          data-test={dataTest && `${dataTest}-dialog-view`}
          data={value}
          style={{ paddingTop: '10px' }}
          columns={1}
        />
      ),
      maskClosable: true,
      width: 650,
    }),
    [MerchantWalletTransferLink, dataTest],
  );

  const { ref, quickFilterMode, exportMode } = useTableButtonsMode();
  const toolBar = useMemo(
    () =>
      FilterForm
        ? (filterComponent?: React.ReactNode) => [
            ...(ExportOperation
              ? [
                  <ExportOperation
                    key="export"
                    data-test={dataTest && `${dataTest}-export`}
                    mode={exportMode}
                    rows={data.data?.total}
                  />,
                ]
              : []),
            <OperationDateTimeRelativeQuickFilter<'broadcastAtRange', 'broadcastAtRangeRelative', FilterType>
              key="quickFilter"
              absoluteKey={nameof<FilterType, 'broadcastAtRange'>('broadcastAtRange')}
              relativeKey={nameof<FilterType, 'broadcastAtRangeRelative'>('broadcastAtRangeRelative')}
              onChange={(newValue) => updateParameters({ filter: newValue })}
              mode={quickFilterMode}
              value={filter}
              disabled={loading}
            />,
            filterComponent,
          ]
        : undefined,
    [
      ExportOperation,
      FilterForm,
      dataTest,
      exportMode,
      data.data?.total,
      quickFilterMode,
      filter,
      loading,
      updateParameters,
    ],
  );

  const filterForm = useMemo(
    () => (FilterForm ? { Form: FilterForm, empty: {} as FilterType } : undefined),
    [FilterForm],
  );
  const TableProps = useMemo(() => (title ? { headerTitle: title } : undefined), [title]);

  return (
    <div ref={ref}>
      <NCPSTable<MerchantWalletTransfer, FilterType, MerchantWalletTransferSortByAPIModel>
        data-test={dataTest}
        filter={filterForm}
        preview={preview}
        toolBarRender={toolBar}
        sortColumnToModel={sortColumnToModel}
        columns={columns}
        data={data}
        loading={loading}
        page={page}
        filterData={filter}
        columnsState={columnState}
        reload={forceRefresh}
        updateParameters={updateParameters}
        extractKey={extractKey}
        TableProps={TableProps}
      />
    </div>
  );
};

export default MerchantWalletTransfersTable;
