import { Typography } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { useCompanyPermissions } from '@/features/company/hooks';
import { withSettlementSchedule } from '@/features/settlements/hocs';
import { WithdrawalPeriodAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';
import { asType } from '@/infrastructure/utils/ts';
import ActualBalancesLink from '@/pages/balances/actual/components/ActualBalancesLink';

import SettlementPeriodLabel from '../SettlementPeriodLabel';

import type { SettlementScheduleViewProps } from './types';

const SettlementScheduleView: React.FC<SettlementScheduleViewProps> = ({
  'data-test': dataTest,
  style,
  className,
  schedule,
}) => {
  const {
    data: { data: permissions },
  } = useCompanyPermissions();
  return (
    <Typography.Paragraph data-test={dataTest} style={style} className={className}>
      {schedule && schedule.period !== WithdrawalPeriodAPIModel.NotSet ? (
        <FormattedMessage
          id={I18nFeatureSettlements.COMPONENTS_SCHEDULE_VIEW_DEFINED}
          values={{
            balance: (text: React.ReactNode) => <ActualBalancesLink title={text} mode="text" />,
            schedule: (
              <strong>
                <SettlementPeriodLabel value={schedule.period} />
              </strong>
            ),
            tokens: <strong>{schedule.unifiedMinAmount}</strong>,
          }}
        />
      ) : permissions?.settleManagement ? (
        <FormattedMessage
          id={I18nFeatureSettlements.COMPONENTS_SCHEDULE_VIEW_NOT_SET_PERMISSIONS_STANDALONE}
          values={{ balance: (text: React.ReactNode) => <ActualBalancesLink title={text} mode="text" /> }}
        />
      ) : (
        <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_SCHEDULE_VIEW_NOT_SET_PERMISSIONS_PARTNERED} />
      )}
    </Typography.Paragraph>
  );
};

const SettlementScheduleViewHOC = withSettlementSchedule<
  Omit<SettlementScheduleViewProps, 'schedule'> & Required<Pick<SettlementScheduleViewProps, 'schedule'>>
>(SettlementScheduleView)(asType<React.FC<Omit<SettlementScheduleViewProps, 'schedule'>>>(SettlementScheduleView));

const SettlementScheduleViewMemo = React.memo(SettlementScheduleViewHOC);

export default SettlementScheduleViewMemo;
