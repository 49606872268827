/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { MerchantGasHistoryRowAPIModel } from './MerchantGasHistoryRowAPIModel';
import {
    MerchantGasHistoryRowAPIModelFromJSON,
    MerchantGasHistoryRowAPIModelFromJSONTyped,
    MerchantGasHistoryRowAPIModelToJSON,
} from './MerchantGasHistoryRowAPIModel';
import type { PageGasHistorySortByAPIModel } from './PageGasHistorySortByAPIModel';
import {
    PageGasHistorySortByAPIModelFromJSON,
    PageGasHistorySortByAPIModelFromJSONTyped,
    PageGasHistorySortByAPIModelToJSON,
} from './PageGasHistorySortByAPIModel';

/**
 * 
 * @export
 * @interface SliceMerchantGasHistoryRowGasHistorySortByAPIModel
 */
export interface SliceMerchantGasHistoryRowGasHistorySortByAPIModel {
    /**
     * 
     * @type {Array<MerchantGasHistoryRowAPIModel>}
     * @memberof SliceMerchantGasHistoryRowGasHistorySortByAPIModel
     */
    list?: Array<MerchantGasHistoryRowAPIModel>;
    /**
     * 
     * @type {PageGasHistorySortByAPIModel}
     * @memberof SliceMerchantGasHistoryRowGasHistorySortByAPIModel
     */
    page: PageGasHistorySortByAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SliceMerchantGasHistoryRowGasHistorySortByAPIModel
     */
    total?: number;
}

/**
 * Check if a given object implements the SliceMerchantGasHistoryRowGasHistorySortByAPIModel interface.
 */
export function instanceOfSliceMerchantGasHistoryRowGasHistorySortByAPIModel(value: object): boolean {
    if (!('page' in value)) return false;
    return true;
}

export function SliceMerchantGasHistoryRowGasHistorySortByAPIModelFromJSON(json: any): SliceMerchantGasHistoryRowGasHistorySortByAPIModel {
    return SliceMerchantGasHistoryRowGasHistorySortByAPIModelFromJSONTyped(json, false);
}

export function SliceMerchantGasHistoryRowGasHistorySortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SliceMerchantGasHistoryRowGasHistorySortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(MerchantGasHistoryRowAPIModelFromJSON)),
        'page': PageGasHistorySortByAPIModelFromJSON(json['page']),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function SliceMerchantGasHistoryRowGasHistorySortByAPIModelToJSON(value?: SliceMerchantGasHistoryRowGasHistorySortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(MerchantGasHistoryRowAPIModelToJSON)),
        'page': PageGasHistorySortByAPIModelToJSON(value['page']),
        'total': value['total'],
    };
}

