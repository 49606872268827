/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum PayoutBatchStatusAPIModel {
    Pending = 'Pending',
    Succeeded = 'Succeeded',
    Failed = 'Failed'
}


export function PayoutBatchStatusAPIModelFromJSON(json: any): PayoutBatchStatusAPIModel {
    return PayoutBatchStatusAPIModelFromJSONTyped(json, false);
}

export function PayoutBatchStatusAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayoutBatchStatusAPIModel {
    return json as PayoutBatchStatusAPIModel;
}

export function PayoutBatchStatusAPIModelToJSON(value?: PayoutBatchStatusAPIModel | null): any {
    return value as any;
}

