import { UndoOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useCallback } from 'react';
import isEqual from 'react-fast-compare';

import DataFetchError from '@/components/DataFetchError';
import FormattedMessage from '@/components/FormattedMessage';
import { I18nComponents } from '@/generated/i18n/i18n';
import { defaultPage } from '@/infrastructure/model/api';

import type { TableDataFetchErrorProps } from './types';

const commonDefaultParameters = { filter: {}, page: defaultPage };

function TableDataFetchErrorRaw<F>({
  'data-test': dataTest,
  refresh,
  restore,
  defaultParameters = commonDefaultParameters as NonNullable<TableDataFetchErrorProps<F>['defaultParameters']>,
}: TableDataFetchErrorProps<F>) {
  const onRestore = useCallback((): unknown => restore?.action(defaultParameters), [defaultParameters, restore]);
  return (
    <DataFetchError
      data-test={dataTest}
      refresh={refresh}
      extra={
        restore
        && !isEqual(restore.parameters, defaultParameters) && (
          <Button danger data-test={dataTest && `${dataTest}-restore`} onClick={onRestore} icon={<UndoOutlined />}>
            <FormattedMessage id={I18nComponents.TABLE_DATA_FETCH_CLEAN_RELOAD_TITLE} tagName="span" />
          </Button>
        )
      }
    />
  );
}

const TableDataFetchError = React.memo(TableDataFetchErrorRaw) as typeof TableDataFetchErrorRaw;

export default TableDataFetchError;
