/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BigDecimalRangeAPIModel } from './BigDecimalRangeAPIModel';
import {
    BigDecimalRangeAPIModelFromJSON,
    BigDecimalRangeAPIModelFromJSONTyped,
    BigDecimalRangeAPIModelToJSON,
} from './BigDecimalRangeAPIModel';
import type { BlockchainNetworkTypeAPIModel } from './BlockchainNetworkTypeAPIModel';
import {
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelFromJSONTyped,
    BlockchainNetworkTypeAPIModelToJSON,
} from './BlockchainNetworkTypeAPIModel';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { DateTimeRangeAPIModel } from './DateTimeRangeAPIModel';
import {
    DateTimeRangeAPIModelFromJSON,
    DateTimeRangeAPIModelFromJSONTyped,
    DateTimeRangeAPIModelToJSON,
} from './DateTimeRangeAPIModel';
import type { PushContractDeployStatusAPIModel } from './PushContractDeployStatusAPIModel';
import {
    PushContractDeployStatusAPIModelFromJSON,
    PushContractDeployStatusAPIModelFromJSONTyped,
    PushContractDeployStatusAPIModelToJSON,
} from './PushContractDeployStatusAPIModel';

/**
 * 
 * @export
 * @interface RechargeAddressPredicatesAPIModel
 */
export interface RechargeAddressPredicatesAPIModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof RechargeAddressPredicatesAPIModel
     */
    idIn?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RechargeAddressPredicatesAPIModel
     */
    addressLike?: string;
    /**
     * 
     * @type {Array<BlockchainTypeAPIModel>}
     * @memberof RechargeAddressPredicatesAPIModel
     */
    btIn?: Array<BlockchainTypeAPIModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RechargeAddressPredicatesAPIModel
     */
    assetIn?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RechargeAddressPredicatesAPIModel
     */
    cidLike?: string;
    /**
     * 
     * @type {DateTimeRangeAPIModel}
     * @memberof RechargeAddressPredicatesAPIModel
     */
    createdAtRange?: DateTimeRangeAPIModel;
    /**
     * 
     * @type {BigDecimalRangeAPIModel}
     * @memberof RechargeAddressPredicatesAPIModel
     */
    balanceRange?: BigDecimalRangeAPIModel;
    /**
     * 
     * @type {Array<PushContractDeployStatusAPIModel>}
     * @memberof RechargeAddressPredicatesAPIModel
     */
    deployStatusIn?: Array<PushContractDeployStatusAPIModel>;
    /**
     * 
     * @type {BlockchainNetworkTypeAPIModel}
     * @memberof RechargeAddressPredicatesAPIModel
     */
    networkEq?: BlockchainNetworkTypeAPIModel;
}

/**
 * Check if a given object implements the RechargeAddressPredicatesAPIModel interface.
 */
export function instanceOfRechargeAddressPredicatesAPIModel(value: object): boolean {
    return true;
}

export function RechargeAddressPredicatesAPIModelFromJSON(json: any): RechargeAddressPredicatesAPIModel {
    return RechargeAddressPredicatesAPIModelFromJSONTyped(json, false);
}

export function RechargeAddressPredicatesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RechargeAddressPredicatesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'idIn': json['idIn'] == null ? undefined : json['idIn'],
        'addressLike': json['addressLike'] == null ? undefined : json['addressLike'],
        'btIn': json['btIn'] == null ? undefined : ((json['btIn'] as Array<any>).map(BlockchainTypeAPIModelFromJSON)),
        'assetIn': json['assetIn'] == null ? undefined : json['assetIn'],
        'cidLike': json['cidLike'] == null ? undefined : json['cidLike'],
        'createdAtRange': json['createdAtRange'] == null ? undefined : DateTimeRangeAPIModelFromJSON(json['createdAtRange']),
        'balanceRange': json['balanceRange'] == null ? undefined : BigDecimalRangeAPIModelFromJSON(json['balanceRange']),
        'deployStatusIn': json['deployStatusIn'] == null ? undefined : ((json['deployStatusIn'] as Array<any>).map(PushContractDeployStatusAPIModelFromJSON)),
        'networkEq': json['networkEq'] == null ? undefined : BlockchainNetworkTypeAPIModelFromJSON(json['networkEq']),
    };
}

export function RechargeAddressPredicatesAPIModelToJSON(value?: RechargeAddressPredicatesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'idIn': value['idIn'],
        'addressLike': value['addressLike'],
        'btIn': value['btIn'] == null ? undefined : ((value['btIn'] as Array<any>).map(BlockchainTypeAPIModelToJSON)),
        'assetIn': value['assetIn'],
        'cidLike': value['cidLike'],
        'createdAtRange': DateTimeRangeAPIModelToJSON(value['createdAtRange']),
        'balanceRange': BigDecimalRangeAPIModelToJSON(value['balanceRange']),
        'deployStatusIn': value['deployStatusIn'] == null ? undefined : ((value['deployStatusIn'] as Array<any>).map(PushContractDeployStatusAPIModelToJSON)),
        'networkEq': BlockchainNetworkTypeAPIModelToJSON(value['networkEq']),
    };
}

