/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PageWithdrawalIntentSortByAPIModel } from './PageWithdrawalIntentSortByAPIModel';
import {
    PageWithdrawalIntentSortByAPIModelFromJSON,
    PageWithdrawalIntentSortByAPIModelFromJSONTyped,
    PageWithdrawalIntentSortByAPIModelToJSON,
} from './PageWithdrawalIntentSortByAPIModel';
import type { WithdrawalIntentAPIModel } from './WithdrawalIntentAPIModel';
import {
    WithdrawalIntentAPIModelFromJSON,
    WithdrawalIntentAPIModelFromJSONTyped,
    WithdrawalIntentAPIModelToJSON,
} from './WithdrawalIntentAPIModel';

/**
 * 
 * @export
 * @interface SliceWithdrawalIntentWithdrawalIntentSortByAPIModel
 */
export interface SliceWithdrawalIntentWithdrawalIntentSortByAPIModel {
    /**
     * 
     * @type {Array<WithdrawalIntentAPIModel>}
     * @memberof SliceWithdrawalIntentWithdrawalIntentSortByAPIModel
     */
    list?: Array<WithdrawalIntentAPIModel>;
    /**
     * 
     * @type {PageWithdrawalIntentSortByAPIModel}
     * @memberof SliceWithdrawalIntentWithdrawalIntentSortByAPIModel
     */
    page: PageWithdrawalIntentSortByAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SliceWithdrawalIntentWithdrawalIntentSortByAPIModel
     */
    total?: number;
}

/**
 * Check if a given object implements the SliceWithdrawalIntentWithdrawalIntentSortByAPIModel interface.
 */
export function instanceOfSliceWithdrawalIntentWithdrawalIntentSortByAPIModel(value: object): boolean {
    if (!('page' in value)) return false;
    return true;
}

export function SliceWithdrawalIntentWithdrawalIntentSortByAPIModelFromJSON(json: any): SliceWithdrawalIntentWithdrawalIntentSortByAPIModel {
    return SliceWithdrawalIntentWithdrawalIntentSortByAPIModelFromJSONTyped(json, false);
}

export function SliceWithdrawalIntentWithdrawalIntentSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SliceWithdrawalIntentWithdrawalIntentSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(WithdrawalIntentAPIModelFromJSON)),
        'page': PageWithdrawalIntentSortByAPIModelFromJSON(json['page']),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function SliceWithdrawalIntentWithdrawalIntentSortByAPIModelToJSON(value?: SliceWithdrawalIntentWithdrawalIntentSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(WithdrawalIntentAPIModelToJSON)),
        'page': PageWithdrawalIntentSortByAPIModelToJSON(value['page']),
        'total': value['total'],
    };
}

