import { parseReport } from '@/features/reports/api';
import type {
  MerchantWalletTransferAPIModel,
  MerchantWalletTransferPredicatesAPIModel,
  MerchantWalletTransferSortByAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { MerchantWalletTransferApi } from '@/generated/api/ncps-core/merchant-bo';
import { coreConfigurationFactory } from '@/infrastructure/api';
import type { SliceDataRequestWithTotal } from '@/infrastructure/model/api';
import { amountFromAPI, pageToAPI, sliceFromAPI } from '@/infrastructure/model/api';

import type { MerchantWalletTransfer } from './types';

const transfersApi = new MerchantWalletTransferApi(coreConfigurationFactory('auth'));

export const parseMerchantWalletTransfer = ({
  amount,
  ...transfer
}: MerchantWalletTransferAPIModel): MerchantWalletTransfer => ({
  ...transfer,
  amount: amountFromAPI(amount),
});

export const queryMerchantWalletTransfers = async (
  {
    page,
    filter,
    orderBy,
    withTotal,
  }: SliceDataRequestWithTotal<MerchantWalletTransferPredicatesAPIModel, MerchantWalletTransferSortByAPIModel>,
  initOverrides?: RequestInit,
) =>
  sliceFromAPI(
    await transfersApi.getMerchantWalletTransfers(
      { page: pageToAPI(page, orderBy), predicates: filter ?? {} },
      withTotal,
      initOverrides,
    ),
    parseMerchantWalletTransfer,
  );

export const queryMerchantWalletTransfer = async (id: string, initOverrides?: RequestInit) =>
  parseMerchantWalletTransfer(await transfersApi.getMerchantWalletTransfer(id, initOverrides));

export const requestExportMerchantWalletTransfers = async (
  predicates: MerchantWalletTransferPredicatesAPIModel,
  initOverrides?: RequestInit,
) => parseReport(await transfersApi.exportMerchantWalletTransfers(predicates, initOverrides));
