/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { ReportSortByAPIModel } from './ReportSortByAPIModel';
import {
    ReportSortByAPIModelFromJSON,
    ReportSortByAPIModelFromJSONTyped,
    ReportSortByAPIModelToJSON,
} from './ReportSortByAPIModel';

/**
 * 
 * @export
 * @interface SortAttributeReportSortByAPIModel
 */
export interface SortAttributeReportSortByAPIModel {
    /**
     * 
     * @type {ReportSortByAPIModel}
     * @memberof SortAttributeReportSortByAPIModel
     */
    attr: ReportSortByAPIModel;
    /**
     * 
     * @type {boolean}
     * @memberof SortAttributeReportSortByAPIModel
     */
    asc: boolean;
}

/**
 * Check if a given object implements the SortAttributeReportSortByAPIModel interface.
 */
export function instanceOfSortAttributeReportSortByAPIModel(value: object): boolean {
    if (!('attr' in value)) return false;
    if (!('asc' in value)) return false;
    return true;
}

export function SortAttributeReportSortByAPIModelFromJSON(json: any): SortAttributeReportSortByAPIModel {
    return SortAttributeReportSortByAPIModelFromJSONTyped(json, false);
}

export function SortAttributeReportSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortAttributeReportSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'attr': ReportSortByAPIModelFromJSON(json['attr']),
        'asc': json['asc'],
    };
}

export function SortAttributeReportSortByAPIModelToJSON(value?: SortAttributeReportSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attr': ReportSortByAPIModelToJSON(value['attr']),
        'asc': value['asc'],
    };
}

