import { useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { createCompany, updateCompanyName } from '@/features/company/actions';
import type { Company, NewCompany, UpdateCompanyName } from '@/features/company/types';
import { useActionPending } from '@/features/global/hooks';
import type { HookAction } from '@/infrastructure/model';

import useActiveCompany from '../useActiveCompany';

type CreateCompanyUnavailabilityReason = 'already-exists';
type UpdateCompanyUnavailabilityReason = 'no-company';

export interface UseCompanyActionsType {
  createCompany: HookAction<[NewCompany], Company, CreateCompanyUnavailabilityReason>;
  updateName: HookAction<[UpdateCompanyName], Company, UpdateCompanyUnavailabilityReason>;
  data?: Company;
}

export default function useCompanyActions(): UseCompanyActionsType {
  const { withExtractDataDispatch } = useAppDispatch();
  const { data: company } = useActiveCompany();

  const creating = useActionPending(createCompany);
  const createCompanyAction: UseCompanyActionsType['createCompany']['act'] = useMemo(
    () => withExtractDataDispatch(createCompany),
    [withExtractDataDispatch],
  );
  const createCompanyHook: UseCompanyActionsType['createCompany'] = useMemo(
    () => ({
      act: createCompanyAction,
      available: !company.data,
      unavailabilityReason: !company.data ? 'already-exists' : undefined,
      inAction: creating,
    }),
    [company.data, createCompanyAction, creating],
  );

  const updatingCompanyName = useActionPending(updateCompanyName);
  const updateCompanyNameAction: UseCompanyActionsType['updateName']['act'] = useMemo(
    () => withExtractDataDispatch(updateCompanyName),
    [withExtractDataDispatch],
  );
  const updateNameHook: HookAction<[UpdateCompanyName], Company, UpdateCompanyUnavailabilityReason> = useMemo(
    () => ({
      act: updateCompanyNameAction,
      available: !!company.data,
      unavailabilityReason: company.data ? 'no-company' : undefined,
      inAction: updatingCompanyName,
    }),
    [company.data, updateCompanyNameAction, updatingCompanyName],
  );

  return { createCompany: createCompanyHook, updateName: updateNameHook, data: company.data };
}
