/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum PushCollectPeriodAPIModel {
    NotSet = 'NotSet',
    LastDayOfWeek = 'LastDayOfWeek',
    LastDayOfMonth = 'LastDayOfMonth'
}


export function PushCollectPeriodAPIModelFromJSON(json: any): PushCollectPeriodAPIModel {
    return PushCollectPeriodAPIModelFromJSONTyped(json, false);
}

export function PushCollectPeriodAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushCollectPeriodAPIModel {
    return json as PushCollectPeriodAPIModel;
}

export function PushCollectPeriodAPIModelToJSON(value?: PushCollectPeriodAPIModel | null): any {
    return value as any;
}

