/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PushAddressSortByAPIModel } from './PushAddressSortByAPIModel';
import {
    PushAddressSortByAPIModelFromJSON,
    PushAddressSortByAPIModelFromJSONTyped,
    PushAddressSortByAPIModelToJSON,
} from './PushAddressSortByAPIModel';

/**
 * 
 * @export
 * @interface SortAttributePushAddressSortByAPIModel
 */
export interface SortAttributePushAddressSortByAPIModel {
    /**
     * 
     * @type {PushAddressSortByAPIModel}
     * @memberof SortAttributePushAddressSortByAPIModel
     */
    attr: PushAddressSortByAPIModel;
    /**
     * 
     * @type {boolean}
     * @memberof SortAttributePushAddressSortByAPIModel
     */
    asc: boolean;
}

/**
 * Check if a given object implements the SortAttributePushAddressSortByAPIModel interface.
 */
export function instanceOfSortAttributePushAddressSortByAPIModel(value: object): boolean {
    if (!('attr' in value)) return false;
    if (!('asc' in value)) return false;
    return true;
}

export function SortAttributePushAddressSortByAPIModelFromJSON(json: any): SortAttributePushAddressSortByAPIModel {
    return SortAttributePushAddressSortByAPIModelFromJSONTyped(json, false);
}

export function SortAttributePushAddressSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortAttributePushAddressSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'attr': PushAddressSortByAPIModelFromJSON(json['attr']),
        'asc': json['asc'],
    };
}

export function SortAttributePushAddressSortByAPIModelToJSON(value?: SortAttributePushAddressSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attr': PushAddressSortByAPIModelToJSON(value['attr']),
        'asc': value['asc'],
    };
}

