import React from 'react';

import type { MerchantWalletTransferViewProps } from '@/features/merchant-wallet-transfers/components';
import { MerchantWalletTransferView } from '@/features/merchant-wallet-transfers/components';

import { withMerchantWalletTransferDataLoading } from './hocs';

const MerchantWalletTransferCard = withMerchantWalletTransferDataLoading<MerchantWalletTransferViewProps>((props) => (
  <MerchantWalletTransferView {...props} columns={1} />
));

const MerchantWalletTransferCardMemo: typeof MerchantWalletTransferCard = React.memo(MerchantWalletTransferCard);

export default MerchantWalletTransferCardMemo;
