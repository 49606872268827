import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { extractGasHistoryId } from '@/features/gas-history/utils';
import { markReportListDirty, storeReport } from '@/features/reports/actions';
import type { GasHistorySortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { mapLoadingState } from '@/infrastructure/model';
import { defaultPageFn, withAPICall } from '@/infrastructure/model/api';
import { createNormalizedListActions } from '@/infrastructure/model/list/actions';
import { listStateToSliceRequest, mapLoadingSliceStateToListData } from '@/infrastructure/model/list/utils';
import { toMultiplePayload } from '@/infrastructure/model/single/utils';
import { notEmpty } from '@/infrastructure/utils/ts';
import { uuidToBase58 } from '@/infrastructure/utils/uuid';

import { queryGasHistory, queryGasHistoryList, requestExportGasHistory } from './api';
import {
  makeSelectDirtyGasHistoryIds,
  makeSelectMultipleGasHistory,
  makeSelectGasHistory,
  makeSelectGasHistoryListData,
  makeSelectGasHistoryListParameters,
} from './selectors';
import { NAMESPACE, type GasHistoryRow, type GasHistoryFilterPredicate } from './types';

export const {
  storeGasHistory,
  storeMultipleGasHistory,
  markGasHistoryDirty,
  storeGasHistoryListParameters,
  storeGasHistoryListData,
  markGasHistoryListDirty,
} = createNormalizedListActions<GasHistoryRow, 'GasHistory', GasHistoryFilterPredicate, GasHistorySortByAPIModel>(
  NAMESPACE,
  'GasHistory' as const,
);

const historyFetchLimit = pLimit(1);
export const fetchGasHistory = createAppAsyncThunk(
  `${NAMESPACE}/fetchGasHistory`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    historyFetchLimit(async () => {
      const saved = makeSelectGasHistory(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const uuid = uuidToBase58(id) ?? id;
      const data = await withAPICall(queryGasHistory, 'unable to fetch merchant')(uuid, { signal });
      dispatch(storeGasHistory({ id, data }));

      return makeSelectGasHistory(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

const multipleHistoryFetchLimit = pLimit(1);
export const fetchMultipleGasHistory = createAppAsyncThunk(
  `${NAMESPACE}/fetchMultipleGasHistory`,
  async ({ force, ids }: { force?: boolean; ids: string[] }, { dispatch, getState, signal }) =>
    multipleHistoryFetchLimit(async () => {
      const absent = makeSelectDirtyGasHistoryIds(ids)(getState());
      if (!force && !absent.length) {
        return makeSelectMultipleGasHistory(ids)(getState());
      }

      const data = await withAPICall(queryGasHistoryList, 'unable to fetch merchant')(
        { filter: { idIn: ids.map(uuidToBase58).filter(notEmpty) }, page: defaultPageFn({ perPage: ids.length }) },
        {
          signal,
        },
      );
      dispatch(
        storeMultipleGasHistory(
          toMultiplePayload(
            mapLoadingState(data, ({ list }) => list),
            ids,
            extractGasHistoryId,
          ),
        ),
      );

      return makeSelectMultipleGasHistory(ids)(getState());
    }),
);

const gasHistoryFetchLimit = pLimit(1);
export const fetchGasHistoryList = createAppAsyncThunk(
  `${NAMESPACE}/fetchGasHistoryList`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    gasHistoryFetchLimit(async () => {
      const saved = makeSelectGasHistoryListData()(getState());
      if (!force && !saved.isDirty && !saved.isTotalDirty) {
        return saved;
      }

      const data = await withAPICall(queryGasHistoryList, 'unable to fetch history')(
        listStateToSliceRequest({ data: saved, ...makeSelectGasHistoryListParameters()(getState()) }),
        { signal },
      );
      dispatch(storeGasHistoryListData(mapLoadingSliceStateToListData(saved.data?.total)(data)));

      return makeSelectGasHistoryListData()(getState());
    }),
);

export const exportGasHistory = createAppAsyncThunk(
  `${NAMESPACE}/exportGasHistory`,
  async ({ predicates }: { predicates: GasHistoryFilterPredicate }, { dispatch, signal }) => {
    const data = await withAPICall(requestExportGasHistory, 'unable to export history')(predicates, { signal });

    if (data.data) {
      dispatch(storeReport({ id: data.data.id, data }));
      dispatch(markReportListDirty());
    }
    return data;
  },
);
