/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  BlockchainNetworkTypeAPIModel,
  CompanyApiFullKeyAPIModel,
  CompanyApiKeyAPIModel,
  CompanyApiKeysSliceAPIModel,
  CompanyKeyRequestAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelToJSON,
    CompanyApiFullKeyAPIModelFromJSON,
    CompanyApiFullKeyAPIModelToJSON,
    CompanyApiKeyAPIModelFromJSON,
    CompanyApiKeyAPIModelToJSON,
    CompanyApiKeysSliceAPIModelFromJSON,
    CompanyApiKeysSliceAPIModelToJSON,
    CompanyKeyRequestAPIModelFromJSON,
    CompanyKeyRequestAPIModelToJSON,
} from '../models/index';

export interface ActivateApiKeyRequest {
    network: BlockchainNetworkTypeAPIModel;
    key: string;
}

export interface CreateApiKeyRequest {
    companyKeyRequestAPIModel: CompanyKeyRequestAPIModel;
}

export interface CreateNetworkApiKeyRequest {
    network: BlockchainNetworkTypeAPIModel;
}

export interface DeactivateApiKeyRequest {
    network: BlockchainNetworkTypeAPIModel;
    key: string;
}

export interface DeleteApiKeyRequest {
    network: BlockchainNetworkTypeAPIModel;
    key: string;
}

/**
 * CompanyKeysApi - interface
 * 
 * @export
 * @interface CompanyKeysApiInterface
 */
export interface CompanyKeysApiInterface {
    /**
     * Activates api key
     * @param {BlockchainNetworkTypeAPIModel} network 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyKeysApiInterface
     */
    activateApiKeyRaw(requestParameters: ActivateApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyApiKeyAPIModel>>;

    /**
     * Activates api key
     */
    activateApiKey(network: BlockchainNetworkTypeAPIModel, key: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyApiKeyAPIModel>;

    /**
     * Creates new api key
     * @param {CompanyKeyRequestAPIModel} companyKeyRequestAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyKeysApiInterface
     */
    createApiKeyRaw(requestParameters: CreateApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyApiFullKeyAPIModel>>;

    /**
     * Creates new api key
     */
    createApiKey(companyKeyRequestAPIModel: CompanyKeyRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyApiFullKeyAPIModel>;

    /**
     * Creates new api key for specific network
     * @param {BlockchainNetworkTypeAPIModel} network 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyKeysApiInterface
     */
    createNetworkApiKeyRaw(requestParameters: CreateNetworkApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyApiFullKeyAPIModel>>;

    /**
     * Creates new api key for specific network
     */
    createNetworkApiKey(network: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyApiFullKeyAPIModel>;

    /**
     * Deactivates api key
     * @param {BlockchainNetworkTypeAPIModel} network 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyKeysApiInterface
     */
    deactivateApiKeyRaw(requestParameters: DeactivateApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyApiKeyAPIModel>>;

    /**
     * Deactivates api key
     */
    deactivateApiKey(network: BlockchainNetworkTypeAPIModel, key: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyApiKeyAPIModel>;

    /**
     * Deletes api key
     * @param {BlockchainNetworkTypeAPIModel} network 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyKeysApiInterface
     */
    deleteApiKeyRaw(requestParameters: DeleteApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Deletes api key
     */
    deleteApiKey(network: BlockchainNetworkTypeAPIModel, key: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Returns api keys for the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyKeysApiInterface
     */
    getAllApiKeysRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyApiKeysSliceAPIModel>>;

    /**
     * Returns api keys for the company
     */
    getAllApiKeys(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyApiKeysSliceAPIModel>;

}

/**
 * 
 */
export class CompanyKeysApi extends runtime.BaseAPI implements CompanyKeysApiInterface {

    /**
     * Activates api key
     */
    async activateApiKeyRaw(requestParameters: ActivateApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyApiKeyAPIModel>> {
        if (requestParameters['network'] == null) {
            throw new runtime.RequiredError(
                'network',
                'Required parameter "network" was null or undefined when calling activateApiKey().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling activateApiKey().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/keys/{network}/{key}/activate`.replace(`{${"network"}}`, encodeURIComponent(String(requestParameters['network']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyApiKeyAPIModelFromJSON(jsonValue));
    }

    /**
     * Activates api key
     */
    async activateApiKey(network: BlockchainNetworkTypeAPIModel, key: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyApiKeyAPIModel> {
        const response = await this.activateApiKeyRaw({ network: network, key: key }, initOverrides);
        return await response.value();
    }

    /**
     * Creates new api key
     */
    async createApiKeyRaw(requestParameters: CreateApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyApiFullKeyAPIModel>> {
        if (requestParameters['companyKeyRequestAPIModel'] == null) {
            throw new runtime.RequiredError(
                'companyKeyRequestAPIModel',
                'Required parameter "companyKeyRequestAPIModel" was null or undefined when calling createApiKey().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/keys`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyKeyRequestAPIModelToJSON(requestParameters['companyKeyRequestAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyApiFullKeyAPIModelFromJSON(jsonValue));
    }

    /**
     * Creates new api key
     */
    async createApiKey(companyKeyRequestAPIModel: CompanyKeyRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyApiFullKeyAPIModel> {
        const response = await this.createApiKeyRaw({ companyKeyRequestAPIModel: companyKeyRequestAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Creates new api key for specific network
     */
    async createNetworkApiKeyRaw(requestParameters: CreateNetworkApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyApiFullKeyAPIModel>> {
        if (requestParameters['network'] == null) {
            throw new runtime.RequiredError(
                'network',
                'Required parameter "network" was null or undefined when calling createNetworkApiKey().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/keys/{network}`.replace(`{${"network"}}`, encodeURIComponent(String(requestParameters['network']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyApiFullKeyAPIModelFromJSON(jsonValue));
    }

    /**
     * Creates new api key for specific network
     */
    async createNetworkApiKey(network: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyApiFullKeyAPIModel> {
        const response = await this.createNetworkApiKeyRaw({ network: network }, initOverrides);
        return await response.value();
    }

    /**
     * Deactivates api key
     */
    async deactivateApiKeyRaw(requestParameters: DeactivateApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyApiKeyAPIModel>> {
        if (requestParameters['network'] == null) {
            throw new runtime.RequiredError(
                'network',
                'Required parameter "network" was null or undefined when calling deactivateApiKey().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling deactivateApiKey().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/keys/{network}/{key}/deactivate`.replace(`{${"network"}}`, encodeURIComponent(String(requestParameters['network']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyApiKeyAPIModelFromJSON(jsonValue));
    }

    /**
     * Deactivates api key
     */
    async deactivateApiKey(network: BlockchainNetworkTypeAPIModel, key: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyApiKeyAPIModel> {
        const response = await this.deactivateApiKeyRaw({ network: network, key: key }, initOverrides);
        return await response.value();
    }

    /**
     * Deletes api key
     */
    async deleteApiKeyRaw(requestParameters: DeleteApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['network'] == null) {
            throw new runtime.RequiredError(
                'network',
                'Required parameter "network" was null or undefined when calling deleteApiKey().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling deleteApiKey().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/keys/{network}/{key}`.replace(`{${"network"}}`, encodeURIComponent(String(requestParameters['network']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes api key
     */
    async deleteApiKey(network: BlockchainNetworkTypeAPIModel, key: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteApiKeyRaw({ network: network, key: key }, initOverrides);
    }

    /**
     * Returns api keys for the company
     */
    async getAllApiKeysRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyApiKeysSliceAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/keys`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyApiKeysSliceAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns api keys for the company
     */
    async getAllApiKeys(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyApiKeysSliceAPIModel> {
        const response = await this.getAllApiKeysRaw(initOverrides);
        return await response.value();
    }

}
