/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  CollectableEntityProcessTransactionAPIModel,
  NewRechargeAddressAPIModel,
  PublicRechargeAddressAPIModel,
  RechargeAddressAPIModel,
  RechargeAddressFilterAPIModel,
  SliceRechargeAddressPushAddressSortByAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    CollectableEntityProcessTransactionAPIModelFromJSON,
    CollectableEntityProcessTransactionAPIModelToJSON,
    NewRechargeAddressAPIModelFromJSON,
    NewRechargeAddressAPIModelToJSON,
    PublicRechargeAddressAPIModelFromJSON,
    PublicRechargeAddressAPIModelToJSON,
    RechargeAddressAPIModelFromJSON,
    RechargeAddressAPIModelToJSON,
    RechargeAddressFilterAPIModelFromJSON,
    RechargeAddressFilterAPIModelToJSON,
    SliceRechargeAddressPushAddressSortByAPIModelFromJSON,
    SliceRechargeAddressPushAddressSortByAPIModelToJSON,
} from '../models/index';

export interface CreateRechargeAddressRequest {
    newRechargeAddressAPIModel: NewRechargeAddressAPIModel;
}

export interface GetRechargeAddressRequest {
    id: string;
}

export interface GetRechargeAddressDeployTransactionRequest {
    id: string;
}

export interface GetRechargeAddressPublicRequest {
    id: string;
}

export interface SearchRechargeAddressesRequest {
    rechargeAddressFilterAPIModel: RechargeAddressFilterAPIModel;
    withTotal?: boolean;
}

/**
 * RechargeApi - interface
 * 
 * @export
 * @interface RechargeApiInterface
 */
export interface RechargeApiInterface {
    /**
     * Creates new recharge address for customer
     * @param {NewRechargeAddressAPIModel} newRechargeAddressAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RechargeApiInterface
     */
    createRechargeAddressRaw(requestParameters: CreateRechargeAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RechargeAddressAPIModel>>;

    /**
     * Creates new recharge address for customer
     */
    createRechargeAddress(newRechargeAddressAPIModel: NewRechargeAddressAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RechargeAddressAPIModel>;

    /**
     * Returns recharge address details by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RechargeApiInterface
     */
    getRechargeAddressRaw(requestParameters: GetRechargeAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RechargeAddressAPIModel>>;

    /**
     * Returns recharge address details by id
     */
    getRechargeAddress(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RechargeAddressAPIModel>;

    /**
     * Returns recharge address deploy transaction
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RechargeApiInterface
     */
    getRechargeAddressDeployTransactionRaw(requestParameters: GetRechargeAddressDeployTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectableEntityProcessTransactionAPIModel>>;

    /**
     * Returns recharge address deploy transaction
     */
    getRechargeAddressDeployTransaction(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectableEntityProcessTransactionAPIModel>;

    /**
     * Returns recharge address public details by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RechargeApiInterface
     */
    getRechargeAddressPublicRaw(requestParameters: GetRechargeAddressPublicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicRechargeAddressAPIModel>>;

    /**
     * Returns recharge address public details by id
     */
    getRechargeAddressPublic(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicRechargeAddressAPIModel>;

    /**
     * Returns recharge addresses by filter
     * @param {RechargeAddressFilterAPIModel} rechargeAddressFilterAPIModel 
     * @param {boolean} [withTotal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RechargeApiInterface
     */
    searchRechargeAddressesRaw(requestParameters: SearchRechargeAddressesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceRechargeAddressPushAddressSortByAPIModel>>;

    /**
     * Returns recharge addresses by filter
     */
    searchRechargeAddresses(rechargeAddressFilterAPIModel: RechargeAddressFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceRechargeAddressPushAddressSortByAPIModel>;

}

/**
 * 
 */
export class RechargeApi extends runtime.BaseAPI implements RechargeApiInterface {

    /**
     * Creates new recharge address for customer
     */
    async createRechargeAddressRaw(requestParameters: CreateRechargeAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RechargeAddressAPIModel>> {
        if (requestParameters['newRechargeAddressAPIModel'] == null) {
            throw new runtime.RequiredError(
                'newRechargeAddressAPIModel',
                'Required parameter "newRechargeAddressAPIModel" was null or undefined when calling createRechargeAddress().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/recharges`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewRechargeAddressAPIModelToJSON(requestParameters['newRechargeAddressAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RechargeAddressAPIModelFromJSON(jsonValue));
    }

    /**
     * Creates new recharge address for customer
     */
    async createRechargeAddress(newRechargeAddressAPIModel: NewRechargeAddressAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RechargeAddressAPIModel> {
        const response = await this.createRechargeAddressRaw({ newRechargeAddressAPIModel: newRechargeAddressAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Returns recharge address details by id
     */
    async getRechargeAddressRaw(requestParameters: GetRechargeAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RechargeAddressAPIModel>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getRechargeAddress().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/recharges/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RechargeAddressAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns recharge address details by id
     */
    async getRechargeAddress(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RechargeAddressAPIModel> {
        const response = await this.getRechargeAddressRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Returns recharge address deploy transaction
     */
    async getRechargeAddressDeployTransactionRaw(requestParameters: GetRechargeAddressDeployTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectableEntityProcessTransactionAPIModel>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getRechargeAddressDeployTransaction().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/recharges/{id}/tx`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectableEntityProcessTransactionAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns recharge address deploy transaction
     */
    async getRechargeAddressDeployTransaction(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectableEntityProcessTransactionAPIModel> {
        const response = await this.getRechargeAddressDeployTransactionRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Returns recharge address public details by id
     */
    async getRechargeAddressPublicRaw(requestParameters: GetRechargeAddressPublicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicRechargeAddressAPIModel>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getRechargeAddressPublic().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/recharges/{id}/public`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicRechargeAddressAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns recharge address public details by id
     */
    async getRechargeAddressPublic(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicRechargeAddressAPIModel> {
        const response = await this.getRechargeAddressPublicRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Returns recharge addresses by filter
     */
    async searchRechargeAddressesRaw(requestParameters: SearchRechargeAddressesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceRechargeAddressPushAddressSortByAPIModel>> {
        if (requestParameters['rechargeAddressFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'rechargeAddressFilterAPIModel',
                'Required parameter "rechargeAddressFilterAPIModel" was null or undefined when calling searchRechargeAddresses().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['withTotal'] != null) {
            queryParameters['withTotal'] = requestParameters['withTotal'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/recharges/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RechargeAddressFilterAPIModelToJSON(requestParameters['rechargeAddressFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceRechargeAddressPushAddressSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns recharge addresses by filter
     */
    async searchRechargeAddresses(rechargeAddressFilterAPIModel: RechargeAddressFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceRechargeAddressPushAddressSortByAPIModel> {
        const response = await this.searchRechargeAddressesRaw({ rechargeAddressFilterAPIModel: rechargeAddressFilterAPIModel, withTotal: withTotal }, initOverrides);
        return await response.value();
    }

}
