import { useCallback, useMemo } from 'react';

import { useBlockchainSystemInfo } from '@/features/dictionary/blockchain/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { combine, mapStoredState } from '@/infrastructure/model';
import { isSameAddress } from '@/infrastructure/utils/web3/address';

import useMerchantWallets from '../useMerchantWallets';

const useActiveMerchantWallet = (bt: BlockchainTypeAPIModel | undefined) => {
  const walletsState = useMerchantWallets();
  const blockchainState = useBlockchainSystemInfo(bt);
  const bcState = useMemo(
    () =>
      mapStoredState(blockchainState.data, (state) =>
        state.apiType === BlockchainAPITypeAPIModel.WEB3 || state.apiType === BlockchainAPITypeAPIModel.Tron
          ? state
          : null,
      ),
    [blockchainState.data],
  );

  const loading = walletsState.loading || blockchainState.loading;
  const forceRefresh = useCallback(
    () =>
      Promise.all([
        walletsState.forceRefresh(),
        ...(!blockchainState.data.data ? [blockchainState.forceRefresh()] : []),
      ]),
    [blockchainState, walletsState],
  );
  const data = useMemo(
    () =>
      combine(walletsState.data, bcState, (wallets, blockchain) =>
        wallets
          .filter((wallet) => wallet.blockchain === bt)
          .find(({ factoryAddress }) => isSameAddress(blockchain?.merchantWalletFactoryAddress, factoryAddress)),
      ),
    [bcState, bt, walletsState.data],
  );
  return useMemo(() => ({ loading, forceRefresh, data }), [data, forceRefresh, loading]);
};

export default useActiveMerchantWallet;
