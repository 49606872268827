/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { MerchantWalletTransferPredicatesAPIModel } from './MerchantWalletTransferPredicatesAPIModel';
import {
    MerchantWalletTransferPredicatesAPIModelFromJSON,
    MerchantWalletTransferPredicatesAPIModelFromJSONTyped,
    MerchantWalletTransferPredicatesAPIModelToJSON,
} from './MerchantWalletTransferPredicatesAPIModel';
import type { PageMerchantWalletTransferSortByAPIModel } from './PageMerchantWalletTransferSortByAPIModel';
import {
    PageMerchantWalletTransferSortByAPIModelFromJSON,
    PageMerchantWalletTransferSortByAPIModelFromJSONTyped,
    PageMerchantWalletTransferSortByAPIModelToJSON,
} from './PageMerchantWalletTransferSortByAPIModel';

/**
 * 
 * @export
 * @interface MerchantWalletTransferFilterAPIModel
 */
export interface MerchantWalletTransferFilterAPIModel {
    /**
     * 
     * @type {MerchantWalletTransferPredicatesAPIModel}
     * @memberof MerchantWalletTransferFilterAPIModel
     */
    predicates: MerchantWalletTransferPredicatesAPIModel;
    /**
     * 
     * @type {PageMerchantWalletTransferSortByAPIModel}
     * @memberof MerchantWalletTransferFilterAPIModel
     */
    page: PageMerchantWalletTransferSortByAPIModel;
}

/**
 * Check if a given object implements the MerchantWalletTransferFilterAPIModel interface.
 */
export function instanceOfMerchantWalletTransferFilterAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    if (!('page' in value)) return false;
    return true;
}

export function MerchantWalletTransferFilterAPIModelFromJSON(json: any): MerchantWalletTransferFilterAPIModel {
    return MerchantWalletTransferFilterAPIModelFromJSONTyped(json, false);
}

export function MerchantWalletTransferFilterAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantWalletTransferFilterAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': MerchantWalletTransferPredicatesAPIModelFromJSON(json['predicates']),
        'page': PageMerchantWalletTransferSortByAPIModelFromJSON(json['page']),
    };
}

export function MerchantWalletTransferFilterAPIModelToJSON(value?: MerchantWalletTransferFilterAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': MerchantWalletTransferPredicatesAPIModelToJSON(value['predicates']),
        'page': PageMerchantWalletTransferSortByAPIModelToJSON(value['page']),
    };
}

