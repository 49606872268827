import React, { useMemo } from 'react';

import type { CollectScheduleViewProps } from '@/features/collectable/components';
import { CollectScheduleView } from '@/features/collectable/components';

import { withRechargeScheduleDataLoading } from './hocs';

import type { CollectableScheduleCardProps } from './types';

const CollectableScheduleCard = ({ Extra, withTasksLinks, ...props }: CollectableScheduleCardProps) => {
  const Component = useMemo(
    () =>
      withRechargeScheduleDataLoading<
        Omit<CollectScheduleViewProps, 'schedule'> & { data: NonNullable<CollectScheduleViewProps['schedule']> }
      >(({ data, ...props }) => <CollectScheduleView {...props} schedule={data} />, Extra, withTasksLinks),
    [Extra, withTasksLinks],
  );
  return <Component {...props} />;
};

const CollectableScheduleCardMemo = React.memo(CollectableScheduleCard);

export default CollectableScheduleCardMemo;
