import { createReducer } from '@reduxjs/toolkit';

import listenerMiddleware from '@/app/listenerMiddleware';
import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { createNotification } from '@/features/global/actions';
import { makeSelectGeneratingReports, makeSelectMultipleReport } from '@/features/reports/selectors';
import { extractReportId } from '@/features/reports/utils';
import { ReportStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureReports } from '@/generated/i18n/i18n';
import { defaultPage } from '@/infrastructure/model/api';
import { createNormalizedListReducers } from '@/infrastructure/model/list/reducers';
import { storedDirtyListDataTyped } from '@/infrastructure/model/list/utils';
import { onlyUnique } from '@/infrastructure/utils/functions';
import { notEmpty } from '@/infrastructure/utils/ts';
import { reportViewLink } from '@/pages/reports/routes';

import {
  fetchReportsByIds,
  markMultipleReportDirty,
  markReportDirty,
  markReportListDirty,
  storeMultipleReport,
  storeRemoveReport,
  storeReport,
  storeReportListData,
  storeReportListParameters,
} from './actions';
import { defaultReportSortBy } from './types';

import type { ReportsState } from './types';
import type { Draft } from 'immer';

const initialState: ReportsState = {
  entities: {},
  list: {
    data: storedDirtyListDataTyped(),
    page: defaultPage,
    filter: {},
    sortBy: defaultReportSortBy,
    columnState: {},
  },
};

const {
  markReportListDirtyReducer,
  storeReportListDataReducer,
  storeReportListParametersReducer,
  markReportDirtyReducer,
  markMultipleReportDirtyReducer,
  storeReportReducer,
  storeRemoveReportReducer,
  storeMultipleReportReducer,
} = createNormalizedListReducers(
  'Report' as const,
  (state: Draft<ReportsState>) => state.list,
  (state, list) => ({ ...state, list }),
  initialState.list,
  (state) => state.entities,
  (state, entities) => ({ ...state, entities }),
  extractReportId,
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markReportListDirty, markReportListDirtyReducer)
    .addCase(storeReportListData, storeReportListDataReducer)
    .addCase(storeReportListParameters, storeReportListParametersReducer)
    .addCase(markReportDirty, markReportDirtyReducer)
    .addCase(markMultipleReportDirty, markMultipleReportDirtyReducer)
    .addCase(storeReport, storeReportReducer)
    .addCase(storeRemoveReport, storeRemoveReportReducer)
    .addCase(storeMultipleReport, storeMultipleReportReducer)

    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address ? initialState : state,
    );

  listenerMiddleware.startListening({
    actionCreator: notifyAuthTokenUpdated,
    effect: async ({ payload: { current } }, listenerApi) => {
      if (!current) {
        return;
      }
      listenerApi.unsubscribe();
      for (;;) {
        const wereGenerated = makeSelectGeneratingReports()(listenerApi.getState());

        await listenerApi.delay(5_000);
        if (!makeSelectAuthToken()(listenerApi.getState()).data) {
          return;
        }
        const areGenerated = makeSelectGeneratingReports()(listenerApi.getState());
        if (areGenerated.length) {
          await listenerApi.dispatch(fetchReportsByIds({ ids: areGenerated.map(extractReportId), force: true }));
        }
        if (areGenerated.length || wereGenerated.length) {
          const updated = makeSelectMultipleReport(
            [...wereGenerated, ...areGenerated].map(extractReportId).filter(onlyUnique),
          )(listenerApi.getState());
          const succeeded = updated
            .filter(({ data }) => data.data?.status === ReportStatusAPIModel.Success)
            .map(({ data }) => data.data)
            .filter(notEmpty);
          const failed = updated
            .filter(({ data }) => data.data?.status === ReportStatusAPIModel.Error)
            .map(({ data }) => data.data)
            .filter(notEmpty);

          succeeded.forEach((report) =>
            listenerApi.dispatch(
              createNotification({
                severity: 'success',
                messageI18n: I18nFeatureReports.COMPONENTS_RESULT_NOTIFICATION_SUCCESS_MESSAGE,
                messageI18nValues: { ln: { type: 'link', to: reportViewLink(report.id) } },
              }),
            ),
          );

          failed.forEach((report) =>
            listenerApi.dispatch(
              createNotification({
                severity: 'error',
                messageI18n: I18nFeatureReports.COMPONENTS_RESULT_NOTIFICATION_ERROR_MESSAGE,
                messageI18nValues: { ln: { type: 'link', to: reportViewLink(report.id) } },
              }),
            ),
          );
        }
      }
    },
  });
});

export default reducer;
