/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  MerchantReportAPIModel,
  SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModel,
  WithdrawalReconciliationAPIModel,
  WithdrawalReconciliationFilterAPIModel,
  WithdrawalReconciliationPredicatesAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    MerchantReportAPIModelFromJSON,
    MerchantReportAPIModelToJSON,
    SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModelFromJSON,
    SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModelToJSON,
    WithdrawalReconciliationAPIModelFromJSON,
    WithdrawalReconciliationAPIModelToJSON,
    WithdrawalReconciliationFilterAPIModelFromJSON,
    WithdrawalReconciliationFilterAPIModelToJSON,
    WithdrawalReconciliationPredicatesAPIModelFromJSON,
    WithdrawalReconciliationPredicatesAPIModelToJSON,
} from '../models/index';

export interface ExportReconciliationTransactionsRequest {
    withdrawalReconciliationPredicatesAPIModel: WithdrawalReconciliationPredicatesAPIModel;
}

export interface ExportReconciliationsRequest {
    withdrawalReconciliationPredicatesAPIModel: WithdrawalReconciliationPredicatesAPIModel;
}

export interface GetWithdrawalReconciliationRequest {
    withdrawalId: string;
}

export interface GetWithdrawalReconciliationsRequest {
    withdrawalReconciliationFilterAPIModel: WithdrawalReconciliationFilterAPIModel;
    withTotal?: boolean;
}

export interface RecalculateWithdrawalReconciliationRequest {
    withdrawalId: string;
}

/**
 * WithdrawalReconciliationApi - interface
 * 
 * @export
 * @interface WithdrawalReconciliationApiInterface
 */
export interface WithdrawalReconciliationApiInterface {
    /**
     * Exports reconciliation transactions by predicates
     * @param {WithdrawalReconciliationPredicatesAPIModel} withdrawalReconciliationPredicatesAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawalReconciliationApiInterface
     */
    exportReconciliationTransactionsRaw(requestParameters: ExportReconciliationTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>>;

    /**
     * Exports reconciliation transactions by predicates
     */
    exportReconciliationTransactions(withdrawalReconciliationPredicatesAPIModel: WithdrawalReconciliationPredicatesAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel>;

    /**
     * Exports reconciliations by predicates
     * @param {WithdrawalReconciliationPredicatesAPIModel} withdrawalReconciliationPredicatesAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawalReconciliationApiInterface
     */
    exportReconciliationsRaw(requestParameters: ExportReconciliationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>>;

    /**
     * Exports reconciliations by predicates
     */
    exportReconciliations(withdrawalReconciliationPredicatesAPIModel: WithdrawalReconciliationPredicatesAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel>;

    /**
     * Returns withdrawal reconciliation by id
     * @param {string} withdrawalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawalReconciliationApiInterface
     */
    getWithdrawalReconciliationRaw(requestParameters: GetWithdrawalReconciliationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalReconciliationAPIModel>>;

    /**
     * Returns withdrawal reconciliation by id
     */
    getWithdrawalReconciliation(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalReconciliationAPIModel>;

    /**
     * Returns withdrawal reconciliations according to filter
     * @param {WithdrawalReconciliationFilterAPIModel} withdrawalReconciliationFilterAPIModel 
     * @param {boolean} [withTotal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawalReconciliationApiInterface
     */
    getWithdrawalReconciliationsRaw(requestParameters: GetWithdrawalReconciliationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModel>>;

    /**
     * Returns withdrawal reconciliations according to filter
     */
    getWithdrawalReconciliations(withdrawalReconciliationFilterAPIModel: WithdrawalReconciliationFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModel>;

    /**
     * Updates the recalculation time to now for failed or active withdrawal reconciliation
     * @param {string} withdrawalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawalReconciliationApiInterface
     */
    recalculateWithdrawalReconciliationRaw(requestParameters: RecalculateWithdrawalReconciliationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalReconciliationAPIModel>>;

    /**
     * Updates the recalculation time to now for failed or active withdrawal reconciliation
     */
    recalculateWithdrawalReconciliation(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalReconciliationAPIModel>;

}

/**
 * 
 */
export class WithdrawalReconciliationApi extends runtime.BaseAPI implements WithdrawalReconciliationApiInterface {

    /**
     * Exports reconciliation transactions by predicates
     */
    async exportReconciliationTransactionsRaw(requestParameters: ExportReconciliationTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>> {
        if (requestParameters['withdrawalReconciliationPredicatesAPIModel'] == null) {
            throw new runtime.RequiredError(
                'withdrawalReconciliationPredicatesAPIModel',
                'Required parameter "withdrawalReconciliationPredicatesAPIModel" was null or undefined when calling exportReconciliationTransactions().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawal-reconciliations/export/reconciliation-transactions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WithdrawalReconciliationPredicatesAPIModelToJSON(requestParameters['withdrawalReconciliationPredicatesAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantReportAPIModelFromJSON(jsonValue));
    }

    /**
     * Exports reconciliation transactions by predicates
     */
    async exportReconciliationTransactions(withdrawalReconciliationPredicatesAPIModel: WithdrawalReconciliationPredicatesAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel> {
        const response = await this.exportReconciliationTransactionsRaw({ withdrawalReconciliationPredicatesAPIModel: withdrawalReconciliationPredicatesAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Exports reconciliations by predicates
     */
    async exportReconciliationsRaw(requestParameters: ExportReconciliationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>> {
        if (requestParameters['withdrawalReconciliationPredicatesAPIModel'] == null) {
            throw new runtime.RequiredError(
                'withdrawalReconciliationPredicatesAPIModel',
                'Required parameter "withdrawalReconciliationPredicatesAPIModel" was null or undefined when calling exportReconciliations().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawal-reconciliations/export/reconciliations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WithdrawalReconciliationPredicatesAPIModelToJSON(requestParameters['withdrawalReconciliationPredicatesAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantReportAPIModelFromJSON(jsonValue));
    }

    /**
     * Exports reconciliations by predicates
     */
    async exportReconciliations(withdrawalReconciliationPredicatesAPIModel: WithdrawalReconciliationPredicatesAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel> {
        const response = await this.exportReconciliationsRaw({ withdrawalReconciliationPredicatesAPIModel: withdrawalReconciliationPredicatesAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Returns withdrawal reconciliation by id
     */
    async getWithdrawalReconciliationRaw(requestParameters: GetWithdrawalReconciliationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalReconciliationAPIModel>> {
        if (requestParameters['withdrawalId'] == null) {
            throw new runtime.RequiredError(
                'withdrawalId',
                'Required parameter "withdrawalId" was null or undefined when calling getWithdrawalReconciliation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawal-reconciliations/{withdrawalId}`.replace(`{${"withdrawalId"}}`, encodeURIComponent(String(requestParameters['withdrawalId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawalReconciliationAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns withdrawal reconciliation by id
     */
    async getWithdrawalReconciliation(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalReconciliationAPIModel> {
        const response = await this.getWithdrawalReconciliationRaw({ withdrawalId: withdrawalId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns withdrawal reconciliations according to filter
     */
    async getWithdrawalReconciliationsRaw(requestParameters: GetWithdrawalReconciliationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModel>> {
        if (requestParameters['withdrawalReconciliationFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'withdrawalReconciliationFilterAPIModel',
                'Required parameter "withdrawalReconciliationFilterAPIModel" was null or undefined when calling getWithdrawalReconciliations().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['withTotal'] != null) {
            queryParameters['withTotal'] = requestParameters['withTotal'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawal-reconciliations/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WithdrawalReconciliationFilterAPIModelToJSON(requestParameters['withdrawalReconciliationFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns withdrawal reconciliations according to filter
     */
    async getWithdrawalReconciliations(withdrawalReconciliationFilterAPIModel: WithdrawalReconciliationFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModel> {
        const response = await this.getWithdrawalReconciliationsRaw({ withdrawalReconciliationFilterAPIModel: withdrawalReconciliationFilterAPIModel, withTotal: withTotal }, initOverrides);
        return await response.value();
    }

    /**
     * Updates the recalculation time to now for failed or active withdrawal reconciliation
     */
    async recalculateWithdrawalReconciliationRaw(requestParameters: RecalculateWithdrawalReconciliationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalReconciliationAPIModel>> {
        if (requestParameters['withdrawalId'] == null) {
            throw new runtime.RequiredError(
                'withdrawalId',
                'Required parameter "withdrawalId" was null or undefined when calling recalculateWithdrawalReconciliation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawal-reconciliations/{withdrawalId}/calculate`.replace(`{${"withdrawalId"}}`, encodeURIComponent(String(requestParameters['withdrawalId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawalReconciliationAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates the recalculation time to now for failed or active withdrawal reconciliation
     */
    async recalculateWithdrawalReconciliation(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalReconciliationAPIModel> {
        const response = await this.recalculateWithdrawalReconciliationRaw({ withdrawalId: withdrawalId }, initOverrides);
        return await response.value();
    }

}
