import { PageContainer } from '@/components';
import { KYBRequiredBanner } from '@/features/company/components';
import { RechargesDocumentationBanner, RechargeTransactionFilterForm } from '@/features/recharges/components';
import { CollectableScheduleCard } from '@/pages/collectable/components';
import RechargeTransactionViewLink from '@/pages/recharges/transaction-view/components/RechargeTransactionLink';

import { RechargeTransactionsBreadcrumb, RechargeTransactionsCard } from './components';

import type React from 'react';

const dataTest: string | undefined = 'transactions';

const RechargeTransactionsPage: React.FC = () => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={<RechargeTransactionsBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <KYBRequiredBanner data-test="kyb-banner" PageProps={{ pageSize: 'big' }} />
    <RechargesDocumentationBanner data-test={dataTest && `${dataTest}-help`} pageSize="big" />
    <CollectableScheduleCard data-test={dataTest && `${dataTest}-schedule`} withTasksLinks />
    <RechargeTransactionsCard
      data-test={dataTest}
      RechargeTransactionLink={RechargeTransactionViewLink}
      FilterForm={RechargeTransactionFilterForm}
    />
  </PageContainer>
);

export default RechargeTransactionsPage;
