/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum MerchantWalletTransferKindAPIModel {
    PaymentTransaction = 'PaymentTransaction',
    SubscriptionCharge = 'SubscriptionCharge',
    RechargeTransaction = 'RechargeTransaction',
    DonationTransaction = 'DonationTransaction',
    Unknown = 'Unknown',
    Withdrawal = 'Withdrawal',
    PushCollect = 'PushCollect',
    Payout = 'Payout',
    PartnerFee = 'PartnerFee',
    ProcessingFee = 'ProcessingFee'
}


export function MerchantWalletTransferKindAPIModelFromJSON(json: any): MerchantWalletTransferKindAPIModel {
    return MerchantWalletTransferKindAPIModelFromJSONTyped(json, false);
}

export function MerchantWalletTransferKindAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantWalletTransferKindAPIModel {
    return json as MerchantWalletTransferKindAPIModel;
}

export function MerchantWalletTransferKindAPIModelToJSON(value?: MerchantWalletTransferKindAPIModel | null): any {
    return value as any;
}

