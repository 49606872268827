import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import { withAPICall } from '@/infrastructure/model/api';
import { createLoadingDataActions } from '@/infrastructure/model/common/actions';

import { queryBalances, queryPaymentsSummaryStats, querySubscriptionSummaryStats } from './api';
import { makeSelectBalances, makeSelectPaymentsSummaryStats, makeSelectSubscriptionsSummaryStats } from './selectors';
import { NAMESPACE, type AssetBalances, type SubscriptionSummaryStats, type PaymentSummaryStats } from './types';

export const { storeBalances, markBalancesDirty } = createLoadingDataActions<AssetBalances, 'Balances'>(
  NAMESPACE,
  'Balances',
);

const balancesFetchLimit = pLimit(1);
export const fetchBalances = createAppAsyncThunk(
  `${NAMESPACE}/fetchBalances`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    balancesFetchLimit(async () => {
      const saved = makeSelectBalances()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const network = makeSelectSelectedNetwork()(getState());

      const data = await withAPICall(queryBalances, 'Unable to fetch balances')(network, { signal });
      dispatch(storeBalances(data));

      return makeSelectBalances()(getState());
    }),
);

export const { storeSubscriptionsSummaryStats, markSubscriptionsSummaryStatsDirty } = createLoadingDataActions<
  SubscriptionSummaryStats,
  'SubscriptionsSummaryStats'
>(NAMESPACE, 'SubscriptionsSummaryStats');

const subscriptionsFetchLimit = pLimit(1);
export const fetchSubscriptionsSummaryStats = createAppAsyncThunk(
  `${NAMESPACE}/fetchSubscriptionsSummaryStats`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    subscriptionsFetchLimit(async () => {
      const saved = makeSelectSubscriptionsSummaryStats()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const network = makeSelectSelectedNetwork()(getState());

      const data = await withAPICall(querySubscriptionSummaryStats, 'Unable to fetch subscriptions summary')(network, {
        signal,
      });
      dispatch(storeSubscriptionsSummaryStats(data));

      return makeSelectSubscriptionsSummaryStats()(getState());
    }),
);

export const { storePaymentsSummaryStats, markPaymentsSummaryStatsDirty } = createLoadingDataActions<
  PaymentSummaryStats,
  'PaymentsSummaryStats'
>(NAMESPACE, 'PaymentsSummaryStats');

const paymentsFetchLimit = pLimit(1);
export const fetchPaymentsSummaryStats = createAppAsyncThunk(
  `${NAMESPACE}/fetchPaymentsSummaryStats`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    paymentsFetchLimit(async () => {
      const saved = makeSelectPaymentsSummaryStats()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const network = makeSelectSelectedNetwork()(getState());

      const data = await withAPICall(queryPaymentsSummaryStats, 'Unable to fetch payments summary')(network, {
        signal,
      });
      dispatch(storePaymentsSummaryStats(data));

      return makeSelectPaymentsSummaryStats()(getState());
    }),
);
