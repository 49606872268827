import { CloudDownloadOutlined } from '@ant-design/icons';
import React, { useCallback, useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { FormattedMessage, FormattedMessageWithMarkup, Operation } from '@/components';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { withCompanyPermissionsGuard } from '@/features/company/hocs';
import { AssetLabel } from '@/features/dictionary/blockchain/components';
import { useListAssets } from '@/features/dictionary/blockchain/hooks';
import {
  OperationDeployMerchantWallet,
  OperationRefreshMerchantWalletDeployment,
} from '@/features/merchant-wallets/components';
import { useSettlementAssetActions } from '@/features/settlements/hooks';
import type { SettlementIntent } from '@/features/settlements/types';
import OperationRefreshBalances from '@/features/statistics/components/OperationRefreshBalances';
import { I18nFeatureMerchantWallets, I18nFeatureSettlements } from '@/generated/i18n/i18n';
import { useDefaultNotification, useNotification, useStateMountSafe } from '@/hooks';
import { withSuppressError } from '@/infrastructure/utils/functions';
import SettlementIntentLink from '@/pages/settlements/intent-view/components/SettlementIntentLink';

import SettlementDocumentationLink from '../SettlementDocumentationLink';

import type { OperationSettleNowProps } from './types';

const selectAuthToken = makeSelectAuthToken();

const OperationSettleNow: React.FC<OperationSettleNowProps> = ({ 'data-test': dataTest, mode = 'inline', assetId }) => {
  const login = useAppSelector(selectAuthToken);
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();
  const { settleNow } = useSettlementAssetActions(assetId);
  const [tooltipVisible, setTooltipVisible] = useStateMountSafe<boolean | undefined>(undefined);
  const updateTooltipVisibility = useCallback(
    (dialogVisible: boolean) => setTooltipVisible(dialogVisible ? false : undefined),
    [setTooltipVisible],
  );

  const {
    data: { data: assets },
  } = useListAssets();
  const asset = useMemo(() => (assets ?? []).find(({ code }) => assetId === code), [assetId, assets]);
  const bt = asset?.blockchain;
  const disabledMessage = useMemo(() => {
    if (settleNow.unavailabilityReason === 'empty-balance') {
      return (
        <FormattedMessage
          id={I18nFeatureSettlements.COMPONENTS_OPERATION_SETTLE_NOW_DISABLED_EMPTY_BALANCE}
          values={{
            ln: (text: React.ReactNode) => (
              <OperationRefreshBalances
                data-test={dataTest && `${dataTest}-disable-opRefresh`}
                mode="link"
                title={text}
              />
            ),
          }}
        />
      );
    }
    if (settleNow.unavailabilityReason === 'no-data') {
      return <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_SETTLE_NOW_DISABLED_LOADING} />;
    }
    if (settleNow.unavailabilityReason === 'in-progress') {
      return <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_SETTLE_NOW_DISABLED_IN_PROGRESS} />;
    }
    if (settleNow.unavailabilityReason === 'no-wallet' && bt) {
      return (
        <FormattedMessageWithMarkup
          id={I18nFeatureMerchantWallets.COMPONENTS_STATUS_BANNER_DESCRIPTION}
          values={{
            bt,
            deploy: (text: React.ReactNode) => (
              <OperationDeployMerchantWallet
                data-test={dataTest && `${dataTest}-opDeploy`}
                bt={bt}
                mode="link"
                title={text}
                onVisibleChange={updateTooltipVisibility}
              />
            ),
            check: (text: React.ReactNode) => (
              <OperationRefreshMerchantWalletDeployment
                data-test={dataTest && `${dataTest}-opCheck`}
                bt={bt}
                mode="link"
                title={text}
              />
            ),
          }}
        />
      );
    }

    return undefined;
  }, [settleNow.unavailabilityReason, bt, dataTest, updateTooltipVisibility]);

  const tooltip = useMemo(
    () => (
      <FormattedMessage
        id={I18nFeatureSettlements.COMPONENTS_OPERATION_SETTLE_NOW_TOOLTIP}
        values={{
          asset: <AssetLabel data-test={dataTest && `${dataTest}-tooltip-asset`} value={assetId} mode="short" />,
          address: login.data?.info.address,
          help: (text: React.ReactNode) => (
            <SettlementDocumentationLink data-test={dataTest && `${dataTest}-docs`} mode="text" title={text} />
          ),
        }}
      />
    ),
    [assetId, dataTest, login.data?.info.address],
  );

  const doWithdraw = useMemo(
    () =>
      withSuppressError(
        withSuccess(withDefaultError(settleNow.act), (intent: SettlementIntent) => ({
          message: <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_SETTLE_NOW_SUCCESS_MESSAGE} />,
          description: (
            <FormattedMessage
              id={I18nFeatureSettlements.COMPONENTS_OPERATION_SETTLE_NOW_SUCCESS_DESCRIPTION}
              values={{
                ln: (text: React.ReactNode) => (
                  <SettlementIntentLink
                    value={intent.id}
                    data-test={dataTest && `${dataTest}-link`}
                    mode="text"
                    title={text}
                  />
                ),
              }}
            />
          ),
        })),
      ),
    [dataTest, withDefaultError, withSuccess, settleNow.act],
  );

  return (
    <Operation
      title={<FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_SETTLE_NOW_TITLE} />}
      tooltip={tooltip}
      tooltipVisible={tooltipVisible}
      disabled={!settleNow.available || !!disabledMessage}
      disabledMessage={disabledMessage}
      icon={<CloudDownloadOutlined />}
      onClick={doWithdraw}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationSettleNowMemo = React.memo(withCompanyPermissionsGuard('settleManagement')(OperationSettleNow));

export default OperationSettleNowMemo;
