import { Space } from 'antd';
import React, { useMemo } from 'react';

import type { LinkMode, OperationMode } from '@/components';
import { FormattedMessage, PageCard } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { PaymentsDocumentationLink, PaymentsTable } from '@/features/payments/components';
import { usePaymentListView } from '@/features/payments/hooks';
import { I18nPagePayments } from '@/generated/i18n/i18n';
import { useDimensions } from '@/hooks';
import PaymentAddLink from '@/pages/payments/payment-add/components/PaymentAddLink';

import type { PaymentsCardProps } from './types';
import type { FC } from 'react';

const PaymentsCard: FC<PaymentsCardProps> = (props) => {
  const parameters = usePaymentListView();
  const [ref, dimension] = useDimensions();
  const documentationLinkMode: LinkMode = useMemo(
    () => ((dimension?.width ?? 0) < 450 ? 'icon' : 'text_icon'),
    [dimension?.width],
  );
  const createMode: OperationMode = useMemo(
    () => ((dimension?.width ?? 0) < 360 ? 'inline' : 'button'),
    [dimension?.width],
  );

  const { 'data-test': dataTest } = props;
  return (
    <PageCard
      ref={ref}
      title={
        <Space>
          <FormattedMessage id={I18nPagePayments.PAYMENTS_COMPONENTS_PAYMENTS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-selectNetwork`} />
        </Space>
      }
      cardSize="big"
      extra={
        <Space>
          <PaymentsDocumentationLink
            data-test={dataTest && `${dataTest}-documentationLink`}
            mode={documentationLinkMode}
          />
          <PaymentAddLink data-test={dataTest && `${dataTest}-create`} mode={createMode} />
        </Space>
      }
    >
      <PaymentsTable {...parameters} {...props} />
    </PageCard>
  );
};

const PaymentsCardMemo = React.memo(PaymentsCard);

export default PaymentsCardMemo;
