import type { AppRootState } from '@/app/store';
import { merchantWalletIdToStoreKey } from '@/features/merchant-wallets/utils';
import { createLoadingDataSelectors } from '@/infrastructure/model/common/selectors';
import { createSingleSelectors } from '@/infrastructure/model/single/selectors';

import { NAMESPACE } from './types';

export const { makeSelectMerchantWalletDeployment } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].deployments,
  'MerchantWalletDeployment' as const,
  merchantWalletIdToStoreKey,
);

export const { makeSelectMerchantWalletOwnership } = createLoadingDataSelectors(
  (state: AppRootState) => state[NAMESPACE].ownership,
  'MerchantWalletOwnership' as const,
);

export const { makeSelectMerchantWallets } = createLoadingDataSelectors(
  (state: AppRootState) => state[NAMESPACE].wallets,
  'MerchantWallets' as const,
);
