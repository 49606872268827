import { Space, Spin } from 'antd';
import React from 'react';

import { FormattedMessage, FormattedMessageWithMarkup, PageCard } from '@/components';
import { OperationDeleteToS } from '@/features/branding/components';
import { useBrandingActiveProfile, useToS } from '@/features/branding/hooks';
import { withCompanyPermissionsGuard } from '@/features/company/hocs';
import { withFeatureToggleGuard } from '@/features/feature-toggle/hocs';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import ToSLink from '@/pages/settings/branding/tos/components/ToSLink';

import type { TermsOfServiceCardProps } from './types';

const TermsOfServiceCard: React.FC<TermsOfServiceCardProps> = ({ 'data-test': dataTest }) => {
  const tos = useToS();
  const profile = useBrandingActiveProfile();

  return (
    <PageCard
      data-test={dataTest}
      title={<FormattedMessage id={I18nPageSettings.BRANDING_SUMMARY_COMPONENTS_TOS_CARD_TITLE} />}
      cardSize="big"
      extra={
        <Space>
          <ToSLink data-test={dataTest && `${dataTest}-card-defineOp`} mode="button" />
          <OperationDeleteToS data-test={dataTest && `${dataTest}-card-deleteOp`} mode="inline" confirmation />
        </Space>
      }
    >
      <Spin spinning={tos.loading || profile.loading}>
        {!tos.data.data && (
          <FormattedMessageWithMarkup
            id={I18nPageSettings.BRANDING_SUMMARY_COMPONENTS_TOS_CARD_DESCRIPTION_EMPTY}
            values={{
              op: (text: React.ReactNode) => (
                <ToSLink data-test={dataTest && `${dataTest}-createOp`} mode="link" title={text} />
              ),
            }}
          />
        )}
        {!!tos.data.data && (
          <FormattedMessageWithMarkup
            id={I18nPageSettings.BRANDING_SUMMARY_COMPONENTS_TOS_CARD_DESCRIPTION_DEFINED}
            values={{
              op: (text: React.ReactNode) => (
                <ToSLink data-test={dataTest && `${dataTest}-createOp`} mode="link" title={text} />
              ),
            }}
          />
        )}
      </Spin>
    </PageCard>
  );
};

const TermsOfServiceCardHOCed = withCompanyPermissionsGuard('termsOfServiceSet')(
  withFeatureToggleGuard('enableBrandingTos')(TermsOfServiceCard),
);
const TermsOfServiceCardMemo = React.memo(TermsOfServiceCardHOCed) as typeof TermsOfServiceCardHOCed;

export default TermsOfServiceCardMemo;
