import { BankOutlined } from '@ant-design/icons';
import React, { useCallback, useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { FormattedMessage, FormattedMessageWithMarkup, Operation } from '@/components';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { useCollectAssetActions } from '@/features/collectable/hooks';
import { withCompanyPermissionsGuard } from '@/features/company/hocs';
import { AssetLabel } from '@/features/dictionary/blockchain/components';
import { useListAssets } from '@/features/dictionary/blockchain/hooks';
import {
  OperationDeployMerchantWallet,
  OperationRefreshMerchantWalletDeployment,
} from '@/features/merchant-wallets/components';
import OperationRefreshBalances from '@/features/statistics/components/OperationRefreshBalances';
import { I18nFeatureMerchantWallets, I18nFeatureCollectable } from '@/generated/i18n/i18n';
import { useDefaultNotification, useNotification, useStateMountSafe } from '@/hooks';
import { withSuppressError } from '@/infrastructure/utils/functions';

import type { OperationCollectNowProps } from './types';

const selectAuthToken = makeSelectAuthToken();

const OperationCollectNow: React.FC<OperationCollectNowProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  assetId,
}) => {
  const login = useAppSelector(selectAuthToken);
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();
  const { collectNow } = useCollectAssetActions(assetId);
  const [tooltipVisible, setTooltipVisible] = useStateMountSafe<boolean | undefined>(undefined);
  const updateTooltipVisibility = useCallback(
    (dialogVisible: boolean) => setTooltipVisible(dialogVisible ? false : undefined),
    [setTooltipVisible],
  );

  const {
    data: { data: assets },
  } = useListAssets();
  const asset = useMemo(() => (assets ?? []).find(({ code }) => assetId === code), [assetId, assets]);
  const bt = asset?.blockchain;
  const disabledMessage = useMemo(() => {
    if (collectNow.unavailabilityReason === 'empty-balance') {
      return (
        <FormattedMessage
          id={I18nFeatureCollectable.COMPONENTS_OPERATION_COLLECT_NOW_DISABLED_EMPTY_BALANCE}
          values={{
            ln: (text: React.ReactNode) => (
              <OperationRefreshBalances
                data-test={dataTest && `${dataTest}-disable-opRefresh`}
                mode="link"
                title={text}
              />
            ),
          }}
        />
      );
    }
    if (collectNow.unavailabilityReason === 'no-data') {
      return <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_COLLECT_NOW_DISABLED_LOADING} />;
    }
    if (collectNow.unavailabilityReason === 'no-wallet' && bt) {
      return (
        <FormattedMessageWithMarkup
          id={I18nFeatureMerchantWallets.COMPONENTS_STATUS_BANNER_DESCRIPTION}
          values={{
            bt,
            deploy: (text: React.ReactNode) => (
              <OperationDeployMerchantWallet
                data-test={dataTest && `${dataTest}-opDeploy`}
                bt={bt}
                mode="link"
                title={text}
                onVisibleChange={updateTooltipVisibility}
              />
            ),
            check: (text: React.ReactNode) => (
              <OperationRefreshMerchantWalletDeployment
                data-test={dataTest && `${dataTest}-opCheck`}
                bt={bt}
                mode="link"
                title={text}
              />
            ),
          }}
        />
      );
    }

    return undefined;
  }, [collectNow.unavailabilityReason, bt, dataTest, updateTooltipVisibility]);

  const tooltip = useMemo(
    () => (
      <FormattedMessage
        id={I18nFeatureCollectable.COMPONENTS_OPERATION_COLLECT_NOW_TOOLTIP}
        values={{
          asset: <AssetLabel data-test={dataTest && `${dataTest}-tooltip-asset`} value={assetId} mode="short" />,
          address: login.data?.info.address,
        }}
      />
    ),
    [assetId, dataTest, login.data?.info.address],
  );

  const doCollect = useMemo(
    () =>
      withSuppressError(
        withSuccess(withDefaultError(collectNow.act), () => ({
          message: <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_COLLECT_NOW_SUCCESS_MESSAGE} />,
        })),
      ),
    [withDefaultError, withSuccess, collectNow.act],
  );

  return (
    <Operation
      title={<FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_COLLECT_NOW_TITLE} />}
      tooltip={tooltip}
      tooltipVisible={tooltipVisible}
      disabled={!collectNow.available || !!disabledMessage}
      disabledMessage={disabledMessage}
      icon={<BankOutlined />}
      onClick={doCollect}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationCollectNowMemo = React.memo(withCompanyPermissionsGuard('collectManagement')(OperationCollectNow));

export default OperationCollectNowMemo;
