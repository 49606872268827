import { Typography } from 'antd';

import { Link as ExternalLink } from '@/components';

import type { TermsOfServiceTextProps } from '../types';
import type React from 'react';

const { Link, Paragraph, Title } = Typography;

const EnUS: React.FC<TermsOfServiceTextProps> = ({ 'data-test': dataTest, lastElementRef }) => (
  <div data-test={dataTest} style={{ lineHeight: '2em', paddingRight: '1em' }}>
    <Paragraph>
      <b>DISCLAIMER</b>: Smarty Pay provides a cryptocurrency transaction processing technology. Smarty Pay does not
      recommend, endorse or promote any investment activities regarding cryptocurrencies. Smarty Pay is not responsible
      for any financial losses caused by cryptocurrency rate fluctuations. Smarty Pay does not guarantee any financial
      benefits from the use of cryptocurrencies as a means of payment.
    </Paragraph>
    <Paragraph>
      Smarty Pay (“Smarty Pay” or “we” or “us” or “our”) is a service that simplifies the acceptance of cryptocurrencies
      as payment for goods and services. Smarty Pay has developed these Terms of Service (these “<b>Terms</b>”) to
      describe the terms that govern your use of Smarty Pay’s service, Smarty Pay developer documents and our website
      located at <ExternalLink to="https://smartypay.io" external title="smartypay.io" /> (the “<b>Site</b>”)
      (collectively the “<b>Services</b>”).
    </Paragraph>
    <Title level={4}>Changes to Terms or Services</Title>
    <Paragraph>
      We may modify the Terms at any time, at our sole discretion. If we do so, we’ll let you know by posting the
      modified Terms on the Site and by providing you a notice in a manner we deem reasonable including both
      notifications within the product’s dashboard and other communications. It’s important that you review the Terms
      whenever we modify them because if you continue to use the Services after we have modified the Terms, you are
      indicating to us that you agree to be bound by the modified Terms. If you do not agree to be bound by the modified
      Terms, you must stop using the Services. We may change or discontinue all or any part of the Services, at any time
      and without notice, at our sole discretion.
    </Paragraph>
    <Title level={4}>General Terms</Title>
    <ul style={{ listStyle: 'disc' }}>
      <li>The relationship between Smarty Pay and Clients is fully governed by these Terms.</li>
      <li>
        The present Terms shall be carefully examined by the Client before making a decision to register and start to
        use services provided by Smarty Pay.
      </li>
      <li>
        By registering an account in Smarty Pay on the service website at{' '}
        <ExternalLink to="https://smartypay.io" external title="https://smartypay.io" /> the Client accepts all clauses
        of these conditions.
      </li>
      <li>
        If at least one clause of these Terms is unacceptable or unfeasible, the Client must immediately stop
        registration and leave the service website{' '}
        <ExternalLink to="https://smartypay.io" external title="https://smartypay.io" />.
      </li>
      <li>
        All Smarty Pay Clients are subject to the KYB process, for which, during the registration process, we request
        data and documents about the company from them in accordance with our compliance policies. If necessary, Smarty
        Pay may request additional data related to the activities of the Client&#39;s company.
      </li>
      <li>
        In case of violation by the Clients of the Terms, their accounts may be blocked temporarily or permanently,
        followed by a complete denial of service.
      </li>
      <li>
        Clients choose a set of cryptocurrencies and service components that they want to use from those available in
        Smarty Pay.
      </li>
      <li>
        Smarty Pay Clients independently provide services to their customers with the necessary compliance formalities
        and procedures, as well as perform the necessary actions to protect personal data. Smarty Pay is not a personal
        data operator and a participant in the compliance process for customers served by Smarty Pay Clients.
      </li>
      <li>
        Smarty Pay works with data related to all participants in crypto payments (Clients and their customers), some of
        which may be personal data. Smarty Pay supports all organizational and technological processes to ensure the
        safety of this data and may participate in the processing of requests addressed to Clients regarding this data
        (for example, a request to delete data). At the same time, Smarty Pay cannot influence transaction data in any
        way, as changing such data is impossible due to the peculiarities of blockchain technology.
      </li>
      <li>
        Transactional data on the blockchain is public. Smarty Pay does not carry out any operations to anonymize
        transactions, and does not guarantee that any blockchain data on transactions conducted through Smarty Pay
        cannot be analyzed and associated with specific individuals/companies by means of external analytics.
      </li>
      <li>
        Smarty Pay does not store and manage funds received by Clients from crypto payments. The Clients themselves are
        responsible for the safety of these funds and the implementation of transactions with them. In case of loss of
        control over funds on the Client&#39;s side, Smarty Pay will not be able to restore access to these funds.
        Smarty Pay is not responsible for transactions with the Client&#39;s funds initiated on the Client&#39;s side,
        except for their technical support.
      </li>
      <li>
        Smarty Pay Clients are fully responsible for tax liabilities in accordance with the tax laws applicable to them.
      </li>
    </ul>
    <Title level={4}>Who May Use the Services</Title>
    <Title level={5} underline>
      Eligibility
    </Title>
    <Paragraph>You shall be considered a Client of Smarty Pay since the following conditions are met:</Paragraph>
    <ul style={{ listStyle: 'disc' }}>
      <li>You have accepted the Terms, and</li>
      <li>You have registered the account in Smarty Pay, and</li>
      <li>You provided all necessary information during the KYB process, and</li>
      <li>You provide services not included in the list of activities not supported by Smarty Pay.</li>
    </ul>
    <Paragraph>The Smarty Pay Service does not serve Clients engaged in the following activities:</Paragraph>
    <ul style={{ listStyle: 'disc' }}>
      <li>Production or trade in weapons and munitions</li>
      <li>Production or activities involving harmful or exploitative forms of forced labor/harmful child labor</li>
      <li>
        Production, trade, storage, or transport of significant volumes of hazardous chemicals, or commercial scale
        usage of hazardous chemicals
      </li>
      <li>Any business relating to pornography or prostitution</li>
      <li>Abusing confidential or material, non-public information</li>
      <li>Trading of Animal Fur, Bones and Ivory</li>
      <li>Indecent and Obscene material including child pornography</li>
      <li>Trading of explosives and Nuclear Weapons</li>
      <li>Drug trafficking including chemicals used to manufacture synthetic drugs</li>
      <li>Investment from several individuals (third party) without proper approval</li>
      <li>Human Body Parts and Pathogens</li>
      <li>
        As well as any other activities that require special licensing, without the necessary licenses, registrations,
        and permits.
      </li>
    </ul>
    <Title level={5} underline>
      Registration and Your Information
    </Title>
    <Paragraph>
      If you want to use the Services, you will have to create an account (“<b>Account</b>”) on our website. To open an
      account, you must provide your company name, native tokens wallet used to pay for the gas (required to
      successfully conduct a transaction), a valid email address and create a password.
    </Paragraph>
    <Paragraph>
      You agree that you will not disclose your Account credentials to anyone, and you’ll notify us immediately of any
      unauthorized use of your Account. You are responsible for all activities that occur under your Account, whether or
      not you know about them.
    </Paragraph>
    <Paragraph>
      You are solely responsible for the retention and security of your twelve-word seed phrase (“<b>Recovery Phrase</b>
      ”). Your Recovery Phrase is the only way to access the cryptocurrency associated with your account. Anyone that
      has access to your Recovery Phrase can access your cryptocurrency. If you lose your Recovery Phrase, you will not
      be able to access your cryptocurrency.{' '}
      <b>
        You acknowledge that Smarty Pay does not store your Recovery Phrase and you agree to hold Smarty Pay harmless in
        the event you lose your Recovery Phrase and cannot access your cryptocurrency.
      </b>
    </Paragraph>
    <Paragraph>
      When you create your account with us, you will be asked to provide your email address, which we may use on
      receipts or materials we send to customers on your behalf (“Support Email”). By providing this email, you agree
      that we may use that Support Email to direct customers that make payments to you using Smarty Pay to contact you
      for customer support. If you change your Support Email, you must provide us with prompt notice of such change to
      enable us to update the Support Email we use on your behalf. You agree to respond to customers contacting you via
      the Support Email timely (within 2 working days).
    </Paragraph>
    <Title level={4}>Pricing</Title>
    <Paragraph>
      Pricing information of Smarty Pay services is available at{' '}
      <ExternalLink to="https://smartypay.io/pricing/" external title="https://smartypay.io/pricing/" />
    </Paragraph>
    <Title level={4}>Content Ownership, Responsibility and Removal</Title>
    <Paragraph>
      We do not claim any ownership rights in any User Content and nothing in these Terms will be deemed to restrict any
      rights that you may have to use and exploit your User Content. You agree that you own or have all rights, title
      and interest, including all intellectual property rights, in any User Content you provide to us.
    </Paragraph>
    <Paragraph>
      Subject to the foregoing, Smarty Pay and its licensors exclusively own all right, title and interest in and to the
      Services and Content, including all associated intellectual property rights. You acknowledge that the Services and
      Content are protected by copyright, trademark, and other applicable laws. You agree not to remove, alter or
      obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying
      the Services or Content.
    </Paragraph>
    <Title level={5} underline>
      Rights in User Content Granted by You
    </Title>
    <Paragraph>
      In order to operate and provide our Services, you grant us a worldwide, non-exclusive, royalty-free,
      sublicensable, and transferable license to use, copy, distribute, create derivative works of, display, and perform
      the User Content that you upload, submit, store, send, or receive on or through our Services. The rights you grant
      in this license are for the purpose of operating and providing our Services, including, but not limited to,
      identifying merchants on the Services in receipts and materials we may send to customers on your behalf.
    </Paragraph>
    <Title level={5} underline>
      Rights in Content Granted by SmartyPay
    </Title>
    <Paragraph>
      Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable,
      non-sublicensable license to download, view, copy, display and print the Content solely in connection with your
      permitted use of the Services.
    </Paragraph>
    <Title level={4}>Acceptable Use and SmartyPay’s Enforcement Rights</Title>
    <Paragraph>You agree not to use the Services in ways that:</Paragraph>
    <ul style={{ listStyle: 'disc' }}>
      <li>
        Violate, misappropriate, or infringe the rights of Smarty Pay, our users, or others, including privacy,
        publicity, intellectual property, or other proprietary rights;
      </li>
      <li>
        Are illegal, obscene, defamatory, threatening, intimidating, harassing, hateful, racially, or ethnically
        offensive, or instigate or encourage conduct that would be illegal, or otherwise inappropriate, including
        promoting violent crimes;
      </li>
      <li>
        Involve publishing falsehoods, misrepresentations, or misleading statements, including impersonating someone; or
      </li>
      <li>
        Involve sending illegal or impermissible communications such as bulk messaging, auto-messaging, auto-dialing,
        and the like;
      </li>
      <li>
        Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure
        implemented by us or any of our service providers or any other third party (including another user) to protect
        the Services or Content;
      </li>
      <li>Disguise your location through IP proxying or other methods;</li>
      <li>
        Interfere with or attempt to interfere with the access of any user, host or network, including, without
        limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;
      </li>
      <li>Violate any applicable law or regulation; or</li>
      <li>Encourage or enable any other individual to do any of the foregoing.</li>
    </ul>
    <Paragraph>
      You may not use or download the Services if you are in, under the control of, or a national or resident of any
      other country subject to sanction lists and other law / regulatory enforcement lists.
    </Paragraph>
    <Title level={5}>Termination</Title>
    <Paragraph>
      We may terminate your access to and use of the Services, at our sole discretion, at any time and with preliminary
      notification regarding the termination reason and providing reasonable time to eliminate User’s inconsistencies
      with the terms of using the Services. You may cancel your Account at any time by following the account closure
      instructions in the dashboard. Upon termination or cancelation of your account, you will cease all use of the
      Service and Content. Upon any termination, discontinuation or cancellation of Services or your Account, except as
      specifically set forth herein, the following provisions will survive: Content and Content Rights, Content
      Ownership, Responsibility and Removal, Termination, Warranty Disclaimers, Indemnity, Limitation of Liability,
      Dispute Resolution and General Terms.
    </Paragraph>
    <Title level={5}>Warranty Disclaimers</Title>
    <Paragraph>
      YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE SERVICES (INCLUDING ANY PRIVATE KEY STORAGE SERVICE OFFERED AS
      PART OF THE SERVICES, WHETHER CLOUD OR HARDWARE-BASED) AND CONTENT IS AT YOUR SOLE RISK AND THAT THE ENTIRE RISK
      AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH YOU. THE APP IS PROVIDED ON AN “AS IS” AND
      “AS AVAILABLE” BASIS WITHOUT ANY REPRESENTATION OR WARRANTY, WHETHER EXPRESS, IMPLIED OR STATUTORY. TO THE MAXIMUM
      EXTENT PERMITTED BY APPLICABLE LAW, Smarty Pay SPECIFICALLY DISCLAIMS ANY EXPRESS OR IMPLIED WARRANTIES OF TITLE,
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND/OR NON-INFRINGEMENT. Smarty Pay DOES NOT MAKE ANY
      REPRESENTATIONS OR WARRANTIES THAT ACCESS TO THE SERVICES OR ANY OF THE MATERIALS CONTAINED THEREIN WILL BE
      CONTINUOUS, UNINTERRUPTED, TIMELY, OR ERROR-FREE.
    </Paragraph>
    <Paragraph>
      USE OF ANY PRIVATE KEY STORAGE SERVICE INCLUDED AS PART OF THE SERVICES IS OFFERED TO YOU AS A CONVENIENCE,
      SUBJECT TO THE LIMITATIONS ABOVE. TO BE SAFE, YOU SHOULD ALWAYS BACKUP YOUR PRIVATE ACCESS KEY VIA SECONDARY
      MEANS.
    </Paragraph>
    <Title level={5}>Indemnity</Title>
    <Paragraph>
      You will indemnify and hold harmless Smarty Pay and its officers, directors, employee and agents, from and against
      any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without
      limitation, reasonable legal and accounting fees arising out of or in any way connected with (i) your access to or
      use of the Services or Content, (ii) your User Content, (iii) Third Party Materials, or (iv) your violation of
      these Terms. Nevertheless, SmartyPay shall be deemed responsible for any real duly documented damage caused
      intentionally or due to negligence.
    </Paragraph>
    <Title level={5}>Limitation of Liability</Title>
    <Paragraph>
      TO THE EXTENT NOT PROHIBITED BY LAW, Smarty Pay SHALL NOT BE LIABLE FOR DAMAGES OF ANY TYPE, WHETHER DIRECT OR
      INDIRECT, ARISING OUT OF OR IN ANY WAY RELATED TO YOUR USE OR INABILITY TO USE THE SERVICES (EXCEPT FOR THE CASES
      WHERE THE FAILURE TO PERFORM THE OBLIGATION OF SMARTYPAY IS DULY PROVED AND CONFIRMED), INCLUDING BUT NOT LIMITED
      TO DAMAGES ALLEGEDLY ARISING FROM THE COMPROMISE OR LOSS OF YOUR LOGIN CREDENTIALS OR FUNDS, OR LOSS OF OR
      INABILITY TO RESTORE ACCESS FROM YOUR BACKUP PHRASE, OR FOR MISTAKES, OMISSIONS, INTERRUPTIONS, DELAYS, DEFECTS
      AND/OR ERRORS IN THE TRANSMISSION OF TRANSACTIONS OR MESSAGES TO THE BINANCE SMART CHAIN, OR THE FAILURE OF ANY
      MESSAGE TO SEND OR BE RECEIVED BY THE INTENDED RECIPIENT IN THE INTENDED FORM, OR FOR DIMINUTION OF VALUE OF
      DIGITAL TOKEN OR DIGITAL ASSET ON THE BINANCE SMART CHAIN. Smarty Pay SHALL NOT BE LIABLE UNDER ANY CIRCUMSTANCES
      FOR ANY LOST PROFITS OR ANY SPECIAL, INCIDENTAL, INDIRECT, INTANGIBLE, OR CONSEQUENTIAL DAMAGES, WHETHER BASED IN
      CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, ARISING OUT OF OR IN CONNECTION WITH AUTHORIZED OR
      UNAUTHORIZED USE OF THE SERVICES, NOT PROVIDED DIRECTLY BY Smarty Pay, EVEN IF AN AUTHORIZED REPRESENTATIVE OF
      SMARTYPAY HAS BEEN ADVISED OF OR KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. Smarty Pay SHALL
      NOT BE LIABLE UNDER ANY CIRCUMSTANCES FOR DAMAGES ARISING OUT OF OR IN ANY WAY RELATED TO SOFTWARE, PRODUCTS,
      SERVICES, AND/OR INFORMATION OFFERED OR PROVIDED.
    </Paragraph>
    <Title level={5}>Dispute Resolution</Title>
    <Title level={5} underline>
      Governing Law
    </Title>
    <Paragraph>
      These Terms and any action related thereto will be governed by the laws of England and Wales without regard to its
      conflict of laws provisions.
    </Paragraph>
    <Title level={5}>General Terms</Title>
    <Paragraph>
      These Terms constitute the entire and exclusive understanding and agreement between SmartyPay and you regarding
      the Services and Content, and these Terms supersede and replace any and all prior oral or written understandings
      or agreements between Smarty Pay and you regarding the Services and Content. If any provision of these Terms is
      held invalid or unenforceable, that provision will be enforced to the maximum extent permissible and the other
      provisions of these Terms will remain in full force and effect. You may not assign or transfer these Terms, by
      operation of law or otherwise, without our prior written consent. Any attempt by you to assign or transfer these
      Terms, without such consent, will be null. We may freely assign or transfer these Terms without restriction.
      Subject to the foregoing, these Terms will bind and insure to the benefit of the parties, their successors and
      permitted assigns.
    </Paragraph>
    <Paragraph>
      Any notices or other communications provided by us under these Terms, including those regarding modifications to
      these Terms, will be given by posting to the Services and/or through other electronic communication.
    </Paragraph>
    <Paragraph>
      Our failure to enforce any right or provision of these Terms will not be considered a waiver of such right or
      provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly
      authorized representative of Smarty Pay. Except as expressly set forth in these Terms, the exercise by either
      party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms
      or otherwise.
    </Paragraph>
    <Title level={5}>Confidentiality</Title>
    <Paragraph>
      Smarty Pay shall maintain the confidentiality of the User’s credentials. Smarty Pay is entitled to communicate the
      specified data only to the User, competent public authorities and only in the cases provided for by the applicable
      legislation.
    </Paragraph>
    <Title level={4}>Contact Information</Title>
    <Paragraph ref={lastElementRef} data-test={dataTest && `${dataTest}-last`}>
      If you have any questions about these Terms or the Services, please contact us at{' '}
      <Link href="mailto:support@smartypay.io">support@smartypay.io</Link>.
    </Paragraph>
  </div>
);

export default EnUS;
