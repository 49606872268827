import { useAppLoadableData } from '@/app/hooks';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchMerchantWalletOwnership } from '@/features/merchant-wallets/actions';
import { makeSelectMerchantWalletOwnership } from '@/features/merchant-wallets/selectors';

const dataSelector = makeSelectMerchantWalletOwnership();
const dataFetchingSelector = makeSelectPending(fetchMerchantWalletOwnership);
const fetchFactory = (force: boolean) => fetchMerchantWalletOwnership({ force });

export default function useMerchantWalletOwnership() {
  return useAppLoadableData(fetchFactory, dataSelector, dataFetchingSelector);
}
