/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  MerchantReportAPIModel,
  ReportDownloadLinkAPIModel,
  ReportGenerateAPIModel,
  ReportsFilterAPIModel,
  SliceMerchantReportReportSortByAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    MerchantReportAPIModelFromJSON,
    MerchantReportAPIModelToJSON,
    ReportDownloadLinkAPIModelFromJSON,
    ReportDownloadLinkAPIModelToJSON,
    ReportGenerateAPIModelFromJSON,
    ReportGenerateAPIModelToJSON,
    ReportsFilterAPIModelFromJSON,
    ReportsFilterAPIModelToJSON,
    SliceMerchantReportReportSortByAPIModelFromJSON,
    SliceMerchantReportReportSortByAPIModelToJSON,
} from '../models/index';

export interface DeleteReportRequest {
    reportId: string;
}

export interface DownloadReportRequest {
    reportId: string;
    attachment?: boolean;
}

export interface GenerateReportRequest {
    reportGenerateAPIModel: ReportGenerateAPIModel;
}

export interface GetReportRequest {
    reportId: string;
}

export interface SearchReportsRequest {
    reportsFilterAPIModel: ReportsFilterAPIModel;
    withTotal?: boolean;
}

/**
 * ReportApi - interface
 * 
 * @export
 * @interface ReportApiInterface
 */
export interface ReportApiInterface {
    /**
     * Deletes report by id
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApiInterface
     */
    deleteReportRaw(requestParameters: DeleteReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Deletes report by id
     */
    deleteReport(reportId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Generates link or redirects to the report file
     * @param {string} reportId 
     * @param {boolean} [attachment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApiInterface
     */
    downloadReportRaw(requestParameters: DownloadReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportDownloadLinkAPIModel>>;

    /**
     * Generates link or redirects to the report file
     */
    downloadReport(reportId: string, attachment?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportDownloadLinkAPIModel>;

    /**
     * Generates report
     * @param {ReportGenerateAPIModel} reportGenerateAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApiInterface
     */
    generateReportRaw(requestParameters: GenerateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>>;

    /**
     * Generates report
     */
    generateReport(reportGenerateAPIModel: ReportGenerateAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel>;

    /**
     * Returns report by id
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApiInterface
     */
    getReportRaw(requestParameters: GetReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>>;

    /**
     * Returns report by id
     */
    getReport(reportId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel>;

    /**
     * Returns reports by filter
     * @param {ReportsFilterAPIModel} reportsFilterAPIModel 
     * @param {boolean} [withTotal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApiInterface
     */
    searchReportsRaw(requestParameters: SearchReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceMerchantReportReportSortByAPIModel>>;

    /**
     * Returns reports by filter
     */
    searchReports(reportsFilterAPIModel: ReportsFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceMerchantReportReportSortByAPIModel>;

}

/**
 * 
 */
export class ReportApi extends runtime.BaseAPI implements ReportApiInterface {

    /**
     * Deletes report by id
     */
    async deleteReportRaw(requestParameters: DeleteReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['reportId'] == null) {
            throw new runtime.RequiredError(
                'reportId',
                'Required parameter "reportId" was null or undefined when calling deleteReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reports/{reportId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters['reportId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes report by id
     */
    async deleteReport(reportId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteReportRaw({ reportId: reportId }, initOverrides);
    }

    /**
     * Generates link or redirects to the report file
     */
    async downloadReportRaw(requestParameters: DownloadReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportDownloadLinkAPIModel>> {
        if (requestParameters['reportId'] == null) {
            throw new runtime.RequiredError(
                'reportId',
                'Required parameter "reportId" was null or undefined when calling downloadReport().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attachment'] != null) {
            queryParameters['attachment'] = requestParameters['attachment'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reports/{reportId}/download`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters['reportId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportDownloadLinkAPIModelFromJSON(jsonValue));
    }

    /**
     * Generates link or redirects to the report file
     */
    async downloadReport(reportId: string, attachment?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportDownloadLinkAPIModel> {
        const response = await this.downloadReportRaw({ reportId: reportId, attachment: attachment }, initOverrides);
        return await response.value();
    }

    /**
     * Generates report
     */
    async generateReportRaw(requestParameters: GenerateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>> {
        if (requestParameters['reportGenerateAPIModel'] == null) {
            throw new runtime.RequiredError(
                'reportGenerateAPIModel',
                'Required parameter "reportGenerateAPIModel" was null or undefined when calling generateReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportGenerateAPIModelToJSON(requestParameters['reportGenerateAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantReportAPIModelFromJSON(jsonValue));
    }

    /**
     * Generates report
     */
    async generateReport(reportGenerateAPIModel: ReportGenerateAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel> {
        const response = await this.generateReportRaw({ reportGenerateAPIModel: reportGenerateAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Returns report by id
     */
    async getReportRaw(requestParameters: GetReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>> {
        if (requestParameters['reportId'] == null) {
            throw new runtime.RequiredError(
                'reportId',
                'Required parameter "reportId" was null or undefined when calling getReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reports/{reportId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters['reportId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantReportAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns report by id
     */
    async getReport(reportId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel> {
        const response = await this.getReportRaw({ reportId: reportId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns reports by filter
     */
    async searchReportsRaw(requestParameters: SearchReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceMerchantReportReportSortByAPIModel>> {
        if (requestParameters['reportsFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'reportsFilterAPIModel',
                'Required parameter "reportsFilterAPIModel" was null or undefined when calling searchReports().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['withTotal'] != null) {
            queryParameters['withTotal'] = requestParameters['withTotal'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reports/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportsFilterAPIModelToJSON(requestParameters['reportsFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceMerchantReportReportSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns reports by filter
     */
    async searchReports(reportsFilterAPIModel: ReportsFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceMerchantReportReportSortByAPIModel> {
        const response = await this.searchReportsRaw({ reportsFilterAPIModel: reportsFilterAPIModel, withTotal: withTotal }, initOverrides);
        return await response.value();
    }

}
