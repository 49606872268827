/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PagePayoutSortByAPIModel } from './PagePayoutSortByAPIModel';
import {
    PagePayoutSortByAPIModelFromJSON,
    PagePayoutSortByAPIModelFromJSONTyped,
    PagePayoutSortByAPIModelToJSON,
} from './PagePayoutSortByAPIModel';
import type { PayoutPredicatesAPIModel } from './PayoutPredicatesAPIModel';
import {
    PayoutPredicatesAPIModelFromJSON,
    PayoutPredicatesAPIModelFromJSONTyped,
    PayoutPredicatesAPIModelToJSON,
} from './PayoutPredicatesAPIModel';

/**
 * 
 * @export
 * @interface PayoutFilterAPIModel
 */
export interface PayoutFilterAPIModel {
    /**
     * 
     * @type {PayoutPredicatesAPIModel}
     * @memberof PayoutFilterAPIModel
     */
    predicates: PayoutPredicatesAPIModel;
    /**
     * 
     * @type {PagePayoutSortByAPIModel}
     * @memberof PayoutFilterAPIModel
     */
    page: PagePayoutSortByAPIModel;
}

/**
 * Check if a given object implements the PayoutFilterAPIModel interface.
 */
export function instanceOfPayoutFilterAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    if (!('page' in value)) return false;
    return true;
}

export function PayoutFilterAPIModelFromJSON(json: any): PayoutFilterAPIModel {
    return PayoutFilterAPIModelFromJSONTyped(json, false);
}

export function PayoutFilterAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayoutFilterAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': PayoutPredicatesAPIModelFromJSON(json['predicates']),
        'page': PagePayoutSortByAPIModelFromJSON(json['page']),
    };
}

export function PayoutFilterAPIModelToJSON(value?: PayoutFilterAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': PayoutPredicatesAPIModelToJSON(value['predicates']),
        'page': PagePayoutSortByAPIModelToJSON(value['page']),
    };
}

