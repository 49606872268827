import { css } from '@emotion/css';
import { theme, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';

import {
  FormattedMessage,
  NCPSTable,
  ReadonlyComponent,
  StopPropagationContainer,
  TitleWithDetails,
} from '@/components';
import { AssetAmount } from '@/features/dictionary/blockchain/components';
import { GasWalletInvalidStateBanner } from '@/features/gas-wallets/components';
import SubscriptionPlanView from '@/features/subscription-plans/components/SubscriptionPlanView';
import type { SubscriptionPlanExtended, SubscriptionPlanFilterPredicate } from '@/features/subscription-plans/types';
import { extractSubscriptionPlanId } from '@/features/subscription-plans/utils';
import { SubscriptionPlanSortByAPIModel, SubscriptionPlanStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nComponents, I18nFeatureSubscriptionPlans } from '@/generated/i18n/i18n';
import { useLocaleSettings, useNotification } from '@/hooks';
import { sortOrderToTable } from '@/infrastructure/model/view';
import type { EmptyObject } from '@/infrastructure/utils/ts';
import { nameof } from '@/infrastructure/utils/ts';

import SubscriptionPlanScheduleView from '../SubscriptionPlanScheduleView';
import SubscriptionPlanStatusView from '../SubscriptionPlanStatusView';
import SubscriptionPlanTagsView from '../SubscriptionPlanTagsView';

import type { SubscriptionPlansTableProps } from './types';
import type { ProColumns, ProTableProps } from '@ant-design/pro-table';

const { Text } = Typography;

const sortMapping = {
  [nameof<SubscriptionPlanExtended>('createdAt')]: SubscriptionPlanSortByAPIModel.CreatedAt,
};
const sortColumnToModel = (s: string) => sortMapping[s];

const SubscriptionPlansTable = <FilterType extends EmptyObject = SubscriptionPlanFilterPredicate>({
  'data-test': dataTest,
  data,
  loading,
  page,
  forceRefresh,
  columnState,
  sortBy,
  updateParameters,
  filter,
  FilterForm,
  SubscriptionPlanLink,
  Operations,
}: SubscriptionPlansTableProps<FilterType>) => {
  const { withError } = useNotification();

  const reload = useMemo(
    () =>
      withError(forceRefresh, () => (
        <FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_SUBSCRIPTION_PLANS_TABLE_REFRESH_ERROR_MESSAGE} />
      )),
    [forceRefresh, withError],
  );

  const { formatDateTime } = useLocaleSettings();
  const columns: ProColumns<SubscriptionPlanExtended>[] = useMemo(
    () => [
      {
        fixed: 'left',
        width: 5,
        hideInSetting: true,
        render: (_, { id, amount: { asset }, status }) => (
          <StopPropagationContainer>
            {status === SubscriptionPlanStatusAPIModel.Active && (
              <GasWalletInvalidStateBanner
                assetId={asset}
                mode="icon"
                title={
                  <FormattedMessage
                    id={I18nFeatureSubscriptionPlans.COMPONENTS_SUBSCRIPTION_PLANS_TABLE_GAS_WALLET_ERROR_MESSAGE}
                  />
                }
                data-test={dataTest && `${dataTest}-${id}-alert`}
              />
            )}
          </StopPropagationContainer>
        ),
      },
      {
        title: <FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_ID} />,
        dataIndex: nameof<SubscriptionPlanExtended>('id'),
        render: (_, { id }) => (
          <ReadonlyComponent value={id} style={{ whiteSpace: 'nowrap' }} data-test={dataTest && `${dataTest}-id`} />
        ),
      },
      {
        title: <FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_NAME} />,
        dataIndex: nameof<SubscriptionPlanExtended>('name'),
        render: (_, { name }) => (
          <Text data-test={dataTest && `${dataTest}-name`} style={{ width: 200 }} ellipsis>
            {name}
          </Text>
        ),
      },
      {
        title: <FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_TAGS} />,
        dataIndex: nameof<SubscriptionPlanExtended>('tags'),
        render: (_, { id, tags }) => (
          <SubscriptionPlanTagsView value={tags} data-test={dataTest && `${dataTest}-${id}-tags`} />
        ),
      },
      {
        title: <FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_CREATED_AT} />,
        dataIndex: nameof<SubscriptionPlanExtended>('createdAt'),
        valueType: 'dateTime',
        sortOrder: sortOrderToTable(sortBy.CreatedAt),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        render: (_, { id, createdAt }) => (
          <ReadonlyComponent
            value={formatDateTime(createdAt)}
            style={{ whiteSpace: 'nowrap' }}
            data-test={dataTest && `${dataTest}-${id}-createdAt`}
          />
        ),
      },
      {
        title: <FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_PERIOD} />,
        dataIndex: nameof<SubscriptionPlanExtended>('periodSeconds'),
        width: 50,
        fixed: 'right',
        render: (_, { id, periodSeconds }) => (
          <SubscriptionPlanScheduleView
            value={periodSeconds}
            mode="short"
            data-test={dataTest && `${dataTest}-${id}-period`}
          />
        ),
        align: 'center',
      },
      {
        title: <FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_AMOUNT} />,
        dataIndex: nameof<SubscriptionPlanExtended>('amount'),
        fixed: 'right',
        width: 120,
        render: (_, { id, amount }) => (
          <ReadonlyComponent
            value={
              <AssetAmount
                assetId={amount.asset}
                value={amount.value}
                withCurrency
                withBlockchainMark
                data-test={dataTest && `${dataTest}-${id}-amount`}
              />
            }
            copyable={false}
          />
        ),
      },
      {
        title: <FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_STATUS} />,
        dataIndex: nameof<SubscriptionPlanExtended>('status'),
        width: 1,
        fixed: 'right',
        render: (_, { id, status }) => (
          <SubscriptionPlanStatusView value={status} data-test={dataTest && `${dataTest}-${id}-status`} mode="icon" />
        ),
        align: 'center',
      },

      {
        key: 'options',
        title: <FormattedMessage id={I18nComponents.TABLE_ROW_OPTIONS} />,
        dataIndex: 'options',
        width: 1,
        fixed: 'right',
        render: (_, value) => (
          <StopPropagationContainer>
            {SubscriptionPlanLink && (
              <SubscriptionPlanLink value={value.id} data-test={dataTest && `${dataTest}-${value.id}-linkTo`} />
            )}
            {Operations && <Operations data-test={dataTest && `${dataTest}-${value.id}-op`} value={value} />}
          </StopPropagationContainer>
        ),
      },
    ],
    [Operations, SubscriptionPlanLink, dataTest, formatDateTime, sortBy.CreatedAt],
  );

  const preview = useCallback(
    (value: SubscriptionPlanExtended) => ({
      title: (
        <TitleWithDetails
          title={
            <FormattedMessage
              id={I18nFeatureSubscriptionPlans.COMPONENTS_SUBSCRIPTION_PLANS_TABLE_DIALOG_TITLE}
              values={{ id: value.id }}
            />
          }
          link={
            SubscriptionPlanLink && (
              <SubscriptionPlanLink
                value={value.id}
                mode="text"
                title={<FormattedMessage id={I18nComponents.TABLE_LINK_DETAILS} values={{ id: value.id }} />}
              />
            )
          }
          data-test={dataTest && `${dataTest}-subscriptionPlanDialogTitle`}
        />
      ),
      content: (
        <SubscriptionPlanView
          data={value}
          data-test={dataTest && `${dataTest}-subscriptionPlan`}
          style={{ paddingTop: '10px' }}
          columns={1}
        />
      ),
    }),
    [SubscriptionPlanLink, dataTest],
  );

  const filterForm = useMemo(
    () => (FilterForm ? { Form: FilterForm, empty: {} as FilterType } : undefined),
    [FilterForm],
  );

  const { token } = theme.useToken();
  const TableProps: ProTableProps<SubscriptionPlanExtended, Record<string, unknown>> = useMemo(
    () => ({
      rowClassName: ({ status, isGasWalletStateInvalid }) =>
        (status === SubscriptionPlanStatusAPIModel.Active
          && isGasWalletStateInvalid
          && css`
            > td {
              background-color: ${token.orange2};
            }
          `)
        || '',
    }),
    [token.orange2],
  );

  return (
    <NCPSTable<SubscriptionPlanExtended, FilterType, SubscriptionPlanSortByAPIModel>
      data-test={dataTest}
      filter={filterForm}
      preview={preview}
      sortColumnToModel={sortColumnToModel}
      columns={columns}
      data={data}
      loading={loading}
      page={page}
      filterData={filter}
      columnsState={columnState}
      reload={reload}
      updateParameters={updateParameters}
      extractKey={extractSubscriptionPlanId}
      TableProps={TableProps}
    />
  );
};

const SubscriptionPlansTableMemo = React.memo(SubscriptionPlansTable) as typeof SubscriptionPlansTable;

export default SubscriptionPlansTableMemo;
