import { createReducer } from '@reduxjs/toolkit';

import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import { extractSubscriptionPlanId } from '@/features/subscription-plans/utils';
import { defaultPage } from '@/infrastructure/model/api';
import { createNormalizedListReducers } from '@/infrastructure/model/list/reducers';
import { storedDirtyListDataTyped } from '@/infrastructure/model/list/utils';

import {
  markSubscriptionPlanDirty,
  markSubscriptionPlansListDirty,
  storeMultipleSubscriptionPlan,
  storeRemoveSubscriptionPlan,
  storeSubscriptionPlan,
  storeSubscriptionPlansListData,
  storeSubscriptionPlansListParameters,
} from './actions';
import { defaultSortBy, type SubscriptionPlanState } from './types';

import type { Draft } from 'immer';

const initialState: SubscriptionPlanState = {
  list: {
    data: storedDirtyListDataTyped(),
    filter: {},
    sortBy: defaultSortBy,
    columnState: {},
    page: defaultPage,
  },
  entities: {},
};

const {
  storeSubscriptionPlanReducer,
  markSubscriptionPlanDirtyReducer,
  storeSubscriptionPlanListDataReducer,
  markSubscriptionPlanListDirtyReducer,
  storeSubscriptionPlanListParametersReducer,
  storeMultipleSubscriptionPlanReducer,
  storeRemoveSubscriptionPlanReducer,
} = createNormalizedListReducers(
  'SubscriptionPlan',
  (state: Draft<SubscriptionPlanState>) => state.list,
  (state, list) => ({ ...state, list }),
  initialState.list,
  // this cast is workaround caused by BigNumber in the store
  (state: Draft<SubscriptionPlanState>) => state.entities as SubscriptionPlanState['entities'],
  (global, entities) => ({ ...global, entities }),
  extractSubscriptionPlanId,
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markSubscriptionPlanDirty, markSubscriptionPlanDirtyReducer)
    .addCase(storeSubscriptionPlan, storeSubscriptionPlanReducer)

    .addCase(storeSubscriptionPlansListData, storeSubscriptionPlanListDataReducer)
    .addCase(storeSubscriptionPlansListParameters, storeSubscriptionPlanListParametersReducer)
    .addCase(markSubscriptionPlansListDirty, markSubscriptionPlanListDirtyReducer)
    .addCase(storeMultipleSubscriptionPlan, storeMultipleSubscriptionPlanReducer)
    .addCase(storeRemoveSubscriptionPlan, storeRemoveSubscriptionPlanReducer)

    .addCase(notifyNetworkUpdated, () => initialState)
    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address ? initialState : state,
    );
});

export default reducer;
