import type { Report } from '@/features/reports/types';
import type {
  MerchantReportSchedulePredicatesAPIModel,
  ReportScheduleAPIModel,
  ReportScheduleSortByAPIModel,
  ReportScheduleUpdateAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { ReportScheduleApi } from '@/generated/api/ncps-core/merchant-bo';
import { coreConfigurationFactory } from '@/infrastructure/api';
import { pageToAPI, sliceFromAPI } from '@/infrastructure/model/api';
import type { DataSlice, SliceDataRequestWithTotal } from '@/infrastructure/model/api';
import { asType } from '@/infrastructure/utils/ts';

import type { NewReportSchedule, ReportSchedule } from './types';

const reportScheduleApi = new ReportScheduleApi(coreConfigurationFactory('auth'));

const parseReportSchedule = ({ parameters, ...schedule }: ReportScheduleAPIModel): ReportSchedule => ({
  ...schedule,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  parameters: asType<Report['parameters']>(parameters),
});

export const queryReportSchedules = async (
  {
    page,
    filter,
    orderBy,
    withTotal,
  }: SliceDataRequestWithTotal<MerchantReportSchedulePredicatesAPIModel, ReportScheduleSortByAPIModel>,
  initOverrides?: RequestInit,
): Promise<DataSlice<ReportSchedule, ReportScheduleSortByAPIModel>> =>
  sliceFromAPI(
    await reportScheduleApi.searchReportSchedules(
      { page: pageToAPI(page, orderBy), predicates: filter ?? {} },
      withTotal,
      initOverrides,
    ),
    parseReportSchedule,
  );

export const queryReportSchedule = async (reportScheduleId: string, initOverrides?: RequestInit) =>
  parseReportSchedule(await reportScheduleApi.getReportSchedule(reportScheduleId, initOverrides));

export const requestCreateReportSchedule = async (reportSchedule: NewReportSchedule, initOverrides?: RequestInit) =>
  parseReportSchedule(await reportScheduleApi.createReportSchedule(reportSchedule, initOverrides));

export const requestUpdateReportSchedule = async (
  reportScheduleId: string,
  data: ReportScheduleUpdateAPIModel,
  initOverrides?: RequestInit,
) => parseReportSchedule(await reportScheduleApi.updateReportSchedule(reportScheduleId, data, initOverrides));

export const requestActivateReportSchedule = async (reportScheduleId: string, initOverrides?: RequestInit) =>
  parseReportSchedule(await reportScheduleApi.activateReportSchedule(reportScheduleId, initOverrides));

export const requestDeactivateReportSchedule = async (
  reportScheduleId: string,
  reason?: string,
  initOverrides?: RequestInit,
) => parseReportSchedule(await reportScheduleApi.deactivateReportSchedule(reportScheduleId, { reason }, initOverrides));

export const requestDeleteReportSchedule = async (reportScheduleId: string, initOverrides?: RequestInit) =>
  reportScheduleApi.deleteReportSchedule(reportScheduleId, initOverrides);
