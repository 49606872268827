import type { DateTimeRelativeQuickFilter } from '@/components';
import type {
  CollectScheduleAPIModel,
  CollectTaskAPIModel,
  CollectTaskSummaryAPIModel,
  PushCollectAddressAPIModel,
  CollectTaskPredicatesAPIModel,
  CollectableEntityProcessTransactionAPIModel,
  CollectableEntityTransactionAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { PushCollectSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import type { AssetAmountValue } from '@/infrastructure/model/api';
import type { ListColumnState, ListSortBy, ListState, ListStateNoColumns } from '@/infrastructure/model/list/types';
import { defaultListState } from '@/infrastructure/model/list/types';
import type { SingleState } from '@/infrastructure/model/single/types';

export const NAMESPACE = 'collectable';

export type CollectScheduleUpdate = CollectScheduleAPIModel;

export type CollectSchedule = CollectScheduleAPIModel;

export interface CollectTaskAddress extends Omit<PushCollectAddressAPIModel, 'collectedAmount'> {
  collectedAmount?: AssetAmountValue;
}

export interface CollectableEntityTransaction extends Omit<CollectableEntityTransactionAPIModel, 'amount'> {
  amount: AssetAmountValue;
}

export type CollectableEntityProcessTransaction = CollectableEntityProcessTransactionAPIModel;

export interface CollectTask extends Omit<CollectTaskAPIModel, 'addresses' | 'collectedAmount'> {
  staleAt: Date;
  addresses: CollectTaskAddress[];
  collectedAmount?: AssetAmountValue;
}

export interface CollectTaskSummary extends Omit<CollectTaskSummaryAPIModel, 'collectedAmount'> {
  staleAt: Date;
  collectedAmount?: AssetAmountValue;
}

export interface CollectTaskFilterPredicate extends CollectTaskPredicatesAPIModel {
  processAtRangeRelative?: DateTimeRelativeQuickFilter;
}

export type CollectTaskForAddressFilterPredicate = Omit<CollectTaskFilterPredicate, 'addressLike'>;

export type CollectTaskListState = ListStateNoColumns<string, CollectTaskFilterPredicate, PushCollectSortByAPIModel>;

export type CollectTaskForAddressListState = ListStateNoColumns<
  string,
  CollectTaskForAddressFilterPredicate,
  PushCollectSortByAPIModel
>;

export type CollectTaskSortBy = ListSortBy<PushCollectSortByAPIModel>;

export const defaultSortBy: CollectTaskSortBy = { [PushCollectSortByAPIModel.ProcessAt]: 'DESC' };
export const defaultCollectTaskListState: ListState<string, CollectTaskFilterPredicate, PushCollectSortByAPIModel> =
  defaultListState({}, defaultSortBy);
export const defaultCollectTaskForAddressListState: CollectTaskForAddressListState = defaultListState(
  {},
  defaultSortBy,
);

export interface CollectableState {
  schedule: LoadingStateWithDirty<CollectSchedule>;
  transactions: SingleState<CollectableEntityTransaction>;
  gwtTransactions: SingleState<CollectableEntityProcessTransaction>;

  tasks: {
    columnState: ListColumnState;
    list: CollectTaskListState;
    byAddresses: Partial<Record<string, CollectTaskForAddressListState>>;
    summaries: SingleState<CollectTaskSummary>;

    entities: SingleState<CollectTask>;
  };
}
