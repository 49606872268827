import React from 'react';

import { DocumentationLink } from '@/components';

import type { PayoutsDocumentationLinkProps } from './types';

const PayoutsDocumentationLink: React.FC<PayoutsDocumentationLinkProps> = ({ 'data-test': dataTest, mode, title }) =>
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition,no-constant-condition
  true ? null : <DocumentationLink mode={mode} title={title} data-test={dataTest} path="/guides/funds-withdraw" />;

const PayoutsDocumentationLinkMemo = React.memo(PayoutsDocumentationLink);

export default PayoutsDocumentationLinkMemo;
