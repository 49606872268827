/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { ForwarderSettingsAPIModel } from './ForwarderSettingsAPIModel';
import {
    ForwarderSettingsAPIModelFromJSON,
    ForwarderSettingsAPIModelFromJSONTyped,
    ForwarderSettingsAPIModelToJSON,
} from './ForwarderSettingsAPIModel';

/**
 * 
 * @export
 * @interface UpdateForwarderSettingsAPIModel
 */
export interface UpdateForwarderSettingsAPIModel {
    /**
     * 
     * @type {Array<ForwarderSettingsAPIModel>}
     * @memberof UpdateForwarderSettingsAPIModel
     */
    settings?: Array<ForwarderSettingsAPIModel>;
}

/**
 * Check if a given object implements the UpdateForwarderSettingsAPIModel interface.
 */
export function instanceOfUpdateForwarderSettingsAPIModel(value: object): boolean {
    return true;
}

export function UpdateForwarderSettingsAPIModelFromJSON(json: any): UpdateForwarderSettingsAPIModel {
    return UpdateForwarderSettingsAPIModelFromJSONTyped(json, false);
}

export function UpdateForwarderSettingsAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateForwarderSettingsAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'settings': json['settings'] == null ? undefined : ((json['settings'] as Array<any>).map(ForwarderSettingsAPIModelFromJSON)),
    };
}

export function UpdateForwarderSettingsAPIModelToJSON(value?: UpdateForwarderSettingsAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'settings': value['settings'] == null ? undefined : ((value['settings'] as Array<any>).map(ForwarderSettingsAPIModelToJSON)),
    };
}

