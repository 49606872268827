import { FormattedMessage } from '@/components';
import { I18nFeatureSettlementReconciliations } from '@/generated/i18n/i18n';

import type React from 'react';

const SettlementReconciliationTransfersReportTitle: React.FC = () => (
  <FormattedMessage id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_EXPORT_TRANSFERS_REPORT_TITLE} />
);

export default SettlementReconciliationTransfersReportTitle;
