/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SortAttributePayoutSortByAPIModel } from './SortAttributePayoutSortByAPIModel';
import {
    SortAttributePayoutSortByAPIModelFromJSON,
    SortAttributePayoutSortByAPIModelFromJSONTyped,
    SortAttributePayoutSortByAPIModelToJSON,
} from './SortAttributePayoutSortByAPIModel';

/**
 * 
 * @export
 * @interface PagePayoutSortByAPIModel
 */
export interface PagePayoutSortByAPIModel {
    /**
     * 
     * @type {number}
     * @memberof PagePayoutSortByAPIModel
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PagePayoutSortByAPIModel
     */
    offset: number;
    /**
     * 
     * @type {Array<SortAttributePayoutSortByAPIModel>}
     * @memberof PagePayoutSortByAPIModel
     */
    sortBy?: Array<SortAttributePayoutSortByAPIModel>;
}

/**
 * Check if a given object implements the PagePayoutSortByAPIModel interface.
 */
export function instanceOfPagePayoutSortByAPIModel(value: object): boolean {
    if (!('limit' in value)) return false;
    if (!('offset' in value)) return false;
    return true;
}

export function PagePayoutSortByAPIModelFromJSON(json: any): PagePayoutSortByAPIModel {
    return PagePayoutSortByAPIModelFromJSONTyped(json, false);
}

export function PagePayoutSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagePayoutSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'limit': json['limit'],
        'offset': json['offset'],
        'sortBy': json['sortBy'] == null ? undefined : ((json['sortBy'] as Array<any>).map(SortAttributePayoutSortByAPIModelFromJSON)),
    };
}

export function PagePayoutSortByAPIModelToJSON(value?: PagePayoutSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'limit': value['limit'],
        'offset': value['offset'],
        'sortBy': value['sortBy'] == null ? undefined : ((value['sortBy'] as Array<any>).map(SortAttributePayoutSortByAPIModelToJSON)),
    };
}

