import { Form } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';

import { NetworkTypeSelect, NetworkTypeSegment } from './components';

import type { NetworkTypeItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const NetworkTypeItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  required,
  readonly,
  mode = 'switch',
  rules = [],
  ItemProps,
}: NetworkTypeItemProps<Values>) => (
  <Form.Item<Values>
    label={<FormattedMessage id={I18nFeatureDictionary.COMPONENTS_NETWORK_TYPE_ITEM_LABEL} />}
    required={required}
    rules={useMemo(
      () => [
        ...(required
          ? [
              {
                required: true,
                message: <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_NETWORK_TYPE_ITEM_REQUIRED} />,
              },
            ]
          : []),
        ...rules,
      ],
      [required, rules],
    )}
    {...ItemProps}
    name={name}
  >
    {mode === 'switch' ? (
      <NetworkTypeSegment data-test={dataTest} readonly={readonly} />
    ) : (
      <NetworkTypeSelect data-test={dataTest} readonly={readonly} />
    )}
  </Form.Item>
);

const NetworkTypeItemMemo = React.memo(NetworkTypeItem) as typeof NetworkTypeItem;

export default NetworkTypeItemMemo;
