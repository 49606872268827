/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainMetaAPIModel } from './BlockchainMetaAPIModel';
import {
    BlockchainMetaAPIModelFromJSON,
    BlockchainMetaAPIModelFromJSONTyped,
    BlockchainMetaAPIModelToJSON,
} from './BlockchainMetaAPIModel';

/**
 * 
 * @export
 * @interface GetBlockchainsResponseAPIModel
 */
export interface GetBlockchainsResponseAPIModel {
    /**
     * 
     * @type {Array<BlockchainMetaAPIModel>}
     * @memberof GetBlockchainsResponseAPIModel
     */
    blockchains?: Array<BlockchainMetaAPIModel>;
}

/**
 * Check if a given object implements the GetBlockchainsResponseAPIModel interface.
 */
export function instanceOfGetBlockchainsResponseAPIModel(value: object): boolean {
    return true;
}

export function GetBlockchainsResponseAPIModelFromJSON(json: any): GetBlockchainsResponseAPIModel {
    return GetBlockchainsResponseAPIModelFromJSONTyped(json, false);
}

export function GetBlockchainsResponseAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBlockchainsResponseAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'blockchains': json['blockchains'] == null ? undefined : ((json['blockchains'] as Array<any>).map(BlockchainMetaAPIModelFromJSON)),
    };
}

export function GetBlockchainsResponseAPIModelToJSON(value?: GetBlockchainsResponseAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'blockchains': value['blockchains'] == null ? undefined : ((value['blockchains'] as Array<any>).map(BlockchainMetaAPIModelToJSON)),
    };
}

