import { useCallback, useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import {
  FormattedMessage,
  NCPSTable,
  OperationDateTimeRelativeQuickFilter,
  ReadonlyComponent,
  ReadonlyDateTime,
  StopPropagationContainer,
  TitleWithDetails,
} from '@/components';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { AddressLink, AssetAmount } from '@/features/dictionary/blockchain/components';
import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import SettlementReconciliationBanner from '@/features/settlement-reconciliations/components/SettlementReconciliationBanner';
import SettlementStatusView from '@/features/settlements/components/SettlementStatusView';
import SettlementTypeView from '@/features/settlements/components/SettlementTypeView';
import SettlementView from '@/features/settlements/components/SettlementView';
import type { Settlement } from '@/features/settlements/types';
import { WithdrawalSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nComponents, I18nFeatureSettlements } from '@/generated/i18n/i18n';
import { useDefaultNotification, useTableButtonsMode } from '@/hooks';
import { sortOrderToTable } from '@/infrastructure/model/view';
import { asType, nameof } from '@/infrastructure/utils/ts';

import type { BaseSettlementFilter, SettlementsTableProps } from './types';
import type { ProColumns } from '@ant-design/pro-table';
import type React from 'react';

const sortMapping = {
  [nameof<Settlement>('estimatedAt')]: WithdrawalSortByAPIModel.EstimatedAt,
};
const extractKey = ({ id }: Settlement) => id;
const sortColumnToModel = (s: string) => sortMapping[s];

const selectAuthToken = makeSelectAuthToken();

const SettlementsTable = <FilterType extends BaseSettlementFilter = BaseSettlementFilter>({
  'data-test': dataTest,
  data,
  columnState,
  filter,
  updateParameters,
  loading,
  forceRefresh,
  page,
  sortBy,
  ExportOperation,
  FilterForm,
  SettlementLink,
}: SettlementsTableProps<FilterType>) => {
  const { data: token } = useAppSelector(selectAuthToken);
  const companyId = token?.info.activeCompanyId;
  const {
    data: { data: featureFlags },
  } = useFeatureToggle();
  const { withDefaultError } = useDefaultNotification();

  const reload = useMemo(() => withDefaultError(forceRefresh), [forceRefresh, withDefaultError]);

  const columns: ProColumns<Settlement>[] = useMemo(
    () => [
      {
        key: nameof<Settlement>('type'),
        title: <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_TYPE} />,
        dataIndex: nameof<Settlement>('type'),
        render: (_, { id, type }) => (
          <SettlementTypeView value={type} data-test={dataTest && `${dataTest}-${id}-type`} mode="icon" />
        ),
      },
      {
        key: nameof<Settlement>('estimatedAmount'),
        title: <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_ESTIMATED_AMOUNT} />,
        dataIndex: nameof<Settlement>('estimatedAmount'),
        render: (_, { id, estimatedAmount }) => (
          <ReadonlyComponent
            value={
              <AssetAmount
                assetId={estimatedAmount.asset}
                value={estimatedAmount.value}
                withCurrency
                withBlockchainMark
                data-test={dataTest && `${dataTest}-${id}-amount`}
              />
            }
            copyable={false}
          />
        ),
      },
      {
        key: nameof<Settlement>('withdrawnAt'),
        title: <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_SETTLED_AT} />,
        dataIndex: nameof<Settlement>('withdrawnAt'),
        valueType: 'dateTime',
        render: (_, { id, withdrawnAt }) => (
          <ReadonlyDateTime value={withdrawnAt} data-test={dataTest && `${dataTest}-${id}-settledAt`} />
        ),
        width: 160,
      },
      {
        key: nameof<Settlement>('destinationAddress'),
        title: <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_DESTINATION_ADDRESS} />,
        dataIndex: nameof<Settlement>('destinationAddress'),
        valueType: 'dateTime',
        render: (_, { id, destinationAddress, estimatedAmount }) =>
          destinationAddress && (
            <AddressLink
              address={destinationAddress}
              assetId={estimatedAmount.asset}
              data-test={dataTest && `${dataTest}-${id}-destination`}
            />
          ),
        width: 160,
      },
      {
        key: nameof<Settlement>('estimatedAt'),
        title: <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_ESTIMATED_AT} />,
        dataIndex: nameof<Settlement>('estimatedAt'),
        valueType: 'dateTime',
        sortOrder: sortOrderToTable(sortBy.EstimatedAt),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        render: (_, { id, estimatedAt }) => (
          <ReadonlyDateTime
            value={estimatedAt}
            style={{ whiteSpace: 'nowrap' }}
            data-test={dataTest && `${dataTest}-${id}-estimatedAt`}
          />
        ),
        width: 160,
      },
      {
        key: nameof<Settlement>('status'),
        title: <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_STATUS} />,
        dataIndex: nameof<Settlement>('status'),
        hideInSetting: true,
        align: 'center',
        fixed: 'right',
        render: (_, { id, status }) => (
          <SettlementStatusView value={status} data-test={dataTest && `${dataTest}-${id}-status`} mode="icon" />
        ),
        width: 60,
      },
      ...(featureFlags?.enableReconciliation
        ? [
            asType<ProColumns<Settlement>>({
              key: nameof<Settlement>('reconciliationStatus'),
              title: <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_RECONCILIATION_STATUS} />,
              dataIndex: nameof<Settlement>('reconciliationStatus'),
              hideInSetting: true,
              align: 'center',
              fixed: 'right',
              render: (_, { id, reconciliationStatus }) => (
                <StopPropagationContainer>
                  <SettlementReconciliationBanner
                    data-test={dataTest && `${dataTest}-${id}-reconciliation`}
                    data={reconciliationStatus}
                    settlementId={id}
                    companyId={companyId!}
                    mode="tag"
                    SettlementReconciliationLink={SettlementLink}
                  />
                </StopPropagationContainer>
              ),
              width: 140,
            }),
          ]
        : []),
      {
        key: 'options',
        title: <FormattedMessage id={I18nComponents.TABLE_ROW_OPTIONS} />,
        width: 1,
        fixed: 'right',
        render: (_, { id }) => (
          <StopPropagationContainer>
            {SettlementLink && <SettlementLink value={id} data-test={dataTest && `${dataTest}-${id}-link`} />}
          </StopPropagationContainer>
        ),
      },
    ],
    [SettlementLink, companyId, dataTest, featureFlags?.enableReconciliation, sortBy.EstimatedAt],
  );

  const preview = useCallback(
    (value: Settlement) => ({
      title: (
        <TitleWithDetails
          title={
            <FormattedMessage
              id={I18nFeatureSettlements.COMPONENTS_SETTLEMENT_TABLE_DIALOG_TITLE}
              values={{ id: value.id }}
            />
          }
          link={
            SettlementLink && (
              <SettlementLink
                value={value.id}
                mode="text"
                title={<FormattedMessage id={I18nComponents.TABLE_LINK_DETAILS} values={{ id: value.id }} />}
              />
            )
          }
          data-test={dataTest && `${dataTest}-dialogTitle`}
        />
      ),
      content: (
        <SettlementView
          data={value}
          data-test={dataTest && `${dataTest}-view`}
          style={{ paddingTop: '10px' }}
          columns={1}
        />
      ),
    }),
    [SettlementLink, dataTest],
  );

  const { ref, quickFilterMode, exportMode } = useTableButtonsMode();
  const toolBar = useMemo(
    () =>
      FilterForm
        ? (filterComponent?: React.ReactNode) => [
            ...(featureFlags?.enableReconciliation && ExportOperation
              ? [
                  <ExportOperation
                    key="export"
                    data-test={dataTest && `${dataTest}-export`}
                    mode={exportMode}
                    rows={data.data?.total}
                  />,
                ]
              : []),
            <OperationDateTimeRelativeQuickFilter<'withdrawnAtRange', 'withdrawnAtRelative', FilterType>
              key="quickFilter"
              absoluteKey={nameof<FilterType, 'withdrawnAtRange'>('withdrawnAtRange')}
              relativeKey={nameof<FilterType, 'withdrawnAtRelative'>('withdrawnAtRelative')}
              onChange={(newValue) => updateParameters({ filter: newValue })}
              mode={quickFilterMode}
              value={filter}
              disabled={loading}
            />,
            filterComponent,
          ]
        : undefined,
    [
      ExportOperation,
      FilterForm,
      featureFlags?.enableReconciliation,
      dataTest,
      exportMode,
      data.data?.total,
      quickFilterMode,
      filter,
      loading,
      updateParameters,
    ],
  );

  const filterForm = useMemo(
    () => (FilterForm ? { Form: FilterForm, empty: {} as FilterType } : undefined),
    [FilterForm],
  );

  return (
    <div ref={ref}>
      <NCPSTable<Settlement, FilterType, WithdrawalSortByAPIModel>
        data-test={dataTest}
        filter={filterForm}
        preview={preview}
        toolBarRender={toolBar}
        sortColumnToModel={sortColumnToModel}
        columns={columns}
        data={data}
        loading={loading}
        page={page}
        filterData={filter}
        columnsState={columnState}
        reload={reload}
        updateParameters={updateParameters}
        extractKey={extractKey}
      />
    </div>
  );
};

export default SettlementsTable;
