import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { queryIsMaintenance } from '@/features/maintenance/api';
import { makeSelectIsOnMaintenance } from '@/features/maintenance/selectors';
import { withAPICall } from '@/infrastructure/model/api';
import { createLoadingDataActions } from '@/infrastructure/model/common/actions';

import { NAMESPACE } from './types';

export const { storeMaintenance, markMaintenanceDirty } = createLoadingDataActions<boolean, 'Maintenance'>(
  NAMESPACE,
  'Maintenance',
);

const fetchLimit = pLimit(1);
export const fetchMaintenance = createAppAsyncThunk(
  `${NAMESPACE}/fetchMaintenance`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    fetchLimit(async () => {
      const saved = makeSelectIsOnMaintenance()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryIsMaintenance)({ signal });
      dispatch(storeMaintenance(data));
      return data;
    }),
);
