import {
  createPublicClient,
  custom,
  encodeAbiParameters,
  getAddress,
  getContract,
  keccak256,
  publicActions,
  zeroAddress,
} from 'viem';

import { type BCRpcRequestAPIMeta, getRpcClient } from '@/features/dictionary/blockchain/web3-api';
import type { MerchantWalletSignature } from '@/features/merchant-wallets/types';
import type { SignatureComponentsAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import type { Account, Address, Chain, Transport, WalletClient } from 'viem';

const walletFactoryAbi = [
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'salt',
        type: 'bytes32',
      },
    ],
    name: 'getInstance',
    outputs: [
      {
        internalType: 'address',
        name: 'instance',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes',
        name: 'initData',
        type: 'bytes',
      },
    ],
    name: 'createInstance',
    outputs: [
      {
        internalType: 'address',
        name: 'instance',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const;

const prepareDeployInitData = (
  address: Address,
  broker: Address,
  messageWithAddress: string,
  { v, r, s }: SignatureComponentsAPIModel,
) => {
  const messageWithOutAddress = messageWithAddress.replace(broker.toLowerCase(), '');
  const message = `\x19Ethereum Signed Message:\n${messageWithAddress.length}${messageWithOutAddress}`;
  return encodeAbiParameters(
    [
      { type: 'address' },
      { type: 'address' },
      { type: 'string' },
      { type: 'uint8' },
      { type: 'bytes32' },
      { type: 'bytes32' },
    ],
    [address, broker, message, v, r as `0x${string}`, s as `0x${string}`],
  );
};

export const queryWeb3MerchantAddress = async (
  caller:
    | { client: WalletClient<Transport, Chain, Account>; meta?: undefined }
    | { meta: BCRpcRequestAPIMeta; client?: undefined },
  merchantWalletFactoryAddress: string,
  address: string,
): Promise<Address | null> => {
  const client = caller.client
    ? createPublicClient({ transport: custom(caller.client.transport) })
    : getRpcClient(caller.meta);
  const contract = getContract({
    address: getAddress(merchantWalletFactoryAddress),
    abi: walletFactoryAbi,
    client,
  });
  const result = await contract.read.getInstance([keccak256(getAddress(address))]);
  return result === zeroAddress ? null : result;
};

export const requestDeployMerchantAddress = async (
  client: WalletClient<Transport, Chain, Account>,
  merchantWalletFactoryAddress: string,
  owner: string,
  signature: MerchantWalletSignature,
): Promise<Address | null> => {
  const contract = getContract({
    address: getAddress(merchantWalletFactoryAddress),
    abi: walletFactoryAbi,
    client,
  });
  const gasPrice = await client.extend(publicActions).getGasPrice();
  const args = [
    prepareDeployInitData(
      getAddress(owner),
      getAddress(signature.brokerAddress),
      signature.bootstrapMessage,
      signature.signature,
    ),
  ] as const;
  const gasLimit = await contract.estimateGas.createInstance(args);
  const hash = await contract.write.createInstance(args, { gasPrice, gasLimit });
  await client.extend(publicActions).waitForTransactionReceipt({ hash, confirmations: 3 });
  return queryWeb3MerchantAddress({ client }, merchantWalletFactoryAddress, owner);
};
