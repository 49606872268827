import { PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { Space, Table } from 'antd';
import BigNumber from 'bignumber.js';
import isNil from 'lodash/isNil';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessageWithMarkup, FormattedMessage, Operation } from '@/components';
import { AddressLink, AssetAmount } from '@/features/dictionary/blockchain/components';
import type { PayoutDestinationRow } from '@/features/payouts/components';
import type { PayoutDestinationTableEditableCellParams } from '@/features/payouts/components/EditPayoutForm/components/PayoutDestinationsTableInput/components/PayoutDestinationTableEditableCell/types';
import { I18nComponents, I18nFeaturePayouts } from '@/generated/i18n/i18n';
import { withAntDesignInputMemo } from '@/hocs';
import { numberComparator } from '@/infrastructure/model/comparators';
import { noopAsync } from '@/infrastructure/utils/functions';
import { asType } from '@/infrastructure/utils/ts';

import {
  PayoutDestinationTableEditableCell,
  PayoutDestinationTableEditOperation,
  PayoutDestinationsPersistOperation,
} from './components';
import { withPayoutDestinationTableEditContext } from './hocs';
import { usePayoutDestinationFilter, usePayoutTableActionsMode, usePayoutTableEditableRow } from './hooks';

import type { PayoutDestinationsTableInputProps } from './types';
import type { TableColumnType, TableProps } from 'antd';
import type { Reference } from 'rc-table/lib/interface';

const rowIdComparator = numberComparator<PayoutDestinationRow>(({ num }) => num)('ASC');
const components = { body: { cell: PayoutDestinationTableEditableCell } };
const defaultValue: PayoutDestinationRow[] = [];

const PayoutDestinationsTableInput = React.forwardRef<Reference, PayoutDestinationsTableInputProps>(
  function PayoutDestinationsTableInput(
    { 'data-test': dataTest, asset, disabled, value = defaultValue, onChange = noopAsync, onStateChange },
    tableRef,
  ) {
    const { formatMessage: i18n } = useIntl();

    const {
      onSave,
      onCancel,
      data: editingData,
      onEdit,
      editingKey,
      onAdd,
      onDelete,
    } = usePayoutTableEditableRow({ data: value, onChange });
    const inEditMode = !isNil(editingKey);

    const {
      filterText,
      isFiltered,
      filterColumnMeta,
      Banner: FilterBanner,
    } = usePayoutDestinationFilter({
      'data-test': dataTest && `${dataTest}-filter`,
      disabled: inEditMode,
    });

    useEffect(() => {
      onStateChange?.(inEditMode);
    }, [inEditMode, onStateChange]);

    const columns = useMemo<
      (Omit<TableColumnType<PayoutDestinationRow>, 'dataIndex'> &
        (
          | { editable: true; dataIndex: PayoutDestinationTableEditableCellParams['dataIndex'] }
          | ({ editable?: undefined } & Pick<TableColumnType<PayoutDestinationRow>, 'dataIndex'>)
        ))[]
    >(
      () => [
        {
          ...filterColumnMeta,
          title: isFiltered ? (
            <FormattedMessageWithMarkup
              id={
                I18nFeaturePayouts.COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_COL_ADDRESS_FILTERED_TITLE
              }
              values={{ filter: filterText }}
            />
          ) : (
            <FormattedMessageWithMarkup
              id={I18nFeaturePayouts.COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_COL_ADDRESS_TITLE}
            />
          ),
          key: 'address',
          dataIndex: 'address',
          className: css`
            max-width: 100px;
          `,
          editable: true,
          render: (_, { address, num }: PayoutDestinationRow) => (
            <AddressLink data-test={dataTest && `${dataTest}-${num}-address`} address={address} assetId={asset} />
          ),
        },
        {
          title: i18n({ id: I18nFeaturePayouts.COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_COL_AMOUNT }),
          dataIndex: 'amount',
          width: 150,
          editable: true,
          render: (_, { num, amount }) => (
            <AssetAmount
              data-test={dataTest && `${dataTest}-${num}-amount`}
              assetId={asset}
              value={amount}
              withCurrency
            />
          ),
        },
        {
          title: i18n({ id: I18nComponents.TABLE_ROW_OPTIONS }),
          dataIndex: 'operation',
          width: 105,
          render: (_, record: PayoutDestinationRow) => (
            <PayoutDestinationTableEditOperation
              data-test={dataTest && `${dataTest}-${record.num}`}
              data={record}
              disabled={disabled}
              onSave={onSave}
              onEdit={onEdit}
              onCancel={onCancel}
              onDelete={onDelete}
            />
          ),
        },
      ],
      [filterColumnMeta, isFiltered, filterText, i18n, dataTest, asset, disabled, onSave, onEdit, onCancel, onDelete],
    );

    const tableData = useMemo(() => editingData.sort(rowIdComparator), [editingData]);

    const mergedColumns = useMemo<TableColumnType<PayoutDestinationRow>[]>(
      () =>
        columns.map((col) =>
          !col.editable
            ? col
            : {
                ...col,
                onCell: (record: PayoutDestinationRow) =>
                  asType<PayoutDestinationTableEditableCellParams>({
                    'data-test': dataTest && `${dataTest}-${record.num}`,
                    record,
                    dataIndex: col.dataIndex,
                    asset,
                    data: value,
                  }) as ReturnType<NonNullable<TableColumnType<PayoutDestinationRow>['onCell']>>,
              },
        ),
      [asset, columns, dataTest, value],
    );

    const { ref, addMode, persistMode } = usePayoutTableActionsMode();
    const footer = useCallback<NonNullable<TableProps<PayoutDestinationRow>['footer']>>(
      () => (
        <FormattedMessageWithMarkup
          id={I18nFeaturePayouts.COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_FOOTER_TOTAL}
          values={{
            total: value.length,
            amount: (
              <AssetAmount
                assetId={asset}
                value={value.reduce((result, value) => result.plus(value.amount), BigNumber(0))}
                withCurrency
                withBlockchainMark
              />
            ),
          }}
        />
      ),
      [asset, value],
    );
    const pagination = useMemo<TableProps<PayoutDestinationRow>['pagination']>(
      () => ({
        disabled: inEditMode || disabled,
        defaultPageSize: 10,
        showSizeChanger: true,
      }),
      [disabled, inEditMode],
    );

    return (
      <Space direction="vertical" style={{ width: '100%', margin: '8px 0', minHeight: '262px' }} ref={ref}>
        {FilterBanner}
        <div
          className={css`
            display: flex;
            flex: 1;
            padding-top: 8px;
            padding-bottom: 8px;
          `}
        >
          <Operation
            data-test={dataTest && `${dataTest}-addDestination`}
            title={
              <FormattedMessage
                id={I18nFeaturePayouts.COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_OPERATIONS_ADD_TITLE}
                tagName="span"
              />
            }
            disabled={inEditMode || isFiltered || disabled}
            onClick={onAdd}
            icon={<PlusOutlined />}
            mode={addMode}
          />
          <div
            className={css`
              display: flex;
              flex: 1;
            `}
          />
          <PayoutDestinationsPersistOperation
            data-test={dataTest && `${dataTest}-persist`}
            value={tableData}
            onChange={onChange}
            disabled={inEditMode || isFiltered}
            mode={persistMode}
          />
        </div>
        <Table<PayoutDestinationRow>
          ref={tableRef}
          components={components}
          dataSource={tableData}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={pagination}
          footer={footer}
        />
      </Space>
    );
  },
);

const PayoutDestinationsTableInputHOCed = withAntDesignInputMemo<
  PayoutDestinationRow[],
  PayoutDestinationsTableInputProps
>(withPayoutDestinationTableEditContext(PayoutDestinationsTableInput));

const PayoutDestinationsTableInputMemo = React.memo(
  PayoutDestinationsTableInputHOCed,
) as typeof PayoutDestinationsTableInputHOCed;

export default PayoutDestinationsTableInputMemo;
