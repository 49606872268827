/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PaymentCompatStatusAPIModel } from './PaymentCompatStatusAPIModel';
import {
    PaymentCompatStatusAPIModelFromJSON,
    PaymentCompatStatusAPIModelFromJSONTyped,
    PaymentCompatStatusAPIModelToJSON,
} from './PaymentCompatStatusAPIModel';
import type { SignatureComponentsAPIModel } from './SignatureComponentsAPIModel';
import {
    SignatureComponentsAPIModelFromJSON,
    SignatureComponentsAPIModelFromJSONTyped,
    SignatureComponentsAPIModelToJSON,
} from './SignatureComponentsAPIModel';

/**
 * 
 * @export
 * @interface InvoiceProofAPIModel
 */
export interface InvoiceProofAPIModel {
    /**
     * 
     * @type {string}
     * @memberof InvoiceProofAPIModel
     */
    invoiceId: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProofAPIModel
     */
    sequenceNumber: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProofAPIModel
     */
    nextPaymentAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProofAPIModel
     */
    pushAddress?: string;
    /**
     * 
     * @type {PaymentCompatStatusAPIModel}
     * @memberof InvoiceProofAPIModel
     */
    invoiceStatus: PaymentCompatStatusAPIModel;
    /**
     * 
     * @type {SignatureComponentsAPIModel}
     * @memberof InvoiceProofAPIModel
     */
    proof?: SignatureComponentsAPIModel;
}

/**
 * Check if a given object implements the InvoiceProofAPIModel interface.
 */
export function instanceOfInvoiceProofAPIModel(value: object): boolean {
    if (!('invoiceId' in value)) return false;
    if (!('sequenceNumber' in value)) return false;
    if (!('invoiceStatus' in value)) return false;
    return true;
}

export function InvoiceProofAPIModelFromJSON(json: any): InvoiceProofAPIModel {
    return InvoiceProofAPIModelFromJSONTyped(json, false);
}

export function InvoiceProofAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceProofAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'invoiceId': json['invoiceId'],
        'sequenceNumber': json['sequenceNumber'],
        'nextPaymentAddress': json['nextPaymentAddress'] == null ? undefined : json['nextPaymentAddress'],
        'pushAddress': json['pushAddress'] == null ? undefined : json['pushAddress'],
        'invoiceStatus': PaymentCompatStatusAPIModelFromJSON(json['invoiceStatus']),
        'proof': json['proof'] == null ? undefined : SignatureComponentsAPIModelFromJSON(json['proof']),
    };
}

export function InvoiceProofAPIModelToJSON(value?: InvoiceProofAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'invoiceId': value['invoiceId'],
        'sequenceNumber': value['sequenceNumber'],
        'nextPaymentAddress': value['nextPaymentAddress'],
        'pushAddress': value['pushAddress'],
        'invoiceStatus': PaymentCompatStatusAPIModelToJSON(value['invoiceStatus']),
        'proof': SignatureComponentsAPIModelToJSON(value['proof']),
    };
}

