import { Space } from 'antd';
import { useMemo } from 'react';

import { DocumentationLink, FormattedMessage } from '@/components';
import { OperationCreateAPIKey, OperationRefreshAPIKeys } from '@/features/company-settings/components';
import { useAPIKeys } from '@/features/company-settings/hooks';
import type { APIKey } from '@/features/company-settings/types';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { useSelectedNetwork } from '@/features/dictionary/blockchain/hooks';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import { mapStoredState } from '@/infrastructure/model';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withAPIKeysDataLoading = <
  Original extends { data: APIKey[] } & TestableProps,
  Wrapper extends Omit<Original, 'data'>,
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithAPIKeysDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const { data: baseData, loading, forceRefresh } = useAPIKeys();
    const { network } = useSelectedNetwork();
    const data = useMemo(
      () => mapStoredState(baseData, (keys) => keys.filter((key) => key.network === network)),
      [baseData, network],
    );

    return withCardDataLoading<APIKey[], Original>({
      forceRefresh,
      loading,
      data,
      'data-test': dataTest,
      title: (
        <Space>
          <FormattedMessage id={I18nPageSettings.INTEGRATION_COMPONENTS_API_KEYS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-selectNetwork`} />
        </Space>
      ),
      CardProps: {
        extra: (
          <Space>
            <DocumentationLink
              data-test={dataTest && `${dataTest}-doc`}
              path="/getting-started/api-keys"
              mode="text_icon"
            />
            <OperationCreateAPIKey key="create" data-test={dataTest && `${dataTest}-createOp`} mode="button" />
            <OperationRefreshAPIKeys data-test={dataTest && `${dataTest}-refreshOp`} mode="inline" />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withAPIKeysDataLoading;
