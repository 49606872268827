import { createAction } from '@reduxjs/toolkit';
import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { makeSelectStatisticsAsset } from '@/features/company-settings/selectors';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import { makeSelectIsFeatureEnabled } from '@/features/feature-toggle/selectors';
import { fetchSubscriptionPlans } from '@/features/subscription-plans/actions';
import { mockChurnRate, mockMonthlyGrowthRate, mockMonthlyRevenue } from '@/features/subscription-statistics/mock';
import type { CommonLoadingState } from '@/infrastructure/model';
import { loadingDataError, mapLoadingState } from '@/infrastructure/model';
import { withAPICall } from '@/infrastructure/model/api';
import { createLoadingDataActions } from '@/infrastructure/model/common/actions';

import { queryChurnRate, queryMonthlyGrowthRate, queryMonthlyRevenue } from './api';
import {
  makeSelectSubscriptionChurnRate,
  makeSelectSubscriptionMonthlyGrowthRate,
  makeSelectSubscriptionMonthlyRevenue,
  makeSelectSubscriptionStatisticsAsset,
} from './selectors';
import { NAMESPACE } from './types';

import type { SubscriptionChurnRate, SubscriptionGrowthRate, SubscriptionRevenue } from './types';

export const { storeChurnRate, markChurnRateDirty } = createLoadingDataActions<SubscriptionChurnRate, 'ChurnRate'>(
  NAMESPACE,
  'ChurnRate',
);

const churnRateFetchLimit = pLimit(1);
export const fetchChurnRate = createAppAsyncThunk(
  `${NAMESPACE}/fetchChurnRate`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    churnRateFetchLimit(async () => {
      const saved = makeSelectSubscriptionChurnRate()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }
      const data = await (async () => {
        const isMocked = makeSelectIsFeatureEnabled('enableSubscriptionStatisticsMock')(getState());
        if (isMocked) {
          try {
            const planState = await dispatch(fetchSubscriptionPlans({})).unwrap();
            return mapLoadingState(planState, (plans) => mockChurnRate(plans.data.map(({ id }) => id)));
          } catch (e) {
            console.warn(e);
            return loadingDataError<SubscriptionChurnRate>('unable to create mock');
          }
        } else {
          const network = makeSelectSelectedNetwork()(getState());
          return withAPICall(queryChurnRate, 'unable to fetch churn rate')(network, { signal });
        }
      })();

      dispatch(storeChurnRate(data));

      return makeSelectSubscriptionChurnRate()(getState());
    }),
);

export const { storeGrowthRate, markGrowthRateDirty } = createLoadingDataActions<SubscriptionGrowthRate, 'GrowthRate'>(
  NAMESPACE,
  'GrowthRate',
);

const growthRateFetchLimit = pLimit(1);
export const fetchGrowthRate = createAppAsyncThunk(
  `${NAMESPACE}/fetchGrowthRate`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    growthRateFetchLimit(async () => {
      const saved = makeSelectSubscriptionMonthlyGrowthRate()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }
      const data = await (async () => {
        const isMocked = makeSelectIsFeatureEnabled('enableSubscriptionStatisticsMock')(getState());
        if (isMocked) {
          try {
            const planState = await dispatch(fetchSubscriptionPlans({})).unwrap();
            return mapLoadingState(planState, (plans) => mockMonthlyGrowthRate(plans.data.map(({ id }) => id)));
          } catch (e) {
            console.warn(e);
            return loadingDataError<SubscriptionGrowthRate>('unable to create mock');
          }
        } else {
          const network = makeSelectSelectedNetwork()(getState());
          return withAPICall(queryMonthlyGrowthRate, 'unable to fetch churn rate')(network, { signal });
        }
      })();

      dispatch(storeGrowthRate(data));

      return makeSelectSubscriptionMonthlyGrowthRate()(getState());
    }),
);

export const { markRevenueDirty } = createLoadingDataActions<SubscriptionRevenue, 'Revenue'>(NAMESPACE, 'Revenue');
export const storeRevenue = createAction<{
  asset?: string;
  data: CommonLoadingState<SubscriptionRevenue>;
}>(`${NAMESPACE}/storeRevenue`);

const revenueFetchLimit = pLimit(1);
export const fetchRevenue = createAppAsyncThunk(
  `${NAMESPACE}/fetchRevenue`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    revenueFetchLimit(async () => {
      const saved = makeSelectSubscriptionMonthlyRevenue()(getState());
      const asset = makeSelectSubscriptionStatisticsAsset()(getState());
      const balanceAsset = makeSelectStatisticsAsset()(getState());
      if (!force && !saved.isDirty && asset === balanceAsset.data) {
        return saved;
      }
      const data = await (async () => {
        const isMocked = makeSelectIsFeatureEnabled('enableSubscriptionStatisticsMock')(getState());
        if (isMocked) {
          try {
            const planState = await dispatch(fetchSubscriptionPlans({})).unwrap();
            return mapLoadingState(planState, (plans) =>
              mockMonthlyRevenue(
                plans.data.map(({ id }) => id),
                balanceAsset.data || 'USD',
              ),
            );
          } catch (e) {
            console.warn(e);
            return loadingDataError<SubscriptionRevenue>('unable to create mock');
          }
        } else {
          const network = makeSelectSelectedNetwork()(getState());
          return withAPICall(queryMonthlyRevenue, 'unable to fetch churn rate')(network, { signal });
        }
      })();

      dispatch(storeRevenue({ asset: balanceAsset.data, data }));

      return makeSelectSubscriptionMonthlyRevenue()(getState());
    }),
);
