/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { CompanyBrandingProfileMetaAPIModel } from './CompanyBrandingProfileMetaAPIModel';
import {
    CompanyBrandingProfileMetaAPIModelFromJSON,
    CompanyBrandingProfileMetaAPIModelFromJSONTyped,
    CompanyBrandingProfileMetaAPIModelToJSON,
} from './CompanyBrandingProfileMetaAPIModel';

/**
 * 
 * @export
 * @interface CompanyBrandingResponseAPIModel
 */
export interface CompanyBrandingResponseAPIModel {
    /**
     * 
     * @type {Array<CompanyBrandingProfileMetaAPIModel>}
     * @memberof CompanyBrandingResponseAPIModel
     */
    profiles?: Array<CompanyBrandingProfileMetaAPIModel>;
    /**
     * 
     * @type {string}
     * @memberof CompanyBrandingResponseAPIModel
     */
    activeProfile?: string;
}

/**
 * Check if a given object implements the CompanyBrandingResponseAPIModel interface.
 */
export function instanceOfCompanyBrandingResponseAPIModel(value: object): boolean {
    return true;
}

export function CompanyBrandingResponseAPIModelFromJSON(json: any): CompanyBrandingResponseAPIModel {
    return CompanyBrandingResponseAPIModelFromJSONTyped(json, false);
}

export function CompanyBrandingResponseAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyBrandingResponseAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'profiles': json['profiles'] == null ? undefined : ((json['profiles'] as Array<any>).map(CompanyBrandingProfileMetaAPIModelFromJSON)),
        'activeProfile': json['activeProfile'] == null ? undefined : json['activeProfile'],
    };
}

export function CompanyBrandingResponseAPIModelToJSON(value?: CompanyBrandingResponseAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'profiles': value['profiles'] == null ? undefined : ((value['profiles'] as Array<any>).map(CompanyBrandingProfileMetaAPIModelToJSON)),
        'activeProfile': value['activeProfile'],
    };
}

