import React, { useCallback } from 'react';

import { DataFetchError, FormattedMessageWithMarkup, Operation, PageLoading, SupportEmail } from '@/components';
import { useAuthActions } from '@/features/auth/hooks';
import { useCompanies } from '@/features/company/hooks';
import { useUser } from '@/features/user/hooks';
import { I18nFeatureCompany } from '@/generated/i18n/i18n';
import { withSuppressError } from '@/infrastructure/utils/functions';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';
import MessageLayout from '@/layouts/MessageLayout';

const withInvitedUserGuard = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
  namedHOC(
    Component,
    'WithInvitedUserGuard',
  )((props) => {
    const userState = useUser();
    const companiesState = useCompanies();

    const { logout } = useAuthActions();
    const logoutLink = useCallback(
      (val: React.ReactNode) => (
        <Operation title={val} onClick={withSuppressError(logout.act)} icon={null} mode="link" />
      ),
      [logout.act],
    );
    const forceRefresh = useCallback(
      async () => Promise.all([userState.forceRefresh(), companiesState.forceRefresh()]),
      [companiesState, userState],
    );
    const refreshLink = useCallback(
      (val: React.ReactNode) => (
        <Operation title={val} onClick={withSuppressError(forceRefresh)} icon={null} mode="link" />
      ),
      [forceRefresh],
    );

    if (
      userState.data.isDirty
      || companiesState.data.isDirty
      || (!userState.data.data && userState.loading)
      || (!companiesState.data.data && companiesState.loading)
    ) {
      return (
        <MessageLayout>
          <PageLoading type="WithInvitedUserGuard" />
        </MessageLayout>
      );
    }

    if (userState.data.error && companiesState.data.error) {
      return (
        <MessageLayout>
          <DataFetchError
            description={
              <FormattedMessageWithMarkup
                id={I18nFeatureCompany.HOCS_INVITED_USER_GUARD_LOAD_ERROR}
                values={{
                  logout: logoutLink,
                  retry: refreshLink,
                  email: (title: React.ReactNode) => <SupportEmail title={title} />,
                }}
              />
            }
          />
        </MessageLayout>
      );
    }

    if (userState.data.data?.invitedBy && !companiesState.data.data?.length) {
      return (
        <MessageLayout>
          <DataFetchError
            description={
              <FormattedMessageWithMarkup
                id={I18nFeatureCompany.HOCS_INVITED_USER_GUARD_INVITED_ON_COMPANY_ERROR}
                values={{
                  logout: logoutLink,
                  retry: refreshLink,
                  email: (title: React.ReactNode) => <SupportEmail title={title} />,
                }}
              />
            }
          />
        </MessageLayout>
      );
    }

    return <Component {...props} />;
  });

export default withInvitedUserGuard;
