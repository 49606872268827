import { Descriptions } from 'antd';
import React from 'react';

import { FormattedMessage, ReadonlyDate } from '@/components';
import { AssetLabel } from '@/features/dictionary/blockchain/components';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';

import SettlementIntentStatusBanner from '../SettlementIntentStatusBanner';
import SettlementIntentStatusView from '../SettlementIntentStatusView';

import type { SettlementIntentViewProps } from './types';

const SettlementIntentView: React.FC<SettlementIntentViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = { xl: 2, lg: 1, md: 1, sm: 1, xs: 1 },
}) => (
  <Descriptions
    style={style}
    className={className}
    contentStyle={{ fontWeight: 'bold', overflow: 'hidden' }}
    title={<SettlementIntentStatusView value={data.status} data-test={dataTest && `${dataTest}-status`} />}
    column={columns}
  >
    <Descriptions.Item>
      <SettlementIntentStatusBanner data-test={dataTest && `${dataTest}-intent`} intent={data} mode="alert" />
    </Descriptions.Item>
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_ASSET} />}
    >
      <AssetLabel data-test={dataTest && `${dataTest}-asset`} value={data.asset} mode="medium" />
    </Descriptions.Item>
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_PROCESS_AT} />}
    >
      <ReadonlyDate value={data.processAt} data-test={dataTest && `${dataTest}-processAt`} />
    </Descriptions.Item>
  </Descriptions>
);

const SettlementIntentViewMemo = React.memo(SettlementIntentView);

export default SettlementIntentViewMemo;
