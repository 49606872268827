/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { DonationAPIModel } from './DonationAPIModel';
import {
    DonationAPIModelFromJSON,
    DonationAPIModelFromJSONTyped,
    DonationAPIModelToJSON,
} from './DonationAPIModel';
import type { PageDonationSortByAPIModel } from './PageDonationSortByAPIModel';
import {
    PageDonationSortByAPIModelFromJSON,
    PageDonationSortByAPIModelFromJSONTyped,
    PageDonationSortByAPIModelToJSON,
} from './PageDonationSortByAPIModel';

/**
 * 
 * @export
 * @interface SliceDonationDonationSortByAPIModel
 */
export interface SliceDonationDonationSortByAPIModel {
    /**
     * 
     * @type {Array<DonationAPIModel>}
     * @memberof SliceDonationDonationSortByAPIModel
     */
    list?: Array<DonationAPIModel>;
    /**
     * 
     * @type {PageDonationSortByAPIModel}
     * @memberof SliceDonationDonationSortByAPIModel
     */
    page: PageDonationSortByAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SliceDonationDonationSortByAPIModel
     */
    total?: number;
}

/**
 * Check if a given object implements the SliceDonationDonationSortByAPIModel interface.
 */
export function instanceOfSliceDonationDonationSortByAPIModel(value: object): boolean {
    if (!('page' in value)) return false;
    return true;
}

export function SliceDonationDonationSortByAPIModelFromJSON(json: any): SliceDonationDonationSortByAPIModel {
    return SliceDonationDonationSortByAPIModelFromJSONTyped(json, false);
}

export function SliceDonationDonationSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SliceDonationDonationSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(DonationAPIModelFromJSON)),
        'page': PageDonationSortByAPIModelFromJSON(json['page']),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function SliceDonationDonationSortByAPIModelToJSON(value?: SliceDonationDonationSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(DonationAPIModelToJSON)),
        'page': PageDonationSortByAPIModelToJSON(value['page']),
        'total': value['total'],
    };
}

