import { Descriptions, Space } from 'antd';
import React from 'react';

import { FormattedMessage, ReadonlyComponent } from '@/components';
import { I18nFeatureReports } from '@/generated/i18n/i18n';
import { useLocaleSettings } from '@/hooks';

import OperationDeleteReport from '../OperationDeleteReport';
import OperationDownloadReport from '../OperationDownloadReport';
import ReportStatusView from '../ReportStatusView';
import ReportTemplateTitle from '../ReportTemplateTitle';
import ReportTypeView from '../ReportTypeView';

import type { ReportViewProps } from './types';

const ReportViewRaw: React.FC<ReportViewProps> = ({
  data,
  'data-test': dataTest,
  style,
  columns = { xl: 2, lg: 1, md: 1, sm: 1, xs: 1 },
  onDelete,
}: ReportViewProps) => {
  const { formatDateTime } = useLocaleSettings();
  return (
    <Descriptions
      style={style}
      contentStyle={{ fontWeight: 'bold', overflow: 'hidden' }}
      title={<ReportStatusView value={data.status} data-test={dataTest && `${dataTest}-status`} />}
      extra={
        <Space>
          <OperationDownloadReport reportId={data.id} data-test={dataTest && `${dataTest}-opDownload`} />
          <OperationDeleteReport
            reportId={data.id}
            data-test={dataTest && `${dataTest}-opDelete`}
            onSuccess={onDelete}
          />
        </Space>
      }
      column={columns}
    >
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureReports.LABELS_ROW_TITLE_TEMPLATE} />}>
        <ReportTemplateTitle
          value={data.template}
          style={{ whiteSpace: 'nowrap' }}
          data-test={dataTest && `${dataTest}-template`}
        />
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureReports.LABELS_ROW_TITLE_TYPE} />}>
        <ReportTypeView value={data.type} data-test={dataTest && `${dataTest}-type`} mode="full" />
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureReports.LABELS_ROW_TITLE_CREATED_AT} />}>
        <ReadonlyComponent value={formatDateTime(data.createdAt)} data-test={dataTest && `${dataTest}-createdAt`} />
      </Descriptions.Item>
      {data.error && (
        <Descriptions.Item
          label={<FormattedMessage id={I18nFeatureReports.LABELS_ROW_TITLE_ERROR} />}
          span={(typeof columns === 'number' ? columns : columns.xl) ?? 2}
        >
          <ReadonlyComponent value={data.error} data-test={dataTest && `${dataTest}-error`} />
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

const ReportView = React.memo(ReportViewRaw);

export default ReportView;
