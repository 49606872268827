import { QuestionCircleOutlined } from '@ant-design/icons';
import { ProLayout } from '@ant-design/pro-components';
import { css } from '@emotion/css';
import { theme } from 'antd';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';

import { useAppSelector } from '@/app/hooks';
import LogoSmallLight from '@/assets/icons/smartypay/logo-small-light';
import { DocumentationLink } from '@/components';
import { makeSelectViewHeight } from '@/features/global/selectors';
import { I18nCommon } from '@/generated/i18n/i18n';
import type { TestableProps } from '@/infrastructure/utils/react';
import { SelectLang } from '@/layouts/components';
import { layoutBgToken } from '@/layouts/theme';

import type React from 'react';
import type { PropsWithChildren } from 'react';

type AuthLayoutProps = PropsWithChildren<TestableProps>;

const selectViewHeight = makeSelectViewHeight();

const bigScreenContentStyle = { paddingLeft: 0, paddingRight: 0 };

const AuthLayout: React.FC<AuthLayoutProps> = ({ 'data-test': dataTest, children }) => {
  const { formatMessage } = useIntl();
  const { token } = theme.useToken();
  const isBigScreen = useMediaQuery({ minWidth: token.screenXSMax });
  const height = useAppSelector(selectViewHeight);
  return (
    <ProLayout
      layout="top"
      disableMobile
      title={formatMessage({ id: I18nCommon.TITLE })}
      logo={<LogoSmallLight width={24} />}
      headerTitleRender={isBigScreen ? false : undefined}
      token={layoutBgToken}
      contentStyle={isBigScreen ? undefined : bigScreenContentStyle}
      className={css`
        height: ${100 * height}px;

        .ant-pro-top-nav-header-main {
          padding-inline-end: 16px;
        }
      `}
      actionsRender={useCallback(
        () => [
          <SelectLang key="lang" data-test={dataTest && `${dataTest}-lang`} />,
          <DocumentationLink
            key="doc"
            data-test={dataTest && `${dataTest}-docs`}
            mode="inline"
            icon={<QuestionCircleOutlined />}
            style={{ paddingLeft: 12 }}
          />,
        ],
        [dataTest],
      )}
    >
      {children}
    </ProLayout>
  );
};

export default AuthLayout;
