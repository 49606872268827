import { type LoadingStateWithDirty, type CommonLoadingState, mapLoadingState } from '@/infrastructure/model';
import type { DataSlice } from '@/infrastructure/model/api';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

export const sliceToFullData = <Value, SortBy extends string = string>({ list }: DataSlice<Value, SortBy>): Value[] =>
  list;

export const loadingSliceStateToFullData = <Value, SortBy extends string = string>(
  data: CommonLoadingState<DataSlice<Value, SortBy>>,
): CommonLoadingState<Value[]> => mapLoadingState(data, sliceToFullData);

export const mapFullDataToListData = <Value, Exc = string>(
  filteredData: LoadingStateWithDirty<Value[], Exc>,
  fullData: LoadingStateWithDirty<Value[], Exc>,
): LoadingListDataState<Value, Exc> => ({
  ...filteredData,
  isTotalDirty: filteredData.isDirty,
  data: filteredData.data
    ? { data: filteredData.data, total: fullData.data?.length ?? filteredData.data.length }
    : undefined,
});
