import BigNumber from 'bignumber.js';
import { getAddress, isHash } from 'viem';

import type { GasWallet, GasWalletDerived } from '@/features/gas-wallets/types';
import type {
  GasWalletsDetailsAPIModel,
  AddGasWalletAPIModel,
  GasWalletFullDetailsAPIModel,
  GasWalletDerivedAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { BlockchainTypeAPIModel, CompanyApi } from '@/generated/api/ncps-core/merchant-bo';
import { coreConfigurationFactory } from '@/infrastructure/api';
import { someOrFail } from '@/infrastructure/utils/functions';
import { enumByKey, notEmpty } from '@/infrastructure/utils/ts';

import type { Address } from 'viem';

const companyApi = new CompanyApi(coreConfigurationFactory('auth'));

const derivedGasWalletParser =
  (bt: BlockchainTypeAPIModel) =>
  ({ address, nativeBalance, refundHash, ...derived }: GasWalletDerivedAPIModel): GasWalletDerived => ({
    bt,
    nativeBalance: new BigNumber(nativeBalance),
    address: getAddress(address),
    refundHash: refundHash
      ? someOrFail(isHash(refundHash) ? refundHash : undefined, () => new Error('Invalid hash'))
      : undefined,
    ...derived,
  });

const gasWalletParser =
  (bt: BlockchainTypeAPIModel) =>
  ({
    derived: apiDerived,
    address,
    lowWatermark,
    refundHash,
    ...gasWallet
  }: GasWalletFullDetailsAPIModel): GasWallet => {
    const derived = (apiDerived ?? []).map(derivedGasWalletParser(bt));
    const derivedBalance = derived.reduce((r, { nativeBalance }) => r.plus(nativeBalance), new BigNumber(0));
    const isAnyOutOfService = !!derived.find(({ isOutOfService }) => isOutOfService);
    const isAllOutOfService = !derived.find(({ isOutOfService }) => !isOutOfService);
    const isAnyRefunding = gasWallet.isRefunding || !!derived.filter(({ isRefunding }) => isRefunding).length;
    const isDerivedBalanceEnough = !derivedBalance.isZero();

    return {
      ...gasWallet,
      bt,
      address: getAddress(address),
      lowWatermark: new BigNumber(lowWatermark),
      refundHash: refundHash
        ? someOrFail(isHash(refundHash) ? refundHash : undefined, () => new Error('Invalid hash'))
        : undefined,
      derived,
      derivedBalance,
      isAnyOutOfService,
      isAllOutOfService,
      isAnyRefunding,
      isDerivedBalanceEnough,
      isReadyForAction: !isAnyRefunding && isDerivedBalanceEnough && !isAllOutOfService,
    };
  };

const parseGasWallets = (wallets: GasWalletsDetailsAPIModel) =>
  Object.entries(wallets.gasWallets)
    .map(([btString, btWallet]) => {
      const bt = enumByKey(BlockchainTypeAPIModel, btString);
      return bt ? gasWalletParser(bt)(btWallet) : undefined;
    })
    .filter(notEmpty);

export const queryGasWallet = async (bt: BlockchainTypeAPIModel, initOverrides?: RequestInit) =>
  gasWalletParser(bt)(await companyApi.getGasWallet(bt, initOverrides));
export const queryGasWallets = async (initOverrides?: RequestInit) =>
  parseGasWallets(await companyApi.getGasWallets(initOverrides));

export const requestCreateGasWallet = async (newWallet: AddGasWalletAPIModel, initOverrides?: RequestInit) => {
  await companyApi.addGasWallet(newWallet, initOverrides);
  return queryGasWallet(newWallet.blockchain, initOverrides);
};
export const requestDeleteGasWallet = async (bt: BlockchainTypeAPIModel, initOverrides?: RequestInit) => {
  await companyApi.removeGasWallet(bt, initOverrides);
  return queryGasWallets(initOverrides);
};
export const requestRefundGasWallet = async (bt: BlockchainTypeAPIModel, to: Address, initOverrides?: RequestInit) => {
  await companyApi.refundGasWallet({ blockchain: bt, to }, initOverrides);
  return queryGasWallet(bt, initOverrides);
};
