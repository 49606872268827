import { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { storeDestinationsPerBatchListParameters } from '@/features/payouts/actions';
import { usePayoutBatches, usePayoutDestinations } from '@/features/payouts/hooks';
import { makeSelectDestinationsPerBatchListParameters } from '@/features/payouts/selectors';
import type {
  PayoutBatchId,
  PayoutDestination,
  PayoutDestinationFilter,
  PayoutDestinationSortByAPIModel,
} from '@/features/payouts/types';
import { combine } from '@/infrastructure/model';
import { numberComparator, stringComparator } from '@/infrastructure/model/comparators';
import type { UpdateListParametersPayload } from '@/infrastructure/model/list/types';
import { noop } from '@/infrastructure/utils/functions';
import { notEmpty } from '@/infrastructure/utils/ts';

import type { UsePayoutBatchDestinationsListView } from './types';

export default function usePayoutBatchDestinationsListView(
  id: PayoutBatchId | undefined,
): UsePayoutBatchDestinationsListView {
  const { dispatch } = useAppDispatch();
  const destinationsState = usePayoutDestinations(id?.payoutId);
  const batchesState = usePayoutBatches(id?.payoutId);
  const forceRefresh = useCallback(
    async () => Promise.all([destinationsState.forceRefresh(), batchesState.forceRefresh()]),
    [batchesState, destinationsState],
  );
  const loading = destinationsState.loading || batchesState.loading;
  const parametersSelector = useMemo(() => makeSelectDestinationsPerBatchListParameters(id), [id]);
  const parameters = useAppSelector(parametersSelector);
  const data = useMemo(
    () => ({
      ...combine(destinationsState.data, batchesState.data, (destinations, batches) => {
        const sorter = parameters.sortBy.Address
          ? stringComparator(({ address }: PayoutDestination) => address)(parameters.sortBy.Address)
          : numberComparator(({ num }: PayoutDestination) => num)(parameters.sortBy.Num ?? 'DESC');

        const filter = (data: PayoutDestination) =>
          (!parameters.filter.addressLike
            || data.address.toLowerCase().includes(parameters.filter.addressLike.toLowerCase()))
          && (!parameters.filter.amountRange?.gte || data.amount.value.gte(parameters.filter.amountRange.gte))
          && (!parameters.filter.amountRange?.lte || data.amount.value.lte(parameters.filter.amountRange.lte));
        const batch = batches.find(({ num }) => num === id?.batchNum);
        const data = batch
          ? destinations
              .filter(({ batchNum }) => batchNum === batch.num)
              .filter(notEmpty)
              .filter(filter)
              .sort(sorter)
          : [];
        return {
          data,
          total: data.length,
        };
      }),
      isTotalDirty: false,
    }),
    [
      batchesState.data,
      destinationsState.data,
      id?.batchNum,
      parameters.filter.addressLike,
      parameters.filter.amountRange?.gte,
      parameters.filter.amountRange?.lte,
      parameters.sortBy.Address,
      parameters.sortBy.Num,
    ],
  );

  const updateParameters = useCallback(
    (parameters: UpdateListParametersPayload<PayoutDestinationFilter, PayoutDestinationSortByAPIModel>) =>
      id ? dispatch(storeDestinationsPerBatchListParameters({ parentId: id, parameters })) : noop(),
    [dispatch, id],
  );

  return {
    ...parameters,
    loading,
    forceRefresh,
    data,
    updateParameters,
  };
}
