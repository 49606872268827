import { css } from '@emotion/css';
import { theme } from 'antd';
import { useMemo } from 'react';

export function useSharedStyles() {
  const { token } = theme.useToken();
  return useMemo(
    () => ({
      prefixIcon: css`
        padding-top: 2px;
        color: #1890ff;
        font-size: ${token.fontSize};
      `,
      disabledTextInput: css`
        color: black !important;

        > input {
          color: black !important;
        }

        > div {
          color: black !important;
        }
      `,
      modalCard: css`
        margin: ${token.marginLG}px auto 0px;
        align-self: center;
        border-radius: 8px;
        width: ${token.screenXS}px;
        @media (max-width: ${token.screenXSMax}px) {
          width: 98%;
          margin: 0 auto 0;
        }

        .icon {
          margin-left: 16px;
          color: ${token.colorTextSecondary};
          font-size: 24px;
          vertical-align: middle;
          cursor: pointer;
          transition: color 0.3s;

          &:hover {
            color: ${token.colorPrimary};
          }
        }

        .other {
          margin-top: ${token.margin};
          line-height: 22px;
          text-align: left;

          .register {
            float: right;
          }
        }
      `,
    }),
    [
      token.colorPrimary,
      token.colorTextSecondary,
      token.fontSize,
      token.margin,
      token.marginLG,
      token.screenXS,
      token.screenXSMax,
    ],
  );
}

export default useSharedStyles;
