import { ErrorPage } from '@/components';
import type { TestableProps } from '@/infrastructure/utils/react';

import type React from 'react';

export const ParamsNotFound: React.FC<TestableProps> = ({ 'data-test': dataTest }) => (
  <ErrorPage data-test={dataTest} type="404" />
);

export default ParamsNotFound;
