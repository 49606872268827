import { ReadOutlined } from '@ant-design/icons';
import { useBreakpoint } from '@ant-design/pro-components';
import React from 'react';

import FormattedMessage from '@/components/FormattedMessage';
import Link from '@/components/Link';
import { I18nComponents } from '@/generated/i18n/i18n';

import type { DocumentationLinkProps, DocumentationSection } from './types';

const sectionPaths: Record<DocumentationSection, string> = {
  'api-webhook': '/api/webhooks',
  settlement: '/guides/funds-withdraw',
};

const DocumentationLink: React.FC<DocumentationLinkProps> = ({
  'data-test': dataTest,
  title,
  mode: baseMode = 'text',
  path: basePath,
  section,
  icon,
  className,
  style,
}) => {
  const breakpoint = useBreakpoint();
  const mode = baseMode === 'text_icon' && breakpoint === 'xs' ? 'icon' : baseMode;
  const to = `${window.env.NCPS_DOCS_BASE_URL}${(section ? sectionPaths[section] : basePath) ?? ''}`;
  return (
    <Link
      className={className}
      style={style}
      icon={icon ?? <ReadOutlined />}
      mode={mode}
      title={title ?? <FormattedMessage id={I18nComponents.DOCUMENTATION_LINK_TITLE} />}
      to={to}
      data-test={dataTest}
      external
    />
  );
};

const DocumentationLinkMemo = React.memo(DocumentationLink);

export default DocumentationLinkMemo;
