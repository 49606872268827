import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { extractMerchantWalletTransferId } from '@/features/merchant-wallet-transfers/utils';
import { markReportListDirty, storeReport } from '@/features/reports/actions';
import type { MerchantWalletTransferSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { mapLoadingState } from '@/infrastructure/model';
import { defaultPageFn, withAPICall } from '@/infrastructure/model/api';
import { createNormalizedListActions } from '@/infrastructure/model/list/actions';
import { listStateToSliceRequest, mapLoadingSliceStateToListData } from '@/infrastructure/model/list/utils';
import { toMultiplePayload } from '@/infrastructure/model/single/utils';

import { queryMerchantWalletTransfers, queryMerchantWalletTransfer, requestExportMerchantWalletTransfers } from './api';
import {
  makeSelectDirtyMerchantWalletTransferIds,
  makeSelectMultipleMerchantWalletTransfer,
  makeSelectMerchantWalletTransfer,
  makeSelectMerchantWalletTransferListData,
  makeSelectMerchantWalletTransferListParameters,
} from './selectors';
import { NAMESPACE, type MerchantWalletTransfer, type MerchantWalletTransferFilterPredicate } from './types';

export const {
  storeMerchantWalletTransfer,
  storeMultipleMerchantWalletTransfer,
  markMerchantWalletTransferDirty,
  storeMerchantWalletTransferListParameters,
  storeMerchantWalletTransferListData,
  markMerchantWalletTransferListDirty,
} = createNormalizedListActions<
  MerchantWalletTransfer,
  'MerchantWalletTransfer',
  MerchantWalletTransferFilterPredicate,
  MerchantWalletTransferSortByAPIModel
>(NAMESPACE, 'MerchantWalletTransfer' as const);

const transferFetchLimit = pLimit(1);
export const fetchMerchantWalletTransfer = createAppAsyncThunk(
  `${NAMESPACE}/fetchMerchantWalletTransfer`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    transferFetchLimit(async () => {
      const saved = makeSelectMerchantWalletTransfer(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryMerchantWalletTransfer, 'unable to fetch merchant')(id, { signal });
      dispatch(storeMerchantWalletTransfer({ id, data }));

      return makeSelectMerchantWalletTransfer(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

const transfersFetchLimit = pLimit(1);
export const fetchMultipleMerchantWalletTransfer = createAppAsyncThunk(
  `${NAMESPACE}/fetchMultipleMerchantWalletTransfer`,
  async ({ force, ids }: { force?: boolean; ids: string[] }, { dispatch, getState, signal }) =>
    transfersFetchLimit(async () => {
      const absent = makeSelectDirtyMerchantWalletTransferIds(ids)(getState());
      if (!force && !absent.length) {
        return makeSelectMultipleMerchantWalletTransfer(ids)(getState());
      }

      const data = await withAPICall(queryMerchantWalletTransfers, 'unable to fetch merchant')(
        { filter: { idIn: ids }, page: defaultPageFn({ perPage: ids.length }) },
        {
          signal,
        },
      );
      dispatch(
        storeMultipleMerchantWalletTransfer(
          toMultiplePayload(
            mapLoadingState(data, ({ list }) => list),
            ids,
            extractMerchantWalletTransferId,
          ),
        ),
      );

      return makeSelectMultipleMerchantWalletTransfer(ids)(getState());
    }),
);

const merchantWalletTransfersFetchLimit = pLimit(1);
export const fetchMerchantWalletTransfers = createAppAsyncThunk(
  `${NAMESPACE}/fetchMerchantWalletTransfers`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    merchantWalletTransfersFetchLimit(async () => {
      const saved = makeSelectMerchantWalletTransferListData()(getState());
      if (!force && !saved.isDirty && !saved.isTotalDirty) {
        return saved;
      }

      const data = await withAPICall(queryMerchantWalletTransfers, 'unable to fetch transfers')(
        listStateToSliceRequest({ data: saved, ...makeSelectMerchantWalletTransferListParameters()(getState()) }),
        { signal },
      );
      dispatch(storeMerchantWalletTransferListData(mapLoadingSliceStateToListData(saved.data?.total)(data)));

      return makeSelectMerchantWalletTransferListData()(getState());
    }),
);

export const exportMerchantWalletTransfers = createAppAsyncThunk(
  `${NAMESPACE}/exportMerchantWalletTransfers`,
  async ({ predicates }: { predicates: MerchantWalletTransferFilterPredicate }, { dispatch, signal }) => {
    const data = await withAPICall(requestExportMerchantWalletTransfers, 'unable to export transfers')(predicates, {
      signal,
    });

    if (data.data) {
      dispatch(storeReport({ id: data.data.id, data }));
      dispatch(markReportListDirty());
    }
    return data;
  },
);
