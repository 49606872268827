import type {
  BlockchainTypeAPIModel,
  MerchantWalletSignatureCreateAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { UserApi } from '@/generated/api/ncps-core/merchant-bo';
import { coreConfigurationFactory } from '@/infrastructure/api';

const userAPI = new UserApi(coreConfigurationFactory('auth'));

export const queryMerchantWallets = async (initOverrides?: RequestInit) =>
  (await userAPI.getUserWallets(initOverrides)).wallets ?? [];

export const queryOwnedCompanyWalletSignature = async (initOverrides?: RequestInit) =>
  await userAPI.getOwnedCompanyWalletSignature(initOverrides);

export const requestSaveOwnedCompanyWalletSignature = async (
  signature: MerchantWalletSignatureCreateAPIModel,
  initOverrides?: RequestInit,
) => await userAPI.confirmOwnedCompanyWalletOwnership(signature, initOverrides);

export const requestHintWalletDeployed = async (blockchain: BlockchainTypeAPIModel, initOverrides?: RequestInit) =>
  // eslint-disable-next-line @typescript-eslint/no-deprecated
  await userAPI.hintWalletDeployed({ blockchain }, initOverrides);
