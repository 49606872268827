import { createReducer } from '@reduxjs/toolkit';

import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { merchantWalletIdToStoreKey } from '@/features/merchant-wallets/utils';
import { storedDirtyData } from '@/infrastructure/model';
import { createLoadingDataReducers } from '@/infrastructure/model/common/reducers';
import { createSingleReducers } from '@/infrastructure/model/single/reducers';

import {
  markMerchantWalletDeploymentDirty,
  markMerchantWalletOwnershipDirty,
  markMerchantWalletsDirty,
  storeMerchantWalletDeployment,
  storeMerchantWalletOwnership,
  storeMerchantWallets,
} from './actions';
import { type MerchantWalletsState } from './types';

import type { Draft } from 'immer';

const initialState: MerchantWalletsState = {
  deployments: {},
  ownership: storedDirtyData,
  wallets: storedDirtyData,
};

const { storeMerchantWalletDeploymentReducer, markMerchantWalletDeploymentDirtyReducer } = createSingleReducers(
  'MerchantWalletDeployment',
  (state: Draft<MerchantWalletsState>) => state.deployments,
  (global, deployments) => ({ ...global, deployments }),
  merchantWalletIdToStoreKey,
);

const { storeMerchantWalletOwnershipReducer, markMerchantWalletOwnershipDirtyReducer } = createLoadingDataReducers(
  'MerchantWalletOwnership',
  (state: Draft<MerchantWalletsState>) => state.ownership,
  (global, ownership) => ({ ...global, ownership }),
);

const { storeMerchantWalletsReducer, markMerchantWalletsDirtyReducer } = createLoadingDataReducers(
  'MerchantWallets',
  (state: Draft<MerchantWalletsState>) => state.wallets,
  (state, wallets) => ({ ...state, wallets }),
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markMerchantWalletDeploymentDirty, markMerchantWalletDeploymentDirtyReducer)
    .addCase(storeMerchantWalletDeployment, storeMerchantWalletDeploymentReducer)

    .addCase(markMerchantWalletOwnershipDirty, markMerchantWalletOwnershipDirtyReducer)
    .addCase(storeMerchantWalletOwnership, storeMerchantWalletOwnershipReducer)

    .addCase(markMerchantWalletsDirty, markMerchantWalletsDirtyReducer)
    .addCase(storeMerchantWallets, storeMerchantWalletsReducer)

    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address ? initialState : state,
    );
});

export default reducer;
