import React, { useCallback, useMemo } from 'react';

import {
  FormattedMessage,
  NCPSTable,
  OperationDateTimeRelativeQuickFilter,
  ReadonlyComponent,
  ReadonlyDateTime,
  StopPropagationContainer,
  TitleWithDetails,
} from '@/components';
import { AssetAmount } from '@/features/dictionary/blockchain/components';
import PayoutStatusView from '@/features/payouts/components/PayoutStatusView';
import PayoutView from '@/features/payouts/components/PayoutView';
import type { PayoutSummary } from '@/features/payouts/types';
import { PayoutSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nComponents, I18nFeaturePayouts } from '@/generated/i18n/i18n';
import { useDefaultNotification, useTableButtonsMode } from '@/hooks';
import { sortOrderToTable } from '@/infrastructure/model/view';
import { nameof } from '@/infrastructure/utils/ts';

import type { BasePayoutFilter, PayoutsTableProps } from './types';
import type { ProColumns } from '@ant-design/pro-table';

const sortMapping = {
  [nameof<PayoutSummary>('createdAt')]: PayoutSortByAPIModel.CreatedAt,
};
const extractKey = ({ id }: PayoutSummary) => id;
const sortColumnToModel = (s: string) => sortMapping[s];

const PayoutsTable = <FilterType extends BasePayoutFilter = BasePayoutFilter>({
  'data-test': dataTest,
  data,
  columnState,
  filter,
  updateParameters,
  loading,
  forceRefresh,
  page,
  sortBy,
  ExportOperation,
  FilterForm,
  PayoutLink,
  Operations,
}: PayoutsTableProps<FilterType>) => {
  const { withDefaultError } = useDefaultNotification();

  const reload = useMemo(() => withDefaultError(forceRefresh), [forceRefresh, withDefaultError]);

  const columns: ProColumns<PayoutSummary>[] = useMemo(
    () => [
      {
        key: nameof<PayoutSummary>('title'),
        title: <FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_PAYOUT_TITLE} />,
        dataIndex: nameof<PayoutSummary>('title'),
        render: (_, { id, title }) => (
          <ReadonlyComponent data-test={dataTest && `${dataTest}-${id}-title`} value={title} copyable={false} />
        ),
      },
      {
        key: nameof<PayoutSummary>('amount'),
        title: <FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_PAYOUT_TOTAL_AMOUNT} />,
        dataIndex: nameof<PayoutSummary>('amount'),
        render: (_, { id, amount }) => (
          <ReadonlyComponent
            value={
              <AssetAmount
                assetId={amount.asset}
                value={amount.value}
                withBlockchainMark
                withCurrency
                data-test={dataTest && `${dataTest}-${id}-amount`}
              />
            }
            copyable={false}
          />
        ),
      },
      {
        key: nameof<PayoutSummary>('amount'),
        title: <FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_PAYOUT_SETTLED_AMOUNT} />,
        dataIndex: nameof<PayoutSummary>('amount'),
        render: (_, { id, settledAmount }) => (
          <ReadonlyComponent
            value={
              <AssetAmount
                assetId={settledAmount.asset}
                value={settledAmount.value}
                withBlockchainMark
                withCurrency
                data-test={dataTest && `${dataTest}-${id}-settledAmount`}
              />
            }
            copyable={false}
          />
        ),
      },
      {
        key: nameof<PayoutSummary>('createdAt'),
        title: <FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_PAYOUT_CREATED_AT} />,
        dataIndex: nameof<PayoutSummary>('createdAt'),
        sortOrder: sortOrderToTable(sortBy.CreatedAt),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        valueType: 'dateTime',
        render: (_, { id, createdAt }) => (
          <ReadonlyDateTime value={createdAt} data-test={dataTest && `${dataTest}-${id}-createdAt`} />
        ),
        width: 160,
      },
      {
        key: nameof<PayoutSummary>('expiresAt'),
        title: <FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_PAYOUT_EXPIRES_AT} />,
        dataIndex: nameof<PayoutSummary>('expiresAt'),
        valueType: 'dateTime',
        render: (_, { id, expiresAt }) => (
          <ReadonlyDateTime value={expiresAt} data-test={dataTest && `${dataTest}-${id}-expiresAt`} />
        ),
        width: 160,
      },
      {
        key: nameof<PayoutSummary>('destinationsCount'),
        title: <FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_PAYOUT_DESTINATION_COUNT} />,
        dataIndex: nameof<PayoutSummary>('destinationsCount'),
        render: (_, { id, destinationsCount }) => (
          <ReadonlyComponent
            data-test={dataTest && `${dataTest}-${id}-destinations`}
            value={destinationsCount}
            copyable={false}
          />
        ),
      },
      {
        key: nameof<PayoutSummary>('status'),
        title: <FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_PAYOUT_STATUS} />,
        dataIndex: nameof<PayoutSummary>('status'),
        hideInSetting: true,
        align: 'center',
        fixed: 'right',
        render: (_, { id, status }) => (
          <PayoutStatusView value={status} data-test={dataTest && `${dataTest}-${id}-status`} mode="icon" />
        ),
        width: 60,
      },
      {
        key: 'options',
        title: <FormattedMessage id={I18nComponents.TABLE_ROW_OPTIONS} />,
        width: 1,
        fixed: 'right',
        render: (_, value) => (
          <StopPropagationContainer>
            {PayoutLink && <PayoutLink value={value.id} data-test={dataTest && `${dataTest}-${value.id}-link`} />}
            {Operations && <Operations value={value} data-test={dataTest && `${dataTest}-${value.id}-op`} />}
          </StopPropagationContainer>
        ),
      },
    ],
    [Operations, PayoutLink, dataTest, sortBy.CreatedAt],
  );

  const preview = useCallback(
    (value: PayoutSummary) => ({
      title: (
        <TitleWithDetails
          title={
            <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_PAYOUT_TABLE_DIALOG_TITLE} values={{ id: value.id }} />
          }
          link={
            PayoutLink && (
              <PayoutLink
                value={value.id}
                mode="text"
                title={<FormattedMessage id={I18nComponents.TABLE_LINK_DETAILS} values={{ id: value.id }} />}
              />
            )
          }
          data-test={dataTest && `${dataTest}-dialogTitle`}
        />
      ),
      content: (
        <PayoutView
          data={value}
          data-test={dataTest && `${dataTest}-view`}
          style={{ paddingTop: '10px' }}
          columns={1}
        />
      ),
    }),
    [PayoutLink, dataTest],
  );

  const { ref, quickFilterMode, exportMode } = useTableButtonsMode();
  const toolBar = useMemo(
    () =>
      FilterForm
        ? (filterComponent?: React.ReactNode) => [
            ...(ExportOperation
              ? [
                  <ExportOperation
                    key="export"
                    data-test={dataTest && `${dataTest}-export`}
                    mode={exportMode}
                    rows={data.data?.total}
                  />,
                ]
              : []),
            <OperationDateTimeRelativeQuickFilter<'createdAtRange', 'createdAtRelative', FilterType>
              key="quickFilter"
              absoluteKey={nameof<FilterType, 'createdAtRange'>('createdAtRange')}
              relativeKey={nameof<FilterType, 'createdAtRelative'>('createdAtRelative')}
              onChange={(newValue) => updateParameters({ filter: newValue })}
              mode={quickFilterMode}
              value={filter}
              disabled={loading}
            />,
            filterComponent,
          ]
        : undefined,
    [
      ExportOperation,
      FilterForm,
      dataTest,
      exportMode,
      data.data?.total,
      quickFilterMode,
      filter,
      loading,
      updateParameters,
    ],
  );

  const filterForm = useMemo(
    () => (FilterForm ? { Form: FilterForm, empty: {} as FilterType } : undefined),
    [FilterForm],
  );

  return (
    <div ref={ref}>
      <NCPSTable<PayoutSummary, FilterType, PayoutSortByAPIModel>
        data-test={dataTest}
        filter={filterForm}
        preview={preview}
        toolBarRender={toolBar}
        sortColumnToModel={sortColumnToModel}
        columns={columns}
        data={data}
        loading={loading}
        page={page}
        filterData={filter}
        columnsState={columnState}
        reload={reload}
        updateParameters={updateParameters}
        extractKey={extractKey}
      />
    </div>
  );
};

const PayoutsTableMemo = React.memo(PayoutsTable);

export default PayoutsTableMemo;
