/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum PushAddressSortByAPIModel {
    CreatedAt = 'CreatedAt'
}


export function PushAddressSortByAPIModelFromJSON(json: any): PushAddressSortByAPIModel {
    return PushAddressSortByAPIModelFromJSONTyped(json, false);
}

export function PushAddressSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushAddressSortByAPIModel {
    return json as PushAddressSortByAPIModel;
}

export function PushAddressSortByAPIModelToJSON(value?: PushAddressSortByAPIModel | null): any {
    return value as any;
}

