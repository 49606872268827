import { useCallback } from 'react';

import type { MerchantWalletDeploymentState } from '@/features/merchant-wallets/types';
import { useWeb3Connector } from '@/features/web3/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { HookAction } from '@/infrastructure/model';
import { someOrFail } from '@/infrastructure/utils/functions';

import useMerchantWalletActions from '../useMerchantWalletActions';

import type { DeployUnavailabilityReason } from '../useMerchantWalletActions';

export interface UseMerchantWalletWeb3ActionsType {
  deploy: HookAction<[], MerchantWalletDeploymentState, DeployUnavailabilityReason>;
}

export default function useMerchantWalletWeb3Actions(
  bt: BlockchainTypeAPIModel | undefined,
): UseMerchantWalletWeb3ActionsType {
  const { client, chainId } = useWeb3Connector();
  const { deploy: deployAction } = useMerchantWalletActions(bt, chainId);
  const deploy = {
    ...deployAction,
    act: useCallback(async () => deployAction.act(someOrFail(client)), [client, deployAction]),
  };

  return { deploy };
}
