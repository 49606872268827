import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { fetchWeb3CompatBlockchainAPI } from '@/features/dictionary/blockchain/actions';
import type { MerchantWalletSignatureCreateAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { mapLoadingState } from '@/infrastructure/model';
import { withAPICall } from '@/infrastructure/model/api';
import { createLoadingDataActions } from '@/infrastructure/model/common/actions';
import { createSingleActions } from '@/infrastructure/model/single/actions';
import { callWithThunkError } from '@/infrastructure/utils/redux';

import { queryMerchantWallets, queryOwnedCompanyWalletSignature, requestSaveOwnedCompanyWalletSignature } from './api';
import {
  makeSelectMerchantWalletDeployment,
  makeSelectMerchantWalletOwnership,
  makeSelectMerchantWallets,
} from './selectors';
import { NAMESPACE } from './types';
import { merchantWalletIdToStoreKey } from './utils';
import { queryWeb3MerchantAddress } from './web3-api';

import type { MerchantWalletDeploymentState, MerchantWalletId, MerchantWalletSignature, MerchantWallet } from './types';

export const { storeMerchantWalletDeployment, markMerchantWalletDeploymentDirty } = createSingleActions<
  MerchantWalletDeploymentState,
  'MerchantWalletDeployment',
  MerchantWalletId
>(NAMESPACE, 'MerchantWalletDeployment');

const walletDeploymentFetchLimit = pLimit(1);
export const fetchMerchantWalletDeploymentState = createAppAsyncThunk(
  `${NAMESPACE}/fetchMerchantWalletDeploymentState`,
  async ({ force, id }: { force?: boolean; id: MerchantWalletId }, { dispatch, getState }) =>
    walletDeploymentFetchLimit(async () => {
      const saved = makeSelectMerchantWalletDeployment(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const status = await callWithThunkError(async () => {
        const bcMeta = await dispatch(fetchWeb3CompatBlockchainAPI({ bt: id.bt, force })).unwrap();
        const queryResult = await withAPICall(queryWeb3MerchantAddress)(
          { meta: { host: bcMeta.host, auth: bcMeta.auth } },
          bcMeta.merchantWalletFactoryAddress,
          id.address,
        );
        return mapLoadingState(
          queryResult,
          (address): MerchantWalletDeploymentState => ({
            bt: bcMeta.bt,
            ...(address ? { address, deployed: true } : { deployed: false }),
          }),
        );
      });
      dispatch(storeMerchantWalletDeployment({ id, data: status }));

      return makeSelectMerchantWalletDeployment(id)(getState());
    }),
  { idGenerator: ({ id }) => merchantWalletIdToStoreKey(id) },
);

export const { storeMerchantWalletOwnership, markMerchantWalletOwnershipDirty } = createLoadingDataActions<
  MerchantWalletSignature,
  'MerchantWalletOwnership'
>(NAMESPACE, 'MerchantWalletOwnership');

const merchantWalletOwnershipFetchLimit = pLimit(1);
export const fetchMerchantWalletOwnership = createAppAsyncThunk(
  `${NAMESPACE}/fetchMerchantWalletOwnership`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    merchantWalletOwnershipFetchLimit(async () => {
      const saved = makeSelectMerchantWalletOwnership()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryOwnedCompanyWalletSignature, 'unable to fetch signature')({ signal });
      dispatch(storeMerchantWalletOwnership(data));

      return makeSelectMerchantWalletOwnership()(getState());
    }),
);

export const confirmMerchantWalletOwnership = createAppAsyncThunk(
  `${NAMESPACE}/confirmMerchantWalletOwnership`,
  async (signature: MerchantWalletSignatureCreateAPIModel, { dispatch, signal }) => {
    const data = await withAPICall(requestSaveOwnedCompanyWalletSignature, 'unable to save signature')(signature, {
      signal,
    });
    if (data.data) {
      dispatch(storeMerchantWalletOwnership(data));
    }
    return data;
  },
);

export const { storeMerchantWallets, markMerchantWalletsDirty } = createLoadingDataActions<
  MerchantWallet[],
  'MerchantWallets'
>(NAMESPACE, 'MerchantWallets');

const merchantWalletsFetchLimit = pLimit(1);
export const fetchMerchantWallets = createAppAsyncThunk(
  `${NAMESPACE}/fetchMerchantWallets`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    merchantWalletsFetchLimit(async () => {
      const saved = makeSelectMerchantWallets()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryMerchantWallets, 'Unable to fetch wallets')({ signal });
      dispatch(storeMerchantWallets(data));

      return makeSelectMerchantWallets()(getState());
    }),
);
