import dayjs from 'dayjs';

import { parseReport } from '@/features/reports/api';
import type {
  UpdateWithdrawalScheduleRequestAPIModel,
  WithdrawalAPIModel,
  WithdrawalBatchAPIModel,
  WithdrawalIntentAPIModel,
  WithdrawalIntentPredicatesAPIModel,
  WithdrawalIntentSortByAPIModel,
  WithdrawalPredicatesAPIModel,
  WithdrawalSortByAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { WithdrawApi, WithdrawScheduleApi } from '@/generated/api/ncps-core/merchant-bo';
import { coreConfigurationFactory } from '@/infrastructure/api';
import type { DataSlice, SliceDataRequestWithTotal } from '@/infrastructure/model/api';
import { amountFromAPI, pageToAPI, sliceFromAPI } from '@/infrastructure/model/api';

import { SettlementType } from './types';

import type { Settlement, SettlementBatch, SettlementIntent } from './types';

const settlementApi = new WithdrawApi(coreConfigurationFactory('auth'));
const scheduleApi = new WithdrawScheduleApi(coreConfigurationFactory('auth'));

const parseSettlement = ({
  estimatedAmount,
  estimatedAmountInvoice,
  estimatedAmountPush,
  processingFeeUnpaid,
  fullness,
  companyId,
  collectFullness,
  batchCount,
  collectStatus,
  activeProcessingFeeTx,
  ...response
}: WithdrawalAPIModel): Settlement => ({
  ...response,
  // FIXME: should be provided by backend
  totalAmount: {
    asset: amountFromAPI(estimatedAmount).asset,
    value: amountFromAPI(estimatedAmountInvoice).value.plus(amountFromAPI(estimatedAmountPush).value),
  },
  estimatedAmount: amountFromAPI(estimatedAmount),
  type: response.isPayout ? SettlementType.Payout : SettlementType.Settlement,
});

const parseSettlementBatch = ({
  totalAmount,
  gwtId,
  btcTxId,
  isPush,
  isPull,
  invoiceProofs,
  ...response
}: WithdrawalBatchAPIModel): SettlementBatch => ({
  ...response,
  assetId: amountFromAPI(totalAmount).asset,
});

export const querySettlements = async (
  { page, filter, orderBy }: SliceDataRequestWithTotal<WithdrawalPredicatesAPIModel, WithdrawalSortByAPIModel>,
  initOverrides?: RequestInit,
): Promise<DataSlice<Settlement, WithdrawalSortByAPIModel>> =>
  sliceFromAPI(
    await settlementApi.getWithdrawals({ page: pageToAPI(page, orderBy), predicates: filter ?? {} }, initOverrides),
    parseSettlement,
  );

export const querySettlement = async (settlementId: string, initOverrides?: RequestInit): Promise<Settlement> =>
  parseSettlement(await settlementApi.getWithdrawal(settlementId, initOverrides));

export const querySettlementBatch = async (
  settlementId: string,
  initOverrides?: RequestInit,
): Promise<SettlementBatch> =>
  parseSettlementBatch(await settlementApi.getWithdrawalBatch(settlementId, 1, initOverrides));

export const requestExportSettlementReconciliations = async (
  filter: WithdrawalPredicatesAPIModel,
  includeTransactions: boolean,
  initOverrides?: RequestInit,
) =>
  parseReport(
    await (includeTransactions
      ? settlementApi.exportReconciliationTransactionsByWithdrawalPredicates(filter, initOverrides)
      : settlementApi.exportReconciliationsByWithdrawalPredicates(filter, initOverrides)),
  );

const parseIntent = (intent: WithdrawalIntentAPIModel): SettlementIntent => ({
  ...intent,
  staleAt: dayjs(intent.processAt).add(1, 'day').toDate(),
});

export const querySettlementSchedule = (initOverrides?: RequestInit) =>
  scheduleApi.getWithdrawalSchedule(initOverrides);

export const requestSettleNow = async (asset: string, initOverrides?: RequestInit) =>
  parseIntent((await scheduleApi.triggerWithdrawNow({ asset }, initOverrides)).intent);

export const requestUpdateSettlementSchedule = (
  update: UpdateWithdrawalScheduleRequestAPIModel,
  initOverrides?: RequestInit,
) => scheduleApi.updateWithdrawalSchedule(update, initOverrides);

export const requestDeleteSettlementSchedule = (initOverrides?: RequestInit) =>
  scheduleApi.deleteWithdrawalSchedule(initOverrides);

export const querySettlementIntents = async (
  {
    page,
    filter,
    orderBy,
  }: SliceDataRequestWithTotal<WithdrawalIntentPredicatesAPIModel, WithdrawalIntentSortByAPIModel>,
  initOverrides?: RequestInit,
) =>
  sliceFromAPI(
    await scheduleApi.searchWithdrawalScheduleIntents(
      { page: pageToAPI(page, orderBy), predicates: filter ?? {} },
      initOverrides,
    ),
    parseIntent,
  );

export const querySettlementIntent = async (intentId: string, initOverrides?: RequestInit) =>
  parseIntent((await scheduleApi.getWithdrawalIntentById(intentId, initOverrides)).intent);
