import { PageLoading } from '@/components';
import { useMerchantWalletOwnership } from '@/features/merchant-wallets/hooks';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';
import MessageLayout from '@/layouts/MessageLayout';

import { MerchantWalletOwnershipCard } from './components';

import type React from 'react';
import type { PropsWithChildren } from 'react';

const withMerchantWalletOwnershipCheck = <T extends PropsWithChildren<EmptyObject>>(
  Component: React.ComponentType<T>,
): React.FC<T> =>
  namedHOC(
    Component,
    'WithMerchantWalletOwnershipCheck',
  )((props) => {
    const ownershipState = useMerchantWalletOwnership();

    if (!ownershipState.data.data && (ownershipState.data.isDirty || ownershipState.loading)) {
      return (
        <MessageLayout>
          <PageLoading type="withMerchantWalletOwnershipCheck" />
        </MessageLayout>
      );
    }

    if (!ownershipState.data.data && !ownershipState.data.isDirty && !ownershipState.loading) {
      return (
        <MessageLayout>
          <MerchantWalletOwnershipCard data-test="wallet" />
        </MessageLayout>
      );
    }

    return <Component {...props} />;
  });

export default withMerchantWalletOwnershipCheck;
