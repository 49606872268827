/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { MerchantReportScheduleAPIModel } from './MerchantReportScheduleAPIModel';
import {
    MerchantReportScheduleAPIModelFromJSON,
    MerchantReportScheduleAPIModelFromJSONTyped,
    MerchantReportScheduleAPIModelToJSON,
} from './MerchantReportScheduleAPIModel';
import type { PageReportScheduleSortByAPIModel } from './PageReportScheduleSortByAPIModel';
import {
    PageReportScheduleSortByAPIModelFromJSON,
    PageReportScheduleSortByAPIModelFromJSONTyped,
    PageReportScheduleSortByAPIModelToJSON,
} from './PageReportScheduleSortByAPIModel';

/**
 * 
 * @export
 * @interface SliceMerchantReportScheduleReportScheduleSortByAPIModel
 */
export interface SliceMerchantReportScheduleReportScheduleSortByAPIModel {
    /**
     * 
     * @type {Array<MerchantReportScheduleAPIModel>}
     * @memberof SliceMerchantReportScheduleReportScheduleSortByAPIModel
     */
    list?: Array<MerchantReportScheduleAPIModel>;
    /**
     * 
     * @type {PageReportScheduleSortByAPIModel}
     * @memberof SliceMerchantReportScheduleReportScheduleSortByAPIModel
     */
    page: PageReportScheduleSortByAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SliceMerchantReportScheduleReportScheduleSortByAPIModel
     */
    total?: number;
}

/**
 * Check if a given object implements the SliceMerchantReportScheduleReportScheduleSortByAPIModel interface.
 */
export function instanceOfSliceMerchantReportScheduleReportScheduleSortByAPIModel(value: object): boolean {
    if (!('page' in value)) return false;
    return true;
}

export function SliceMerchantReportScheduleReportScheduleSortByAPIModelFromJSON(json: any): SliceMerchantReportScheduleReportScheduleSortByAPIModel {
    return SliceMerchantReportScheduleReportScheduleSortByAPIModelFromJSONTyped(json, false);
}

export function SliceMerchantReportScheduleReportScheduleSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SliceMerchantReportScheduleReportScheduleSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(MerchantReportScheduleAPIModelFromJSON)),
        'page': PageReportScheduleSortByAPIModelFromJSON(json['page']),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function SliceMerchantReportScheduleReportScheduleSortByAPIModelToJSON(value?: SliceMerchantReportScheduleReportScheduleSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(MerchantReportScheduleAPIModelToJSON)),
        'page': PageReportScheduleSortByAPIModelToJSON(value['page']),
        'total': value['total'],
    };
}

