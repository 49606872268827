import type {
  PaymentTransactionAPIModel,
  PaymentTransactionBlockchainIdAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import type { AssetAmountValue } from '@/infrastructure/model/api';
import type { SingleState } from '@/infrastructure/model/single/types';

export const NAMESPACE = 'payment-transactions';

export interface PaymentTransaction extends Omit<PaymentTransactionAPIModel, 'amount' | 'invoiceId'> {
  paymentId: string;
  amount: AssetAmountValue;
}

export type PaymentTransactionBlockchainId = PaymentTransactionBlockchainIdAPIModel;

export interface PaymentTransactionsState {
  entities: SingleState<PaymentTransaction>;
}
