// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Func<T extends unknown[] = any[], R = unknown> = ((...args: T) => Promise<R>) | ((...args: T) => R);
export type WithFunc<T extends unknown[] = unknown[], R = unknown, F = Func<T, R>> = (func: F) => F;
export type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];
export type DeepPartial<T> = T extends object ? { [K in keyof T]?: DeepPartial<T[K]> } : T;
export type PartialBy<T, K extends keyof T> = Partial<Pick<T, K>> & Omit<T, K>;
export type DeepPartialBy<T, K extends keyof T> = DeepPartial<Pick<T, K>> & Omit<T, K>;
export type Diff<T, U> = T extends U ? never : T;
export type Filter<T, U> = T extends U ? T : never;
export type AllNonNullable<T> = { [P in keyof T]: NonNullable<T[P]> };
export type SomeNonNullable<T, K extends keyof T> = T & AllNonNullable<Pick<T, K>>;
export type SomeNullable<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
export type ClosedConstructorParameters<T> = ConstructorParameters<(new () => never) & T>;

export type PromiseType<T extends Promise<unknown>> = T extends Promise<infer U> ? U : never;
export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType[number];

export type EmptyObject = object;

export const nameof = <T, K extends keyof T = keyof T>(key: K): K => key;
export const extractKey = <T, K extends Extract<keyof T, string>>(_: T, k: K) => k;
export const asType = <T>(t: T): T => t;

export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue =>
  value !== null && value !== undefined;

export function getRecordValues<K extends string | number | symbol, V>(record: Record<K, V>): V[] {
  return Object.values(record);
}

export function getRecordEntries<K extends string | number | symbol, V>(record: Record<K, V>): [K, V][] {
  return Object.entries(record) as [K, V][];
}

// do not check the return type, we can only "trust" the caller
export const isThenable = <T>(input: unknown): input is PromiseLike<T> =>
  !!input && typeof (input as PromiseLike<T>).then === 'function';

// enum TS helpers
export interface EnumByKey {
  <E = Record<string, unknown>>(v: E, key: keyof E): E[typeof key];

  <E = Record<string, unknown>>(v: E, key?: string): E[keyof E] | undefined;
}

export const enumByKey: EnumByKey = <E = Record<string, unknown>>(v: E | undefined, key?: string | keyof E) =>
  key ? v?.[key as keyof E] : undefined;

export const isEnumKey = <E = Record<string, unknown>>(v: E | undefined, key?: string | keyof E): key is keyof E =>
  !!(key ? v?.[key as keyof E] : undefined);

export const enumKeys = <E extends object = object>(v: E): (keyof E)[] => Object.keys(v) as unknown as (keyof E)[];
export const enumValues = <E extends object = object>(v: E): E[keyof E][] =>
  Object.values(v) as unknown as E[keyof E][];

export interface EnumToObject {
  <T = unknown, E extends object = object>(v: E, func: (key: E[keyof E]) => T): Record<keyof E, T>;

  <T = unknown, E extends object = object>(
    v: E,
    func: (key: E[keyof E]) => T | undefined,
    skiUndefined: true,
  ): Partial<Record<keyof E, T>>;
}

export const enumToObject: EnumToObject = <T = unknown, E extends object = object>(
  v: E,
  func: (key: keyof E) => T,
  skipUndefined?: true,
) =>
  Object.fromEntries(
    enumKeys(v)
      .map<[keyof E, T]>((key) => [key, func(key)])
      .filter(([key, value]) => (skipUndefined && !value ? undefined : [key, value]))
      .filter(notEmpty),
  );

export type ReplaceFirstParameter<TParams extends readonly unknown[], TReplace> = {
  [K in keyof TParams]: K extends '0' ? TReplace : TParams[K];
};
