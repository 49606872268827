import { PageContainer } from '@/components';
import { KYBRequiredBanner } from '@/features/company/components';
import { RechargeAddressFilterForm, RechargesDocumentationBanner } from '@/features/recharges/components';
import { CollectableScheduleCard } from '@/pages/collectable/components';
import RechargeAddressViewLink from '@/pages/recharges/address-view/components/RechargeAddressViewLink';

import { RechargeAddressesBreadcrumb, RechargeAddressesCard, RechargeAddressesTableOperations } from './components';

import type React from 'react';

const dataTest: string | undefined = 'recharges';

const RechargeAddressesPage: React.FC = () => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={<RechargeAddressesBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <KYBRequiredBanner data-test="kyb-banner" PageProps={{ pageSize: 'big' }} />
    <RechargesDocumentationBanner data-test={dataTest && `${dataTest}-help`} pageSize="big" />
    <CollectableScheduleCard data-test={dataTest && `${dataTest}-schedule`} withTasksLinks />
    <RechargeAddressesCard
      data-test={dataTest}
      RechargeAddressLink={RechargeAddressViewLink}
      FilterForm={RechargeAddressFilterForm}
      Operations={RechargeAddressesTableOperations}
    />
  </PageContainer>
);

export default RechargeAddressesPage;
