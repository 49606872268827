/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { WithdrawalIntentStatusAPIModel } from './WithdrawalIntentStatusAPIModel';
import {
    WithdrawalIntentStatusAPIModelFromJSON,
    WithdrawalIntentStatusAPIModelFromJSONTyped,
    WithdrawalIntentStatusAPIModelToJSON,
} from './WithdrawalIntentStatusAPIModel';

/**
 * 
 * @export
 * @interface WithdrawalIntentAPIModel
 */
export interface WithdrawalIntentAPIModel {
    /**
     * 
     * @type {string}
     * @memberof WithdrawalIntentAPIModel
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof WithdrawalIntentAPIModel
     */
    companyId: number;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof WithdrawalIntentAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalIntentAPIModel
     */
    asset: string;
    /**
     * 
     * @type {Date}
     * @memberof WithdrawalIntentAPIModel
     */
    processAt: Date;
    /**
     * 
     * @type {WithdrawalIntentStatusAPIModel}
     * @memberof WithdrawalIntentAPIModel
     */
    status: WithdrawalIntentStatusAPIModel;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalIntentAPIModel
     */
    transactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalIntentAPIModel
     */
    compatTransactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalIntentAPIModel
     */
    compat2TransactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalIntentAPIModel
     */
    compat3TransactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalIntentAPIModel
     */
    withdrawalId?: string;
}

/**
 * Check if a given object implements the WithdrawalIntentAPIModel interface.
 */
export function instanceOfWithdrawalIntentAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('asset' in value)) return false;
    if (!('processAt' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function WithdrawalIntentAPIModelFromJSON(json: any): WithdrawalIntentAPIModel {
    return WithdrawalIntentAPIModelFromJSONTyped(json, false);
}

export function WithdrawalIntentAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalIntentAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'asset': json['asset'],
        'processAt': (new Date(json['processAt'])),
        'status': WithdrawalIntentStatusAPIModelFromJSON(json['status']),
        'transactionId': json['transactionId'] == null ? undefined : json['transactionId'],
        'compatTransactionId': json['compatTransactionId'] == null ? undefined : json['compatTransactionId'],
        'compat2TransactionId': json['compat2TransactionId'] == null ? undefined : json['compat2TransactionId'],
        'compat3TransactionId': json['compat3TransactionId'] == null ? undefined : json['compat3TransactionId'],
        'withdrawalId': json['withdrawalId'] == null ? undefined : json['withdrawalId'],
    };
}

export function WithdrawalIntentAPIModelToJSON(value?: WithdrawalIntentAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'asset': value['asset'],
        'processAt': ((value['processAt']).toISOString()),
        'status': WithdrawalIntentStatusAPIModelToJSON(value['status']),
        'transactionId': value['transactionId'],
        'compatTransactionId': value['compatTransactionId'],
        'compat2TransactionId': value['compat2TransactionId'],
        'compat3TransactionId': value['compat3TransactionId'],
        'withdrawalId': value['withdrawalId'],
    };
}

