import type {
  PaymentTransactionAPIModel,
  PaymentTransactionSearchIdAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { PaymentTransactionApi } from '@/generated/api/ncps-core/merchant-bo';
import { coreConfigurationFactory } from '@/infrastructure/api';
import { amountFromAPI } from '@/infrastructure/model/api';
import { someOrFail } from '@/infrastructure/utils/functions';

import type { PaymentTransaction, PaymentTransactionBlockchainId } from './types';

const paymentTransactionApi = new PaymentTransactionApi(coreConfigurationFactory('auth'));

export const parsePaymentTransaction = ({
  amount,
  ...paymentTransaction
}: PaymentTransactionAPIModel): PaymentTransaction => ({
  ...paymentTransaction,
  amount: amountFromAPI(amount),
});

export const queryMultiplePaymentTransactions = async (
  searchIds: PaymentTransactionSearchIdAPIModel[],
  initOverrides?: RequestInit,
) =>
  (await paymentTransactionApi.getPaymentTransactions({ predicates: { idIn: searchIds } }, initOverrides)).list?.map(
    parsePaymentTransaction,
  ) ?? [];

export const queryPaymentTransaction = async (
  searchId: PaymentTransactionSearchIdAPIModel,
  initOverrides?: RequestInit,
) =>
  someOrFail(
    (await queryMultiplePaymentTransactions([searchId], initOverrides))[0],
    () => new Error('PaymentTransactionNotFound'),
  );

export const queryPaymentTransactionByBlockchainId = async (
  id: PaymentTransactionBlockchainId,
  initOverrides?: RequestInit,
) =>
  someOrFail(
    ((
      await paymentTransactionApi.getPaymentTransactions({ predicates: { transactionIdIn: [id] } }, initOverrides)
    ).list?.map(parsePaymentTransaction) ?? [])[0],
    () => new Error('PaymentTransactionNotFound'),
  );

export const queryPaymentTransactionsForPayment = async (paymentId: string, initOverrides?: RequestInit) =>
  (await paymentTransactionApi.getPaymentTransactions({ predicates: { paymentId } }, initOverrides)).list?.map(
    parsePaymentTransaction,
  ) ?? [];
