import isNil from 'lodash/isNil';
import { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { storeDestinationsPerPayoutListParameters } from '@/features/payouts/actions';
import { usePayoutBatches, usePayoutDestinations } from '@/features/payouts/hooks';
import { makeSelectDestinationsPerPayoutListParameters } from '@/features/payouts/selectors';
import type {
  PayoutDestinationDetailed,
  PayoutDestinationDetailedFilter,
  PayoutDestinationDetailedSortByAPIModel,
} from '@/features/payouts/types';
import { combine } from '@/infrastructure/model';
import { numberComparator, stringComparator } from '@/infrastructure/model/comparators';
import type { UpdateListParametersPayload } from '@/infrastructure/model/list/types';
import { noop } from '@/infrastructure/utils/functions';

import type { UsePayoutDetailedDestinationsListView } from './types';

export default function usePayoutDetailedDestinationsListView(
  id: string | undefined,
): UsePayoutDetailedDestinationsListView {
  const { dispatch } = useAppDispatch();
  const destinationsState = usePayoutDestinations(id);
  const batchesState = usePayoutBatches(id);
  const forceRefresh = useCallback(
    async () => Promise.all([destinationsState.forceRefresh(), batchesState.forceRefresh()]),
    [batchesState, destinationsState],
  );
  const loading = destinationsState.loading || batchesState.loading;
  const parametersSelector = useMemo(() => makeSelectDestinationsPerPayoutListParameters(id), [id]);
  const parameters = useAppSelector(parametersSelector);
  const data = useMemo(
    () => ({
      ...combine(destinationsState.data, batchesState.data, (destinations, batches) => {
        const sorter = parameters.sortBy.Address
          ? stringComparator(({ address }: PayoutDestinationDetailed) => address)(parameters.sortBy.Address)
          : parameters.sortBy.Batch
            ? numberComparator(({ batchNum, num }: PayoutDestinationDetailed) => (batchNum ?? 0) * 1000 + num)(
                parameters.sortBy.Batch,
              )
            : numberComparator(({ num }: PayoutDestinationDetailed) => num)(parameters.sortBy.Num ?? 'DESC');

        const filter = (data: PayoutDestinationDetailed) =>
          (!parameters.filter.addressLike
            || data.address.toLowerCase().includes(parameters.filter.addressLike.toLowerCase()))
          && (!parameters.filter.batchHashLike
            || data.batchHash?.toLowerCase().includes(parameters.filter.batchHashLike.toLowerCase()))
          && (!parameters.filter.batchStatusIn
            || !data.batchStatus
            || parameters.filter.batchStatusIn.includes(data.batchStatus))
          && (!parameters.filter.amountRange?.gte || data.amount.value.gte(parameters.filter.amountRange.gte))
          && (!parameters.filter.amountRange?.lte || data.amount.value.lte(parameters.filter.amountRange.lte));
        const data = destinations
          .map((destination): PayoutDestinationDetailed => {
            const batch = !isNil(destination.batchNum)
              ? batches.find(({ num }) => num === destination.batchNum)
              : undefined;
            return {
              ...destination,
              batchNum: batch?.num,
              batchHash: batch?.hash,
              batchStatus: batch?.status,
            };
          })
          .filter(filter)
          .sort(sorter);
        return { data, total: data.length };
      }),
      isTotalDirty: false,
    }),
    [
      batchesState.data,
      destinationsState.data,
      parameters.filter.addressLike,
      parameters.filter.amountRange?.gte,
      parameters.filter.amountRange?.lte,
      parameters.filter.batchHashLike,
      parameters.filter.batchStatusIn,
      parameters.sortBy.Address,
      parameters.sortBy.Batch,
      parameters.sortBy.Num,
    ],
  );

  const updateParameters = useCallback(
    (
      parameters: UpdateListParametersPayload<PayoutDestinationDetailedFilter, PayoutDestinationDetailedSortByAPIModel>,
    ) => (id ? dispatch(storeDestinationsPerPayoutListParameters({ parentId: id, parameters })) : noop()),
    [dispatch, id],
  );

  return {
    ...parameters,
    loading,
    forceRefresh,
    data,
    updateParameters,
  };
}
