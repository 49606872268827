import { parseReport } from '@/features/reports/api';
import type {
  MerchantWalletRunningBalanceAPIModel,
  MerchantWalletRunningBalancePredicatesAPIModel,
  MerchantWalletRunningBalanceSortByAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { MerchantWalletRunningBalanceApi } from '@/generated/api/ncps-core/merchant-bo';
import { coreConfigurationFactory } from '@/infrastructure/api';
import type { SliceDataRequestWithTotal } from '@/infrastructure/model/api';
import { amountFromAPI, pageToAPI, sliceFromAPI } from '@/infrastructure/model/api';

import type { MerchantWalletRunningBalance } from './types';

const transactionApi = new MerchantWalletRunningBalanceApi(coreConfigurationFactory('auth'));

export const parseMerchantWalletRunningBalance = ({
  amount,
  balance,
  ...transaction
}: MerchantWalletRunningBalanceAPIModel): MerchantWalletRunningBalance => ({
  ...transaction,
  amount: amountFromAPI(amount),
  balance: amountFromAPI(balance),
});

export const queryMerchantWalletRunningBalances = async (
  {
    page,
    filter,
    orderBy,
    withTotal,
  }: SliceDataRequestWithTotal<
    MerchantWalletRunningBalancePredicatesAPIModel,
    MerchantWalletRunningBalanceSortByAPIModel
  >,
  initOverrides?: RequestInit,
) =>
  sliceFromAPI(
    await transactionApi.searchMerchantWalletRunningBalances(
      { page: pageToAPI(page, orderBy), predicates: filter ?? {} },
      withTotal,
      initOverrides,
    ),
    parseMerchantWalletRunningBalance,
  );

export const queryMerchantWalletRunningBalance = async (id: string, initOverrides?: RequestInit) =>
  parseMerchantWalletRunningBalance(await transactionApi.getMerchantWalletRunningBalance(id, initOverrides));

export const requestExportMerchantWalletRunningBalances = async (
  predicates: MerchantWalletRunningBalancePredicatesAPIModel,
  initOverrides?: RequestInit,
) => parseReport(await transactionApi.exportMerchantWalletRunningBalances(predicates, initOverrides));
