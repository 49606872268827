/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';

/**
 * 
 * @export
 * @interface AddGasWalletAPIModel
 */
export interface AddGasWalletAPIModel {
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof AddGasWalletAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
}

/**
 * Check if a given object implements the AddGasWalletAPIModel interface.
 */
export function instanceOfAddGasWalletAPIModel(value: object): boolean {
    if (!('blockchain' in value)) return false;
    return true;
}

export function AddGasWalletAPIModelFromJSON(json: any): AddGasWalletAPIModel {
    return AddGasWalletAPIModelFromJSONTyped(json, false);
}

export function AddGasWalletAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddGasWalletAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
    };
}

export function AddGasWalletAPIModelToJSON(value?: AddGasWalletAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
    };
}

