import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { mapStoredState } from '@/infrastructure/model';
import { stringComparator } from '@/infrastructure/model/comparators';
import { createFullSelectors } from '@/infrastructure/model/full/selectors';
import { createSingleSelectors } from '@/infrastructure/model/single/selectors';

import { NAMESPACE } from './types';
import {
  createAllowanceKey,
  createAssetAddressHistoryKey,
  createAssetAddressKey,
  createBlockchainAddressKey,
  extractBlockchainType,
} from './utils';

import type { BlockchainInfo, Asset } from './types';

export const makeSelectSelectedNetworkState = () => (state: AppRootState) => state[NAMESPACE].selected;
export const makeSelectSelectedNetwork = () => (state: AppRootState) => state[NAMESPACE].selected.value;

export const { makeSelectBlockchain, makeSelectBlockchainFullData } = createFullSelectors(
  (state: AppRootState) => state[NAMESPACE].blockchains,
  'Blockchain',
  (sortBy) => stringComparator((v: BlockchainInfo) => v.blockchain)(sortBy.blockchain ?? 'DESC'),
  () => () => true,
  extractBlockchainType,
);

export const makeSelectSelectedNetworkBlockchains = () =>
  createSelector(makeSelectBlockchainFullData(), makeSelectSelectedNetwork(), (state, network) =>
    mapStoredState(state, (data) => data.filter(({ net }) => net === network)),
  );

export const makeSelectBlockchainSystemInfos = () => (state: AppRootState) => state[NAMESPACE].bcSystemInfo;
export const makeSelectBlockchainSystemInfo = (blockchain: BlockchainTypeAPIModel) =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE].bcSystemInfo,
    (state) => mapStoredState(state, (info) => info[blockchain]),
  );

export const { makeSelectNativeBalance } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].nativeBalance,
  'NativeBalance',
  createBlockchainAddressKey,
);

export const { makeSelectTokenBalance } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].tokenBalance,
  'TokenBalance',
  createAssetAddressKey,
);

export const { makeSelectTokenHistoryBalance } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].tokenHistoryBalance,
  'TokenHistoryBalance',
  createAssetAddressHistoryKey,
);

export const { makeSelectContractExistence } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].contractExistence,
  'ContractExistence',
  createBlockchainAddressKey,
);

export const { makeSelectAllowance } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].allowance,
  'Allowance',
  createAllowanceKey,
);

export const { makeSelectAsset, makeSelectAssetFullData } = createFullSelectors(
  (state: AppRootState) => state[NAMESPACE].assets,
  'Asset',
  (sortBy) => stringComparator((v: Asset) => v.code)(sortBy.name ?? 'DESC'),
  () => () => true,
  ({ code }) => code,
);

export const makeSelectSupportedAssets = () =>
  createSelector(makeSelectAssetFullData(), (allAssets) =>
    mapStoredState(allAssets, (data) => data.filter(({ isSettlementSupported }) => isSettlementSupported)),
  );

export const makeSelectExchangeableAssets = () =>
  createSelector(makeSelectAssetFullData(), ({ data, ...assets }) => ({
    ...assets,
    data,
  }));
