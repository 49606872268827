/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { SubscriptionChargeStatusAPIModel } from './SubscriptionChargeStatusAPIModel';
import {
    SubscriptionChargeStatusAPIModelFromJSON,
    SubscriptionChargeStatusAPIModelFromJSONTyped,
    SubscriptionChargeStatusAPIModelToJSON,
} from './SubscriptionChargeStatusAPIModel';

/**
 * 
 * @export
 * @interface SubscriptionChargePredicatesAPIModel
 */
export interface SubscriptionChargePredicatesAPIModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionChargePredicatesAPIModel
     */
    idIn?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChargePredicatesAPIModel
     */
    subscriptionId?: string;
    /**
     * 
     * @type {SubscriptionChargeStatusAPIModel}
     * @memberof SubscriptionChargePredicatesAPIModel
     */
    status?: SubscriptionChargeStatusAPIModel;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof SubscriptionChargePredicatesAPIModel
     */
    btEq?: BlockchainTypeAPIModel;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionChargePredicatesAPIModel
     */
    hashIn?: Array<string>;
}

/**
 * Check if a given object implements the SubscriptionChargePredicatesAPIModel interface.
 */
export function instanceOfSubscriptionChargePredicatesAPIModel(value: object): boolean {
    return true;
}

export function SubscriptionChargePredicatesAPIModelFromJSON(json: any): SubscriptionChargePredicatesAPIModel {
    return SubscriptionChargePredicatesAPIModelFromJSONTyped(json, false);
}

export function SubscriptionChargePredicatesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionChargePredicatesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'idIn': json['idIn'] == null ? undefined : json['idIn'],
        'subscriptionId': json['subscriptionId'] == null ? undefined : json['subscriptionId'],
        'status': json['status'] == null ? undefined : SubscriptionChargeStatusAPIModelFromJSON(json['status']),
        'btEq': json['btEq'] == null ? undefined : BlockchainTypeAPIModelFromJSON(json['btEq']),
        'hashIn': json['hashIn'] == null ? undefined : json['hashIn'],
    };
}

export function SubscriptionChargePredicatesAPIModelToJSON(value?: SubscriptionChargePredicatesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'idIn': value['idIn'],
        'subscriptionId': value['subscriptionId'],
        'status': SubscriptionChargeStatusAPIModelToJSON(value['status']),
        'btEq': BlockchainTypeAPIModelToJSON(value['btEq']),
        'hashIn': value['hashIn'],
    };
}

