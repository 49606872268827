import { Space } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { withAssetsDataLoading } from '@/features/dictionary/blockchain/hocs';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';
import { stringComparator } from '@/infrastructure/model/comparators';

import { AssetsInput, AssetsEditForm } from './components';

import type { AssetsInputRow } from './components';
import type { AssetsFormProps } from './types';

const compareNetworks = stringComparator((v: AssetsInputRow) => v.network ?? '')('ASC');
const compareBlockchains = stringComparator((v: AssetsInputRow) => v.blockchain ?? '')('ASC');

const AssetsForm: React.FC<AssetsFormProps> = (props) => {
  const { readonly, data, selected, description } = props;
  const formValues = useMemo(
    () =>
      (readonly ? data.filter(({ code }) => selected.includes(code)) : data)
        .map(({ code, name, blockchain, net }) => ({
          code,
          name,
          blockchain,
          net,
          isActive: selected.includes(code),
        }))
        .sort((v1, v2) => compareNetworks(v1, v2) * 10 + compareBlockchains(v1, v2)),
    [readonly, data, selected],
  );

  if (!data.length) {
    return <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ASSETS_FORM_ERROR_NO_ASSETS} />;
  }

  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      {description}
      {readonly ? (
        <AssetsInput withBlockchainMark {...props} value={formValues} />
      ) : (
        <AssetsEditForm {...props} values={formValues} />
      )}
    </Space>
  );
};

const AssetsFormHOC = withAssetsDataLoading('supported', true)<AssetsFormProps>(AssetsForm);

const AssetsFormMemo = React.memo(AssetsFormHOC) as typeof AssetsFormHOC;

export default AssetsFormMemo;
