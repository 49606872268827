/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewCompanyUserAPIModel
 */
export interface NewCompanyUserAPIModel {
    /**
     * 
     * @type {string}
     * @memberof NewCompanyUserAPIModel
     */
    address: string;
}

/**
 * Check if a given object implements the NewCompanyUserAPIModel interface.
 */
export function instanceOfNewCompanyUserAPIModel(value: object): boolean {
    if (!('address' in value)) return false;
    return true;
}

export function NewCompanyUserAPIModelFromJSON(json: any): NewCompanyUserAPIModel {
    return NewCompanyUserAPIModelFromJSONTyped(json, false);
}

export function NewCompanyUserAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewCompanyUserAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'],
    };
}

export function NewCompanyUserAPIModelToJSON(value?: NewCompanyUserAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
    };
}

