import dayjs from 'dayjs';
import duration, { type Duration } from 'dayjs/plugin/duration';

import { asType } from '@/infrastructure/utils/ts';

dayjs.extend(duration);

export const normalizeDateTime = (
  date: Date,
  opts?: {
    res?: Response;
    delta?: Duration;
  },
): Date => {
  const responseDateString = opts?.res?.headers.get('date') || opts?.res?.headers.get('Date');
  if (!responseDateString) {
    return date;
  }
  const diff = dayjs(new Date()).diff(responseDateString, 's');
  const delta = opts?.delta ?? dayjs.duration(1, 'second');
  return Math.abs(diff) > delta.asSeconds() ? dayjs(date).add(diff, 's').toDate() : date;
};

export const parseDateTime = (
  date: string,
  opts?: {
    res?: Response;
    delta?: Duration;
  },
): Date => normalizeDateTime(new Date(date), opts);

export interface ServerErrorResponse {
  status: number;
  code?: string;
  message?: string;
  serviceName: string;
  timestamp: string;
}

export interface ResponseErrorData {
  headers: Headers;
  ok: boolean;
  status: number;
  statusText: string;
  // if json
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

export class ResponseError extends Error {
  private readonly _data: ResponseErrorData;

  constructor(data: ResponseErrorData) {
    super('response error');
    this._data = data;
  }

  get data() {
    return this._data;
  }
}

export const parseErrorResponse = async (error: Response): Promise<ResponseError> => {
  let data;
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    data = asType<ResponseErrorData>(await error.json());
  } catch (e) {
    try {
      console.warn(`unable to parse error response`, e);
    } catch {
      console.warn(`unable to parse error response`, e?.toString());
    }
  }
  return new ResponseError({
    data,
    headers: new Headers(error.headers),
    ok: error.ok,
    status: error.status,
    statusText: error.statusText,
  });
};
