import React from 'react';

import { DocumentationLink, FormattedMessage } from '@/components';
import { I18nFeatureSubscriptions } from '@/generated/i18n/i18n';

import type { SubscriptionsDocumentationLinkProps } from './types';

const SubscriptionsDocumentationLink: React.FC<SubscriptionsDocumentationLinkProps> = ({
  'data-test': dataTest,
  mode,
  title,
}) => (
  <DocumentationLink
    mode={mode}
    title={
      title ?? <FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTIONS_DOCUMENTATION_LINK_TITLE} />
    }
    data-test={dataTest}
    path="/guides/subscriptions"
  />
);

const SubscriptionsDocumentationLinkMemo = React.memo(SubscriptionsDocumentationLink);

export default SubscriptionsDocumentationLinkMemo;
