import type { DateTimeRelativeQuickFilter } from '@/components';
import type { CollectableEntityTransaction } from '@/features/collectable/types';
import type {
  NewRechargeAddressAPIModel,
  RechargeAddressAPIModel,
  RechargeAddressPredicatesAPIModel,
  RechargeTransactionPredicatesAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { PushTransactionSortByAPIModel, PushAddressSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { AssetAmountValue } from '@/infrastructure/model/api';
import type { ListColumnState, ListSortBy, ListState, ListStateNoColumns } from '@/infrastructure/model/list/types';
import { defaultListState } from '@/infrastructure/model/list/types';
import type { SingleState } from '@/infrastructure/model/single/types';

export const NAMESPACE = 'recharges';

export type NewRechargeAddress = NewRechargeAddressAPIModel;

export interface RechargeAddress extends Omit<RechargeAddressAPIModel, 'balance'> {
  balance: AssetAmountValue;
}

export interface RechargeAddressFilterPredicate extends RechargeAddressPredicatesAPIModel {
  createdAtRangeRelative?: DateTimeRelativeQuickFilter;
}

export type RechargeAddressListState = ListState<string, RechargeAddressFilterPredicate, PushAddressSortByAPIModel>;
export type RechargeAddressSortBy = ListSortBy<PushAddressSortByAPIModel>;

export const defaultRechargeAddressSortBy: RechargeAddressSortBy = { [PushAddressSortByAPIModel.CreatedAt]: 'DESC' };
export const defaultRechargeAddressListState: RechargeAddressListState = defaultListState(
  {},
  defaultRechargeAddressSortBy,
);

export interface RechargeTransactionFilterPredicate extends RechargeTransactionPredicatesAPIModel {
  createdAtRangeRelative?: DateTimeRelativeQuickFilter;
  broadcastAtRangeRelative?: DateTimeRelativeQuickFilter;
}

export type RechargeTransaction = CollectableEntityTransaction;

export type RechargeTransactionListState = ListStateNoColumns<
  string,
  RechargeTransactionFilterPredicate,
  PushTransactionSortByAPIModel
>;
export type RechargeTransactionSortBy = ListSortBy<PushTransactionSortByAPIModel>;

export const defaultRechargeTransactionSortBy: RechargeTransactionSortBy = {
  [PushTransactionSortByAPIModel.BroadcastAt]: 'DESC',
};
export const defaultRechargeTransactionListState: RechargeTransactionListState = defaultListState(
  {},
  defaultRechargeTransactionSortBy,
);

export interface RechargesState {
  entities: SingleState<RechargeAddress>;
  list: ListState<string, RechargeAddressFilterPredicate, PushAddressSortByAPIModel>;

  transactions: {
    columnState: ListColumnState;
    byAddress: Partial<Record<string, RechargeTransactionListState>>;
    list: RechargeTransactionListState;
  };
}
