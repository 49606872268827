import Icon, { FileUnknownOutlined, ImportOutlined, ShareAltOutlined, TeamOutlined } from '@ant-design/icons';
import React from 'react';

import LogoSmallLight from '@/assets/icons/smartypay/logo-small-light';
import { StatusViewElement } from '@/components';
import DonationsIcon from '@/features/donations/components/DonationsIcon';
import RechargesIcon from '@/features/recharges/components/RechargesIcon';
import SettlementsIcon from '@/features/settlements/components/SettlementsIcon';
import SubscriptionsIcon from '@/features/subscriptions/components/SubscriptionsIcon';
import { MerchantWalletTransferKindAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureMerchantWalletTransfers } from '@/generated/i18n/i18n';
import PaymentsIcon from '@/pages/payments/components/PaymentsIcon';

import type { MerchantWalletTransferKindViewProps } from './types';

const icons: Record<MerchantWalletTransferKindAPIModel, React.ReactNode> = {
  [MerchantWalletTransferKindAPIModel.Unknown]: <FileUnknownOutlined />,
  [MerchantWalletTransferKindAPIModel.PaymentTransaction]: <PaymentsIcon />,
  [MerchantWalletTransferKindAPIModel.DonationTransaction]: <DonationsIcon />,
  [MerchantWalletTransferKindAPIModel.RechargeTransaction]: <RechargesIcon />,
  [MerchantWalletTransferKindAPIModel.SubscriptionCharge]: <SubscriptionsIcon />,
  [MerchantWalletTransferKindAPIModel.Withdrawal]: <SettlementsIcon />,
  [MerchantWalletTransferKindAPIModel.PushCollect]: <ImportOutlined />,
  [MerchantWalletTransferKindAPIModel.Payout]: <ShareAltOutlined />,
  [MerchantWalletTransferKindAPIModel.PartnerFee]: <TeamOutlined />,
  [MerchantWalletTransferKindAPIModel.ProcessingFee]: <Icon component={LogoSmallLight} />,
};

export const i18n: Record<MerchantWalletTransferKindAPIModel, I18nFeatureMerchantWalletTransfers> = {
  [MerchantWalletTransferKindAPIModel.Unknown]: I18nFeatureMerchantWalletTransfers.COMPONENTS_KIND_VIEW_UNKNOWN,
  [MerchantWalletTransferKindAPIModel.PaymentTransaction]:
    I18nFeatureMerchantWalletTransfers.COMPONENTS_KIND_VIEW_PAYMENTTRANSACTION,
  [MerchantWalletTransferKindAPIModel.DonationTransaction]:
    I18nFeatureMerchantWalletTransfers.COMPONENTS_KIND_VIEW_DONATIONTRANSACTION,
  [MerchantWalletTransferKindAPIModel.RechargeTransaction]:
    I18nFeatureMerchantWalletTransfers.COMPONENTS_KIND_VIEW_RECHARGETRANSACTION,
  [MerchantWalletTransferKindAPIModel.SubscriptionCharge]:
    I18nFeatureMerchantWalletTransfers.COMPONENTS_KIND_VIEW_SUBSCRIPTIONCHARGE,
  [MerchantWalletTransferKindAPIModel.Withdrawal]: I18nFeatureMerchantWalletTransfers.COMPONENTS_KIND_VIEW_SETTLEMENT,
  [MerchantWalletTransferKindAPIModel.PushCollect]: I18nFeatureMerchantWalletTransfers.COMPONENTS_KIND_VIEW_PUSHCOLLECT,
  [MerchantWalletTransferKindAPIModel.Payout]: I18nFeatureMerchantWalletTransfers.COMPONENTS_KIND_VIEW_PAYOUT,
  [MerchantWalletTransferKindAPIModel.PartnerFee]: I18nFeatureMerchantWalletTransfers.COMPONENTS_KIND_VIEW_PARTNERFEE,
  [MerchantWalletTransferKindAPIModel.ProcessingFee]:
    I18nFeatureMerchantWalletTransfers.COMPONENTS_KIND_VIEW_PROCESSINGFEE,
};

const MerchantWalletTransferKindViewRaw: React.FC<MerchantWalletTransferKindViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
}) => (value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null);

const MerchantWalletTransferKindView = React.memo(MerchantWalletTransferKindViewRaw);

export default MerchantWalletTransferKindView;
