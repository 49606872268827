import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { FormattedMessage } from '@/components';
import { usePaymentTransaction } from '@/features/payment-transactions/hooks';
import {
  MerchantWalletTransferKindAPIModel,
  type PaymentTransactionSearchIdAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { uuidToBase58 } from '@/infrastructure/utils/uuid';
import { paymentTransactionViewLink } from '@/pages/payments/routes';

import EntityNotFoundAlert from '../EntityNotFoundAlert';

import type { PaymentTransactionEntityLinkProps } from './types';

const PaymentTransactionEntityLink: React.FC<PaymentTransactionEntityLinkProps> = ({
  'data-test': dataTest,
  value,
}) => {
  const id = useMemo<PaymentTransactionSearchIdAPIModel | undefined>(() => {
    const [paymentIdOrHid] = value.entityId?.split('_') ?? [];
    const paymentHid = paymentIdOrHid.includes('-') ? uuidToBase58(paymentIdOrHid) : paymentIdOrHid;
    return paymentHid ? { paymentId: paymentHid, hash: value.txHash } : undefined;
  }, [value.entityId, value.txHash]);
  const data = usePaymentTransaction(id);

  if (data.data.isDirty || data.loading) {
    return <FormattedMessage id={I18nPageAudit.TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_LOADING} />;
  }

  if (data.data.data && id) {
    return <Navigate to={paymentTransactionViewLink(id.paymentId, id.hash)} replace />;
  }

  return (
    <EntityNotFoundAlert
      data-test={dataTest}
      value={MerchantWalletTransferKindAPIModel.PaymentTransaction}
      onRetry={data.forceRefresh}
    />
  );
};

const PaymentTransactionEntityLinkMemo = React.memo(PaymentTransactionEntityLink);

export default PaymentTransactionEntityLinkMemo;
