import { isRejectedByMetaMaskUser } from '@/features/web3/utils';
import type { Func } from '@/infrastructure/utils/ts';

export type Rejected = 'Rejected';

export const rejection: Rejected = 'Rejected';

export type Cancellable = Promise<Rejected>;

export const cancellable = (fn: (reject: () => void) => unknown) =>
  new Promise<Rejected>((resolve) => {
    fn(() => {
      resolve(rejection);
    });
  });

export const isRejected = <T = unknown>(value: T | Rejected): value is Rejected =>
  !!value && typeof value === 'object' && 'message' in value && value.message === rejection;

export const rejected = () => new Error(rejection);

export const withRejectedByUser =
  <V extends unknown[], R>(func: Func<V, R>): Func<V, R> =>
  async (...args: V): Promise<R> => {
    try {
      return await func(...args);
    } catch (error) {
      if (
        (error as Error | undefined)?.message.toLowerCase().includes('user denied')
        || isRejectedByMetaMaskUser(error)
      ) {
        throw rejected();
      }
      throw error;
    }
  };
