/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SignatureComponentsAPIModel } from './SignatureComponentsAPIModel';
import {
    SignatureComponentsAPIModelFromJSON,
    SignatureComponentsAPIModelFromJSONTyped,
    SignatureComponentsAPIModelToJSON,
} from './SignatureComponentsAPIModel';

/**
 * 
 * @export
 * @interface MerchantWalletSignatureAPIModel
 */
export interface MerchantWalletSignatureAPIModel {
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletSignatureAPIModel
     */
    brokerAddress: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletSignatureAPIModel
     */
    bootstrapMessage: string;
    /**
     * 
     * @type {SignatureComponentsAPIModel}
     * @memberof MerchantWalletSignatureAPIModel
     */
    signature: SignatureComponentsAPIModel;
    /**
     * 
     * @type {Date}
     * @memberof MerchantWalletSignatureAPIModel
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the MerchantWalletSignatureAPIModel interface.
 */
export function instanceOfMerchantWalletSignatureAPIModel(value: object): boolean {
    if (!('brokerAddress' in value)) return false;
    if (!('bootstrapMessage' in value)) return false;
    if (!('signature' in value)) return false;
    if (!('createdAt' in value)) return false;
    return true;
}

export function MerchantWalletSignatureAPIModelFromJSON(json: any): MerchantWalletSignatureAPIModel {
    return MerchantWalletSignatureAPIModelFromJSONTyped(json, false);
}

export function MerchantWalletSignatureAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantWalletSignatureAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'brokerAddress': json['brokerAddress'],
        'bootstrapMessage': json['bootstrapMessage'],
        'signature': SignatureComponentsAPIModelFromJSON(json['signature']),
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function MerchantWalletSignatureAPIModelToJSON(value?: MerchantWalletSignatureAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'brokerAddress': value['brokerAddress'],
        'bootstrapMessage': value['bootstrapMessage'],
        'signature': SignatureComponentsAPIModelToJSON(value['signature']),
        'createdAt': ((value['createdAt']).toISOString()),
    };
}

