import { css } from '@emotion/css';
import { Space, theme, Typography } from 'antd';
import React from 'react';
import MediaQuery from 'react-responsive';

import LogoSmallLight from '@/assets/icons/smartypay/logo-small-light';
import { layoutTheme } from '@/layouts/theme';

import type { AuthTitleProps } from './types';

const AuthTitle: React.FC<AuthTitleProps> = ({ 'data-test': dataTest, title }) => {
  const { token } = theme.useToken();
  return (
    <MediaQuery minWidth={token.screenXSMax}>
      <Typography.Title
        className={css`
          align-self: center;

          > div {
            color: ${layoutTheme.colorText};
          }
        `}
      >
        <Space
          data-test={dataTest}
          classNames={{
            item: css`
              display: flex;
              color: black;
            `,
          }}
        >
          <LogoSmallLight height={token.fontSizeHeading1 * token.lineHeightHeading1} />
          {title}
        </Space>
      </Typography.Title>
    </MediaQuery>
  );
};

const AuthTitleMemo = React.memo(AuthTitle);

export default AuthTitleMemo;
