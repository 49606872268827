/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3603
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressAssetHistoryBalanceAPIModel
 */
export interface AddressAssetHistoryBalanceAPIModel {
    /**
     * 
     * @type {string}
     * @memberof AddressAssetHistoryBalanceAPIModel
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof AddressAssetHistoryBalanceAPIModel
     */
    asset: string;
    /**
     * 
     * @type {string}
     * @memberof AddressAssetHistoryBalanceAPIModel
     */
    blockNum: string;
    /**
     * 
     * @type {number}
     * @memberof AddressAssetHistoryBalanceAPIModel
     */
    balance: number;
    /**
     * 
     * @type {boolean}
     * @memberof AddressAssetHistoryBalanceAPIModel
     */
    isValueFromArchive: boolean;
}

/**
 * Check if a given object implements the AddressAssetHistoryBalanceAPIModel interface.
 */
export function instanceOfAddressAssetHistoryBalanceAPIModel(value: object): boolean {
    if (!('address' in value)) return false;
    if (!('asset' in value)) return false;
    if (!('blockNum' in value)) return false;
    if (!('balance' in value)) return false;
    if (!('isValueFromArchive' in value)) return false;
    return true;
}

export function AddressAssetHistoryBalanceAPIModelFromJSON(json: any): AddressAssetHistoryBalanceAPIModel {
    return AddressAssetHistoryBalanceAPIModelFromJSONTyped(json, false);
}

export function AddressAssetHistoryBalanceAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressAssetHistoryBalanceAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'],
        'asset': json['asset'],
        'blockNum': json['blockNum'],
        'balance': json['balance'],
        'isValueFromArchive': json['isValueFromArchive'],
    };
}

export function AddressAssetHistoryBalanceAPIModelToJSON(value?: AddressAssetHistoryBalanceAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'asset': value['asset'],
        'blockNum': value['blockNum'],
        'balance': value['balance'],
        'isValueFromArchive': value['isValueFromArchive'],
    };
}

