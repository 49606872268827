import type { SubscriptionChargeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { AssetAmountValue } from '@/infrastructure/model/api';
import type { SingleState } from '@/infrastructure/model/single/types';

export const NAMESPACE = 'subscription-charges';

export interface SubscriptionCharge extends Omit<SubscriptionChargeAPIModel, 'amount'> {
  amount: AssetAmountValue;
}

export interface SubscriptionChargesState {
  entities: SingleState<SubscriptionCharge>;
}
