import { Space } from 'antd';
import React, { useMemo } from 'react';

import type { LinkMode } from '@/components';
import { FormattedMessage, PageCard } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { DonationsDocumentationLink, DonationTransactionsTable } from '@/features/donations/components';
import { useDonationTransactionListView } from '@/features/donations/hooks';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import { useDimensions } from '@/hooks';

import type { DonationTransactionsCardProps } from './types';
import type { FC } from 'react';

const DonationTransactionsCard: FC<DonationTransactionsCardProps> = (props) => {
  const parameters = useDonationTransactionListView();
  const [ref, dimension] = useDimensions();
  const documentationLinkMode: LinkMode = useMemo(
    () => ((dimension?.width ?? 0) < 300 ? 'icon' : 'text_icon'),
    [dimension?.width],
  );

  const { 'data-test': dataTest } = props;
  return (
    <PageCard
      ref={ref}
      title={
        <Space>
          <FormattedMessage id={I18nPageDonations.TRANSACTIONS_COMPONENTS_TRANSACTIONS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-selectNetwork`} />
        </Space>
      }
      cardSize="big"
      extra={
        <Space>
          <DonationsDocumentationLink
            data-test={dataTest && `${dataTest}-documentationLink`}
            mode={documentationLinkMode}
          />
        </Space>
      }
    >
      <DonationTransactionsTable {...parameters} {...props} />
    </PageCard>
  );
};

const DonationTransactionsCardMemo = React.memo(DonationTransactionsCard);

export default DonationTransactionsCardMemo;
