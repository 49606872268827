import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { extractMerchantWalletRunningBalanceId } from '@/features/merchant-wallet-balance/utils';
import { storeMultipleMerchantWalletTransfer } from '@/features/merchant-wallet-transfers/actions';
import { queryMerchantWalletTransfers } from '@/features/merchant-wallet-transfers/api';
import type {
  MerchantWalletTransfer,
  MerchantWalletTransferFilterPredicate,
} from '@/features/merchant-wallet-transfers/types';
import { extractMerchantWalletTransferId } from '@/features/merchant-wallet-transfers/utils';
import { markReportListDirty, storeReport } from '@/features/reports/actions';
import type {
  MerchantWalletRunningBalanceSortByAPIModel,
  MerchantWalletTransferSortByAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { loadingDataError, mapLoadingState } from '@/infrastructure/model';
import { defaultPageFn, withAPICall, withFetchAllDataOrThrow } from '@/infrastructure/model/api';
import { createNormalizedFullActions } from '@/infrastructure/model/full/actions';
import { loadingSliceStateToFullData } from '@/infrastructure/model/full/utils';
import { createNormalizedListActions } from '@/infrastructure/model/list/actions';
import {
  listStateToSliceRequest,
  mapLoadingSliceStateToListData,
  sliceToMultipleEntities,
} from '@/infrastructure/model/list/utils';
import { toMultiplePayload } from '@/infrastructure/model/single/utils';
import { callWithThunkError } from '@/infrastructure/utils/redux';

import {
  queryMerchantWalletRunningBalances,
  queryMerchantWalletRunningBalance,
  requestExportMerchantWalletRunningBalances,
} from './api';
import {
  makeSelectDirtyMerchantWalletRunningBalanceIds,
  makeSelectMultipleMerchantWalletRunningBalance,
  makeSelectMerchantWalletRunningBalance,
  makeSelectMerchantWalletRunningBalanceListData,
  makeSelectMerchantWalletRunningBalanceListParameters,
  makeSelectTransfersForRunningBalanceFullData,
} from './selectors';
import {
  NAMESPACE,
  type MerchantWalletRunningBalance,
  type MerchantWalletRunningBalanceFilterPredicate,
} from './types';

export const {
  storeMerchantWalletRunningBalance,
  storeMultipleMerchantWalletRunningBalance,
  markMerchantWalletRunningBalanceDirty,
  storeMerchantWalletRunningBalanceListParameters,
  storeMerchantWalletRunningBalanceListData,
  markMerchantWalletRunningBalanceListDirty,
} = createNormalizedListActions<
  MerchantWalletRunningBalance,
  'MerchantWalletRunningBalance',
  MerchantWalletRunningBalanceFilterPredicate,
  MerchantWalletRunningBalanceSortByAPIModel
>(NAMESPACE, 'MerchantWalletRunningBalance' as const);

const transactionFetchLimit = pLimit(1);
export const fetchMerchantWalletRunningBalance = createAppAsyncThunk(
  `${NAMESPACE}/fetchMerchantWalletRunningBalance`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    transactionFetchLimit(async () => {
      const saved = makeSelectMerchantWalletRunningBalance(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryMerchantWalletRunningBalance, 'unable to fetch merchant')(id, { signal });
      dispatch(storeMerchantWalletRunningBalance({ id, data }));

      return makeSelectMerchantWalletRunningBalance(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

const balancesFetchLimit = pLimit(1);
export const fetchMultipleMerchantWalletRunningBalance = createAppAsyncThunk(
  `${NAMESPACE}/fetchMultipleMerchantWalletRunningBalance`,
  async ({ force, ids }: { force?: boolean; ids: string[] }, { dispatch, getState, signal }) =>
    balancesFetchLimit(async () => {
      const absent = makeSelectDirtyMerchantWalletRunningBalanceIds(ids)(getState());
      if (!force && !absent.length) {
        return makeSelectMultipleMerchantWalletRunningBalance(ids)(getState());
      }

      const data = await withAPICall(queryMerchantWalletRunningBalances, 'unable to fetch balance')(
        { filter: { idIn: ids }, page: defaultPageFn({ perPage: ids.length }) },
        { signal },
      );
      dispatch(
        storeMultipleMerchantWalletRunningBalance(
          toMultiplePayload(
            mapLoadingState(data, ({ list }) => list),
            ids,
            extractMerchantWalletRunningBalanceId,
          ),
        ),
      );

      return makeSelectMultipleMerchantWalletRunningBalance(ids)(getState());
    }),
);

const merchantWalletTransfersFetchLimit = pLimit(1);
export const fetchMerchantWalletRunningBalances = createAppAsyncThunk(
  `${NAMESPACE}/fetchMerchantWalletRunningBalances`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    merchantWalletTransfersFetchLimit(async () => {
      const saved = makeSelectMerchantWalletRunningBalanceListData()(getState());
      if (!force && !saved.isDirty && !saved.isTotalDirty) {
        return saved;
      }

      const data = await withAPICall(queryMerchantWalletRunningBalances, 'unable to fetch balance')(
        listStateToSliceRequest({ data: saved, ...makeSelectMerchantWalletRunningBalanceListParameters()(getState()) }),
        { signal },
      );
      dispatch(storeMerchantWalletRunningBalanceListData(mapLoadingSliceStateToListData(saved.data?.total)(data)));

      return makeSelectMerchantWalletRunningBalanceListData()(getState());
    }),
);

export const exportMerchantWalletRunningBalances = createAppAsyncThunk(
  `${NAMESPACE}/exportMerchantWalletRunningBalances`,
  async ({ predicates }: { predicates: MerchantWalletRunningBalanceFilterPredicate }, { dispatch, signal }) => {
    const data = await withAPICall(requestExportMerchantWalletRunningBalances, 'unable to export balance')(predicates, {
      signal,
    });

    if (data.data) {
      dispatch(storeReport({ id: data.data.id, data }));
      dispatch(markReportListDirty());
    }
    return data;
  },
);

export const {
  storeTransfersForRunningBalanceFullData,
  storeTransfersForRunningBalanceFullDataParameters,
  markTransfersForRunningBalanceFullDirty,
} = createNormalizedFullActions<
  MerchantWalletTransfer,
  'TransfersForRunningBalance',
  MerchantWalletTransferFilterPredicate,
  MerchantWalletTransferSortByAPIModel
>(NAMESPACE, 'TransfersForRunningBalance');

const transfersPerReconciliationFetchLimit = pLimit(1);
export const fetchTransfersForRunningBalance = createAppAsyncThunk(
  `${NAMESPACE}/fetchTransfersForRunningBalance`,
  async ({ force, balanceId }: { force?: boolean; balanceId: string }, { dispatch, getState, signal }) =>
    transfersPerReconciliationFetchLimit(async () => {
      const saved = makeSelectTransfersForRunningBalanceFullData(balanceId)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const balance = await callWithThunkError(() =>
        dispatch(fetchMerchantWalletRunningBalance({ id: balanceId, force })).unwrap(),
      );
      const balanceData = balance.data;
      if (!balanceData) {
        dispatch(
          storeTransfersForRunningBalanceFullData({
            parentId: balanceId,
            data: loadingDataError(balance.error ?? 'Unable to load balance'),
          }),
        );
        return makeSelectTransfersForRunningBalanceFullData(balanceId)(getState());
      }

      const data = await withAPICall(
        withFetchAllDataOrThrow(
          (page) => () =>
            queryMerchantWalletTransfers(
              {
                filter: { btIn: [balanceData.blockchain], blockNumIn: [balanceData.blockNum] },
                page,
              },
              { signal },
            ),
        ),
        'unable to fetch balance',
      )();
      dispatch(
        storeTransfersForRunningBalanceFullData({
          parentId: balanceId,
          data: loadingSliceStateToFullData(data),
        }),
      );
      if (data.data) {
        dispatch(
          storeMultipleMerchantWalletTransfer(sliceToMultipleEntities(data.data, extractMerchantWalletTransferId)),
        );
      }

      return makeSelectTransfersForRunningBalanceFullData(balanceId)(getState());
    }),
  { idGenerator: ({ balanceId }) => balanceId },
);
