import { useMemo } from 'react';
import { walletActions } from 'viem';

import { useAppDispatch } from '@/app/hooks';
import { useBlockchainSystemInfo } from '@/features/dictionary/blockchain/hooks';
import { storeMerchantWalletDeployment } from '@/features/merchant-wallets/actions';
import { requestHintWalletDeployed } from '@/features/merchant-wallets/api';
import type { MerchantWalletDeploymentState } from '@/features/merchant-wallets/types';
import { requestDeployMerchantAddress } from '@/features/merchant-wallets/web3-api';
import { useUser } from '@/features/user/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { useSubmitting } from '@/hooks';
import { type HookAction, loadingDataLoaded, mapStoredState } from '@/infrastructure/model';
import { assertNotNil, suppressPromise } from '@/infrastructure/utils/functions';
import { withRejectedByUser } from '@/infrastructure/utils/ui';

import useMerchantWalletDeployment from '../useMerchantWalletDeployment';
import useMerchantWalletOwnership from '../useMerchantWalletOwnership';

import type { Account, Chain, Client, Transport } from 'viem';

export type DeployUnavailabilityReason =
  | 'loading'
  | 'already-deployed'
  | 'no-blockchain-data'
  | 'no-user'
  | 'no-wallet-signature'
  | 'invalid-chain-id'
  | 'unsupported-chain';

export interface UseMerchantWalletActionsType {
  deploy: HookAction<[Client<Transport, Chain, Account>], MerchantWalletDeploymentState, DeployUnavailabilityReason>;
}

export default function useMerchantWalletActions(
  bt: BlockchainTypeAPIModel | undefined,
  chainId?: number,
): UseMerchantWalletActionsType {
  const { dispatch } = useAppDispatch();
  const ownershipState = useMerchantWalletOwnership();
  const deploymentState = useMerchantWalletDeployment(bt);
  const userState = useUser();
  const bcSystemState = useBlockchainSystemInfo(bt);
  const bcState = useMemo(
    () =>
      mapStoredState(bcSystemState.data, (state) => (state.apiType === BlockchainAPITypeAPIModel.WEB3 ? state : null)),
    [bcSystemState.data],
  );
  const merchantWalletFactoryAddress = bcState.data?.merchantWalletFactoryAddress;
  const bcChainId = bcState.data?.chainId;

  const deployUnavailabilityReason: UseMerchantWalletActionsType['deploy']['unavailabilityReason'] = useMemo(() => {
    if (
      bcSystemState.data.isDirty
      || (bcSystemState.loading && !bcSystemState.data.data)
      || deploymentState.data.isDirty
      || (deploymentState.loading && !deploymentState.data.data)
      || ownershipState.data.isDirty
      || (ownershipState.loading && !ownershipState.data.data)
      || userState.data.isDirty
      || (userState.loading && !userState.data.data)
    ) {
      return 'loading';
    }
    if (!bcState.isDirty && !bcSystemState.loading && !!bcSystemState.data.data && !bcState.data?.apiType) {
      return 'unsupported-chain';
    }
    if (deploymentState.data.data?.deployed) {
      return 'already-deployed';
    }
    if (!merchantWalletFactoryAddress || !bcChainId) {
      return 'no-blockchain-data';
    }
    if (!ownershipState.data.data) {
      return 'no-wallet-signature';
    }
    if (!userState.data.data) {
      return 'no-user';
    }
    if (chainId && bcChainId && bcChainId !== chainId) {
      return 'invalid-chain-id';
    }
    return undefined;
  }, [
    bcSystemState.data.isDirty,
    bcSystemState.data.data,
    bcSystemState.loading,
    deploymentState.data.isDirty,
    deploymentState.data.data,
    deploymentState.loading,
    ownershipState.data.isDirty,
    ownershipState.data.data,
    ownershipState.loading,
    userState.data.isDirty,
    userState.data.data,
    userState.loading,
    bcState.isDirty,
    bcState.data?.apiType,
    merchantWalletFactoryAddress,
    bcChainId,
    chainId,
  ]);

  const [deploying, withDeploying] = useSubmitting(false);
  const deployAction: UseMerchantWalletActionsType['deploy']['act'] = useMemo(
    () =>
      withDeploying(async (client): Promise<MerchantWalletDeploymentState> => {
        if (deployUnavailabilityReason) {
          throw new Error(deployUnavailabilityReason);
        }

        const signature = ownershipState.data.data;
        const user = userState.data.data;
        assertNotNil(signature);
        assertNotNil(user);
        assertNotNil(merchantWalletFactoryAddress);
        assertNotNil(bt);
        const walletClient = client.extend(walletActions);
        const address = await withRejectedByUser(requestDeployMerchantAddress)(
          walletClient,
          merchantWalletFactoryAddress,
          user.address,
          signature,
        );
        assertNotNil(address, () => new Error('no address'));

        const data = { bt, address, deployed: true };
        dispatch(storeMerchantWalletDeployment({ id: { address, bt }, data: loadingDataLoaded(data) }));
        suppressPromise(requestHintWalletDeployed(bt));
        return data;
      }),
    [
      withDeploying,
      deployUnavailabilityReason,
      ownershipState.data.data,
      userState.data.data,
      merchantWalletFactoryAddress,
      bt,
      dispatch,
    ],
  );
  const deploy = {
    act: deployAction,
    inAction: deploying,
    unavailabilityReason: deployUnavailabilityReason,
    available: !deployUnavailabilityReason,
  };

  return { deploy };
}
